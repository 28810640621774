import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Row, Col, Modal } from 'react-bootstrap'
import { Td, Tr } from 'react-super-responsive-table'
import Alert from 'sweetalert-react'

import {
  CustomTabs,
  Title,
  Action,
  CustomSummary,
  FAB,
  Dropdown,
  ModalTable,
  Icon,
  FormTextField,
  Button,
  TableV2,
  Card,
  SelectedDates,
  Select,
} from 'src/components'
import {
  faEllipsisV,
  faFileAlt,
  faLink,
  faReceipt,
  faSearch,
  faShoppingBag,
  faTimes,
  faTruck,
} from '@fortawesome/free-solid-svg-icons'

import { selectCurrentModule, selectCurrentUser } from '../../selectors/user.selector'

import {
  actionTypes as typesP,
  getAllPurchases,
  getSummative,
  setStep,
  seeAuto,
  getSinglePurchases,
  exportCSV,
  onCreateSimplePurchase,
  getPurchaseLog,
  seePurchasePayments,
} from '../../actions/purchase.actions'
import {
  selectPurchases,
  selectPurchaseStatus as getStatus,
  selectTotalPurchases,
  selectPurchaseSummative,
  selectPurchasesSingle,
  selectPurchaseLog,
} from '../../selectors/purchase.selector'

import { loadingSelector } from '../../selectors/loading.selector'
import {
  selectCurrentUserRoles,
  isAllowed,
  selectUsers,
} from '../../selectors/modules.selector'

import { actionTypes as typesC } from '../../actions/clients.actions'
import { selectHistoricalLog } from '../../selectors/clients.selector'

import { formatDateFromMillis, formatNumberWithCommas } from '../../utils/formatters'

import CustomReference from '../../components/modal/CustomReference'
import { getUsersChildrenByModule } from '../../actions/modules.actions'
import { faFileCsv } from '@fortawesome/free-solid-svg-icons'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { history } from '../../App'
import {
  handlerError,
  handlerSuccess,
  hasErrorsSelector,
  singleErrorSelector,
} from '../../selectors/error.selector'
import { actionTypes as typeR, sendInvoiceEmail } from '../../actions/resolutions.actions'
import { selectAllProviders } from '../../selectors/producer.selector'
import { selectAllCategorizations } from '../../selectors/categorizations.selector'
import {
  actionTypes as typeC,
  assignCategorizationToMultipleEntities,
  getAllCategorizations,
  getCustomCategorizationsByEntity,
} from '../../actions/categorization.actions'
import ButtonIcon from '../../components/buttons/IconButton'
import Folder, { categoryType } from '../../components/folders/Folder'
import ExpensesAndWaste from '../Reports/ExpensesAndWaste/ExpensesAndWaste'
import CreditNote from 'src/content/CreditNote/purchase/CreditNote'
import { onRefresh, onSetModalPurchase } from '../../actions/utilities.actions'
import SimplePurchase from '../../components/custom/SimplePurchase/SimplePurchase'
import { toMoney } from '../../utils/utilities'
import { selectCurrentCurrency } from '../../selectors/currencies.selector'
import { selectRefresh } from 'src/selectors/utilities.selector'
import { getProviders } from 'src/actions/producer.actions'
import { default as PurchaseTransactions } from 'src/content/Purchase/PurchaseNullify'

const Purchase = () => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(true)

  const [inPurchase, setInPurchase] = useState(true)
  const [alert, setAlert] = useState({ title: '' })
  const [customRef, setCustomRef] = useState({ show: false, documentId: null })
  const [selected, setSelected] = useState([])
  const [select, setSelect] = useState({})
  const [action, setAction] = useState({ update: false, email: false, single: false })
  const [seeEmail, setEmail] = useState({})
  const [showCategorization, setShowCategorization] = useState({
    show: false,
    filter: false,
  })
  const [selectedCategories, setSelectedCategories] = useState([])
  const [show, setShow] = useState(false)
  const [showLog, setShowLog] = useState(false)
  const [detailToSee, setDetailToSee] = useState({})

  const categorization = useSelector(selectAllCategorizations)
  const providers = useSelector(selectAllProviders)
  const module = useSelector(selectCurrentModule)
  const user = useSelector(selectCurrentUser)
  const purchases = useSelector(selectPurchases)
  const summative = useSelector(selectPurchaseSummative)
  const total = useSelector(selectTotalPurchases)
  const roles = useSelector(selectCurrentUserRoles)
  const users = useSelector(selectUsers)
  const auto = useSelector(state => state.purchase.auto)
  const currentCurrency = useSelector(selectCurrentCurrency)
  const historicalLog = useSelector(selectHistoricalLog)
  const single = useSelector(selectPurchasesSingle)
  const refresh = useSelector(selectRefresh)

  const loadingAssigned = useSelector(state =>
    loadingSelector([typeC.GET_ASSIGNED, typeC.GET_ALL])(state),
  )
  const loadingP = useSelector(state => loadingSelector([typesP.GET_ALL_PURCHASE])(state))
  const loadingSingles = useSelector(state =>
    loadingSelector([typesP.GET_PURCHASES_SINGLE])(state),
  )
  const loadingH = useSelector(state =>
    loadingSelector([typesC.GET_HISTORICAL_PAYMENT])(state),
  )
  const loadingHL = useSelector(state =>
    loadingSelector([typesC.GET_HISTORICAL_LOG])(state),
  )

  const log = useSelector(selectPurchaseLog)
  const loadingLog = useSelector(state => loadingSelector([typesP.GET_LOG])(state))

  const loadingLink = useSelector(state => loadingSelector([typesP.LINK_EXPENSE])(state))
  const linkHasError = useSelector(state =>
    hasErrorsSelector([typesP.LINK_EXPENSE])(state),
  )
  const linkError = useSelector(state =>
    singleErrorSelector([typesP.LINK_EXPENSE])(state),
  )

  const seeAllPurchases = useSelector(state => isAllowed(state, [1951, 6051]))
  const createPurchases = useSelector(state => isAllowed(state, [1953, 6053]))
  const createImportPurchases = useSelector(state => isAllowed(state, [1966, 6066]))
  const seeCreditNotesPurchases = useSelector(state => isAllowed(state, [1957, 6057]))
  const seeReferenceDocumentsPurchases = useSelector(state =>
    isAllowed(state, [1967, 6067]),
  )
  const seeMoneyBalancesPurchases = useSelector(state => isAllowed(state, [1973, 6070]))
  const canDownloadReportPurchase = useSelector(state => isAllowed(state, [1974, 6071]))

  const seeAllExpenses = useSelector(state => isAllowed(state, [12402, 12452]))
  const createExpenses = useSelector(state => isAllowed(state, [12403, 12453]))
  const linkExpenses = useSelector(state => isAllowed(state, [12413, 12463]))
  const seeCreditNotesExpenses = useSelector(state => isAllowed(state, [12458, 12408]))
  const seeReferenceDocumentsExpenses = useSelector(state =>
    isAllowed(state, [12412, 12462]),
  )
  const seeMoneyBalancesExpenses = useSelector(state => isAllowed(state, [12417, 12465]))
  const canDownloadReportExpense = useSelector(state => isAllowed(state, [12418, 12466]))

  const canSeeAll = inPurchase ? seeAllPurchases : seeAllExpenses
  const canSeeCreditNotes = inPurchase ? seeCreditNotesPurchases : seeCreditNotesExpenses
  const canCreate = inPurchase ? createPurchases : createExpenses
  const canSeeReferenceDocuments = inPurchase
    ? seeReferenceDocumentsPurchases
    : seeReferenceDocumentsExpenses
  const canSeeMoneyBalances = inPurchase
    ? seeMoneyBalancesPurchases
    : seeMoneyBalancesExpenses
  const canDownloadReport = inPurchase
    ? canDownloadReportPurchase
    : canDownloadReportExpense

  const loadingEmail = useSelector(state =>
    loadingSelector([typeR.SEND_INVOICE_EMAIL])(state),
  )
  const hasErrorEmail = useSelector(state =>
    hasErrorsSelector([typeR.SEND_INVOICE_EMAIL])(state),
  )
  const errorEmail = useSelector(state =>
    singleErrorSelector([typeR.SEND_INVOICE_EMAIL])(state),
  )

  const loadingCreateSimple = useSelector(state =>
    loadingSelector([typesP.CREATE_SIMPLE])(state),
  )
  const hasErrorCreateSimple = useSelector(state =>
    hasErrorsSelector([typesP.CREATE_SIMPLE])(state),
  )
  const errorCreateSimple = useSelector(state =>
    singleErrorSelector([typesP.CREATE_SIMPLE])(state),
  )

  const [textFilter, setTextFilter] = useState('')
  const [filters, setFilters] = useState({
    user: { value: null, label: ' -- Todos --' },
    state: { value: null, label: ' -- Todos --' },
    paymentType: { value: null, label: ' -- Todos --' },
    type: { value: null, label: '-- Todos --' },
    provider: { value: null, label: '-- Todos --' },
    dateType: { value: 1, label: 'Fecha de emisión' },
  })

  const [coreFilters, setCoreFilters] = useState({
    advances: false,
    skip: 0,
    size: 10,
    search: null,
    start: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1,
      0,
      0,
      0,
    ).valueOf(),
    end: new Date().setHours(23, 59, 59).valueOf(),
  })

  useEffect(() => {
    dispatch(getUsersChildrenByModule(module, true))
    const inPurchase = !window.location.href.includes('/gastos')
    dispatch(getAllCategorizations(inPurchase ? 6 : 2))
    setInPurchase(inPurchase)
    dispatch(getProviders())
  }, [])

  useEffect(() => {
    const categories = selectedCategories.map(p => p.id).join(',')
    setFilters({ ...filters, categories })
  }, [selectedCategories])

  useEffect(() => {
    if (!roles || roles.length <= 0) return
    dispatch(setStep(0))
    setTextFilter(JSON.stringify(coreFilters))
  }, [roles])

  useEffect(() => {
    if (textFilter === '' || textFilter === JSON.stringify(coreFilters)) return
    setTextFilter(JSON.stringify(coreFilters))
  }, [coreFilters])

  useEffect(() => {
    if (textFilter !== JSON.stringify(coreFilters)) return
    setUp()
  }, [textFilter])

  useEffect(() => {
    if (!auto.id) return
    dispatch(seeAuto(null))
  }, [auto])

  useEffect(() => {
    if (loadingLink) setAction({ ...action, update: true })
    else if (action.update) {
      setAction({ ...action, update: false })
      if (linkHasError) {
        setAlert({
          ...handlerError(linkError.message || 'Ha ocurrido un error'),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      } else {
        setAlert({
          ...handlerSuccess('Gastos asociados satisfactoriamente'),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
        setSelect({ show: false })
        setSelected([])
        setUp()
      }
    }
  }, [loadingLink])

  useEffect(() => {
    if (loadingEmail) setAction({ ...action, email: true })
    else if (action.email) {
      setAction({ ...action, email: false })
      if (hasErrorEmail)
        setAlert({
          ...handlerError(errorEmail.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else
        setAlert({
          ...handlerSuccess('Correo enviado exitosamente.'),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
          },
        })
    }
  }, [loadingEmail])

  useEffect(() => {
    if (loadingCreateSimple) setAction({ ...action, create: true })
    else if (action.create) {
      setAction({ ...action, create: false })
      if (hasErrorCreateSimple)
        setAlert({
          ...handlerError(errorCreateSimple.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else
        setAlert({
          ...handlerSuccess(
            inPurchase
              ? 'Compra creada satisfactoriamente.'
              : 'Gasto creado satisfactoriamente.',
          ),
          onConfirm: () => {
            setUp()
            setShow(false)
            setAlert({ ...alert, show: false })
          },
        })
    }
  }, [loadingCreateSimple])

  useEffect(() => {
    if (refresh) setUp()
    dispatch(onRefresh(false))
  }, [refresh])

  const setUp = csv => {
    let { state, type, categories, dateType } = filters
    let { skip, size, search, start, end, advances } = coreFilters

    let paymentType = null
    let userId = null
    let providerId

    if (!inPurchase) paymentType = 3
    if (!canSeeAll) userId = user.id

    if (!userId) userId = filters.user.value
    providerId = filters.provider.value

    if (csv) {
      if (!start) start = new Date().valueOf()
      if (!end) end = new Date().valueOf()
    }

    const params = {
      start,
      end,
      paymentType,
      userId,
      providerId,
      state: state.value,
      search,
      categories,
      advances,
      type: type.value,
      skip,
      size,
      dateType: dateType.value,
    }

    if (csv) dispatch(exportCSV(params))
    else {
      dispatch(getAllPurchases(params))

      if (canSeeMoneyBalances)
        dispatch(getSummative({ ...params, state: params.state || 10 }))

      setLoading(false)
    }
  }

  const assignCategorizationToOrder = categorization => {
    const array = []
    //if(selected.length > 0) array = selected.map(s => s.id)
    //else
    array.push(showCategorization.order)
    dispatch(
      assignCategorizationToMultipleEntities(
        null,
        categorization,
        array,
        !!showCategorization.order,
        false,
        inPurchase ? 6 : 2,
      ),
    )
    setTimeout(() => {
      dispatch(
        getCustomCategorizationsByEntity(
          showCategorization.order,
          false,
          inPurchase ? 6 : 2,
        ),
      )
    }, 200)
  }

  const getNames = (param, item) => {
    const data = item[param]
    if (data) return data.name
    return '--'
  }

  const isSelected = item => {
    const select = selected.find(s => item.id === s.id)
    return select !== undefined
  }

  const addOrRemoveSelected = item => {
    if (isSelected(item)) {
      //remove
      setSelected(selected.filter(s => s.id !== item.id))
    } else {
      //add
      selected.push(item)
      setSelected([...selected])
    }
  }

  const headers = inPurchase
    ? [
        {
          label: 'Creado',
          show: true,
          type: 'text',
          value: ['creadoC'],
          custom: item => (item.creadoC = getNames('createdData', item)),
        },
        {
          label: 'Autorizado',
          show: true,
          type: 'text',
          value: ['autorizadoC'],
          custom: item => (item.autorizadoC = getNames('updatedData', item)),
        },
        {
          label: 'Estado',
          show: true,
          type: 'text',
          value: ['estadoC'],
          custom: item => (item.estadoC = getStatus(item.status, !inPurchase).name),
        },
        {
          label: 'Factura',
          show: true,
          type: 'text',
          value: ['invoice'],
          custom: item => item.invoice || 'Sin referencia',
        },
        {
          label: 'No. Serie',
          show: true,
          type: 'text',
          value: ['serie'],
          custom: item => item.serie || 'Desconocido',
        },
        { label: 'NIT', show: true, type: 'text', value: ['nit'] },
        {
          label: 'Proveedor',
          show: true,
          type: 'text',
          value: ['providerName'],
          custom: item => item.providerName || 'Desconocido',
        },
        { label: 'Fecha de emisión', show: true, type: 'date', value: ['emissionAt'] },
        //{ label: 'Fecha de Registro', show: true, type: 'date', value: ['createdAt'] },
        {
          label: 'Fecha de Pago',
          className: 'medium',
          show: true,
          type: 'date',
          value: ['paymentDate'],
        },
        {
          label: `Descuento (${currentCurrency.symbol})`,
          show: true,
          type: 'currency',
          value: ['discount'],
        },
        {
          label: `Total (${currentCurrency.symbol})`,
          show: true,
          type: 'currency',
          value: ['total'],
        },
        {
          label: `Saldo pendiente (${currentCurrency.symbol})`,
          show: true,
          type: 'currency',
          value: ['balance'],
        },
        {
          label: '',
          config: true,
          show: true,
          type: '',
          value: [],
          custom: item => getAction(item),
        },
      ]
    : [
        {
          label: '',
          show: true,
          type: '',
          value: [],
          custom: item =>
            item.linked && <Icon icon={faLink} tooltip={'Asociado a una compra'} />,
          select: true,
        },
        {
          label: 'Solicitado',
          show: true,
          type: 'text',
          value: ['creadoC'],
          custom: item => (item.creadoC = getNames('createdData', item)),
        },
        {
          label: 'Autorizado',
          show: true,
          type: 'text',
          value: ['autorizadoC'],
          custom: item => (item.autorizadoC = getNames('updatedData', item)),
        },
        {
          label: 'Estado',
          show: true,
          type: 'text',
          value: ['estadoC'],
          custom: item => (item.estadoC = getStatus(item.status, !inPurchase).name),
        },
        {
          label: 'Referencia',
          show: true,
          type: 'text',
          value: ['invoice'],
          custom: item => item.invoice || 'Sin referencia',
        },
        {
          label: 'No. Serie',
          show: true,
          type: 'text',
          value: ['serie'],
          custom: item => item.serie || 'Desconocido',
        },
        { label: 'NIT', show: true, type: 'text', value: ['nit'] },
        {
          label: 'Proveedor',
          show: true,
          type: 'text',
          value: ['providerName'],
          custom: item => item.providerName || 'Desconocido',
        },
        { label: 'Fecha de emisión', show: true, type: 'date', value: ['emissionAt'] },
        //{ label: 'Fecha de registro', show: true, type: 'date', value: ['createdAt'] },
        {
          label: 'Fecha de Pago',
          className: 'mini',
          show: true,
          type: 'date',
          value: ['paymentDate'],
        },
        { label: 'Total', show: true, type: 'currency', value: ['total'] },
        { label: 'Saldo Pendiente', show: true, type: 'currency', value: ['balance'] },
        {
          label: '',
          config: true,
          show: true,
          type: '',
          value: [],
          custom: item => getAction(item),
        },
      ]

  const getAction = item => {
    return (
      <Dropdown
        className={'dp-custom'}
        items={[
          {
            title: 'Detalle',
            action: () => {
              dispatch(onSetModalPurchase(item.id))
            },
          },
          {
            title: 'Editar',
            show: (item.status === 1 || item.status === 7) && !item.importation,
            action: () =>
              history.push(`${inPurchase ? 'compras' : 'gastos'}/editar/${item.id}`),
          },
          {
            title: 'Enviar factura',
            show: item.status === 2 && item.certificate,
            action: () => {
              setEmail({ show: true, order: item.id })
            },
          },
          {
            title: 'Ver categorías',
            action: () => {
              dispatch(getAllCategorizations(inPurchase ? 6 : 2))
              dispatch(
                getCustomCategorizationsByEntity(item.id, false, inPurchase ? 6 : 2),
              )
              setShowCategorization({ show: true, filter: false, order: item.id })
            },
          },
          {
            title: 'Ver documentos utilizados',
            show: canSeeReferenceDocuments && item.status !== 1,
            action: () =>
              dispatch(seePurchasePayments({ id: item.id, invoice: item.invoice })),
          },
          {
            title: 'Ver bitácora',
            show: true,
            action: () => {
              dispatch(getPurchaseLog(item.id))
              setShowLog(true)
            },
          },
          {
            title: 'Ver transacciones',
            show: item.status !== 1,
            action: () => setDetailToSee({ show: true, id: item.id }),
          },
        ]}
      />
    )
  }

  const customFilter = () => {
    const { state, user, type, provider, dateType } = filters

    return (
      <div>
        <Row>
          <Col xs={6} sm={6} md={6} lg={6}>
            <Select
              label={'Por estado'}
              value={state}
              info={`Filtra ${inPurchase ? 'las compras' : 'los gastos'} por estado`}
              options={[
                { value: null, label: '- Todos -' },
                { value: 1, label: 'Ingresada' },
                { value: 2, label: 'Pendiente' },
                { value: 3, label: 'Completada' },
                { value: 4, label: 'Cancelada' },
                { value: 5, label: 'Reservada' },
                { value: 6, label: 'Anulada' },
                { value: 7, label: 'Pendiente de Inventariar' },
                { value: 8, label: 'Pendiente de Certificar' },
              ]}
              onChange={value => setFilters({ ...filters, state: value })}
            />
          </Col>
          <Col xs={6} sm={6} md={6} lg={6}>
            <Select
              label={'Por usuario'}
              value={user}
              info={`Filtra ${inPurchase ? 'las compras' : 'los gastos'} por usuario`}
              options={[{ value: null, label: '- Todos -' }, ...users]}
              onChange={value => setFilters({ ...filters, user: value })}
            />
          </Col>
          {inPurchase && (
            <Col xs={6} sm={6} md={6} lg={6}>
              <Select
                label={'Por tipo'}
                value={type}
                info={`Filtra las compras por su tipo`}
                options={[
                  { value: null, label: '- Todos -' },
                  { value: 1, label: 'Propias' },
                  { value: 2, label: 'Importación' },
                ]}
                onChange={value => setFilters({ ...filters, type: value })}
              />
            </Col>
          )}
          <Col xs={6} sm={6} md={6} lg={6}>
            <Select
              label={'Por proveedor'}
              value={provider}
              info={`Filtra ${inPurchase ? 'las compras' : 'los gastos'} por proveedor`}
              options={[{ value: null, label: '- Todos -' }, ...providers]}
              onChange={value => setFilters({ ...filters, provider: value })}
            />
          </Col>
          {!inPurchase && (
            <Col xs={6} sm={6} md={6} lg={6}>
              <Select
                label={'Por tipo de fecha'}
                value={dateType}
                info={`Filtra las órdenes por fecha de ingreso al sistema o fecha de emisión`}
                options={[
                  { value: 1, label: 'Fecha de emisión' },
                  { value: 0, label: 'Fecha de ingreso al sistema' },
                ]}
                onChange={value => setFilters({ ...filters, dateType: value })}
              />
            </Col>
          )}
        </Row>
        <br />
        <Row>
          <Col xl={6} lg={6} sm={12}>
            <Row className={'pl-1'}>
              {selectedCategories.map((p, i) => (
                <div className={'user-tag product-t ml-2'} key={i}>
                  <label className={'label-user-tag'}>{p.name}</label>
                  <ButtonIcon
                    className={'delete-user-tag d-product-t'}
                    icon={faTimes}
                    tooltip={'Quitar'}
                    color={'white'}
                    onClick={() =>
                      setSelectedCategories(selectedCategories.filter(f => f.id !== p.id))
                    }
                  />
                </div>
              ))}
              <Button
                style={{ marginLeft: 15 }}
                color={'primary'}
                onClick={() => {
                  dispatch(getAllCategorizations(inPurchase ? 6 : 2))
                  setShowCategorization({
                    ...showCategorization,
                    show: true,
                    filter: true,
                  })
                }}>
                Filtrar por categorías
              </Button>
            </Row>
          </Col>

          <Col xl={6} lg={6} sm={12}>
            <Button
              style={{ float: 'right' }}
              loading={loading || loadingP}
              color={'accent'}
              icon={faSearch}
              onClick={() => setUp()}>
              Aplicar Filtros
            </Button>
          </Col>
        </Row>

        <hr />
      </div>
    )
  }

  const getTotal = list => {
    let response = 0
    list.forEach(l => {
      response += Number(l.total)
    })
    return response
  }

  /**
   * Tabla de compras
   * @returns {JSX.Element} Tabla de compras
   */
  const getTablePurchases = () => {
    return (
      <div style={{ display: 'contents' }} className={'column'}>
        <Action
          to={'nueva'}
          action={canCreate}
          actionTitle={inPurchase ? 'Crear nueva compra' : 'Crear nuevo gasto'}
        />
        {canSeeMoneyBalances && (
          <CustomSummary
            items={[
              {
                title: `Total de ${inPurchase ? 'Compras' : 'Gastos'}`,
                value: toMoney(summative.summative),
              },
              {
                title: 'Total de cuentas por pagar',
                value: toMoney(summative.payment),
              },
            ]}
          />
        )}
        <TableV2
          headers={headers}
          items={purchases}
          loading={loading || loadingP || loadingH || loadingHL}
          customClass={'scrollX'}
          onClickTr={(e, item) => {
            if (!inPurchase && linkExpenses) addOrRemoveSelected(item)
          }}
          isSelected={item => isSelected(item)}
          mobileAuto
          storageKey={`purchase`}
          customFilter={customFilter()}
          getItems={item => {
            if (item !== null) {
              if (!inPurchase && linkExpenses) addOrRemoveSelected(item.item)
            } else {
              setSelect([])
            }
          }}
          total={total}
          getPagination={(skip, size, search) =>
            setCoreFilters({ ...coreFilters, skip, size, search })
          }
          handleChange={search =>
            setTimeout(() => {
              setCoreFilters({ ...coreFilters, search })
            }, 1000)
          }
          balancePermission={canSeeMoneyBalances}
        />
      </div>
    )
  }

  return (
    <div>
      {inPurchase ? (
        <Title
          title={'Compras'}
          action={createPurchases}
          to={'nueva'}
          actionTitle={'Crear nueva compra'}
        />
      ) : (
        <Title
          title={'Gastos'}
          action={createExpenses}
          to={'nueva'}
          actionTitle={'Crear nuevo gasto'}
        />
      )}

      <Row>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <Card>
            <SelectedDates
              withOptionNull
              dates={{ dateFrom: coreFilters.start, dateTo: coreFilters.end }}
              setValueDates
              onDateChange={(start, end) =>
                setCoreFilters({ ...coreFilters, start, end })
              }
            />
            <CustomTabs
              ctBank
              setKey={key => {
                if (key === '3') {
                  setCoreFilters({ ...coreFilters, advances: true })
                } else if (key === '0') {
                  setCoreFilters({ ...coreFilters, advances: false })
                }
              }}
              items={[
                {
                  title: `Historial de ${inPurchase ? 'compras' : 'Gastos'}`,
                  info: `Registro de todas ${
                    inPurchase ? 'las compras' : 'los gastos'
                  } en las fechas establecidas`,
                  component: getTablePurchases(),
                },

                {
                  title: 'Cuentas por Pagar',
                  info: 'Reporte de los saldos de cuentas por pagar',
                  show: false /*inPurchase ? viewSum : false*/,
                  component: (
                    <div className={'column'}>
                      <TableV2
                        headers={[
                          {
                            label: 'Fecha',
                            show: true,
                            value: ['date'],
                            type: 'date',
                            className: 'mini',
                          },
                          {
                            label: 'Descripción',
                            show: true,
                            value: ['description'],
                            type: 'text',
                            className: 'medium',
                          },
                          {
                            label: 'Cantidad',
                            show: true,
                            value: ['amount'],
                            type: 'currency',
                            className: 'mini',
                          },
                          {
                            label: 'Saldo Anterior',
                            show: true,
                            value: ['prevBalance'],
                            type: 'currency',
                            className: 'medium',
                          },
                          {
                            label: 'Saldo nuevo',
                            show: true,
                            value: ['newBalance'],
                            type: 'currency',
                            className: 'mini',
                          },
                          { config: true, show: true, label: '', className: 'mini' },
                        ]}
                        items={historicalLog.sort((a, b) => b.date - a.date)}
                        loading={loadingP}
                        tableDivStyle={{
                          overflowX: 'scroll',
                          minHeight: '450px',
                          maxHeight: '600px',
                        }}
                        mobileAuto
                        storageKey={`cuentas-por-pagar-1`}
                      />
                    </div>
                  ),
                },
                {
                  show: canSeeCreditNotes,
                  title: 'Gráficas',
                  info: `Información sobre ${inPurchase ? 'los gastos' : 'las compras'}`,
                  component: () => (
                    <ExpensesAndWaste
                      inTab
                      filterDates={coreFilters}
                      inPurchase={inPurchase}
                    />
                  ),
                },
                {
                  show: false, // canSeeCreditNotes,
                  title: 'Notas de crédito',
                  info: 'Listado de notas de crédito',
                  component: () => (
                    <div className={'column'}>
                      <CreditNote
                        inPurchase={inPurchase}
                        setCustomRef={data => setCustomRef(data)}
                      />
                    </div>
                  ),
                },
                {
                  show: !inPurchase,
                  title: 'Adelanto a proveedores',
                  info: 'Listado de todos los adelantos a proveedores',
                  component: () => getTablePurchases(),
                },
              ]}
            />
          </Card>
        </Col>
      </Row>

      <CustomReference
        show={customRef.show}
        documentModule={1}
        documentType={3}
        documentId={customRef.documentId}
        onHide={() => setCustomRef({ ...customRef, show: false })}
      />

      <Modal
        show={seeEmail.show}
        size={'md'}
        centered
        onHide={() => setEmail({ show: false })}>
        {' '}
        <Modal.Header closeButton>
          <Modal.Title> Enviar facturas por correo </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormTextField
            name={'email'}
            label={'Correo a donde se enviaran las facturas'}
            value={seeEmail.email}
            type={'text'}
            onChange={event => setEmail({ ...seeEmail, email: event.target.value })}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={!seeEmail.email}
            onClick={() => {
              dispatch(
                sendInvoiceEmail({ id: seeEmail.order, email: seeEmail.email }, true),
              )
              setEmail({ show: false })
            }}>
            Enviar Correo
          </Button>
        </Modal.Footer>
      </Modal>

      <ModalTable
        show={select.show}
        title={'Asociar Gastos'}
        size={'lg'}
        onHide={() => setSelect({ show: false })}
        subtitle={
          'Al asociar gastos a una compra, aumentará el valor de los productos respecto a los gastos asociados'
        }
        headers={[
          '',
          'Proveedor',
          'Nit',
          'Usuario',
          'Factura',
          `Total (${currentCurrency.symbol})`,
        ]}
        items={single}
        sum={{ title: 'Total', value: getTotal(selected) }}
        loading={loadingSingles}
        button={select.id && 'Asociar gastos'}
        icon={faLink}
        onClick={() => {
          dispatch(
            linkExpenses(
              select.id,
              selected.map(s => s.id),
            ),
          )
        }}
        renderRow={(item, index) => (
          <Tr
            className={`data ${select.id === item.id ? 'active' : ''}`}
            key={`${index}`}
            onClick={() => {
              if (item.id === select.id) setSelect({ show: true })
              else setSelect({ ...item, show: true })
            }}>
            <Td>
              <input type={'radio'} checked={item.id === select.id} />
            </Td>
            <Td className={'medium'}>{item.providerName}</Td>
            <Td className={'medium'}>{item.nit}</Td>
            <Td className={'medium'}>{getNames('createdData', item)}</Td>
            <Td className={'medium'}>{item.invoice || 'Sin Referencia'}</Td>
            <Td className={'medium'}>{formatNumberWithCommas(item.total)}</Td>
          </Tr>
        )}
      />

      <ModalTable
        show={showLog}
        title={'Bitácora'}
        size={'md'}
        onHide={() => setShowLog(false)}
        headers={['Responsable', 'Fecha', 'Descripción']}
        items={log}
        loading={loadingLog}
        renderRow={(item, index) => (
          <tr className={`data ${isSelected(item) ? 'active' : ''}`} key={index}>
            <td>{item.createdName}</td>
            <td className={'medium'}>{formatDateFromMillis(item.createdAt)}</td>
            <td className={'medium'}>{item.description}</td>
          </tr>
        )}
      />

      <Folder
        noMessage
        list={selectedCategories.map(p => p.id)}
        onHide={() => setShowCategorization({ show: false, filter: false, order: null })}
        onAssign={item => {
          if (showCategorization.filter) {
            const d = selectedCategories.find(d => d.id === item.id)
            if (!d) {
              selectedCategories.push(item)
              setSelectedCategories([...selectedCategories])
            }
          } else if (showCategorization.order) assignCategorizationToOrder(item.id)
        }}
        data1={categorization?.children ? categorization.children[0] : {}}
        data2={categorization?.children ? categorization.children[1] : {}}
        show={showCategorization.show}
        type={inPurchase ? categoryType.PURCHASE : categoryType.EXPENSES}
        loading={loadingAssigned}
      />

      <FAB
        show
        items={[
          {
            style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
            text: 'Crear nueva compra',
            icon: faShoppingBag,
            action: () => {
              history.push('compras/nueva')
            },
            show: inPurchase && createPurchases,
          },
          {
            style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
            text: 'Generar orden de compra',
            icon: faFileAlt,
            action: () => {
              history.push('compras/orden')
            },
            show: false, //inPurchase && pCreate,
          },
          {
            style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
            text: 'Crear nueva compra de importación',
            icon: faTruck,
            action: () => {
              history.push('compras/importaciones/nueva')
            },
            show: inPurchase && createImportPurchases,
          },
          {
            style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
            text: 'Descargar reporte',
            icon: faFileCsv,
            show: canDownloadReport,
            action: () => setUp(true),
          },
          {
            style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
            text: inPurchase
              ? 'Crear nueva compra sin items'
              : 'Crear nuevo gasto sin items',
            icon: faReceipt,
            show: true,
            action: () => setShow(true),
          },
          {
            style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
            text: 'Asociar gastos a una compra',
            icon: faLink,
            show:
              selected.length > 0 &&
              selected.filter(d => d.linked).length === 0 &&
              linkExpenses,
            action: () => {
              dispatch(getSinglePurchases({ state: 1, linked: false }))
              setSelect({ show: true })
            },
          },
        ]}
        icon={
          /*((!eLink || selected.length === 0 || selected.filter(d => d.linked).length > 0) && !inPurchase) ? faFileCsv : */ createPurchases &&
          createImportPurchases
            ? faEllipsisV
            : faPlus
        }
        tooltip={
          /*((!eLink || selected.length === 0 || selected.filter(d => d.linked).length > 0) && !inPurchase) ?
            'Descargar reporte en CSV' : */ createPurchases && !createImportPurchases
            ? 'Crear nueva compra'
            : !createPurchases && createImportPurchases
            ? 'Crear nueva compra de importación'
            : ''
        }
      />
      {show && (
        <SimplePurchase
          modal
          purchase={inPurchase}
          amount={null}
          onHide={() => setShow(false)}
          show={show}
          load={loadingCreateSimple}
          updated={() => {
            setShow(false)
            setUp()
          }}
          onSave={request => {
            setAlert({
              show: true,
              title: inPurchase
                ? 'Registrar compra sin producto'
                : 'Registrar gasto sin producto',
              text: `¿Desea registrar ${inPurchase ? 'la compra' : 'el gasto'}?`,
              type: 'info',
              confirmButtonText: 'Registrar',
              confirmButtonColor: '#226095',
              showCancelButton: true,
              cancelButtonText: 'Cancelar',
              onCancel: () => setAlert({ ...alert, show: false }),
              onConfirm: () => {
                setAlert({ ...alert, show: false })
                dispatch(onCreateSimplePurchase(request))
              },
            })
          }}
        />
      )}
      <PurchaseTransactions
        modal
        show={detailToSee.show}
        id={detailToSee.id}
        read
        transactions
        onHide={() => setDetailToSee({ ...detailToSee, show: false })}
      />
      <Alert {...alert} />
    </div>
  )
}

export default Purchase
