import React from 'react'
import { Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Select } from 'src/components'
import Commentary from 'src/components/inputs/Commentary/Commentary'
import { selectFelItemTypes } from 'src/selectors/company.selector'
import { getUniversalItem } from 'src/utils/getUniversalItem'

interface Props {
  item: IItemSelectTable
  updateValues: () => void
  show: boolean
  onHide: () => void
}
export const ItemCommentary = ({ item, updateValues, show, onHide }: Props) => {
  const felItemTypes = useSelector(selectFelItemTypes)

  return (
    <>
      {/* @ts-expect-error Error por tipado JS*/}
      <Commentary
        value={item.commentary}
        currentValue={item.commentary}
        show={show}
        onChange={value => {
          if (value && item.line.length === 0) {
            const unix = getUniversalItem()
            item.line = unix.line
          }
          item.commentary = value
          updateValues()
        }}
        onHide={() => {
          if (!item.commentary || item.commentary === null) item.line = []
          onHide()
        }}
        textCommentary={item.label}
        edit
        max={item.id === 1 ? 100 : 1000}
        label={item.id === 1 && 'Nombre del producto'}
        commentaryLabel={item.id === 1 && 'Nombre del producto'}
        prepend={
          item.id === 1 && (
            <Col xl={12}>
              <Select
                label={'Bien o Servicio'}
                info={
                  'La propiedad "Bien o Servicio" se usará para la factura electrónica'
                }
                required
                value={felItemTypes.find(({ value }) => value === item.felItemTypeId)}
                options={felItemTypes}
                onChange={felItemType => {
                  item.felItemTypeId = felItemType.value
                  updateValues()
                }}
              />
            </Col>
          )
        }
        textarea={item.id !== 1}
        isRequired
      />
    </>
  )
}
