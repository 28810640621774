import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Title, FABV2 } from 'src/components'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import {
  actions,
  getAllTaxes,
  onCreateTax,
  onDeleteTax,
  onUpdateTax,
} from 'src/actions/taxes.actions'

import { companyFieldsFel } from 'src/enums/companyFields'
import { getCompanyField } from 'src/actions/company.actions'
import { selectCompanyFieldById } from 'src/selectors/company.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import { handlerError, handlerSuccess, hasErrors } from 'src/selectors/error.selector'
import { showAlert } from 'src/actions/alert.actions'

import TaxesList from 'src/content/Taxes/TaxesList'
import TaxForm from 'src/content/Taxes/TaxForm'
import TaxToDelete from 'src/content/Taxes/TaxToDelete'
import { isAllowed } from 'src/selectors/modules.selector'
import { taxesPermissions } from 'src/enums/permissions'

const Taxes = () => {
  const dispatch = useDispatch()

  const loadingUpdateOrCreate: boolean = useSelector(state =>
    loadingSelector([actions.UPDATE_TAX_OR_CREATE_TAX])(state),
  )

  const errorUpdateOrCreate = useSelector(state =>
    hasErrors([actions.UPDATE_TAX_OR_CREATE_TAX])(state),
  )

  const loadingToDelete: boolean = useSelector(state =>
    loadingSelector([actions.DELETE_TAX])(state),
  )
  const errorToDelete = useSelector(state => hasErrors([actions.DELETE_TAX])(state))

  const canCreate: boolean = useSelector(state =>
    isAllowed(state, [taxesPermissions.create]),
  )

  const felConfiguration = useSelector(state =>
    selectCompanyFieldById(state, companyFieldsFel.useFEL),
  )

  const useFEL: boolean = felConfiguration?.value === '1'

  const [taxToDelete, setTaxToDelete] = useState<Tax>({})

  const [tax, setTax] = useState<Tax>({})
  const [show, setShow] = useState<boolean>(false)

  const [flag, setFlag] = useState<boolean>(false)
  const [flagDelete, setFlagDelete] = useState<boolean>(false)

  useEffect(() => {
    setUp()
  }, [])

  useEffect(() => {
    if (loadingUpdateOrCreate) setFlag(true)
    else if (flag) {
      setFlag(false)
      const alert = errorUpdateOrCreate
        ? handlerError(errorUpdateOrCreate.message)
        : {
            ...handlerSuccess(),
            onConfirm: () => {
              setShow(false)
              setTax({})
              setUp()
            },
          }
      dispatch(showAlert(alert))
    }
  }, [loadingUpdateOrCreate])

  useEffect(() => {
    if (loadingToDelete) setFlagDelete(true)
    else if (flagDelete) {
      setFlagDelete(false)
      const alert = errorToDelete
        ? handlerError(errorToDelete.message)
        : {
            ...handlerSuccess(),
            onConfirm: () => {
              setTaxToDelete({})
              setUp()
            },
          }
      dispatch(showAlert(alert))
    }
  }, [loadingToDelete])

  const setUp = () => {
    dispatch(getAllTaxes(true))
    dispatch(getCompanyField(companyFieldsFel.useFEL))
  }

  const validateCanAction = (): boolean => {
    if (useFEL) {
      showAlertWarning()
      return false
    }
    return true
  }

  const showAlertWarning = () => {
    dispatch(
      showAlert({
        ...handlerError(
          'No es posible modificar los perfiles de impuestos por que la cuenta está configurada para usar FEL',
        ),
      }),
    )
  }

  return (
    <div>
      <Title title="Administración de impuestos" />
      <TaxesList
        onRefresh={setUp}
        onUpdate={tax => {
          if (validateCanAction()) {
            setTax(tax)
            setShow(true)
          }
        }}
        onDelete={tax => {
          if (validateCanAction()) setTaxToDelete(tax)
        }}
      />

      <TaxForm
        show={show}
        tax={tax}
        onClose={() => {
          setTax({})
          setShow(false)
        }}
        onResponse={tax => {
          const request = {
            name: tax.name,
            rate: tax.rate / 100,
            active: tax.active,
            defaultValue: tax.defaultValue,
          }

          if (tax.id) dispatch(onUpdateTax(tax.id, request))
          else dispatch(onCreateTax(request))
        }}
      />

      <TaxToDelete
        tax={taxToDelete}
        onClose={() => setTaxToDelete({})}
        onDelete={() => dispatch(onDeleteTax(taxToDelete.id))}
      />
      <FABV2
        show={canCreate}
        title={'Crear'}
        icon={faPlus}
        onClick={() => {
          if (validateCanAction()) setShow(true)
        }}
      />
    </div>
  )
}
export default Taxes
