import './Clients.scss'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Td, Tr } from 'react-super-responsive-table'
import { Col, Modal, ModalBody, ModalFooter, ModalTitle, Row } from 'react-bootstrap'
import {
  Card,
  Button,
  Icon,
  Select as CustomSelect,
  ModalTable,
  Checkbox,
  TableV2,
  Tooltip,
} from 'src/components'

import SweetAlert from 'sweetalert-react'
import logo from 'src/assets/images/svg/logo-blue.svg'

import ClientInside from './Map/ClientInsidePolygonCustom'

import {
  faSave,
  faWindowClose,
  faTrash,
  faDownload,
  faArchive,
  faFileArchive,
  faLink,
  faEye,
  faFileAlt,
  faExclamationTriangle,
  faEllipsisV,
  faTag,
  faTimes,
  faMailBulk,
  faPaperPlane,
  faFileExcel,
  faBusinessTime,
  faDrawPolygon,
} from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes,
  getAllClients2 as getAllClients,
  changeStatus,
  setSelectedClients,
  setAllCotizapClients,
  updateMultipleClients,
  onCreateCompanyWithClient,
  searchCotizapClient,
  softDelete,
  loadClientsCSV,
  onChangeDefaulterStatus,
  setClientViewersUsers,
  getArchivedClients,
  onHideClients,
  getClientLog,
  onUpdateDirtyClient,
  getReport,
  createLog,
  mergeClients,
  getSingleClient,
  updateClient,
  actionTypes as typesClients,
  downloadExcel,
  getSimpleLog,
  sendEmail,
  getTransactionClient,
  downloadTransactionsExcel,
  getProductsClient,
  downloadProductsExcel,
  setCategorizationMultiple,
  lockClient,
  unlockClient,
} from 'src/actions/clients.actions'
import {
  selectAllClients,
  selectGenericResponse,
  selectCompany,
  selectReport,
  selectHideResponse,
  selectArchived,
  selectClientLog,
  selectDirtyResponse,
  selectSingleClient,
  selectTotalClients,
  selectClientRequests,
  selectSimpleLog,
  selectTransactions,
  selectProducts,
} from 'src/selectors/clients.selector'

import { actionTypes as moduleTypes } from 'src/actions/modules.actions'
import { selectModuleId, selectCurrentUserRoles } from 'src/selectors/modules.selector'

import {
  selectCurrentUser,
  selectCurrentModule,
  selectCurrentCompany,
} from 'src/selectors/user.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import {
  hasErrorsSelector,
  singleErrorSelector,
  handlerError,
  handlerSuccess,
  hasErrors,
} from 'src/selectors/error.selector'

import {
  actionTypes as orderType,
  onCreateOrderPayment,
  onCreateOrdersVisit,
  onSetPaymentId,
} from 'src/actions/orders.actions'
import { selectOrderPayment } from 'src/selectors/orders.selector'

import { getUsersChildrenByModule } from 'src/actions/modules.actions'
import { selectUsers, getPermission, isAllowed } from 'src/selectors/modules.selector'

import CreateOrderPayment from 'src/content/Orders/OrderPayment/CreateOrderPayment'

import CreateOrderVisit from 'src/content/Orders/OrderVisit/CreateOrderVisit'
import { formatDateFromMillis, getStatusLastDay } from 'src/utils/formatters'

import moment from 'moment'
import ClientIcon from 'src/assets/images/svg/client.svg'
import { selectAllPolygonsBySelect } from 'src/selectors/polygons.selector'
import ClientOrderHistory from './ClientOrderHistory/ClientOrderHistory'
import { faEdit, faSlidersH } from '@fortawesome/free-solid-svg-icons'
import FAB from 'src/components/buttons/FAB'
import DropdownCustom from 'src/components/buttons/Dropdown'
import IconButton from 'src/components/buttons/IconButton'

import { options } from './chartOptions'
import { HorizontalBar } from 'react-chartjs-2'
import ModalLog from 'src/components/modal/ModalLog'
import MapCard from 'src/components/maps/MapCard'
import Geocoding from 'src/components/maps/Geocoding/Geocoding'
import ModalHeader from 'react-bootstrap/ModalHeader'
import SelectedDates from 'src/components/SelectedDates/SelectedDates'
import { Action, CustomDate, FormTextField, Title } from 'src/components'
import { setParams } from 'src/actions/subscriptions.actions'
import Folder, { categoryType } from 'src/components/folders/Folder'
import { selectAllCategorizations } from 'src/selectors/categorizations.selector'
import {
  actionTypes as action,
  assignCategorizationToEntity,
  assignCategorizationToMultipleEntities,
  getAllCategorizations,
  getCategorizationsByEntity,
} from 'src/actions/categorization.actions'
import ButtonIcon from 'src/components/buttons/IconButton'
import ClientViewers from './ClientViewers'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import ReactQuill from 'react-quill'
import { onSetModalOrder, setModalEditClient } from 'src/actions/utilities.actions'
import { toMoney } from 'src/utils/utilities'

import ClientTransactions from './ClientTransactions'
import { selectCurrentCurrency } from 'src/selectors/currencies.selector'
import CSVLoaderV2, { CATEGORY_UPLOAD } from 'src/components/CSVLoader/CSVLoaderV2'
import { selectActivePlans } from 'src/selectors/plans.selector'
import { Country } from 'src/enums/countries'
import { selectCompanyCountry } from 'src/selectors/company.selector'
import { identificationTypesEnum } from 'src/enums/identificationTypes'
import { clientPermissions } from 'src/enums/permissions'
import { showAlert } from 'src/actions/alert.actions'

const lat = 14.589465440976774
const lon = -90.51898866891861

const csvCategories = {
  [Country.GT]: CATEGORY_UPLOAD.CLIENTS,
  [Country.SV]: CATEGORY_UPLOAD.CLIENTS_SV,
  [Country.HN]: CATEGORY_UPLOAD.CLIENTS_HN,
  [Country.UNI]: CATEGORY_UPLOAD.CLIENTS_HN,
}

class Clients extends Component {
  state = {
    selectedClients: [],
    newSelectedClients: [],
    updateMessageString: '',
    clientPayment: {},
    withCpc: true,
    withoutCpc: true,
    withSeller: false,
    withDeliverer: false,
    vendor: { value: 0, label: 'sin filtrar' },
    deliverer: { value: 0, label: 'sin filtrar' },
    updateSeller: false,
    updateDeliverer: false,
    updateVisitDay: false,
    updateCallDay: false,
    modalPayment: false,
    createVisits: false,
    defaulter: true,
    withoutDefaulter: true,
    paymentAlert: {
      show: false,
      title: 'Default',
      text: 'default',
      type: 'info',
    },
    modal: { show: false, item: null, company: {} },
    errors: {},
    createdBy: { value: null, label: 'sin filtrar' },
    start: null,
    end: null,
    fromDate: false,
    code: '',
    active: true,
    inactive: true,
    selected: {},
    showHistory: false,
    showDelete: false,
    showClient: false,
    viewers: { show: false, request: {} },
    polygon: { value: null, label: 'Sin seleccionar' },
    inPolygon: { show: false, clients: null },
    archiveModal: false,
    hideType: null,
    showLog: false,
    showCommentary: false,
    showMap: false,
    clientId: 0,
    archivedClients: [],
    ids: [],
    action: { see: false, id: null, type: null },
    merge: false,
    sort: false,
    showFilter: false,
    modalLog: { id: null, show: false },
    newlog: false,
    showLocation: false,
    average: false,
    filters: {
      skip: 0,
      size: 10,
      search: '',
      dirty: { value: null, label: '-- Todos --' },
      attendant: { value: null, label: '-- Todos --' },
      state: { value: null, label: '-- Todos --' },
      cpc: { value: null, label: '-- Todos --' },
      sort: { value: 1, label: 'Nombre (A-Z)' },
      user: { value: null, label: '-- Todos --' },
      visit: { value: null, label: '-- Todos --' },
      log: { value: null, label: '-- Todos --' },
    },
    all: false,
    showCategorizations: false,
    selectedCategorization: [],
    clientViewers: { show: false, id: null },
    createEmail: { show: false },
    modalTransactions: { show: false },
    filtersTransactions: {
      start: null,
      end: null,
      skip: 0,
      size: 10,
      search: null,
      transactionType: null,
    },
    excelTransaction: { show: false, start: null, end: null },
    showProducts: { show: false, client: null },
    setUpCall: false,
  }

  componentDidMount() {
    const { getUsers, modules } = this.props
    getUsers(modules)
    this.props.getAllCategorization()
    this.setUp()
  }

  UNSAFE_componentWillReceiveProps(next) {
    const {
      orderPayment,
      loadingPayment,
      deleteIsLoading,
      deleteHasError,
      statusLoading,
      statusHasError,
      reportIsLoading,
      addIsLoading,
      addHasError,
      excelHasError,
    } = this.props
    const {
      loadingClient,
      loadingUpdate,
      updateHasError,
      loadingMerge,
      mergeHasError,
      sendingEmail,
      loadingET,
      allClients,
    } = this.props
    const { statusError } = this.state

    const { addError, errorPayment } = next

    if (loadingET && !next.loadingET) {
      let { paymentAlert } = this.state
      if (!excelHasError && next.P) {
        paymentAlert = handlerError('No se ha podido generar el Excel')
        paymentAlert.onConfirm = () => {
          this.setState({ paymentAlert: { ...paymentAlert, show: false } })
        }
      } else {
        paymentAlert = handlerSuccess('Excel generado con éxito')
        paymentAlert.onConfirm = () => {
          this.setState({
            paymentAlert: { ...paymentAlert, show: false },
            all: false,
            selectedClients: [],
            excelTransaction: { show: false, start: null, end: null },
          })
        }
        this.setState({ paymentAlert })
      }
    }

    if (sendingEmail && !next.sendingEmail) {
      let { paymentAlert } = this.state
      paymentAlert = handlerSuccess('Correos enviados')
      paymentAlert.onConfirm = () => {
        this.setState({
          paymentAlert: { ...paymentAlert, show: false },
          all: false,
          selectedClients: [],
          createEmail: { show: false },
        })
      }
      this.setState({ paymentAlert })
    }

    if (addIsLoading && !next.addIsLoading) {
      let { paymentAlert } = this.state
      if (!addHasError && next.addHasError) {
        paymentAlert = handlerError(
          addError.message || 'ha ocurrido un error inesperado.',
        )
        paymentAlert.onConfirm = () => {
          this.setState({ paymentAlert: { ...paymentAlert, show: false } })
        }
      } else {
        paymentAlert = handlerSuccess('Categorías actualizadas')
        paymentAlert.onConfirm = () => {
          this.setState({ paymentAlert: { ...paymentAlert, show: false } })
        }
      }
      this.setState({ paymentAlert })
    }

    if (loadingClient && !next.loadingClient) {
      if (this.state.selected.id) this.setState({ showLocation: true })
    }

    if (loadingUpdate && !next.loadingUpdate) {
      if (this.state.selected.id) {
        let { paymentAlert } = this.state
        if (!updateHasError && next.updateHasError) {
          paymentAlert = this.handlerError('Ha ocurrido un error inesperado.')
        } else {
          paymentAlert = this.handlerSuccess('Ubicación actualizada')
        }
        paymentAlert.onConfirm = () => {
          this.setState({ paymentAlert: { ...paymentAlert, show: false }, selected: {} })
        }
        this.setState({ paymentAlert })
      }
      this.setState({ selectedClients: [] })
      this.setUp()
    }

    if (loadingMerge && !next.loadingMerge) {
      let { paymentAlert } = this.state
      if (!mergeHasError && next.mergeHasError) {
        paymentAlert = this.handlerError('Ha ocurrido un error inesperado.')
      } else {
        paymentAlert = this.handlerSuccess('Cliente unificado')
      }
      paymentAlert.onConfirm = () => {
        this.setState({
          paymentAlert: { ...paymentAlert, show: false },
          selectedClients: [],
          merge: false,
        })
        this.setUp()
      }
      this.setState({ paymentAlert })
    }

    if (statusLoading && !next.statusLoading) {
      let { paymentAlert } = this.state
      if (!statusHasError && next.statusHasError) {
        paymentAlert = this.handlerError(
          statusError.response
            ? statusError.response.data.message
            : 'Ha ocurrido un error inesperado.',
        )
      } else {
        paymentAlert = this.handlerSuccess('Estado del cliente actualizado')
        paymentAlert.onConfirm = () => {
          this.setUp()
          this.setState({ paymentAlert: { ...paymentAlert, show: false } })
        }
      }
      this.setState({ paymentAlert })
    }

    if (loadingPayment && !next.loadingPayment) {
      let { modalPayment, paymentAlert } = this.state
      if (errorPayment) {
        paymentAlert = handlerError(errorPayment.message)
      } else {
        modalPayment = false
        const data = next.orderPayment
        paymentAlert = handlerSuccess(
          `La orden de pago ${data?.number} fue creada satisfactoriamente`,
        )
        this.setUp()
      }
      this.setState({ modalPayment, paymentAlert })
    }

    const { loadingVisit, visitsHasError } = this.props
    if (loadingVisit && !next.loadingVisit) {
      let { paymentAlert } = this.state
      if (!visitsHasError && next.visitsHasError) {
        paymentAlert = this.handlerError(
          next.visitsHasError.message || 'Ha ocurrido un error inesperado',
        )
      } else {
        paymentAlert = this.handlerSuccess('Visitas programadas satisfactoriamente')
        this.setState({
          all: false,
          selectedClients: [],
        })
      }
      this.setState({ paymentAlert, createVisits: false })
    }

    const { errorC } = next
    let { paymentAlert } = this.state

    if (allClients.length === 0 && !this.state.setUpCall) {
      this.setState({ setUpCall: true })
      setTimeout(() => {
        this.setUp()
      }, 100)
    }

    if (deleteIsLoading && !next.deleteIsLoading) {
      if (!deleteHasError && next.deleteHasError) {
        paymentAlert = handlerError(errorC.message || 'Ha ocurrido un error inesperado')
        paymentAlert.onConfirm = () =>
          this.setState({ paymentAlert: { ...paymentAlert, show: false } })
      } else {
        paymentAlert = handlerSuccess('Usuario Eliminado')
        paymentAlert.onConfirm = () =>
          this.setState({ paymentAlert: { ...paymentAlert, show: false } })
        this.setUp()
      }
      this.setState({ paymentAlert })
    }

    if (reportIsLoading && !next.reportIsLoading) {
      this.setState({ showClient: true })
    }

    const { loadingD, hasErrorD } = this.props
    const { errorD } = next
    if (loadingD && !next.loadingD) {
      if (!hasErrorD && next.hasErrorD) paymentAlert = handlerError(errorD.message)
      else
        paymentAlert = handlerSuccess('El estado del cliente cambio satisfactoriamente')
      paymentAlert.onConfirm = () =>
        this.setState({ paymentAlert: { ...paymentAlert, show: false } })
      this.setState({ paymentAlert })
    }

    const { vLoading, vHasError } = this.props
    const { vError } = next
    if (vLoading && !next.vLoading) {
      if (!vHasError && next.vHasError) {
        paymentAlert = handlerError(vError.message || 'Ha ocurrido un error inesperado')
        paymentAlert.onConfirm = () =>
          this.setState({ paymentAlert: { ...paymentAlert, show: false } })
      } else {
        paymentAlert = handlerSuccess('Clientes asignados')
        paymentAlert.onConfirm = () =>
          this.setState(
            {
              paymentAlert: {
                ...paymentAlert,
                show: false,
              },
            },
            () => {
              this.setState({ viewers: { ...this.state.viewers, show: false } })
              this.setUp()
            },
          )
      }
      this.setState({ paymentAlert })
    }

    const { loadingH, hasErrorH, getArchivedClients } = this.props
    const { responseH, errorH } = next
    const { hideType } = this.state
    if (loadingH && !next.loadingH) {
      if (!hasErrorH && next.hasErrorH) paymentAlert = { ...handlerError(errorH.message) }
      else {
        if (hideType !== 3) this.setUp()
        getArchivedClients()
        paymentAlert = { ...handlerSuccess(responseH) }
      }
      paymentAlert.onConfirm = () =>
        this.setState({ paymentAlert: { ...paymentAlert, show: false } })
      this.setState({ paymentAlert, hideType: null })
    }

    const { dirtyLoading, dirtyHasError } = this.props
    const { dirtyResponse, dirtyError } = next
    if (dirtyLoading && !next.dirtyLoading) {
      if (!dirtyHasError && next.dirtyHasError) {
        paymentAlert = handlerError(dirtyError.message)
        paymentAlert.onConfirm = () =>
          this.setState({ paymentAlert: { ...paymentAlert, show: false } })
      } else {
        paymentAlert = handlerSuccess(dirtyResponse)
        paymentAlert.onConfirm = () =>
          this.setState({ paymentAlert: { ...paymentAlert, show: false }, ids: [] }, () =>
            this.setUp(),
          )
      }
      this.setState({ paymentAlert })
    }

    const {
      lockClientLoading,
      lockClientHasError,
      unlockClientLoading,
      unlockClientHasError,
      showAlert,
    } = this.props
    const { lockClientError, unlockClientError } = next

    if (lockClientLoading && !next.lockClientLoading) {
      if (!lockClientHasError && next.lockClientHasError) {
        showAlert({
          ...handlerError(lockClientError.message),
        })
      } else {
        showAlert({
          ...handlerSuccess('Cliente bloqueado con éxito'),
          onConfirm: () => this.setUp(),
        })
      }
    }
    if (unlockClientLoading && !next.unlockClientLoading) {
      if (!unlockClientHasError && next.unlockClientHasError) {
        showAlert({
          ...handlerError(unlockClientError.message),
        })
      } else {
        showAlert({
          ...handlerSuccess('Cliente desbloqueado con éxito'),
          onConfirm: () => this.setUp(),
        })
      }
    }
  }

  handlerError = message => {
    return { show: true, title: 'Uh-Oh..', text: message, type: 'warning' }
  }

  handlerSuccess = message => {
    return { show: true, title: 'Yeah!', text: message, type: 'success' }
  }

  saveMultiple = () => {
    let {
      updateSeller,
      updateDeliverer,
      updateCallDay,
      updateVisitDay,
      selectedClients,
      seller,
      deliverer,
      callDay,
      visitDay,
    } = this.state

    let request = {}
    request.updateSeller = updateSeller
    request.updateDeliverer = updateDeliverer
    request.updateVisitDay = updateVisitDay
    request.updateCallDay = updateCallDay
    request.clientList = selectedClients.map(item => item.id)
    if (updateSeller) request.seller = seller.value
    if (updateDeliverer) request.deliverer = deliverer.value
    if (updateVisitDay) request.visitDay = visitDay.value
    if (updateCallDay) request.callDay = callDay.value
    this.setState({ showConfirmMultiple: false })
    this.setState({ showSuccessMultiple: true })
    return this.props.updateMassive(request)
  }

  rowClicked = (e, data) => {
    const { allClients } = this.props
    let { selectedClients } = this.state
    let select = false
    if (e !== null) if (!e.target.type && !e.target.href) select = true
    if (select) {
      for (let i = 0, len = allClients.length; i < len; i++) {
        if (allClients[i].id === data.id) {
          allClients[i].selected = !allClients[i].selected
          let found = false
          for (let j = 0, len2 = selectedClients.length; j < len2 && !found; j++) {
            if (selectedClients[j].id === data.id) {
              selectedClients.splice(j, 1)
              found = true
            }
          }
          if (!found) {
            selectedClients.push(data)
          }
        }
      }
      this.setState({ selectedClients })
      this.props.setSelectedClients(selectedClients)
    }
  }

  isSelected = item => {
    const { selectedClients } = this.state
    return selectedClients.findIndex(i => item.id === i.id) >= 0
  }

  goTo = (route, newPage) => {
    const { match } = this.props
    this.props.history.push((newPage ? '' : match.url) + route)
  }

  paymentItem = client => {
    if (client.balance <= 0) {
      let { paymentAlert } = this.state
      paymentAlert.show = true
      paymentAlert.title = 'Sin saldo pendiente'
      paymentAlert.text = `El cliente: ${client.companyName} no tiene saldo pendiente`
      paymentAlert.type = 'info'
      this.setState({ paymentAlert })
    } else {
      this.setState({ modalPayment: true, clientPayment: client })
    }
  }

  lockClient = id => {
    const { lockClient, showAlert } = this.props

    showAlert({
      title: '¿Está seguro que desea bloquear este cliente?',
      text: 'Razón de bloqueo',
      type: 'input',
      inputPlaceholder: 'El cliente no ha pagado su deuda',
      onConfirm: message => {
        lockClient(id, message)
      },
      confirmButtonText: 'Bloquear',
      confirmButtonColor: '#22B573',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
    })
  }

  unlockClient = id => {
    const { unlockClient, showAlert } = this.props

    showAlert({
      title: 'Desbloquear cliente',
      text: '¿Está seguro que desea desbloquear este cliente?',
      type: 'warning',
      showCancelButton: true,
      onConfirm: () => unlockClient(id),
      confirmButtonText: 'Desbloquear',
      confirmButtonColor: '#22B573',
    })
  }

  validateClients = (clients, text, single, status) => {
    const { all } = this.state
    if (all && !single) clients = [{ id: -1 }, ...clients]
    this.setState({
      paymentAlert: {
        title: 'Validación de información',
        text,
        type: 'input',
        inputPlaceholder: 'Agrega un comentario',
        show: true,
        showCancelButton: true,
        onCancel: () =>
          this.setState({ paymentAlert: { ...this.state.paymentAlert, show: false } }),
        onConfirm: description => {
          if (description) {
            this.setState(
              { paymentAlert: { ...this.state.paymentAlert, show: false } },
              () => {
                const ids = clients.map(c => c.id)
                this.setState({ ids }, () =>
                  this.props.onUpdateDirtyClient(ids, {
                    commentary: description,
                    status,
                  }),
                )
              },
            )
          }
        },
      },
    })
    this.setState({ selectedClients: [], all: false })
  }

  getStatus = visit => {
    if (visit === 1) return 'Exitosa'
    if (visit === 2) return 'No Exitosa'
    if (visit === 3) return 'Incumplida'
    else return 'Sin registro'
  }

  handleCheckBoxChange = event => {
    const { name, checked } = event.target
    this.setState({ [name]: checked })
  }

  onCreateOrderPayment = payment => {
    this.props.onCreateOrderPayment(payment)
  }

  validateStartOrders = () => {
    const { selectedClients, paymentAlert, all } = this.state
    if (!all && selectedClients.length <= 0) {
      paymentAlert.show = true
      paymentAlert.title = 'Uh-Oh'
      paymentAlert.text =
        'Es necesario seleccionar a los clientes para programar una visita.'
      paymentAlert.type = 'warning'
      this.setState({ paymentAlert })
      return null
    } else this.setState({ createVisits: true })
  }

  validate = () => {
    const { item, company } = this.state.modal
    let errors = {}

    if (!company.name) errors.name = 'El campo es requerido'
    if (!company.address) errors.address = 'El campo es requerido'
    if (!company.nit) errors.nit = 'El campo es requerido'
    if (!company.email) errors.email = 'El campo es requerido'

    if (Object.keys(errors).length === 0) {
      company.isClient = true
      company.isDistributor = false
      company.isProducer = true
      company.isPromotor = false

      company.client = Object.assign({}, { ...item, creationClient: item.id })

      this.props.onCreateCompanyWithClient(company)
    }
    this.setState({ errors })
  }

  getAllClients = clients => {
    return clients.map(c => {
      let lastOrderDateF = '-'
      c.status = { value: 0, label: 'Inactivo' }
      if (c.lastOrderDate) {
        lastOrderDateF = formatDateFromMillis(c.lastOrderDate)
        if (getStatusLastDay(c.lastOrderDate, 8)) c.status = { value: 1, label: 'Activo' }
      }
      c.lastOrderDateF = lastOrderDateF
      return c
    })
  }

  setUp = (transactions, excel, visitRequest, categorizationRequest, getParams) => {
    const {
      getAllClients,
      getExcelTransactions,
      downloadExcel,
      onCreateOrdersVisit,
      setCategorizationMultiple,
    } = this.props
    const { filters, start, end, selectedCategorization, inPolygon, selectedClients } =
      this.state
    let sDate = null,
      eDate = null
    if (start && end) {
      sDate = moment(start).hour(0).minute(0).second(0).valueOf()
      eDate = moment(end).hour(23).minute(59).second(59).valueOf()
    }
    let categorization = null
    selectedCategorization.forEach(s => {
      if (!categorization) categorization = ''
      categorization += s.id + ','
    })
    const params = {
      attendant: filters.attendant.value,
      stateId: filters.state.value,
      dirty: filters.dirty.value,
      cpc: filters.cpc.value,
      sort: filters.sort.value,
      skip: filters.skip,
      size: filters.size,
      search: filters.search,
      start: sDate,
      end: eDate,
      userId: filters.user.value,
      visit: filters.visit.value,
      categorization: categorization
        ? categorization.substring(0, categorization.length - 1)
        : null,
      log: filters.log.value,
    }

    if (getParams) return params
    else if (categorizationRequest)
      setCategorizationMultiple(
        {
          clientList: [-1],
          categories: [categorizationRequest.categorization],
          clientsId: inPolygon.clients,
        },
        { ...params, remove: categorizationRequest.remove },
      )
    else if (visitRequest)
      onCreateOrdersVisit({ ...visitRequest, clientsId: inPolygon.clients }, params)
    else if (excel)
      downloadExcel({
        ...params,
        skip: null,
        size: null,
        clientsId:
          selectedClients.length > 0
            ? selectedClients.map(c => c.id).join(',')
            : undefined,
      })
    else if (transactions)
      getExcelTransactions(inPolygon.clients, {
        ...params,
        ...transactions,
      })
    else getAllClients(params, inPolygon.clients || undefined)
  }

  getData = data => {
    let { selected } = this.state
    selected.address = data.address
    selected.latitude = data.latLng.lat
    selected.longitude = data.latLng.lng
    this.setState({ selected: { ...selected, pending: true } })
  }

  handleViewers = event => {
    const { viewers } = this.state
    const { request } = viewers
    const { name, checked } = event.target

    this.setState({ viewers: { ...viewers, request: { ...request, [name]: checked } } })
  }

  setView = () => {
    const { selectedClients, paymentAlert, all } = this.state
    if (!all && selectedClients.length <= 0) {
      paymentAlert.show = true
      paymentAlert.title = 'Uh-Oh'
      paymentAlert.text = 'Es necesario seleccionar a los clientes para la asignación.'
      paymentAlert.type = 'warning'
      this.setState({ paymentAlert })
    } else this.setState({ viewers: { show: true, request: {} } })
  }

  getUserSelected = item => {
    const { viewers } = this.state
    let { users } = viewers.request
    if (!users) users = []

    const find = users.find(i => i === item.id)
    return find !== undefined
  }

  setUsers = item => {
    let { viewers } = this.state
    let { users } = viewers.request
    if (!users) users = []

    if (this.getUserSelected(item)) {
      users = users.filter(u => u !== item.id)
    } else {
      users.push(item.id)
    }

    viewers.request.users = users
    this.setState({ viewers })
  }

  saveViewers = () => {
    const { setClientViewersUsers } = this.props
    const { viewers, selectedClients, paymentAlert, all, inPolygon } = this.state
    let request = viewers.request
    if (all) request.clients = [-1, ...selectedClients.map(client => client.id)]
    else request.clients = selectedClients.map(c => c.id)
    request.type = 1
    request.clientsId = inPolygon.clients

    paymentAlert.show = true
    paymentAlert.title = 'Asignación'
    paymentAlert.text =
      '¿Desea asignar a los usuarios seleccionados como visualizadores de los clientes seleccionados?'
    paymentAlert.type = 'warning'
    paymentAlert.showCancelButton = true
    paymentAlert.onCancel = () =>
      this.setState({ paymentAlert: { ...paymentAlert, show: false } })
    paymentAlert.onConfirm = () =>
      this.setState({ paymentAlert: { ...paymentAlert, show: false } }, () => {
        setClientViewersUsers(request, { ...this.setUp(null, false, null, null, true) })
      })
    this.setState({ paymentAlert, selectedClients: [], all: false })
  }

  getID = clients => {
    return clients.map(c => c.id)
  }

  hideAction = (type, message, clients) => {
    const { onHideClients } = this.props
    let { paymentAlert, all } = this.state

    if (all && type === 2) clients = [-1, ...clients]

    if (clients.length <= 0)
      paymentAlert = {
        ...handlerError('Es necesario seleccionar uno o mas clientes'),
        onConfirm: () =>
          this.setState({ paymentAlert: { ...paymentAlert, show: false } }),
      }
    else
      paymentAlert = {
        ...handlerError(message, true),
        showCancelButton: true,
        onCancel: () => this.setState({ paymentAlert: { ...paymentAlert, show: false } }),
        onConfirm: () =>
          this.setState({ paymentAlert: { ...paymentAlert, show: false } }, () =>
            onHideClients(type, clients),
          ),
      }

    this.setState({ paymentAlert, hideType: type, selectedClients: [], all: false })
  }

  getButtonAction = () => {
    const { requests, isAllowed, currentCompany, setParams } = this.props
    const { inPolygon } = this.state

    const isAdmin = isAllowed([1001, 2001])

    const canArchive = isAllowed([clientPermissions.archive])

    return (
      <Row className={'button-container'}>
        {currentCompany === 1 && (
          <Icon
            tooltip={'Ver todas las suscripciones'}
            style={{ marginRight: 10 }}
            icon={faBusinessTime}
            onClick={() => {
              setParams({
                id: null,
                type: currentCompany === 1 ? 2 : 1,
                name: 'Todas las suscripciones',
              })
              this.goTo(`/suscripciones/detalle`)
            }}
          />
        )}

        {isAdmin && (
          <Icon
            tooltip={
              requests && requests.filter(f => f.status === 1).length > 0
                ? 'Solicitudes de edición pendientes'
                : 'Solicitudes de edición'
            }
            style={{ marginRight: 10 }}
            icon={faFileAlt}
            onClick={() => {
              this.props.history.push('clientes/solicitudes')
            }}
            color={
              requests && requests.filter(f => f.status === 1).length > 0
                ? 'red'
                : 'black'
            }
          />
        )}

        {canArchive && (
          <Icon
            tooltip={'Archivados'}
            icon={faArchive}
            style={{ marginRight: 10 }}
            onClick={() =>
              this.setState({ archiveModal: true }, () => this.props.getArchivedClients())
            }
          />
        )}
        <Icon
          tooltip={'Ver clientes dentro de un polígono'}
          icon={faDrawPolygon}
          style={{ marginRight: 10 }}
          onClick={() => this.setState({ inPolygon: { ...inPolygon, show: true } })}
        />

        {isAdmin && (
          <Icon
            tooltip={'Exportar'}
            icon={faDownload}
            onClick={() => this.setUp(null, true)}
          />
        )}
      </Row>
    )
  }

  isSelectedClient = item => {
    const { archivedClients } = this.state
    let selected = archivedClients.filter(o => o.id === item.id).length
    return selected > 0
  }

  onSelectItem = (e, item) => {
    let select = false
    if (!e.target.type && !e.target.href) select = true
    if (select) {
      const { archivedClients } = this.state
      const index = archivedClients.findIndex(i => i.id === item.id)
      if (index === -1) archivedClients.push(item)
      else archivedClients.splice(index, 1)
      this.setState({ archivedClients })
    }
  }

  mergeClients = selected => {
    let list = selected.filter(s => !s.checked).map(s => s.id)
    let pivot = selected.filter(s => s.checked).map(s => s.id)[0]
    this.props.mergeClients(pivot, list)
  }

  renderButtons = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}>
        <IconButton
          icon={faSlidersH}
          color={'#3570A3'}
          size={'3x'}
          onClick={() => this.setState({ showFilter: true })}
        />
      </div>
    )
  }

  customFilter = () => {
    const { users, isAllowed, country } = this.props
    const { filters, selectedCategorization, inPolygon } = this.state

    const canViewAll = isAllowed([clientPermissions.viewAll])

    return (
      <div>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <SelectedDates
              withOptionNull
              onDateChange={(start, end) => this.setState({ start, end }, this.setUp)}
            />
          </Col>
        </Row>
        <Row>
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <CustomSelect
              label={'Ordenar por'}
              isSearchable={false}
              value={filters.sort}
              options={[
                { value: 1, label: 'Nombre (A-Z)' },
                { value: 2, label: 'Nombre (Z-A)' },
                { value: 3, label: 'Tickets (Ascendente)' },
                { value: 4, label: 'Tickets (Descendente)' },
                { value: 5, label: 'Compra mas antigua' },
                { value: 6, label: 'Compra mas reciente' },
                { value: 7, label: 'Registro de log más antiguo' },
                { value: 8, label: 'Registro de log más reciente' },
                { value: 9, label: 'Fecha de creación más antigua' },
                { value: 10, label: 'Fecha de creación más reciente' },
              ]}
              onChange={value =>
                this.setState({ filters: { ...filters, sort: value } }, this.setUp)
              }
              info={'Ordena los clientes según el criterio seleccionado'}
            />
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <CustomSelect
              label={'Por log del cliente'}
              isSearchable={false}
              value={filters.log}
              options={[
                { value: null, label: '- Todos -' },
                { value: 0, label: 'Con registros' },
                { value: 1, label: 'Sin registros' },
              ]}
              onChange={value =>
                this.setState({ filters: { ...filters, log: value } }, this.setUp)
              }
              info={'Filtra los clientes según las fechas y el valor seleccionado'}
            />
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <CustomSelect
              label={'Por estado del cliente'}
              isSearchable={false}
              value={filters.state}
              options={[
                { value: null, label: '- Todos -' },
                { value: 1, label: 'Activos' },
                { value: 2, label: 'Inactivos' },
              ]}
              onChange={value =>
                this.setState({ filters: { ...filters, state: value } }, this.setUp)
              }
              info={'Filtra los clientes activos e inactivos'}
            />
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <CustomSelect
              label={'Por cuentas por cobrar'}
              isSearchable={false}
              value={filters.cpc}
              options={[
                { value: null, label: '- Todos -' },
                { value: 1, label: 'Saldo pendiente' },
                { value: 2, label: 'Sin cuentas por cobrar' },
              ]}
              onChange={value =>
                this.setState({ filters: { ...filters, cpc: value } }, this.setUp)
              }
              info={'Filtra los clientes según sus cuentas por cobrar'}
            />
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <CustomSelect
              label={'Por revisión'}
              isSearchable={false}
              value={filters.dirty}
              options={[
                { value: null, label: '- Todos -' },
                { value: 1, label: 'Revisados' },
                { value: 2, label: 'Sin revisar' },
              ]}
              onChange={value =>
                this.setState({ filters: { ...filters, dirty: value } }, this.setUp)
              }
              info={'Filtra según la revisión de los clientes'}
            />
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <CustomSelect
              label={'Por estado de visita'}
              isSearchable={false}
              value={filters.visit}
              options={[
                { value: null, label: '- Todos -' },
                { value: 1, label: 'Exitosa' },
                { value: 2, label: 'No exitosa' },
                { value: 3, label: 'Incumplida' },
              ]}
              onChange={value =>
                this.setState({ filters: { ...filters, visit: value } }, this.setUp)
              }
              info={'Filtra los clientes según el estado de su ultima visita'}
            />
          </Col>
          {canViewAll && (
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <CustomSelect
                label={'Por usuario visualizador'}
                value={filters.attendant}
                options={[{ value: null, label: '- sin seleccionar -' }, ...users]}
                onChange={value =>
                  this.setState({ filters: { ...filters, attendant: value } }, this.setUp)
                }
                info={'Filtrar por usuario visualizador'}
              />
            </Col>
          )}
          {canViewAll && (
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <CustomSelect
                label={'Por usuario creador'}
                isSearchable={false}
                value={filters.user}
                options={[{ value: null, label: '- sin seleccionar -' }, ...users]}
                onChange={value =>
                  this.setState({ filters: { ...filters, user: value } }, this.setUp)
                }
                info={'Filtra los clientes por el usuario que los creó'}
              />
            </Col>
          )}
        </Row>
        <hr />
        <Row>
          {inPolygon && inPolygon.clients && inPolygon.clients !== '' && (
            <Col xl={4} lg={4} md={6} sm={12}>
              <Row>
                <div className={'user-tag product-t ml-5'}>
                  <label className={'label-user-tag'}>Filtrado por polígono</label>
                  <ButtonIcon
                    className={'delete-user-tag d-product-t'}
                    icon={faTimes}
                    tooltip={'Quitar'}
                    color={'white'}
                    onClick={() =>
                      this.setState({ inPolygon: { show: false, clients: null } }, () =>
                        this.setUp(),
                      )
                    }
                  />
                </div>
              </Row>
            </Col>
          )}
          <Col xl={6} lg={6} sm={12}>
            <CSVLoaderV2
              category={csvCategories[country.id] || CATEGORY_UPLOAD.CLIENTS}
              title={'Carga de clientes por csv'}
              line
              showInfo
              onSuccess={() => this.setUp()}
            />
          </Col>
          <Col xl={6} lg={6} sm={12}>
            <Button
              color={'primary'}
              onClick={() =>
                this.setState({ isFilter: true, showCategorizations: true })
              }>
              Filtrar por categorías
            </Button>
            <Row className={'pl-1'}>
              {selectedCategorization.map(p => (
                <div className={'user-tag product-t ml-2'} key={p.id}>
                  <label className={'label-user-tag'}>{p.name}</label>
                  <ButtonIcon
                    className={'delete-user-tag d-product-t'}
                    icon={faTimes}
                    tooltip={'Quitar'}
                    color={'white'}
                    onClick={() =>
                      this.setState(
                        {
                          selectedCategorization: selectedCategorization.filter(
                            f => f.id !== p.id,
                          ),
                        },
                        () => this.setUp(),
                      )
                    }
                  />
                </div>
              ))}
            </Row>
          </Col>
        </Row>
        <br />
      </div>
    )
  }

  getChartData = () => {
    const { report } = this.props
    return {
      labels: report.report.map(r => r.name),
      datasets: [
        {
          data: report.report.map(r => r.total),
          label: 'Productos más consumidos',
          borderColor: `rgb(90, 193, 69)`,
          backgroundColor: 'rgba(90, 193, 69, 0.7)',
        },
      ],
    }
  }

  save = () => {
    const { selected } = this.state
    let request = Object.assign({}, selected)
    this.props.updateClient(request.id, request)
  }

  assignCategorizationToClient(categorization) {
    const { selectedClients, clientId, all } = this.state
    let array = selectedClients.map(c => c.id)
    if (array.length === 0) array.push(clientId)
    if (all) {
      this.setUp(null, false, null, { categorization, remove: !!clientId })
    } else
      this.props.assignCategorizationToMultipleClients(categorization, array, !!clientId)
  }

  filtersChange = () => {
    const { filtersTransactions, modalTransactions } = this.state

    setTimeout(() => {
      if (filtersTransactions.transactionType === 5) {
        this.props.getProducts(
          modalTransactions.id,
          filtersTransactions.start,
          filtersTransactions.end,
        )
      } else {
        let config = {
          start: filtersTransactions.start ? filtersTransactions.start.valueOf() : null,
          end: filtersTransactions.end ? filtersTransactions.end.valueOf() : null,
          skip: filtersTransactions.skip,
          size: filtersTransactions.size,
          search: filtersTransactions.search,
          transactionType: filtersTransactions.transactionType,
        }
        this.props.getTransactions(config, modalTransactions.id)
      }
    }, 100)
  }

  onDownloadTransactions = (items, startDate, endDate) => {
    let start, end
    if (items && items.length > 0) {
      start = startDate ? new Date(startDate).setHours(0, 0, 0).valueOf() : null
      end = endDate ? new Date(endDate).setHours(23, 59, 59).valueOf() : null
    } else {
      start = (startDate || new Date()).setHours(0, 0, 0).valueOf()
      end = (endDate || new Date()).setHours(23, 59, 59).valueOf()
    }

    let clients = ''
    if (items && items.length > 0) {
      items.forEach((s, i) => {
        let sign = items.length !== i + 1 ? ',' : ''
        clients += s.id + sign
      })
      this.props.getExcelTransactions(clients, {
        start,
        end,
      })
    } else {
      this.setUp({
        useFilter: true,
        start,
        end,
      })
    }
  }

  getTransactionHeader = index => {
    const { currentCurrency } = this.props

    const response = []
    if (index === 0) {
      response.push(
        ...[
          { label: 'Tipo', show: true, type: 'text' },
          { label: 'Documento', show: true, type: 'text' },
          { label: 'Fecha', show: true, type: 'date' },
          { label: `Valor (${currentCurrency.symbol})`, show: true, type: 'currency' },
          { label: `Recibo (${currentCurrency.symbol})`, show: true, type: 'currency' },
          { label: 'N/C', show: true, type: 'currency' },
          { label: '', show: true },
        ],
      )
    } else if (index !== 5) {
      response.push(
        ...[
          { label: 'Código', show: true, type: 'text' },
          { label: 'Estado', show: true, type: 'text' },
          { label: 'Fecha', show: true, type: 'date' },
          { label: 'Encargado', show: true, type: 'total' },
          { label: `Total (${currentCurrency.symbol})`, show: true, type: 'currency' },
          { label: '', show: true },
        ],
      )
    } else {
      response.push(
        ...[
          { label: 'SKU / Código', show: true, type: 'text' },
          { label: 'Producto', show: true, type: 'text' },
          { label: 'Unidades', show: true, type: 'number' },
          {
            label: `Precio promedio (${currentCurrency.symbol})`,
            show: true,
            type: 'currency',
          },
          {
            label: `Último precio (${currentCurrency.symbol})`,
            show: true,
            type: 'currency',
          },
          { label: 'Ultima compra', show: true, type: 'date' },
        ],
      )
    }
    return response
  }

  getHeadersVendidos = () => {
    return [
      {
        label: 'SKU / Código',
        show: true,
        value: ['code'],
        type: 'text',
        className: 'medium',
      },
      {
        label: 'Producto',
        show: true,
        value: ['name'],
        type: 'text',
        className: 'medium',
      },
      {
        label: 'Unidades',
        show: true,
        value: ['quantity'],
        type: 'text',
        className: 'mini',
      },
      {
        label: 'Precio promedio',
        show: true,
        value: ['promedioC'],
        type: 'text',
        className: 'mini',
        custom: item => (item.promedioC = toMoney(item.total / item.quantity)),
      },
      {
        label: 'Último precio',
        show: true,
        value: ['price'],
        type: 'currency',
        className: 'mini',
      },
      {
        label: 'Ultima compra',
        show: true,
        value: ['date'],
        type: 'date',
        className: 'medium',
      },
      { config: true, show: true, label: '', className: 'mini center' },
    ]
  }

  restrictedByPlan = () => {
    const { plan } = this.props
    return plan?.id !== 8 && plan?.id !== 9
  }

  getIdentification = (item, identificationTypeId) =>
    item.identifications?.find(i => i.identificationTypeId === identificationTypeId)
      ?.value || ''

  getHeaders = (all, selectedClients) => {
    const { users, country, setModalEditClient } = this.props

    return [
      {
        label: '',
        show: true,
        select: true,
        value: [''],
        type: '',
        checked: all && selectedClients.length === 0,
        style: { verticalAlign: 'middle', textAlign: 'center' },
        classNameCustom: item => `center mini ${item.dirty ? 't-gray' : ''}`,
        custom: item => {
          return item.cotizapClient ? (
            <img
              src={logo}
              className="cotizap-client-icon toolbar-logo"
              alt="Logo"
              width={50}
            />
          ) : item.dirty ? (
            <IconButton icon={faExclamationTriangle} tooltip={'Sin revisar'} />
          ) : (
            ''
          )
        },
      },
      { label: 'Código', show: true, value: ['code'], type: 'text', className: 'mini' },
      {
        label: 'Nombre',
        show: true,
        value: ['companyName'],
        type: 'text',
        className: 'medium',
      },
      {
        label: 'NIT',
        show: country.id === Country.GT,
        value: ['nit'],
        type: 'nit',
        className: 'mini',
      },
      {
        label: 'CUI',
        show: country.id === Country.GT,
        value: ['cui'],
        type: 'text',
        className: 'mini',
      },
      {
        label: 'NIT',
        show: country.id === Country.SV,
        custom: item => this.getIdentification(item, identificationTypesEnum.NIT_SV),
        type: 'nit',
        className: 'mini',
      },
      {
        label: 'DUI',
        show: country.id === Country.SV,
        custom: item => this.getIdentification(item, identificationTypesEnum.DUI),
        type: 'text',
        className: 'mini',
      },
      {
        label: 'NRC',
        show: country.id === Country.SV,
        custom: item => this.getIdentification(item, identificationTypesEnum.NRC),
        type: 'text',
        className: 'mini',
      },
      {
        label: 'RTN',
        show: country.id === Country.HN,
        custom: item => this.getIdentification(item, identificationTypesEnum.RTN),
        type: 'text',
        className: 'mini',
      },
      {
        label: 'Bloqueado',
        show: true,
        value: ['locked'],
        type: 'text',
        className: 'mini center',
        custom: item =>
          item.locked ? (
            <Tooltip placement="top" content={item.lockMessage || 'Sin mensaje'}>
              Sí
            </Tooltip>
          ) : (
            'No'
          ),
      },
      {
        label: 'Teléfono',
        show: true,
        value: ['phone'],
        type: 'text',
        className: 'mini',
        custom: item => item.phone || 'Desconocido',
      },
      {
        label: 'Dirección',
        show: true,
        value: ['direccionC'],
        type: 'text',
        className: 'medium',
        custom: item => (item.direccionC = item.secondAddress || item.address),
      },
      {
        label: 'cpc',
        show: this.restrictedByPlan(),
        value: ['balance'],
        type: 'currency',
        className: 'mini',
      },
      {
        label: 'Ultima orden',
        show: true,
        value: ['lastOrderDateF'],
        type: 'date',
        className: 'mini',
      },
      {
        label: 'Visita',
        show: this.restrictedByPlan(),
        value: ['visitaC'],
        type: 'text',
        className: 'mini',
        custom: item => (item.visitaC = this.getStatus(item.visitStatus)),
      },
      {
        label: 'Log',
        show: this.restrictedByPlan(),
        value: ['amount'],
        type: 'text',
        className: 'mini',
        custom: item => (
          <div>
            {item.logInfo != null ? item.logInfo : 'Sin registro'}
            {this.props.isAllowed([1060, 2159]) && (
              <IconButton
                icon={faPlus}
                tooltip={'Agregar registro'}
                style={{ marginRight: 4 }}
                onClick={() => {
                  this.setState({ modalLog: { id: item.id, show: true }, newlog: true })
                }}
              />
            )}
          </div>
        ),
      },
      {
        label: 'Ticket Promedio',
        show: true,
        value: ['avg'],
        type: 'number',
        className: 'mini',
      },
      {
        label: 'Estado',
        show: true,
        value: ['disponibleC'],
        type: 'text',
        className: 'mini',
        custom: item => (item.disponibleC = item.available ? 'Activo' : 'Inactivo'),
      },
      {
        label: 'Creado por',
        show: true,
        value: ['disponibleC'],
        type: 'text',
        className: 'mini',
        custom: item =>
          users.find(u => u.value === item.createdBy)?.label || 'Desconocido',
      },
      {
        label: 'Mora',
        show: this.restrictedByPlan(),
        value: ['moraC'],
        type: 'text',
        className: 'mini',
        custom: item => (item.moraC = item.defaulter ? 'Con mora' : 'Sin mora'),
      },
      {
        config: true,
        show: true,
        label: '',
        className: 'mini center',
        custom: item => {
          const { getPermission, seeViewers, seeTransactions, currentCompany } =
            this.props

          const isAdmin = getPermission(1001) || getPermission(2001)
          const canLockClients = getPermission(clientPermissions.lock)
          const canUnlockClients = getPermission(clientPermissions.unlock)

          return (
            <div className={'jc-right'}>
              <DropdownCustom
                className={'dp-custom'}
                items={[
                  {
                    title: 'Detalle completo',
                    action: () =>
                      setModalEditClient({ show: true, showProfile: true, id: item.id }),
                  },
                  {
                    title: 'Ver ubicación',
                    show: this.restrictedByPlan(),
                    action: () =>
                      this.setState({ selected: item }, () =>
                        this.props.getSingleClient(item.id),
                      ),
                  },
                  {
                    title: 'Ver suscripciones',
                    show: this.restrictedByPlan(),
                    action: () => {
                      this.props.setParams({
                        id: currentCompany === 1 ? item.cotizapClient : item.id,
                        type: currentCompany === 1 ? 2 : 1,
                        name: item.companyName,
                      })
                      this.goTo(`/suscripciones/detalle`)
                    },
                  },
                  {
                    title: 'Cuentas por cobrar',
                    action: () => this.goTo(`/distribucion/cxc/pagos/${item.id}`, true),
                    show:
                      this.props.seeMoneyBalances &&
                      item.balance > 0 &&
                      this.restrictedByPlan(),
                  },
                  {
                    title: 'Abonar a la cuenta',
                    action: () => this.paymentItem(item),
                    show: this.restrictedByPlan(),
                  },
                  {
                    title: 'Bloquear',
                    action: () => this.lockClient(item.id),
                    show: canLockClients && !item.locked,
                  },
                  {
                    title: 'Desbloquear',
                    action: () => this.unlockClient(item.id),
                    show: canUnlockClients && item.locked,
                  },
                  {
                    show: isAdmin && item.defaulter && this.restrictedByPlan(),
                    title: 'Cambiar estado moroso',
                    action: () =>
                      this.setState({
                        paymentAlert: {
                          show: true,
                          title: 'Cambiar estado de moroso al cliente',
                          type: 'info',
                          showCancelButton: true,
                          onCancel: () =>
                            this.setState({
                              paymentAlert: { ...this.state.paymentAlert, show: false },
                            }),
                          onConfirm: () =>
                            this.setState(
                              {
                                paymentAlert: { ...this.state.paymentAlert, show: false },
                              },
                              () => {
                                this.props.onChangeDefaulterStatus(item.id)
                              },
                            ),
                        },
                      }),
                  },
                  {
                    title: 'Ver categorías',
                    show: this.restrictedByPlan(),
                    action: () => {
                      this.props.getCategorizationsByClient(item.id)
                      this.setState({ showCategorizations: true, clientId: item.id })
                    },
                  },
                  {
                    title: 'Ver productos vendidos',
                    action: () => {
                      this.props.getProducts(item.id)
                      this.setState({
                        showProducts: {
                          show: true,
                          client: item.id,
                          name: item.companyName,
                        },
                      })
                    },
                  },
                  {
                    title: 'Ver productos más consumidos',
                    action: () => this.props.getReport(item.id),
                  },
                  {
                    title: 'Ver bitácora del cliente',
                    action: () => {
                      this.setState({
                        modalLog: { id: item.id, show: true },
                        newlog: false,
                      })
                    },
                  },
                  {
                    show: seeViewers && this.restrictedByPlan(),
                    title: 'Ver usuarios visualizadores',
                    action: () =>
                      this.setState({
                        clientViewers: {
                          show: true,
                          id: item.id,
                          name: item.companyName,
                        },
                      }),
                  },
                  {
                    show:
                      item.dirty &&
                      this.props.isAllowed([1058, 2157]) &&
                      this.restrictedByPlan(),
                    title: `Validar información del cliente`,
                    action: () =>
                      this.validateClients(
                        [item],
                        '¿Desea validar la información del cliente seleccionado?',
                        true,
                      ),
                  },
                  {
                    show:
                      !item.dirty &&
                      this.props.isAllowed([1058, 2157]) &&
                      this.restrictedByPlan(),
                    title: `Invalidar información del cliente`,
                    action: () =>
                      this.validateClients(
                        [item],
                        '¿Desea invalidar la información del cliente seleccionado?',
                        true,
                        true,
                      ),
                  },
                  {
                    title: 'Abrir ubicación en Waze',
                    href: `https://www.waze.com/ul?ll=${item.latitude}%2C${item.longitude}&navigate=yes`,
                    target: '_none',
                    show: this.restrictedByPlan(),
                  },
                  {
                    show: seeTransactions && this.restrictedByPlan(),
                    title: 'Ver transacciones del cliente',
                    action: () => {
                      this.setState({
                        modalTransactions: { ...item, show: true, id: item.id },
                      })
                    },
                  },
                ]}
              />
            </div>
          )
        },
      },
    ]
  }

  render() {
    const { getPermission, users, allClients, clientsAreLoading, report } = this.props
    const {
      loadingPayment,
      vLoading,
      total,
      categorization,
      loadingAssigned,
      sendingEmail,
      clientProducts,
      seeMoneyBalances,
    } = this.props
    const {
      createVisits,
      paymentAlert,
      modalPayment,
      clientPayment,
      selectedClients,
      selected,
      showHistory,
      showCategorizations,
      showDelete,
      showClient,
      showCommentary,
      clientId,
      archivedClients,
      showLocation,
      showMap,
      filters,
      all,
      isFilter,
      selectedCategorization,
      createEmail,
      modalTransactions,
      excelTransaction,
      showProducts,
    } = this.state

    const { modules, loadingH } = this.props

    const clients = this.getAllClients(allClients)

    let seeFilter = false,
      canArchive = false

    const canMerge = getPermission(1059) || getPermission(2158)

    if (modules === 1000 && (getPermission(1000) || getPermission(1001))) seeFilter = true

    if (modules === 2000 && (getPermission(2000) || getPermission(2001))) seeFilter = true

    if (modules === 1000 && getPermission(clientPermissions.archive)) canArchive = true
    if (modules === 2000 && getPermission(2155)) canArchive = true

    let { viewers, inPolygon } = this.state

    return (
      <div style={{ paddingBottom: 50 }}>
        <FAB
          show={all || selectedClients.length > 0}
          items={[
            {
              next: true,
              style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
              show: canMerge && !all && selectedClients.length > 1,
              text: 'Unificar Clientes',
              icon: faLink,
              action: () => this.setState({ merge: true }),
            },
            {
              next: true,
              style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
              show: !all && selectedClients.length > 0,
              text: 'Ver Seleccionados en el Mapa',
              icon: faEye,
              action: () => this.setState({ showMap: true }),
            },
            {
              next: true,
              style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
              text: 'Programar Visitas',
              icon: faFileAlt,
              action: () => this.validateStartOrders(),
              show: all || selectedClients.length > 0,
            },
            {
              style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
              text: 'Asignar visualizadores',
              icon: faEdit,
              action: () => this.setView(),
              show: seeFilter && (all || selectedClients.length > 0),
            },
            {
              next: true,
              style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
              text: 'Archivar Clientes',
              icon: faArchive,
              action: () =>
                this.hideAction(
                  2,
                  '¿Desea archivar a los clientes seleccionados?',
                  this.getID(selectedClients),
                ),
              show:
                canArchive &&
                selectedClients.length > 0 &&
                selectedClients.every(c => c.cotizapClient !== 3000),
            },
            {
              style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
              text: 'Validar información',
              icon: faFileArchive,
              action: () =>
                this.validateClients(
                  selectedClients.filter(i => i.dirty),
                  '¿Desea validar la información de los clientes seleccionados?',
                ),
              show:
                this.props.isAllowed([1058, 2157]) &&
                (all || selectedClients.filter(i => i.dirty).length > 0),
            },
            {
              style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
              text: 'Invalidar información',
              icon: faFileArchive,
              action: () =>
                this.validateClients(
                  selectedClients.filter(i => !i.dirty),
                  '¿Desea invalidar la información de los clientes seleccionados?',
                  false,
                  true,
                ),
              show:
                this.props.isAllowed([1058, 2157]) &&
                (all || selectedClients.filter(i => !i.dirty).length > 0),
            },
            {
              next: true,
              style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
              text: 'Añadir Categorización',
              icon: faTag,
              action: () => this.setState({ showCategorizations: true, isFilter: false }),
              show: all || selectedClients.length > 0,
            },
            {
              style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
              text: 'Enviar correo',
              icon: faMailBulk,
              action: () => this.setState({ createEmail: { show: true } }),
              show:
                this.props.isAllowed([1065, 2165]) && !all && selectedClients.length > 0,
            },
            {
              style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
              text: 'Descargar Transacciones',
              icon: faFileExcel,
              action: () => this.setState({ excelTransaction: { show: true } }),
              show:
                this.props.isAllowed([1066, 2166]) && (all || selectedClients.length > 0),
            },
          ]}
          icon={faEllipsisV}
        />

        <Title title={'Clientes'} action to={'nuevo'} actionTitle={'Cliente Nuevo'} />
        <Row>
          <Col lg={12}>
            <Card title={'Clientes'} button={this.getButtonAction()} white>
              <Action action to={'nuevo'} actionTitle={'Cliente Nuevo'} />
              <TableV2
                customFilter={this.customFilter()}
                buttonTitle={this.renderButtons()}
                getPagination={(skip, size, search) =>
                  this.setState(
                    { filters: { ...filters, skip, size, search } },
                    this.setUp,
                  )
                }
                handleChange={search =>
                  setTimeout(() => {
                    this.setState(
                      { filters: { ...filters, search, skip: 0, size: 10 } },
                      this.setUp,
                    )
                  }, 1000)
                }
                total={total}
                items={clients}
                loading={clientsAreLoading}
                onCheck={value => this.setState({ all: value, selectedClients: [] })}
                headers={this.getHeaders(all, selectedClients)}
                onClickTr={(e, item) => this.rowClicked(e, item)}
                isSelected={item => {
                  const selected = this.isSelected(item)
                  return selected && all ? false : all || selected
                }}
                mobileAuto
                storageKey={`clientstable`}
                getItems={(item, e) => {
                  if (item === null) {
                    this.setState({ all: false, selectedClients: [] })
                  } else {
                    this.rowClicked(e, item)
                  }
                }}
                balancePermission={seeMoneyBalances}
              />
            </Card>
          </Col>
        </Row>

        <Modal
          show={viewers.show}
          centered
          size={'lg'}
          onHide={() => this.setState({ viewers: { ...viewers, show: false } })}>
          <Modal.Header>
            <Modal.Title>Asignación de visualizadores</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className={'column'}>
              <h5>
                Los clientes seleccionados serán visualizados por los usuarios que se
                seleccionen
              </h5>
              <Row>
                <Col xl={6} md={6} sm={6} xs={12}>
                  <Row>
                    <Col xl={12}>
                      <Checkbox
                        label={'Asignar clientes a los usuarios seleccionados'}
                        checked={viewers.request.add}
                        onChange={({ target }) => {
                          const { checked } = target
                          const { viewers } = this.state
                          const { request } = viewers

                          this.setState({
                            viewers: {
                              ...viewers,
                              request: {
                                ...request,
                                add: checked,
                                hideCurrentViewers: !checked,
                              },
                            },
                          })
                        }}
                      />
                    </Col>
                    {viewers.request.add && (
                      <>
                        <Col xl={12}>
                          <div>
                            <hr />
                          </div>
                        </Col>

                        <Col xl={12} md={12} sm={12} xs={12}>
                          <Checkbox
                            label={
                              'Desasignar clientes de sus usuarios visualizadores actuales'
                            }
                            checked={viewers.request.hideOldViewers}
                            onChange={({ target }) => {
                              const { checked } = target
                              const { viewers } = this.state
                              const { request } = viewers

                              this.setState({
                                viewers: {
                                  ...viewers,
                                  request: { ...request, hideOldViewers: checked },
                                },
                              })
                            }}
                          />
                        </Col>
                      </>
                    )}
                  </Row>
                </Col>
                <Col xl={6} md={6} sm={6} xs={12}>
                  <Checkbox
                    label={'Desasignar clientes de los usuarios seleccionados'}
                    checked={viewers.request.hideCurrentViewers}
                    onChange={({ target }) => {
                      const { checked } = target
                      const { viewers } = this.state
                      const { request } = viewers

                      this.setState({
                        viewers: {
                          ...viewers,
                          request: {
                            ...request,
                            add: !checked,
                            hideCurrentViewers: checked,
                            hideOldViewers: false,
                          },
                        },
                      })
                    }}
                  />
                </Col>
              </Row>
              <br />
              <TableV2
                headers={[
                  {
                    label: 'Seleccion',
                    show: true,
                    value: ['amount'],
                    type: 'text',
                    className: 'mini center',
                    simpleChecked: true,
                    custom: item => (
                      <input
                        type="checkbox"
                        name="hideOldViewers"
                        checked={this.getUserSelected(item)}
                        onChange={() => this.setUsers(item)}
                      />
                    ),
                  },
                  {
                    label: 'Nombre',
                    show: true,
                    value: ['name'],
                    type: 'text',
                    className: 'medium',
                  },
                  {
                    label: 'Email',
                    show: true,
                    value: ['email'],
                    type: 'text',
                    className: 'medium',
                  },
                  { config: true, show: true, label: '', className: 'mini center' },
                ]}
                items={users}
                mobileAuto
                storageKey={`assingnViewers`}
                onClickTr={(e, item) => this.setUsers(item)}
              />
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Row className={'button-container'}>
              <Button
                disabled={vLoading}
                variant={'secondary'}
                icon={faWindowClose}
                onClick={() => this.setState({ viewers: { ...viewers, show: false } })}>
                Cerrar
              </Button>
              <Button
                loading={vLoading}
                disabled={
                  viewers.request.add
                    ? !viewers.request.users || viewers.request.users.length <= 0
                    : !viewers.request.hideCurrentViewers
                }
                variant={'info'}
                icon={faSave}
                onClick={() => this.saveViewers()}>
                Asignar visualizadores
              </Button>
            </Row>
          </Modal.Footer>
        </Modal>

        <Modal
          show={modalPayment}
          size={'md'}
          centered
          onHide={() => this.setState({ modalPayment: false })}>
          <Modal.Header closeButton>
            <Modal.Title>Realizar un pago.</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CreateOrderPayment
              balance={clientPayment.balance}
              loading={loadingPayment}
              onCreateOrder={this.onCreateOrderPayment}
              client={clientPayment}
            />
          </Modal.Body>
        </Modal>

        <Modal
          show={createVisits}
          size={'md'}
          centered
          onHide={() => this.setState({ createVisits: false })}>
          <Modal.Header closeButton>
            <Modal.Title>Programar visitas</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CreateOrderVisit
              allClientsToSelect={[]}
              isRequiredClient={false}
              onCreateOrderVisit={visit => {
                if (all) this.setUp(null, false, { ...visit, clients: [-1] })
                else {
                  visit.clients = selectedClients.map(client => {
                    return client.id
                  })
                  this.props.onCreateOrdersVisit(visit)
                }
              }}
            />
          </Modal.Body>
        </Modal>

        <Modal
          show={showHistory}
          size={'xl'}
          centered
          onHide={() => this.setState({ showHistory: false })}>
          <Modal.Header closeButton>
            <Modal.Title>Historial</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ClientOrderHistory id={clientId} />
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>

        <Modal
          show={showClient}
          size={'xl'}
          centered
          onHide={() => this.setState({ showClient: false })}>
          <Modal.Header closeButton>
            <Modal.Title>{report ? report.companyName : ''}</Modal.Title>
          </Modal.Header>
          {report && (
            <Modal.Body>
              <Row>
                <Col xs={12} sm={12} md={12} lg={4}>
                  <div className="custom-card">
                    <Row style={{ justifyContent: 'center' }}>
                      <img
                        src={ClientIcon}
                        alt={'client profile'}
                        className={'picture-client'}
                      />
                    </Row>
                    <Row style={{ justifyContent: 'center' }}>
                      <span className={'client-name'}>
                        {report ? report.companyName : 'desconocido'}
                      </span>
                    </Row>
                    <Row>
                      <Col xl={2} lg={2} md={2} sm={2} xs={2} />
                      <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                        <Row>
                          <Col className={'label'}>
                            <b>Código</b>
                          </Col>
                          <Col>{report.code || 'Desconocido'}</Col>
                        </Row>
                        <Row>
                          <Col className={'label'}>
                            <b>Nit</b>
                          </Col>
                          <Col>{report.nit || 'CF'}</Col>
                        </Row>
                        <Row>
                          <Col className={'label'}>
                            <b>Dirección</b>
                          </Col>
                          <Col>{report.address || 'Desconocido'}</Col>
                        </Row>
                        <Row>
                          <Col className={'label'}>
                            <b>Referencia</b>
                          </Col>
                          <Col>{report.reference || 'Desconocido'}</Col>
                        </Row>
                        <Row>
                          <Col className={'label'}>
                            <b>Telefono</b>
                          </Col>
                          <Col>{report.phone || 'Desconocido'}</Col>
                        </Row>
                      </Col>
                    </Row>
                    <br />
                    <br />
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={8}>
                  <SelectedDates
                    withOptionNull
                    onDateChange={(start, end) =>
                      this.props.getReport(report.id, start, end)
                    }
                  />
                  <div className="ru-graph-w">
                    <HorizontalBar
                      data={this.getChartData}
                      height={175}
                      options={options}
                    />
                  </div>
                  {/*codigo, nombre, precio de venta, precio de produccion, existencias, producto base y factor de conversion*/}
                  <div className="ru-graph-m">
                    <HorizontalBar
                      data={this.getChartData}
                      height={350}
                      options={options}
                    />
                  </div>
                </Col>
              </Row>
            </Modal.Body>
          )}
          <Modal.Footer>
            <Row className={'container-buttons'} />
          </Modal.Footer>
        </Modal>

        <SweetAlert
          show={this.state.showConfirmMultiple}
          title="Confirmar"
          text={
            'Se han seleccionado ' +
            selectedClients.length +
            ' clientes ¿desea realizar edición de los campos seleccionados para todos ellos?'
          }
          type={'warning'}
          showCancelButton
          onConfirm={() => {
            this.saveMultiple()
          }}
          onCancel={() => {
            this.setState({ showConfirmMultiple: false })
          }}
        />
        <SweetAlert
          show={this.state.showSuccessMultiple}
          title="Edición realizada con éxito"
          text={''}
          type={'success'}
          onConfirm={() => {
            this.setState({ showSuccessMultiple: false })
          }}
        />
        <SweetAlert
          onConfirm={() =>
            this.setState({ paymentAlert: { ...paymentAlert, show: false } })
          }
          {...paymentAlert}
        />

        <SweetAlert
          show={showDelete}
          title={'Advertencia'}
          showCancelButton
          text={'Desea eliminar este cliente'}
          type={'warning'}
          onConfirm={() => {
            this.props.softDelete(selected.id)
            this.setState({ showDelete: false })
          }}
          onCancel={() => this.setState({ showDelete: false })}
        />

        <SweetAlert
          show={showCommentary}
          title="Registrar un comentario"
          text="Ingrese un comentario u observación"
          type="input"
          inputType="email"
          inputPlaceholder="Comentario"
          showCancelButton
          onConfirm={inputValue => {
            this.props.createLog(clientId, inputValue)
            this.setState({ showCommentary: false, showLog: true })
          }}
          onCancel={() => {
            this.setState({ showCommentary: false, clientId: 0 })
          }}
        />

        <ClientInside
          show={inPolygon.show}
          clients={clients}
          onHide={result => {
            if (result.type === 2) {
              inPolygon.clients = result.clients
            }
            this.setState({ inPolygon: { ...inPolygon, show: false } }, this.setUp)
          }}
        />
        <ModalTable
          show={this.state.archiveModal}
          onHide={() => this.setState({ archiveModal: false }, () => this.setUp())}
          loading={this.props.loadingA || loadingH}
          title={'Clientes archivados'}
          items={this.props.archived}
          headers={[
            {
              label: 'Código',
              show: true,
              value: ['code'],
              type: 'text',
              className: 'mini',
            },
            {
              label: 'Nombre',
              show: true,
              value: ['companyName'],
              type: 'text',
              className: 'medium',
            },
            {
              label: 'Actualizado por',
              show: true,
              value: ['updatedData', 'name'],
              type: 'text',
              className: 'mini',
            },
            {
              config: true,
              show: true,
              label: '',
              className: 'mini center',
              custom: item => (
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                  <Icon
                    onClick={() =>
                      this.hideAction(
                        3,
                        '¿Desea desarchivar al cliente?',
                        this.getID([item]),
                      )
                    }
                    icon={faFileArchive}
                    tooltip={'Recuperar'}
                  />
                  <Icon
                    onClick={() =>
                      this.hideAction(
                        1,
                        '¿Desea eliminar al cliente?',
                        this.getID([item]),
                      )
                    }
                    icon={faTrash}
                    tooltip={'Eliminar'}
                  />
                </div>
              ),
            },
          ]}
          renderRow={undefined}
          onClickTr={(e, item) => this.onSelectItem(e, item)}
          isSelected={selected => this.isSelectedClient(selected)}
          button={archivedClients.length > 0 ? 'Recuperar' : false}
          onClick={() => {
            this.hideAction(
              3,
              '¿Desea desarchivar al cliente?',
              this.getID(archivedClients),
            )
            this.setState({ archivedClients: [] })
          }}
          del={archivedClients.length > 0}
          loadingDel={loadingH}
          onDelete={() => {
            this.hideAction(
              1,
              '¿Desea eliminar a los clientes seleccionados?',
              this.getID(archivedClients),
            )
            this.setState({ archivedClients: [] })
          }}
        />

        <Modal
          show={showLocation}
          size={'lg'}
          centered
          onHide={() => this.setState({ showLocation: false, selected: {} })}>
          <ModalHeader closeButton>
            <ModalTitle>{selected.companyName}</ModalTitle>
          </ModalHeader>
          <ModalBody>
            {this.props.client.latitude !== 0 && this.props.client.longitude !== 0 && (
              <Geocoding
                lat={selected.latitude || lat}
                lng={selected.longitude || lon}
                auto={false}
                defaultAddress={selected.address}
                editable={true}
                getData={this.getData}
              />
            )}
            {selected.pending && (
              <h6 style={{ color: 'red' }}>
                Presione &quot;Guardar&quot; Si quiere confirmar los cambios
              </h6>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                this.save()
              }}>
              Guardar
            </Button>
          </ModalFooter>
        </Modal>

        <ModalTable
          show={this.state.merge}
          onHide={() => this.setState({ merge: false })}
          title={'Clientes Seleccionados'}
          subtitle={
            'El cliente que seleccione se le añadirán todas las cuentas por cobrar, ordenes de pagos y el log del resto de los clientes.' +
            ' Los otros clientes serán eliminados'
          }
          items={selectedClients}
          headers={['Principal', 'Código', 'Nombre', 'Estado']}
          renderRow={(item, index) => (
            <Tr className={'data'} key={`${index}`}>
              <Td className={'mini'}>
                <input
                  type={'radio'}
                  checked={item.checked}
                  onClick={() => {
                    this.setState({
                      selectedClients: selectedClients.map(client => ({
                        ...client,
                        checked: client.id === item.id,
                      })),
                    })
                  }}
                />
              </Td>
              <Td className={'medium'}>{item.code}</Td>
              <Td className={'medium'}>{item.companyName}</Td>
              <Td className={'medium'}>{item.active ? 'Activo' : 'Inactivo'}</Td>
            </Tr>
          )}
          button={
            selectedClients.filter(f => f.checked).length === 1 ? 'Unificar' : false
          }
          onClick={() => {
            this.mergeClients(selectedClients)
          }}
        />

        <Modal
          show={showMap}
          size={'lg'}
          centered
          onHide={() => this.setState({ showMap: false, selected: {} })}>
          <Modal.Header closeButton />

          <Modal.Body>
            <div style={{ paddingBottom: 50 }}>
              <MapCard
                startCollapsed={false}
                address={'Mapa'}
                height={600}
                latitude={lat}
                longitude={lon}
                markerList={selectedClients}
                showMarker={true}
              />
            </div>
          </Modal.Body>
        </Modal>

        {this.state.modalLog.id && (
          <ModalLog
            modal
            id={this.state.modalLog.id}
            logType={1}
            newlog={this.state.newlog}
            search={filters.search}
            onHide={() =>
              this.setState({ modalLog: { show: false, id: null }, newlog: false })
            }
            title={'Registros del Cliente'}
            balancePermission={seeMoneyBalances}
          />
        )}

        <Folder
          noMessage
          onHide={() => {
            if (this.state.isDetail)
              this.setState({
                showCategorizations: false,
                isFilter: false,
                isDetail: false,
              })
            else
              this.setState({
                showCategorizations: false,
                isFilter: false,
                clientId: 0,
              })
          }}
          onAssign={item => {
            if (!isFilter) this.assignCategorizationToClient(item.id)
            else {
              selectedCategorization.push(item)
              this.setState({ selectedCategorization }, () => this.setUp())
            }
          }}
          data1={
            categorization && categorization.children ? categorization.children[0] : {}
          }
          data2={
            categorization && categorization.children ? categorization.children[1] : {}
          }
          show={showCategorizations}
          list={selectedCategorization.map(p => p.id)}
          loading={loadingAssigned}
          type={categoryType.CLIENTS}
        />

        <ClientViewers
          {...this.state.clientViewers}
          onHide={() => {
            this.setState({ clientViewers: { show: false } })
          }}
        />

        <ClientTransactions
          show={modalTransactions.show}
          id={modalTransactions.id}
          onHide={() => this.setState({ modalTransactions: { show: false } })}
          balancePermission={seeMoneyBalances}
          canDownload={this.props.isAllowed([1068, 2168])}
        />
        <Modal
          show={createEmail.show}
          size={'lg'}
          centered
          onHide={() => this.setState({ createEmail: { show: false } })}>
          <Modal.Header closeButton>
            <Modal.Title>Envío masivo de correo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <h5>Destinatarios:</h5>
                {all && (
                  <div
                    style={{
                      fontSize: 15,
                      fontWeight: 'bold',
                      color: 'rgb(179, 87, 150, 0.9)',
                    }}>
                    {`Se le enviará un correo a todos los clientes ${
                      selectedClients.length > 0 ? 'Excepto a:' : ''
                    }`}
                  </div>
                )}
                {
                  <Row className={'pl-1'}>
                    {selectedClients
                      .filter(s => s.email)
                      .map(p => (
                        <div className={'user-tag product-t ml-2'} key={p.id}>
                          <label className={'label-user-tag'}>{p.companyName}</label>
                          <ButtonIcon
                            className={'delete-user-tag d-product-t'}
                            icon={faTimes}
                            tooltip={'Quitar'}
                            color={'white'}
                            onClick={() =>
                              this.setState({
                                selectedClients: selectedClients.filter(
                                  f => f.id !== p.id,
                                ),
                              })
                            }
                          />
                        </div>
                      ))}
                  </Row>
                }
              </Col>
            </Row>
            <Row>
              <Col>
                <FormTextField
                  required
                  label={'Asunto'}
                  type={'text'}
                  value={createEmail.heading}
                  onChange={({ target }) => {
                    this.setState({
                      createEmail: { ...createEmail, heading: target.value },
                    })
                  }}
                  placeholder={'Asunto del correo'}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <ReactQuill
                  theme="snow"
                  value={createEmail.body || ''}
                  onChange={value => {
                    this.setState({ createEmail: { ...createEmail, body: value } })
                  }}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              icon={faTimes}
              color={'secondary'}
              right
              onClick={() => this.setState({ createEmail: { show: false } })}>
              Cancelar
            </Button>
            <Button
              disabled={
                !createEmail.body ||
                !createEmail.heading ||
                (selectedClients.filter(s => s.email).length === 0 && !all)
              }
              loading={sendingEmail}
              icon={faPaperPlane}
              right
              onClick={() => {
                this.setState({ createEmail: { show: false } })
                this.props.sendEmail(
                  {
                    ...createEmail,
                    ids: selectedClients.filter(f => f.email).map(p => p.id),
                  },
                  all,
                )
              }}>
              Enviar
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={excelTransaction.show}
          size={'md'}
          centered
          onHide={() =>
            this.setState({ excelTransaction: { show: false }, selected: {} })
          }>
          <Modal.Header closeButton>
            <Modal.Title>Descargar transacciones</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Col xl={12} md={12} sm={12}>
              <CustomDate
                label={'Fecha de inicio'}
                value={excelTransaction.start}
                onDayChange={e => {
                  this.setState({ excelTransaction: { ...excelTransaction, start: e } })
                }}
                required
              />
            </Col>
            <Col xl={12} md={12} sm={12}>
              <CustomDate
                label={'Fecha de fin'}
                value={excelTransaction.end}
                onDayChange={e => {
                  this.setState({ excelTransaction: { ...excelTransaction, end: e } })
                }}
                required
              />
            </Col>
          </Modal.Body>
          <Modal.Footer>
            <Row className={'container-buttons'}>
              <Button
                disabled={!excelTransaction.start || !excelTransaction.end}
                color={'primary'}
                loading={this.props.loadingET}
                icon={faFileExcel}
                onClick={() => {
                  this.onDownloadTransactions(
                    selectedClients,
                    excelTransaction.start,
                    excelTransaction.end,
                  )
                }}>
                Exportar
              </Button>
            </Row>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showProducts.show}
          size={'lg'}
          centered
          onHide={() => this.setState({ showProducts: { show: false, client: null } })}>
          <Modal.Header closeButton>
            <Modal.Title>Productos vendidos: {showProducts.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TableV2
              mobileAuto
              storageKey={`salesProducts`}
              loading={this.props.loadingP}
              items={clientProducts}
              noItemsLegend={'El cliente no ha comprado productos'}
              headers={this.getHeadersVendidos()}
              balancePermission={seeMoneyBalances}
            />
          </Modal.Body>
          <Modal.Footer>
            <Row className={'container-buttons'}>
              <Button
                disabled={this.props.loadingP}
                color={'primary'}
                loading={this.props.loadingET}
                icon={faFileExcel}
                onClick={() => {
                  this.props.getExcelProducts(showProducts.client)
                }}>
                Exportar
              </Button>
            </Row>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

Clients.defaultProps = {
  allClients: [],
  selectedClients: [],
}

const mapStateToProps = state => ({
  allClients: selectAllClients(state),
  total: selectTotalClients(state),
  selectedClients: state.selectedClients,
  module: selectModuleId(state),
  modules: selectCurrentModule(state),
  clientsAreLoading: loadingSelector([actionTypes.GET_ALL_CLIENTS])(state),

  client: selectSingleClient(state),
  loadingClient: loadingSelector([actionTypes.GET_SINGLE_CLIENTS])(state),

  genericResponse: selectGenericResponse(state),
  orderPayment: selectOrderPayment(state),
  loadingPayment: loadingSelector([orderType.ON_CREATE_PAYMENT_ORDER])(state),
  errorPayment: hasErrors([orderType.ON_CREATE_PAYMENT_ORDER])(state),
  loadingRoles: loadingSelector([moduleTypes.GET_CURRENT_USER_ROLES])(state),
  currentRoles: selectCurrentUserRoles(state),
  user: selectCurrentUser(state),
  loadingVisit: loadingSelector([orderType.ON_CREATE_ORDERS_VISIT])(state),
  visitsHasError: hasErrors([orderType.ON_CREATE_ORDERS_VISIT])(state),
  currentCompany: selectCurrentCompany(state),

  password: selectCompany(state),
  handlerError: message => handlerError(message),
  handlerSuccess: message => handlerSuccess(message),
  users: selectUsers(state),
  getPermission: id => getPermission(state, id),
  deleteIsLoading: loadingSelector([actionTypes.DELETE_CLIENT])(state),
  deleteHasError: hasErrorsSelector([actionTypes.DELETE_CLIENT])(state),
  deleteError: singleErrorSelector([actionTypes.DELETE_CLIENT])(state),
  polygons: selectAllPolygonsBySelect(state),
  statusLoading: loadingSelector([actionTypes.CHANGE_STATE])(state),
  statusHasError: hasErrorsSelector([actionTypes.CHANGE_STATE])(state),
  statusError: singleErrorSelector([actionTypes.CHANGE_STATE])(state),
  report: selectReport(state),
  reportIsLoading: loadingSelector([actionTypes.GET_REPORT])(state),
  vLoading: loadingSelector([actionTypes.SET_CLIENT_VIEWERS])(state),
  vHasError: hasErrorsSelector([actionTypes.SET_CLIENT_VIEWERS])(state),
  vError: singleErrorSelector([actionTypes.SET_CLIENT_VIEWERS])(state),
  loadingD: loadingSelector([actionTypes.ON_DEFAULTER])(state),
  hasErrorD: hasErrorsSelector([actionTypes.ON_DEFAULTER])(state),
  errorD: singleErrorSelector([actionTypes.ON_DEFAULTER])(state),
  archived: selectArchived(state),
  loadingA: loadingSelector([actionTypes.GET_ARCHIVED])(state),
  responseH: selectHideResponse(state),
  loadingH: loadingSelector([actionTypes.HIDE_ACTION])(state),
  hasErrorH: hasErrorsSelector([actionTypes.HIDE_ACTION])(state),
  errorH: singleErrorSelector([actionTypes.HIDE_ACTION])(state),
  log: selectClientLog(state),
  simpleLog: selectSimpleLog(state),
  loadingLog: loadingSelector([actionTypes.CLIENT_LOG])(state),
  loadingCreateLog: loadingSelector([actionTypes.CREATE_LOG])(state),
  loadingCreateError: singleErrorSelector([actionTypes.CREATE_LOG])(state),
  loadingCreateHasError: hasErrorsSelector([actionTypes.CREATE_LOG])(state),
  isAllowed: permissions => isAllowed(state, permissions),
  dirtyResponse: selectDirtyResponse(state),
  dirtyLoading: loadingSelector([actionTypes.VALIDATE_INFORMATION])(state),
  dirtyHasError: hasErrorsSelector([actionTypes.VALIDATE_INFORMATION])(state),
  dirtyError: singleErrorSelector([actionTypes.VALIDATE_INFORMATION])(state),
  loadingMerge: loadingSelector([actionTypes.MERGE_CLIENTS])(state),
  mergeHasError: hasErrorsSelector([actionTypes.MERGE_CLIENTS])(state),
  loadingUpdate: loadingSelector([typesClients.UPDATE_SINGLE_CLIENTS])(state),
  updateHasError: hasErrorsSelector([typesClients.UPDATE_SINGLE_CLIENTS])(state),
  requests: selectClientRequests(state),

  categorization: selectAllCategorizations(state),
  categorizationLoading: loadingSelector([action.GET_ALL])(state),
  loadingAssigned: loadingSelector([action.GET_ASSIGNED])(state),
  addIsLoading: loadingSelector([action.ADD_ENTITY_CATEGORIZATION])(state),
  addHasError: hasErrorsSelector([action.ADD_ENTITY_CATEGORIZATION])(state),
  addError: singleErrorSelector([action.ADD_ENTITY_CATEGORIZATION])(state),
  seeViewers: isAllowed(state, [1063, 2163]),
  seeTransactions: isAllowed(state, [1066, 2166]),
  seeMoneyBalances: isAllowed(state, [1067, 2167]),
  sendingEmail: loadingSelector([actionTypes.SEND_CLIENT_EMAIL])(state),
  loadingT: loadingSelector([actionTypes.GET_TRANSACTIONS])(state),
  loadingET: loadingSelector([
    actionTypes.DOWNLOAD_EXCEL_TRANSACTIONS,
    actionTypes.GET_EXCEL_PRODUCTS,
  ])(state),
  excelHasError: hasErrorsSelector([
    actionTypes.DOWNLOAD_EXCEL_TRANSACTIONS,
    actionTypes.GET_EXCEL_PRODUCTS,
  ])(state),
  clientTransactions: selectTransactions(state),
  clientProducts: selectProducts(state),
  loadingP: loadingSelector([actionTypes.GET_PRODUCTS_CLIENT])(state),
  currentCurrency: selectCurrentCurrency(state),
  plan: selectActivePlans(state),
  country: selectCompanyCountry(state),
  lockClientLoading: loadingSelector([actionTypes.LOCK_CLIENT])(state),
  lockClientHasError: hasErrorsSelector([actionTypes.LOCK_CLIENT])(state),
  lockClientError: singleErrorSelector([actionTypes.LOCK_CLIENT])(state),
  unlockClientLoading: loadingSelector([actionTypes.UNLOCK_CLIENT])(state),
  unlockClientHasError: hasErrorsSelector([actionTypes.UNLOCK_CLIENT])(state),
  unlockClientError: singleErrorSelector([actionTypes.UNLOCK_CLIENT])(state),
})

const mapDispatchToProps = dispatch => ({
  getAllClients: (params, clientsId) => dispatch(getAllClients(params, clientsId)),
  setSelectedClients: clients => dispatch(setSelectedClients(clients)),
  setAllCotizapClients: clients => dispatch(setAllCotizapClients(clients)),
  changeStatus: clientId => dispatch(changeStatus(clientId)),
  updateMassive: request => dispatch(updateMultipleClients(request)),
  onCreateOrderPayment: payment => dispatch(onCreateOrderPayment(payment)),
  onCreateOrdersVisit: (request, params) =>
    dispatch(onCreateOrdersVisit(request, params)),
  onCreateCompanyWithClient: company => dispatch(onCreateCompanyWithClient(company)),
  getUsers: module => dispatch(getUsersChildrenByModule(module, true)),
  search: code => dispatch(searchCotizapClient(code)),
  softDelete: id => dispatch(softDelete(id)),
  uploadClientsCSV: (file, global, onSuccessLoadFile) =>
    dispatch(loadClientsCSV(file, global, onSuccessLoadFile)),
  getReport: (id, start, end) => dispatch(getReport(id, start, end)),
  setClientViewersUsers: (request, params) =>
    dispatch(setClientViewersUsers(request, params)),
  onChangeDefaulterStatus: clientId => dispatch(onChangeDefaulterStatus(clientId)),
  getArchivedClients: () => dispatch(getArchivedClients()),
  onHideClients: (type, clients) => dispatch(onHideClients(type, clients)),
  getClientLog: clientId => dispatch(getClientLog(clientId)),
  onUpdateDirtyClient: (ids, params) => dispatch(onUpdateDirtyClient(ids, params)),
  createLog: (client, description) => dispatch(createLog(client, description)),
  mergeClients: (pivot, list) => dispatch(mergeClients(pivot, list)),
  getSingleClient: id => dispatch(getSingleClient(id)),
  updateClient: (id, request) => dispatch(updateClient(id, request)),
  downloadExcel: (params, clientsId) => dispatch(downloadExcel(params, clientsId)),
  setParams: params => dispatch(setParams(params)),
  getSimpleLog: id => dispatch(getSimpleLog(id)),
  sendEmail: (request, all) => dispatch(sendEmail(request, all)),
  getTransactions: (params, id) => dispatch(getTransactionClient(params, id)),
  getExcelTransactions: (request, params) =>
    dispatch(downloadTransactionsExcel(request, params)),
  getProducts: (id, start, end) => dispatch(getProductsClient(id, { start, end })),
  getExcelProducts: (id, start, end) =>
    dispatch(downloadProductsExcel(id, { start, end })),

  getAllCategorization: () => dispatch(getAllCategorizations(5)),
  getCategorizationsByClient: clientId =>
    dispatch(getCategorizationsByEntity('clients', clientId)),
  assignCategorizationToClient: (categorization, id) =>
    dispatch(assignCategorizationToEntity('clients', categorization, id, true)),
  assignCategorizationToMultipleClients: (categorizationId, clients, remove) =>
    dispatch(
      assignCategorizationToMultipleEntities(
        'clients',
        categorizationId,
        clients,
        remove,
      ),
    ),
  setCategorizationMultiple: (request, params) =>
    dispatch(setCategorizationMultiple(request, params)),
  onSetModalOrder: id => dispatch(onSetModalOrder(id)),
  onSetPaymentId: id => dispatch(onSetPaymentId(id)),
  setModalEditClient: params => dispatch(setModalEditClient(params)),
  lockClient: (id, message) => dispatch(lockClient(id, message)),
  unlockClient: id => dispatch(unlockClient(id)),
  showAlert: alert => dispatch(showAlert(alert)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Clients)
