import React, { Component } from 'react'

import CustomSelect from '../inputs/Select/CustomSelect'
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker'

import {
  Row,
  Col,
  Accordion,
  useAccordionButton as useAccordionToggle,
} from 'react-bootstrap'
import IconFilter from 'src/assets/v2/icon-filter.svg'
import IconImg from 'src/components/buttons/IconImg'

const presets = [
  { value: 0, label: 'Hoy' },
  { value: 1, label: 'Últimos 7 días' },
  { value: 2, label: 'Este mes' },
  { value: 3, label: 'El mes pasado' },
  { value: 4, label: 'Últimos 2 meses' },
  { value: 5, label: 'Últimos 3 meses' },
]
const today = new Date()

function CustomToggle({ eventKey, setValue }) {
  const decoratedOnClick = useAccordionToggle(eventKey, () => undefined)

  return (
    <div>
      <IconImg
        style={{
          borderColor: 'hsl(0,0%,80%)',
          borderRadius: 7,
          borderStyle: 'solid',
          borderWidth: 1,
          padding: 3,
          margin: 0,
        }}
        icon={IconFilter}
        tooltip={'Filtrar'}
        onClick={() => {
          decoratedOnClick()
          setValue(0)
        }}
      />
    </div>
  )
}

export default class SelectedDates extends Component {
  state = {
    dateFrom: null,
    dateTo: null,
    preset: { value: 0, label: 'Hoy' },
    value: 0,
  }

  componentDidMount() {
    let {
      withOptionNull,
      noFormat,
      onDateChange,
      dates,
      setValueDates,
      nonExecute,
      initialPreset,
    } = this.props
    initialPreset = initialPreset ? initialPreset : { value: -1, label: 'Sin fecha' }

    if (!withOptionNull) {
      let dateFrom = dates && dates.dateFrom ? dates.dateFrom : new Date()
      let dateTo = dates && dates.dateTo ? dates.dateTo : new Date()
      this.setState({ dateFrom, dateTo, preset: initialPreset }, () => {
        if (nonExecute) return
        if (noFormat) onDateChange(this.state.dateFrom, this.state.dateTo)
        else this.formatDates(this.state.dateFrom, this.state.dateTo)

        if (initialPreset) this.onPresetChange(initialPreset)
      })
    } else {
      this.setState({ preset: initialPreset }, () => {
        if (initialPreset && initialPreset.value !== -1)
          this.onPresetChange(initialPreset)
      })
      if (dates && setValueDates) {
        this.setState({
          dateFrom: new Date(dates.dateFrom),
          dateTo: new Date(dates.dateTo),
        })
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.withOptionNull !== this.props.withOptionNull) {
      if (nextProps.withOptionNull) {
        this.setState({
          dateFrom: null,
          dateTo: null,
          preset: { value: -1, label: 'Sin Fecha' },
        })
        this.onPresetChange({ value: -1, label: 'Sin Fecha' })
      } else {
        this.setState(
          {
            dateFrom: new Date(),
            dateTo: new Date(),
            preset: { value: 0, label: 'Hoy' },
          },
          () => {
            if (this.props.nonExecute) return
            if (this.props.noFormat)
              this.props.onDateChange(this.state.dateFrom, this.state.dateTo)
            else this.formatDates(this.state.dateFrom, this.state.dateTo)
          },
        )
      }
    }
  }

  onDateFromChange = date => {
    const { noFormat, onDateChange } = this.props
    if (date !== undefined) {
      this.setState({ dateFrom: date, preset: null })
      if (noFormat) onDateChange(date, this.state.dateTo)
      else this.formatDates(date, this.state.dateTo)
    }
  }

  onDateToChange = date => {
    const { noFormat, onDateChange } = this.props
    if (date !== undefined) {
      this.setState({ dateTo: date, preset: null })
      if (noFormat) onDateChange(this.state.dateFrom, date)
      else this.formatDates(this.state.dateFrom, date)
    }
  }

  onPresetChange = preset => {
    const { onDateChange, noFormat } = this.props
    let start = new Date()
    start.setHours(0, 0, 0)
    let end = new Date()
    end.setHours(23, 59, 59)

    switch (preset.value) {
      case -1: {
        start = null
        end = null
        break
      }
      case 1: {
        start.setDate(start.getDate() - 7)
        break
      }
      case 2: {
        start = new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, 0)
        break
      }
      case 3: {
        start = new Date(today.getFullYear(), today.getMonth() - 1, 1, 0, 0, 0)
        end = new Date(today.getFullYear(), today.getMonth(), 0, 23, 59, 59)
        break
      }
      case 4: {
        start.setMonth(start.getMonth() - 2)
        break
      }
      case 5: {
        start.setMonth(start.getMonth() - 3)
        break
      }
      default:
    }
    this.setState({ dateFrom: start, dateTo: end, preset: preset })
    if (onDateChange && !noFormat) {
      this.formatDates(start, end)
    } else if (onDateChange && noFormat) {
      onDateChange(start, end)
    }
  }

  formatDates = (start, end) => {
    const { onDateChange } = this.props
    if (start) start = start.setHours(0, 0, 0, 0).valueOf()
    if (end) end = end.setHours(23, 59, 59, 99).valueOf()

    onDateChange(start, end)
  }

  getOptions = () => {
    const { withOptionNull } = this.props
    const response = []

    if (withOptionNull) response.push({ label: 'Sin fecha', value: -1 })
    return response.concat(presets)
  }

  getSize = () => {
    const { customSize } = this.props
    let response = { xl: 4, lg: 4, md: 4, sm: 4, xs: 6 }
    if (customSize) response = customSize
    return response
  }

  renderDates = () => {
    let { dateFrom, dateTo, preset } = this.state
    const { disabled, legend, range, tablet, mobile, hideS, mt } = this.props

    const size = this.getSize()

    return !range ? (
      <Row>
        <Col {...size}>
          <CustomDatePicker
            mt={mt}
            disabled={disabled}
            label={'Desde:'}
            value={dateFrom}
            disabledDays={{ after: dateTo }}
            info={legend || 'Fecha de inicio'}
            onDayChange={this.onDateFromChange}
          />
        </Col>
        <Col {...size}>
          <CustomDatePicker
            mt={mt}
            disabled={disabled}
            label={'Hasta:'}
            value={dateTo}
            disabledDays={{ before: dateFrom }}
            info={legend || 'Fecha de finalizaciòn'}
            onDayChange={this.onDateToChange}
          />
        </Col>
        <Col {...size}>
          <div className={'d-flex'}>
            <CustomSelect
              mt={mt}
              disabled={disabled}
              value={preset}
              options={this.getOptions()}
              onChange={this.onPresetChange}
              label={'o'}
            />
            {this.props.filter && (
              <div className={'mt-5 ml-3'}>
                <CustomToggle
                  eventKey={'0'}
                  setValue={v => this.setState({ value: v })}
                />
              </div>
            )}
          </div>
        </Col>
      </Row>
    ) : (
      <Row>
        <Col xl={6} lg={6} md={4} sm={6} xs={6}>
          <CustomDatePicker
            mt={mt}
            disabled={disabled}
            value={dateFrom}
            label={'Desde:'}
            disabledDays={{ after: dateTo }}
            onDayChange={this.onDateFromChange}
          />
        </Col>
        <Col xl={6} lg={6} md={4} sm={6} xs={6}>
          <CustomDatePicker
            mt={mt}
            disabled={disabled}
            value={dateTo}
            label={'Hasta:'}
            disabledDays={{ before: dateFrom }}
            onDayChange={this.onDateToChange}
          />
        </Col>
        {!hideS && (
          <Col
            style={{ marginTop: ''.concat(!tablet || mobile ? '-15px' : '') }}
            xl={12}
            lg={12}
            md={4}
            sm={12}
            xs={12}>
            <div className={'d-flex'}>
              <CustomSelect
                mt={mt}
                withoutLabel={mobile}
                label={!mobile && 'o'}
                disabled={disabled}
                value={preset}
                options={this.getOptions()}
                onChange={this.onPresetChange}
              />
              <div className={'mt-5 ml-3'}>
                {this.props.filter && (
                  <CustomToggle
                    eventKey={'0'}
                    setValue={v => this.setState({ value: v })}
                  />
                )}
              </div>
            </div>
          </Col>
        )}
      </Row>
    )
  }

  render() {
    return this.props.filter ? (
      <div>
        <Accordion defaultActiveKey={this.props.defaultActive ? '0' : '1'}>
          {this.renderDates()}
          <Accordion.Collapse eventKey={'0'}>
            {this.props.customFilter}
          </Accordion.Collapse>
        </Accordion>
      </div>
    ) : (
      this.renderDates()
    )
  }
}
