import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Col, Modal, Row } from 'react-bootstrap'
import { Paragraph, Button, FormText, SwitchV2 } from 'src/components'
import { faCheckDouble } from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes,
  generateToken,
  onSaveTableOrderClear,
  onUpdateItems,
  verifyToken,
  initClearTerminal,
} from 'src/actions/restaurant.actions'
import {
  selectAllPOSUser,
  selectClearRequest,
  selectGetTableOrder,
  selectTokenVerified,
} from 'src/selectors/restaurant.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import { selectCurrentUser } from 'src/selectors/user.selector'
import { showAlert } from 'src/actions/alert.actions'
import { handlerError, handlerSuccess, hasErrors } from 'src/selectors/error.selector'

import { haveAnyValue } from 'src/utils/utilitiesV2'

interface IFlags {
  init: boolean
  verify: boolean
  deleted: boolean
  update: boolean
}

/** Component that manages the process of cleaning the terminal and updating the commanded items
 * */
const TableOrderClear = ({ history }) => {
  const dispatch = useDispatch()
  const location = useLocation()

  const user = useSelector(selectCurrentUser)
  const tableOrder = useSelector(selectGetTableOrder)
  const posUser = useSelector(selectAllPOSUser)
  const pos = posUser.find(pos => pos.id === tableOrder.posId)

  const clearRequest: IPOSClearRequestProps = useSelector(selectClearRequest)
  const loading = useSelector(state =>
    loadingSelector([actionTypes.TABLE_ORDER_CLEAR_REQUEST])(state),
  )

  const tokenVerified = useSelector(selectTokenVerified)
  const loadingGeneratingToken = useSelector(state =>
    loadingSelector([actionTypes.GENERATE_TOKEN])(state),
  )
  const loadingVerifyingToken = useSelector(state =>
    loadingSelector([actionTypes.VERIFY_TOKEN])(state),
  )

  const loadingUpdateItems = useSelector(state =>
    loadingSelector([actionTypes.GET_TABLE_ORDER])(state),
  )

  const loadingDeletingTable = useSelector(state =>
    loadingSelector([actionTypes.DELETE_TABLE_ORDER])(state),
  )
  const hasErrorDeletingTable = useSelector(state =>
    hasErrors([actionTypes.DELETE_TABLE_ORDER])(state),
  )

  const loadingToken: boolean =
    loadingVerifyingToken || loadingUpdateItems || loadingDeletingTable

  const token = new URLSearchParams(location.search).get('token')
  const all = new URLSearchParams(location.search).get('all')

  const [flags, setFlags] = useState<IFlags>({
    init: false,
    verify: false,
    deleted: false,
    update: false,
  })
  const [request, setRequest] = useState<IPOSClearRequestProps>({
    show: false,
    showInventory: false,
    returnInventory: false,
    all: false,
    action: () => undefined,
  })

  const [initLink, setInitLink] = useState<boolean>(false)

  useEffect(() => {
    if (loading) setFlags({ ...flags, init: true })
    else if (flags.init) {
      setFlags({ ...flags, init: false })
      if (clearRequest.action) {
        // @ts-ignore
        const url = new URL(window.location)
        url.searchParams.delete('token')
        url.searchParams.delete('all')
        window.history.replaceState({}, '', url)

        const show = haveAnyValue(clearRequest.token) || pos?.clearToken

        if (haveAnyValue(clearRequest.token)) dispatch(verifyToken(clearRequest.token))
        else if (pos.clearToken)
          dispatch(
            generateToken(
              user.id,
              tableOrder.posId,
              tableOrder.tableId,
              clearRequest.items,
            ),
          )

        setRequest({
          ...clearRequest,
          returnInventory: false,
          show,
          showInventory: !show,
        })
      }
    }
  }, [loading])

  useEffect(() => {
    if (loadingVerifyingToken) setFlags({ ...flags, verify: true })
    else if (flags.verify) {
      setFlags({ ...flags, verify: false })
      if (!tokenVerified)
        dispatch(
          showAlert({
            type: 'error',
            show: true,
            title: 'Token inválido',
            text: 'Por favor intente nuevamente',
            showCancelButton: true,
            cancelButtonText: 'Cerrar',
            cancelButtonColor: '#B35796',
            confirmButtonText: 'Intentar de nuevo',
            confirmButtonColor: '#B35796',
          }),
        )
      else {
        if (request.seeInventory)
          setRequest({ ...request, show: false, showInventory: true })
        else request.action(false, request.byLink)
      }
    }
  }, [loadingVerifyingToken])

  useEffect(() => {
    if (loadingDeletingTable) setFlags({ ...flags, deleted: true })
    else if (flags.deleted) {
      setFlags({ ...flags, deleted: false })

      if (hasErrorDeletingTable) {
        dispatch(
          showAlert({
            ...handlerError(
              hasErrorDeletingTable.message ||
                'Ocurrió un error al intentar limpiar la terminal',
            ),
          }),
        )
      } else {
        dispatch(
          showAlert({
            ...handlerSuccess('Se ha limpiado la terminal correctamente'),
            onConfirm: () => {
              setRequest({ ...request, show: false, showInventory: false })
              dispatch(onSaveTableOrderClear({}))
              history.push('/distribucion/mesas')
            },
          }),
        )
      }
    }
  }, [loadingDeletingTable])

  useEffect(() => {
    if (!clearRequest.action) return
    if (loadingUpdateItems) setFlags({ ...flags, update: true })
    else if (flags.update) {
      setFlags({ ...flags, update: false })
      setRequest({ ...request, show: false, showInventory: false })
      dispatch(onSaveTableOrderClear({}))
    }
  }, [loadingUpdateItems])

  useEffect(() => {
    if (clearRequest.action) return
    if (loadingUpdateItems && haveAnyValue(token)) setInitLink(true)
    else if (initLink) {
      setInitLink(false)
      initAutomaticallyClear()
    }
  }, [loadingUpdateItems])

  const initAutomaticallyClear = () => {
    if (all === 'true') dispatch(initClearTerminal(tableOrder.id, token))
    else {
      const updated = tableOrder.detail
        .filter(d => d.preDeleted || haveAnyValue(d.preDiscount))
        .map(d => ({
          productId: d.detailId,
          deleted: d.preDeleted,
          discount: d.preDiscount,
        }))

      const action = (returnInventory: boolean, byLink: boolean): void => {
        dispatch(onUpdateItems(tableOrder.id, { returnInventory, updated, byLink }))
      }

      dispatch(
        onSaveTableOrderClear({
          all: false,
          token,
          action,
          items: [],
          seeInventory: updated.some(u => u.deleted),
          byLink: true,
        }),
      )
    }
  }

  return (
    <div>
      <Modal
        show={request.show}
        centered
        onHide={() => setRequest({ ...request, show: false })}>
        <Modal.Header closeButton>
          <Modal.Title>
            {request.all ? 'Limpiar la terminal' : 'Actualizar ítems'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Para realizar está acción se requiere permisos del administrador. Por favor
          ingrese el token enviado al correo electrónico del administrador.
          <FormText
            label={'Token'}
            value={request.token}
            disabled={loadingVerifyingToken}
            onChange={({ target }) => {
              setRequest({ ...request, token: target.value })
            }}
            required
          />
        </Modal.Body>

        <Modal.Footer>
          <div className={'container-buttons'}>
            <Button
              loading={loadingGeneratingToken}
              disabled={loadingVerifyingToken}
              right
              variant={'secondary'}
              onClick={() =>
                dispatch(generateToken(user.id, tableOrder.posId, tableOrder.tableId))
              }>
              Enviar nuevamente
            </Button>
            <Button
              loading={loadingToken}
              disabled={!request.token || loadingGeneratingToken}
              icon={faCheckDouble}
              right
              variant={'success'}
              onClick={() => dispatch(verifyToken(request.token))}>
              Verificar
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        show={request.showInventory}
        centered
        onHide={() => setRequest({ ...request, showInventory: false })}>
        <Modal.Header>
          <Modal.Title>
            {request.all ? 'Limpiar terminal' : 'Actualizar ítems'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12}>
              <Paragraph>
                ¿Desea realizar está acción?{' '}
                {request.all
                  ? 'Está acción liberara la terminal y ya no será\n' +
                    'posible seguir operando la orden actual'
                  : 'Está acción Actualizara los ítems seleccionados y ya no será posible restaurarlos'}
              </Paragraph>
            </Col>

            {clearRequest.seeInventory && (
              <Col xl={12}>
                <SwitchV2
                  label={'Restaurar inventario'}
                  info={
                    'Al activar está opción los insumos reducidos por manufacturas serán restaurados a la bodega origen'
                  }
                  checked={request.returnInventory}
                  onChange={value => setRequest({ ...request, returnInventory: value })}
                />
              </Col>
            )}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              loading={loadingDeletingTable || loading || loadingUpdateItems}
              onClick={() => {
                request.action(request.returnInventory, request.byLink)
              }}>
              Continuar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
export default TableOrderClear
