import './PaginatedTable.scss'
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import {
  Accordion,
  Col,
  Modal,
  ProgressBar,
  Row,
  useAccordionButton as useAccordionToggle,
} from 'react-bootstrap'
import ReactPaginate from 'react-paginate'

import { default as FormTextField } from 'src/components/inputs/FormTextField/FormText'
import CustomFilter from 'src/components/CustomFilter/CustomFilter'
import Checkbox from 'src/components/inputs/Checkbox/Checkbox'
import SelectedDates from 'src/components/SelectedDates/SelectedDates'
import Icon from 'src/components/buttons/IconButton'
import IconButton from 'src/components/buttons/IconButton'
import Button from 'src/components/buttons/Button'
import Select from 'src/components/inputs/Select/CustomSelect'
import Dropdown from 'src/components/buttons/Dropdown'
import Switch from 'src/components/inputs/Switch/Switch'

import './PaginatedTableV2.scss'

import useStateCallback from '../../hooks/useStateCallback'

import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { SortableContainer, sortableElement } from 'react-sortable-hoc'
import { Empty } from '../Empty/Empty'
import {
  faCog,
  faEdit,
  faInfoCircle,
  faSave,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons'
// import {useAccordionToggle} from "react-bootstrap/AccordionToggle";
import { Media } from 'react-breakpoints'

import { getItemValue } from '../../utils/formatters'

const pageSizes = [
  { value: 5, label: '5 resultados por página' },
  { value: 10, label: '10 resultados por página' },
  { value: 20, label: '20 resultados por página' },
  { value: 50, label: '50 resultados por página' },
  { value: 100, label: '100 resultados por página' },
]

let interval = null

const SortableItem = sortableElement(({ children }) => {
  return children
})

function RenderItems({
  eventKey,
  item,
  isSelect,
  onSelect,
  mobileSlide,
  principalAction,
}) {
  const decoratedOnClick = useAccordionToggle(eventKey, () => undefined)
  return (
    <li
      key={eventKey}
      style={{ padding: 0, 'list-style': 'none', color: '#525252' }}
      id={`${item.key}`}>
      <div
        className={`b-item ${item.selected ? 'table-mobile-selected' : ''}`}
        onClick={e => {
          if (isSelect) onSelect(item, e)
        }}>
        <div className="space-between">
          {item.checked && (
            <div
              className={'d-flex row align-items-center mr-2'}
              style={{ textAlign: 'left', justifyContent: 'flex-end' }}>
              {item.checked}
            </div>
          )}
          <div
            className="justify-content-start align-items-start bu-en"
            onClick={() => {
              decoratedOnClick()
            }}
            style={{
              textAlign: 'left',
              marginTop: 4,
              maxWidth: '50%',
              alignSelf: 'center',
            }}>
            <div className={`b-user-name ${item.selected ? 'text-white' : ''}`}>
              {item.title}
            </div>
            <div className={`b-user-email ${item.selected ? 'text-white' : ''}`}>
              {item.subtitle}
            </div>
          </div>
          <div className={'d-flex'} style={{ maxWidth: '50%' }}>
            <div style={{ alignSelf: 'center' }}>
              <div
                className={`b-user-email text-right ${
                  item.selected ? 'text-white' : ''
                }`}>
                {item.complement1}
              </div>
              <div
                className={`b-user-email text-right ${
                  item.selected ? 'text-white' : ''
                }`}>
                {item.complement2}
              </div>
            </div>
            <div
              className={'d-flex row align-items-center mr-2'}
              style={{ textAlign: 'right', justifyContent: 'flex-end' }}>
              <Row
                className="no-margins"
                style={{
                  paddingBottom: 2,
                  marginTop: 8,
                  marginLeft: 20,
                  justifyContent: 'flex-end',
                }}>
                {item.action}
              </Row>
            </div>
          </div>
        </div>
        {principalAction && principalAction(item)}
      </div>
      {!isSelect && (
        <Accordion.Collapse eventKey={eventKey}>
          <div className={'pi-more'} style={{ paddingBottom: 5 }}>
            {mobileSlide && mobileSlide(item) ? (
              mobileSlide(item)
            ) : (
              <div>
                {item.more &&
                  item.more.map((m, i) => (
                    <div
                      key={i}
                      className={'d-flex'}
                      style={{
                        justifyContent: 'space-between',
                        paddingLeft: 10,
                        paddingRight: 20,
                      }}>
                      <div className={'b-user-email'} style={{ minWidth: 100 }}>
                        <b>{m.title}</b>
                      </div>
                      <div className={'b-user-email text-right'}>{m.info}</div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </Accordion.Collapse>
      )}
    </li>
  )
}

const PaginatedTable = forwardRef(
  (
    {
      dateFrom,
      dateTo,
      dateFilter,
      getItems,
      pSize,
      total,
      getPagination,
      renderRow,
      noItemsLegend,
      loading,
      items,
      handleChange,
      placeholder,
      identifier,
      dragAndDrop,
      onDateChange,
      hideFilter,
      customClass,
      type,
      storageKey,
      footerText,
      headers = [],
      onCheck,
      headerText,
      defaultActive,
      tableDivStyle,
      customFilter,
      onClick,
      buttonTitle,
      itemsMobile,
      hideConfig,
      nullDate,
      isSelected,
      onClickTr,
      mobileAuto,
      styleTr,
      getItem,
      onScan,
      balancePermission,
      mobileSlide,
      hideEmpty,
      editable,
      setItems,
      principalAction,
      stopCicle,
    },
    ref,
  ) => {
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(pageSizes[1])
    const [search, setSearch] = useState('')
    const [editables, setEditables] = useState([])
    const [time, setTime] = useState(801)
    const [config, setConfig] = useState({ show: false })
    const [multiple, setMultiple] = useState(false)
    const [selected, setSelected] = useState([])
    const [load, setLoad] = useState(false)
    const [selection, setSelection] = useStateCallback([])
    let timer = 1000

    const [modalMobile, setModaMobile] = useState(false)
    const [modalDesktop, setModalDesktop] = useState(true)
    const [configDesktop, setConfigDesktop] = useState([])
    const [headerArr, setHeaderArr] = useState([])
    const [headersAux, setHeadersAux] = useState([])

    const [editValues, setEditValues] = useState(false)
    const [itemValues, setItemValues] = useState([])

    useEffect(() => {
      if (pSize !== undefined) setPageSize(pageSizes[pSize])
    }, [])

    useEffect(() => {
      setConfig({ show: false })
    }, [])

    useEffect(() => {
      if (stopCicle) {
        // cuando la tabla se actualiza constantemente y no permite usar filtros.
        const headersReceived = headers.filter(x => x.show === true).length // headers recibidos con show: true
        if (headersReceived === headersAux.length) return // Si coincide los headers recibidos con los cargados en la tabla, ya no se actualizan de nuevo.
      }

      if (headers.length > 0) {
        headersAux.splice(0, headersAux.length)
        setHeadersAux([headersAux])
        fillHeadersAux()
      } else if (headersAux.length > 0) {
        setHeadersAux([])
      }
    }, [headers])

    const fillHeadersAux = () => {
      headers.map(header => {
        if (header.show === false) return
        headersAux.push(header)
      })

      setHeadersAux([...headersAux])

      renderHeaders()
    }

    const renderHeaders = async () => {
      setConfigDesktop([])
      if (storageKey === undefined) return
      let values = storageKey ? localStorage.getItem(storageKey + 'desk') : null
      if (values !== null && Array.isArray(JSON.parse(values))) {
        setHeaderArr(JSON.parse(values))
        let arrAux = JSON.parse(values)
        headersAux.map((header, i) => {
          if (arrAux.find(x => x === i) !== undefined) {
            header.show = false
            configDesktop.push(i)
          }
        })
        setConfigDesktop([...configDesktop])
      } else {
        setHeaderArr([])
        headersAux.map((header, i) => {
          if (header === '') return
          // if(header.type === "currency" && balancePermission === false){
          //   header.show = false;
          //   return
          // }
          //console.log(header)
          header.show = true
        })
      }
    }

    useEffect(() => {
      if (items?.length > 0 && editable) {
        setItemValues(JSON.parse(JSON.stringify(items)))
      } else if (headersAux.length > 0) {
        setItemValues([])
      }
    }, [])

    const recoverData = () => {
      if (itemValues.length > 0) setItems(itemValues)
    }

    /*
  useEffect(() => {
    if(loading && handleChange){
      timer = 1000
    }
  }, [handleChange])

  useEffect(() => {
    if(time === 800 && handleChange) handleChange(search)
  }, [time])


  const myTimer = () => {
    if(timer <= 0){
      clearInterval(interval);
      interval = null;
      resetPagination();
    }
    else timer = timer - 100
  };
  */

    const onChange = e => {
      let value = e.target.value
      setSearch(value)
      setPage(1)
      setEditables([])
      if (handleChange) {
        setLoad(true)
        clearInterval(interval)
        interval = setTimeout(() => {
          handleChange(value)
          setLoad(false)
        }, 1000)
      }
    }

    const onPageClick = data => {
      if (getPagination) {
        getPagination(data.selected * pageSize.value, pageSize.value, search)
      }
      setPage(data.selected + 1)
      setEditables([])
    }

    const onPageSizeChanges = pageSize => {
      if (getPagination) {
        getPagination(0, pageSize.value, search)
      }
      setPageSize(pageSize)
      setPage(1)
      setEditables([])
    }

    const filter = item => {
      try {
        if (search && search !== '') {
          const exp = new RegExp(search.toUpperCase(), 'g')
          return Object.keys(item).some(p =>
            `${JSON.stringify(item[p])}`.toUpperCase().match(exp),
          )
        }
      } catch (e) {
        console.log(e)
      }

      return true
    }

    const resetPagination = () => {
      setPage(1)
      setEditables([])
      setTime(800)
    }

    const renderItem = (item, index) => {
      // let item = !valItem ? {} : { ...valItem }
      if (!item) item = {}

      item.editable = editables?.includes(item[identifier])

      if (dragAndDrop)
        return (
          <SortableItem key={index} index={index} sortIndex={index}>
            {renderRow(item, index)}
          </SortableItem>
        )

      let aux = renderRow(item, index)
      // console.log(aux.props.children.pop())
      return aux
    }

    const renderRows = (renderRow, total, lowerLimit, upperLimit, itemsToRender) => {
      return renderRow && total
        ? itemsToRender?.map(renderItem)
        : itemsToRender.map((item, index) => {
            if (index >= lowerLimit && index < upperLimit) {
              return renderItem(item, index)
            }
          })
    }
    const renderRowsFilter = (total, lowerLimit, upperLimit, itemsToRender) => {
      return total ? itemsToRender : itemsToRender.slice(lowerLimit, upperLimit)
    }

    const getItemsFiltered = values => {
      if (handleChange) return values
      else {
        return values.filter(filter)
      }
    }

    //  const values = window.innerWidth > 480 ? items : itemsMobile
    const values =
      window.innerWidth > 480 ? items : mobileAuto || renderRow ? items : itemsMobile

    const itemsToRender = Array.isArray(values) ? getItemsFiltered(values) : []

    const totalItems = itemsToRender.length
    const totalPages = Math.ceil((total || totalItems) / pageSize.value)
    const upperLimit = (page - 1) * pageSize.value + pageSize.value
    const lowerLimit = (page - 1) * pageSize.value

    useEffect(() => {
      if (totalPages < page && page > 1) setPage(page - 1)
    }, [totalPages])

    const resetSelection = () => {
      return new Promise(resolve => {
        setSelection([], resolve)
      })
    }

    const onSelectRow = (item, id) => {
      const newSelection = [...selection]
      const index = newSelection.findIndex(i => i[id] === item[id])
      index !== -1 ? newSelection.splice(index, 1) : newSelection.push(item)
      setSelection(newSelection)
    }

    useImperativeHandle(
      ref,
      () => ({
        onSelectRow,
        selection,
        resetSelection,
      }),
      [selection],
    )

    /*  ---- QUITAR COLUMNAS ----   */
    const renderConfig = (index, title, component) => {
      if (title === undefined) return
      return (
        <Row
          key={index}
          style={{ marginBottom: 8, borderBottom: '1px dotted lightgrey' }}>
          <Col className={'text-center'} lg={8} xs={8} md={8} xl={8}>
            <h5>{title}</h5>
          </Col>
          <Col
            className={'text-center'}
            style={{ alignSelf: 'center', padding: 0 }}
            xs={4}
            md={4}
            xl={4}>
            {component}
          </Col>
        </Row>
      )
    }

    const modalConfigDesktop = () => {
      return (
        <Modal
          show={config.show && modalDesktop}
          size={'md'}
          centered
          aria-labelledby={'contained-modal-title-vcenter'}
          onHide={() => {
            setConfig({ show: false })
            renderHeaders()
          }}>
          <Modal.Header closeButton>
            <Modal.Title>Configuraciones</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {headersAux.map((item, i) => {
              if (
                (item.custom !== undefined && item.label === undefined) ||
                item.label === '' ||
                item.label === undefined ||
                (item.type === 'currency' && balancePermission === false)
              )
                return
              return renderConfig(
                i,
                item.label,
                <Switch
                  loading={false}
                  key={i}
                  id={item.id}
                  checked={
                    headerArr !== null && !headerArr.find(x => x === i)
                      ? item.show
                      : false
                  }
                  changeValue={val => {
                    item.show = !item.show
                    if (headerArr !== null) {
                      if (headerArr.some(x => x === i)) {
                        if (val) setHeaderArr([...headerArr.filter(x => x !== i)])
                        else item.show = false
                      }
                    }
                    if (configDesktop.some(index => index === i) && val)
                      setConfigDesktop([...configDesktop.filter(x => x !== i)])
                    else {
                      headerArr.push(i)
                      setConfigDesktop([...configDesktop, i])
                    }
                  }}
                />,
              )
            })}
          </Modal.Body>
          <Modal.Footer>
            <Row className={'container-buttons'}>
              <Button
                color={'secondary'}
                onClick={() => {
                  setModaMobile(true)
                  setModalDesktop(false)
                }}>
                Mobile
              </Button>

              <Button
                color={'secondary'}
                onClick={() => {
                  localStorage.removeItem(storageKey + 'desk')
                  renderHeaders()
                  setConfig({ show: false })
                }}>
                Restaurar por defecto
              </Button>
              <Button
                onClick={() => {
                  localStorage.setItem(storageKey + 'desk', JSON.stringify(configDesktop))
                  setConfig({ show: false })
                }}>
                Guardar
              </Button>
            </Row>
          </Modal.Footer>
        </Modal>
      )
    }

    const getItemsMobile = data => {
      let array = []
      let store = undefined
      if (storageKey) store = localStorage.getItem(storageKey)
      if (!store) {
        store = {
          title: { value: [''], type: 'text' },
          subtitle: { value: [''], type: 'text' },
          complement1: { value: [''], type: 'text' },
          complement2: { value: [''], type: 'text' },
        }
        headersAux
          .filter(x => {
            if (!x.select && !x.config && x.checked === undefined) {
              if (x.type === 'currency' && balancePermission === false) return false
              return true
            } else return false
          })
          .map((header, index) => {
            //se quito x.show
            switch (index) {
              case 0:
                store.title = {
                  value: header.value,
                  type: header.type,
                  custom: header.custom,
                }
                break
              case 1:
                store.subtitle = {
                  value: header.value,
                  type: header.type,
                  custom: header.custom,
                }
                break
              case 2:
                store.complement1 = {
                  value: header.value,
                  type: header.type,
                  custom: header.custom,
                }
                break
              case 3:
                store.complement2 = {
                  value: header.value,
                  type: header.type,
                  custom: header.custom,
                }
                break
              default:
                return
            }
          })
      } else store = JSON.parse(store)
      data &&
        data.map((item, i) => {
          let more = []
          let custom = undefined
          let simpleChecked = undefined
          headersAux.map((header, index) => {
            if (header.type === 'currency' && balancePermission === false) return
            if (header.simpleChecked) {
              simpleChecked = header.custom
              return
            }
            if (header.index) {
              more.push({ title: header.label, info: i + 1 })
              return
            }
            if (header.config) {
              if (header.custom) {
                custom = header.custom
              }
              return
            }
            if (header.checked !== undefined) {
              return
            }
            if (header.action) {
              if (header.custom) {
                custom = header.custom
              }
              return
            }
            if (header.custom && header.value) {
              more.push({ title: header.label, info: header.custom(item, index) })
              return
            }

            if (header.custom) {
              /* empty */
            } else
              more.push({
                title: header.label,
                info: getItemValue(item, header.value, header.type),
              })
          })
          let object = {
            id: item.id,
            title: getItemValue(
              item,
              store.title.value,
              store.title.type,
              false,
              false,
              store.title.custom,
            ),
            subtitle: getItemValue(
              item,
              store.subtitle.value,
              store.subtitle.type,
              false,
              false,
              store.subtitle.custom,
            ),
            complement1: getItemValue(
              item,
              store.complement1.value,
              store.complement1.type,
              false,
              false,
              store.complement1.custom,
            ),
            complement2: getItemValue(
              item,
              store.complement2.value,
              store.complement2.type,
              false,
              false,
              store.complement2.custom,
            ),
            selected: selected.filter(d => d.id === item.id).length > 0,
            checked: simpleChecked && simpleChecked(item),
            key: `storeKey-${item.id}`,
            action: custom && custom(item),
            more,
            item: item,
          }
          array.push(object)
        })
      return array
    }

    const renderRowV2 = (hide, selected, item, index) => {
      return !hide ? (
        <tr
          className={selected ? 'active data' : 'data'}
          key={index}
          onClick={e => {
            onClickTr && onClickTr(e, item, index)
          }}
          data-item={item}
          style={styleTr && styleTr(item)}>
          {headersAux.map((header, i) => {
            if (header.type === 'currency' && balancePermission === false)
              header.show = false
            if (headerArr.find(x => x === i) !== undefined) {
              header.show = false
            }
            let value = header.value
            if (header.label === undefined)
              return (
                <td key={i} className={header.className ? header.className : ''}>
                  {' '}
                </td>
              )
            if (header.label === '' && header.custom === undefined)
              return (
                <td key={i} className={header.className ? header.className : ''}>
                  {' '}
                </td>
              )
            if (header.index) {
              return (
                <td key={i} className={header.className}>
                  {' '}
                  {index + 1}{' '}
                </td>
              )
            }
            if (header.custom !== undefined && header.show) {
              return (
                <td
                  key={i}
                  style={{ ...header.style }}
                  className={
                    header.classNameCustom
                      ? header.classNameCustom(item)
                      : header.className
                  }>
                  {' '}
                  {header.custom(item, index)}
                </td>
              )
            }
            if (header.show === true) {
              return (
                <>
                  <td key={i} className={header.className}>
                    {' '}
                    {editValues ? (
                      <input
                        value={item[value[0]]}
                        onChange={e => {
                          item[value[0]] = e.target.value
                          setItems(items)
                        }}
                      />
                    ) : (
                      getItemValue(item, value, header.type)
                    )}
                    {header.addText && header.addText}{' '}
                  </td>
                </>
              )
            }
          })}
        </tr>
      ) : (
        <></>
      )
    }

    return (
      <div style={{ overflowX: 'hidden' }}>
        {dateFilter && (
          <SelectedDates
            noFormat
            nonExecute
            withOptionNull={nullDate}
            dates={{ dateFrom, dateTo }}
            onDateChange={(start, end) => onDateChange(start, end, search)}
          />
        )}

        {!hideFilter ? (
          <Row>
            {!customFilter ? (
              <>
                <Col xs={12} sm={0} md={8}>
                  {' '}
                  <FormTextField
                    placeholder={placeholder ? placeholder : 'Buscar...'}
                    id="filter"
                    value={search}
                    onChange={onChange}
                  />
                </Col>
                <Col md={4}>
                  <Select
                    withoutLabel
                    value={pageSize}
                    onChange={onPageSizeChanges}
                    options={pageSizes}
                  />
                </Col>
              </>
            ) : (
              <div style={{ marginLeft: 0, width: '100%' }}>
                <CustomFilter
                  defaultActive={defaultActive}
                  placeholder={placeholder ? placeholder : 'Buscar...'}
                  search={search}
                  onChange={onChange}
                  customFilter={customFilter}
                  onScan={onScan}
                  pageSelect={
                    <Select
                      withoutLabel
                      value={pageSize}
                      onChange={onPageSizeChanges}
                      options={pageSizes}
                    />
                  }
                />
              </div>
            )}
          </Row>
        ) : (
          ''
        )}
        {editable && (
          <Row>
            <Col>
              <div
                style={{
                  float: 'right',
                  display: 'inline',
                }}>
                {editValues && (
                  <Button
                    color={'secondary'}
                    icon={faSignOutAlt}
                    onClick={() => {
                      recoverData()
                      setEditValues(false)
                    }}>
                    Cancelar edicion
                  </Button>
                )}
                <Button
                  icon={editValues ? faSave : faEdit}
                  onClick={() => {
                    editValues ? setEditValues(false) : setEditValues(true)
                    setItemValues(JSON.parse(JSON.stringify(items)))
                  }}>
                  {editValues ? 'Guardar' : 'Editar valores'}
                </Button>
              </div>
            </Col>
          </Row>
        )}
        {(loading || load) && (
          <Row>
            <Col>
              <div className={'pb-custom'}>
                <ProgressBar
                  label="Cargando"
                  animated
                  now={100}
                  style={{ marginBottom: 10 }}
                />
              </div>
            </Col>
          </Row>
        )}

        <div
          style={{
            backgroundColor: (loading || load) && 'rgba(203,200,200,0.3)',
            opacity: (loading || load) && 0.7,
          }}>
          <div className={customClass ? customClass : 'scroll-x-without-height'}>
            <Media>
              {({ breakpoints, currentBreakpoint }) => {
                //const mobile = breakpoints[currentBreakpoint] < breakpoints.mobileTable && itemsMobile;
                const mobile =
                  breakpoints[currentBreakpoint] < breakpoints.mobileTable &&
                  (itemsMobile || mobileAuto)
                const it = getItemsFiltered(
                  !mobile
                    ? items
                      ? items
                      : []
                    : mobileAuto
                    ? getItemsMobile(items)
                    : itemsMobile,
                )

                return breakpoints[currentBreakpoint] >= breakpoints.mini ? (
                  <div>
                    {mobile && (
                      <div
                        className={`w-100 d-flex ${
                          getItems ? 'justify-content-between' : 'justify-content-end'
                        }`}>
                        {getItems || getItem ? (
                          <Checkbox
                            label={'Habilitar selección múltiple'}
                            checked={multiple}
                            onChange={val => {
                              if (!val.target.checked) {
                                setSelected([])
                                if (getItems) getItems(null, val)
                                else if (getItem) getItem(null, val)
                              }
                              setMultiple(!multiple)
                            }}
                          />
                        ) : (
                          <></>
                        )}
                        {!hideConfig && (
                          <Icon
                            icon={faCog}
                            color="#525252"
                            onClick={() => {
                              let values = storageKey
                                ? localStorage.getItem(storageKey)
                                : null
                              if (values) setConfig({ ...JSON.parse(values), show: true })
                              else setConfig({ show: true })
                            }}
                            size={'1_5x'}
                          />
                        )}
                      </div>
                    )}
                    {mobile ? (
                      <Accordion style={{ height: 500 }}>
                        {it.length > 0 ? (
                          <div className="main-list" style={{ maxHeight: 500 }}>
                            {(total ? it : it.slice(lowerLimit, upperLimit)).map(
                              (item, index) => (
                                <li
                                  key={index}
                                  style={{
                                    listStyle: 'none',
                                  }}>
                                  <RenderItems
                                    item={item}
                                    eventKey={`${index}`}
                                    type={type}
                                    isSelect={multiple}
                                    mobileSlide={mobileSlide}
                                    principalAction={
                                      principalAction ? principalAction : undefined
                                    }
                                    onSelect={(v, e) => {
                                      if (selected.findIndex(d => d.id === v.id) === -1) {
                                        selected.push(v)
                                        if (mobileAuto) {
                                          if (getItems) getItems(v, e)
                                          else {
                                            selected.splice(0, selected.length - 1)
                                            getItem(item, e)
                                          }
                                        } else if (getItems) getItems(selected)
                                        setSelected([...selected])
                                      } else {
                                        let values = selected.filter(s => s.id !== v.id)
                                        setSelected([...values])
                                        if (mobileAuto) {
                                          if (getItems) getItems(v, e)
                                          else {
                                            getItem(item, e)
                                          }
                                        } else if (getItems) getItems(values)
                                      }
                                    }}
                                  />
                                </li>
                              ),
                            )}
                          </div>
                        ) : (
                          <Empty
                            subtitle={noItemsLegend}
                            loading={loading}
                            subtitleSecond={' '}
                            onClick={onClick}
                            hideButton={!onClick}
                          />
                        )}
                      </Accordion>
                    ) : (
                      <div style={{ ...tableDivStyle }}>
                        {headerText && <p>{headerText}</p>}
                        <table className="tf-table table table-hover mb-4">
                          <thead>
                            <tr className="table-title">
                              {headersAux &&
                                headersAux
                                  .filter((header, i) => {
                                    if (
                                      headerArr.find(x => x === i) === undefined &&
                                      !(
                                        header.type === 'currency' &&
                                        balancePermission === false
                                      )
                                    ) {
                                      return true
                                    }
                                  })
                                  .map((item, index) =>
                                    onCheck && index === 0 ? (
                                      <th key={`${index}`} style={{ paddingLeft: 12 }}>
                                        <OverlayTrigger
                                          placement={'top'}
                                          overlay={
                                            <Tooltip id={'tooltip'}>
                                              Seleccionar todos
                                            </Tooltip>
                                          }>
                                          <Checkbox
                                            style={{
                                              display: 'inline-table',
                                              marginBottom: 0,
                                            }}
                                            checked={item.checked}
                                            onChange={() => onCheck(!item.checked)}
                                          />
                                        </OverlayTrigger>
                                      </th>
                                    ) : item.config ? (
                                      <th
                                        key={`${index}`}
                                        className={item.className ? item.className : ''}
                                        style={{ textAlign: 'center' }}>
                                        <Icon
                                          icon={faCog}
                                          onClick={() => {
                                            let values = storageKey
                                              ? localStorage.getItem(storageKey)
                                              : null
                                            if (values)
                                              setConfig({
                                                ...JSON.parse(values),
                                                show: true,
                                              })
                                            else setConfig({ show: true })
                                          }}
                                          size={'1_5x'}
                                        />
                                      </th>
                                    ) : (
                                      item.show && (
                                        <th
                                          scope="col"
                                          className={
                                            '' + item.className
                                              ? item.className
                                              : 'text-left '
                                          }
                                          style={item.style}
                                          key={`${index}`}>
                                          <div>
                                            {item.title || item.label}
                                            {item.info && (
                                              <IconButton
                                                placement={'right'}
                                                tooltip={item.info}
                                                color={'rgba(34, 96, 149, 0.75)'}
                                                style={{ marginLeft: 5 }}
                                                icon={faInfoCircle}
                                                size={'sm'}
                                              />
                                            )}
                                          </div>
                                        </th>
                                      )
                                    ),
                                  )}
                            </tr>
                          </thead>
                          {it.length > 0 ? (
                            <tbody className="table-option">
                              {renderRow ? (
                                !dragAndDrop ? (
                                  renderRows(renderRow, total, lowerLimit, upperLimit, it)
                                ) : (
                                  <SortableContainer
                                    hideSortableGhost={true}
                                    onSortEnd={true}>
                                    <div>
                                      {renderRows(
                                        renderRow,
                                        total,
                                        lowerLimit,
                                        upperLimit,
                                        it,
                                      )}
                                    </div>
                                  </SortableContainer>
                                )
                              ) : (
                                renderRowsFilter(total, lowerLimit, upperLimit, it).map(
                                  (item, index) => {
                                    // let item = !valItem ? {} : { ...valItem }
                                    let selected
                                    if (isSelected) {
                                      selected = isSelected(item, index)
                                    }
                                    //console.log(headersAux)
                                    let hide =
                                      headersAux.length > 0
                                        ? headersAux[0].hideTr
                                          ? headersAux[0].hideTr(item)
                                          : false
                                        : false

                                    item.editable = editables.includes(item[identifier])
                                    if (dragAndDrop)
                                      return (
                                        <SortableItem
                                          key={index}
                                          index={index}
                                          sortIndex={index}>
                                          {renderRowV2(hide, selected, item, index)}
                                        </SortableItem>
                                      )
                                    return renderRowV2(hide, selected, item, index)
                                  },
                                )
                              )}
                            </tbody>
                          ) : (
                            ''
                          )}
                        </table>
                        {it.length === 0 && !hideEmpty && (
                          <Empty
                            subtitle={noItemsLegend}
                            loading={loading}
                            subtitleSecond={' '}
                            buttonTitle={buttonTitle}
                            onClick={onClick}
                            hideButton={!onClick}
                          />
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <div />
                )
              }}
            </Media>
          </div>
          {footerText && (
            <Row
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                textAlign: 'right',
                margin: 5,
              }}>
              {footerText}
            </Row>
          )}
          <Row className={'container-buttons'}>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              {(total || itemsToRender.length) > pageSize.value && (
                <ReactPaginate
                  previousLabel={'<'}
                  nextLabel={'>'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={totalPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={onPageClick}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                  forcePage={page - 1}
                />
              )}
            </Col>
          </Row>

          {!hideFilter && itemsToRender.length > 0 && (
            <div style={{ textAlign: 'center', width: '100%', marginTop: 20 }}>
              Mostrando {lowerLimit + 1} a{' '}
              {(total || itemsToRender.length) < upperLimit
                ? total || itemsToRender.length
                : upperLimit}{' '}
              de {total || itemsToRender.length}
            </div>
          )}
        </div>

        <Media>
          {({ breakpoints, currentBreakpoint }) => {
            //const mobile = breakpoints[currentBreakpoint] < breakpoints.tablet && itemsMobile;
            const mobile =
              breakpoints[currentBreakpoint] < breakpoints.mobileTable &&
              (itemsMobile || mobileAuto)

            if (renderRow || mobile) {
              setModaMobile(true)
              setModalDesktop(false)
            }
            return (
              <>
                {modalConfigDesktop()}

                <Modal
                  show={config.show && modalMobile}
                  size={'lg'}
                  centered
                  onHide={() => {
                    setConfig({ show: false })
                  }}>
                  <Modal.Header closeButton>
                    <Modal.Title> Configuración de la tabla versión móvil</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <h6>
                      Seleccione los campos que quiere que se vean en la versión móvil de
                      esta tabla
                    </h6>
                    <div className="b-item">
                      <div className="space-between" style={{ alignItems: 'center' }}>
                        <div
                          className="justify-content-start align-items-start"
                          style={{ width: '45%' }}>
                          <div className={'b-user-name'}>
                            <Select
                              isSearchable={false}
                              withoutLabel
                              options={headersAux.filter(h => {
                                if (!h.select && !h.config) {
                                  if (
                                    h.type === 'currency' &&
                                    balancePermission === false
                                  )
                                    return false
                                  return true
                                } else {
                                  return false
                                }
                              })}
                              value={config.title}
                              onChange={item => {
                                setConfig({ ...config, title: item })
                              }}
                            />
                          </div>
                          <div className={'b-user-email'}>
                            <Select
                              isSearchable={false}
                              withoutLabel
                              options={headersAux.filter(h => {
                                if (!h.select && !h.config) {
                                  if (
                                    h.type === 'currency' &&
                                    balancePermission === false
                                  )
                                    return false
                                  return true
                                } else {
                                  return false
                                }
                              })}
                              value={config.subtitle}
                              onChange={item => {
                                setConfig({ ...config, subtitle: item })
                              }}
                            />
                          </div>
                        </div>
                        <div className={'align-items-center'} style={{ width: '45%' }}>
                          <div className={'b-user-email text-right'}>
                            <Select
                              isSearchable={false}
                              withoutLabel
                              options={headersAux.filter(h => {
                                if (!h.select && !h.config) {
                                  if (
                                    h.type === 'currency' &&
                                    balancePermission === false
                                  )
                                    return false
                                  return true
                                } else {
                                  return false
                                }
                              })}
                              value={config.complement1}
                              onChange={item => {
                                setConfig({ ...config, complement1: item })
                              }}
                            />
                          </div>
                          <div className={'b-user-email text-right'}>
                            <Select
                              isSearchable={false}
                              withoutLabel
                              options={headersAux.filter(h => {
                                if (!h.select && !h.config) {
                                  if (
                                    h.type === 'currency' &&
                                    balancePermission === false
                                  )
                                    return false
                                  return true
                                } else {
                                  return false
                                }
                              })}
                              value={config.complement2}
                              onChange={item => {
                                setConfig({ ...config, complement2: item })
                              }}
                            />
                          </div>
                        </div>
                        <div>
                          <Dropdown disabled />
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <div className="container-buttons">
                      {!renderRow && !mobile && (
                        <Button
                          color={'secondary'}
                          onClick={() => {
                            setModaMobile(false)
                            setModalDesktop(true)
                          }}>
                          Escritorio
                        </Button>
                      )}
                      <Button
                        color={'secondary'}
                        onClick={() => {
                          localStorage.removeItem(storageKey)
                          setConfig({ show: false })
                        }}>
                        Restaurar por defecto
                      </Button>
                      <Button
                        disabled={
                          !config.complement2 ||
                          !config.complement1 ||
                          !config.subtitle ||
                          !config.title
                        }
                        onClick={() => {
                          localStorage.setItem(storageKey, JSON.stringify(config))
                          setConfig({ show: false })
                        }}>
                        Guardar
                      </Button>
                    </div>
                  </Modal.Footer>
                </Modal>
              </>
            )
          }}
        </Media>
      </div>
    )
  },
)

export default PaginatedTable

export const configurationType = {
  PROVIDER: { key: 'table-orders', default: ['provider', 'client', ''] },
}
