import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { ModalTaxFree } from 'src/components/Items/TaxFree/ModalTaxFree'
import { isAllowed } from 'src/selectors/modules.selector'
import { selectCompanyCountry } from 'src/selectors/company.selector'
import { Country } from 'src/enums/countries'

export const TaxFree = ({ item, toSelect, updateTax }) => {
  const country = useSelector(selectCompanyCountry)
  const isGT = country.id === Country.GT

  const allowDisableIvaPermission = useSelector(state => isAllowed(state, [1133]))

  const allowDisableIva = isGT && allowDisableIvaPermission

  const [showIVA, setShowIVA] = useState(false)

  return (
    <>
      {allowDisableIva && item.id !== undefined && !item.includeIva ? (
        <div
          className="ft-sub left mt-2 ml-2"
          style={{
            cursor: 'pointer',
            fontWeight: 500,
            textDecoration: 'underline',
          }}
          onClick={() => {
            setShowIVA(true)
          }}>
          {`Exento de IVA: ${
            item.phraseReference && item.phraseReference.label.substring(0, 25)
          }...`}
        </div>
      ) : (
        allowDisableIva &&
        item.id && (
          <div
            className="ft-sub left mt-2 ml-2"
            style={{
              cursor: 'pointer',
              fontWeight: 500,
              textDecoration: 'underline',
            }}
            onClick={() => {
              setShowIVA(true)
            }}>
            Quitar IVA
          </div>
        )
      )}
      <ModalTaxFree
        show={showIVA}
        item={toSelect}
        onClose={() => setShowIVA(false)}
        updateTax={updateTax}
      />
    </>
  )
}
