import './TableOrderPage.scss'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Alert from 'sweetalert-react'
import { Spinner } from 'react-bootstrap'
import { Action, CustomFilter, Icon, Title } from 'src/components'
import { faShare, faSpinner } from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes as posTypes,
  getCategoryItems,
  getItems,
  getSearchItems,
  getTableOrder,
  replaceCategoryItems,
  replaceItems,
  replaceSearchItems,
  setPOSSearch,
  setShowItemDetail,
  setTabSelected,
} from 'src/actions/restaurant.actions'
import { loadingSelector } from 'src/selectors/loading.selector'
import {
  selectCategorySelected,
  selectDescription,
  selectGetTableOrder,
  selectPosConfigurationByType,
  selectPosItemsExistence,
  selectPosItemsExistenceSearch,
  selectPosItemsExistenceCategory,
  selectPOSSearch,
  selectPOSTabs,
  selectPOSTabSelected,
  selectTableCategoryItems,
  selectTableItems,
  selectTableSearchItems,
  selectTableTotalCategoryItems,
  selectTableTotalItems,
  selectTableTotalSearchItems,
  selectAllPOSUser,
} from 'src/selectors/restaurant.selector'

import ItemCard from './ItemCard'
import useDebounce from 'src/hooks/useDebounce'
import useIntersectionObserver from 'src/hooks/useIntersectionObserver'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { seeProductLine } from 'src/actions/products.actions'
import { handlerError } from 'src/selectors/error.selector'

import TableOrderPayments from './TableOrderPayments'
import TableOrderTickets from './TableOrderTickets'
import ManufacturePOS from './../ManufacturePOS/ManufacturePOS'
import restaurantService from 'src/services/restaurant.service'
import { posPanelConfigurations } from 'src/enums/posPanelConfigurations'
import { posTypesEnum } from 'src/enums/posTypes'
import { selectCompanyFieldById } from 'src/selectors/company.selector'
import { companyFieldsSell } from 'src/enums/companyFields'
import { getContextCanvasToPOSLogo, getExistenceByItemId } from '../POSFunctions'
import { haveAnyValue } from 'src/utils/utilitiesV2'
import usePOSExistence from 'src/content/Restaurant/hooks/usePOSExistence'

const TableOrderItems = ({
  id,
  posId,
  initLoading,
  onTransferTable,
  onClick,
  minimum,
  step,
}) => {
  const dispatch = useDispatch()
  const search = useSelector(selectPOSSearch)

  const description = useSelector(selectDescription)
  const tableOrder = useSelector(selectGetTableOrder)
  const selectedCategory = useSelector(selectCategorySelected)

  const posUser = useSelector(selectAllPOSUser)
  const pos = posUser.find(pos => pos.id === tableOrder.posId)

  const items = useSelector(selectTableItems)
  const total = useSelector(selectTableTotalItems)
  const categoryItems = useSelector(selectTableCategoryItems)
  const categoryTotal = useSelector(selectTableTotalCategoryItems)
  const searchItems = useSelector(selectTableSearchItems)
  const searchTotal = useSelector(selectTableTotalSearchItems)

  const productLine = useSelector(state => state.products.productLine)
  const enabledZeroPrices = useSelector(state =>
    selectCompanyFieldById(state, companyFieldsSell.enabledZeroPrices),
  )
  const canUseActionTransfer = useSelector(state =>
    selectPosConfigurationByType(state, posPanelConfigurations.actionTransfer),
  )
  const canUseActionBarcode = useSelector(state =>
    selectPosConfigurationByType(state, posPanelConfigurations.actionBarcode),
  )

  const loading = useSelector(state =>
    loadingSelector([
      posTypes.GET_TABLE_ITEMS,
      posTypes.GET_TABLE_SEARCH_ITEMS,
      posTypes.GET_TABLE_CATEGORY_ITEMS,
      posTypes.REPLACE_TABLE_ITEMS,
      posTypes.REPLACE_TABLE_SEARCH_ITEMS,
      posTypes.REPLACE_TABLE_CATEGORY_ITEMS,
    ])(state),
  )

  const loadingExistence = useSelector(state =>
    loadingSelector([posTypes.SET_POS_ITEMS_EXISTENCE])(state),
  )
  const itemsExistence = useSelector(selectPosItemsExistence)
  const itemsExistenceSearch = useSelector(selectPosItemsExistenceSearch)
  const itemsExistenceCategory = useSelector(selectPosItemsExistenceCategory)

  const isSearchActive = !!search
  const isCategorySelected = Object.keys(selectedCategory).length !== 0

  usePOSExistence(isSearchActive, isCategorySelected)

  const itemsToGetExistence = isSearchActive
    ? itemsExistenceSearch
    : isCategorySelected
    ? itemsExistenceCategory
    : itemsExistence

  const initial = (!isSearchActive && !isCategorySelected && items.length) || minimum

  const selectedTab = useSelector(selectPOSTabSelected)
  const tabs = useSelector(selectPOSTabs)
  const filteredTabs = tabs.filter(({ initialized, posTypes, show = true }) => {
    const posTypeAccess =
      !posTypes || posTypes.findIndex(pt => tableOrder?.posType === pt) > -1

    return (!initialized || tableOrder.id) && show && posTypeAccess
  })

  const [start, setStart] = useState(initial)

  const usedItems = isSearchActive
    ? searchItems
    : isCategorySelected
    ? categoryItems
    : items

  const usedTotal = isSearchActive
    ? searchTotal
    : isCategorySelected
    ? categoryTotal
    : total

  const final = usedItems.length === usedTotal

  const loaderRef = useRef(null)
  const itemsRef = useRef(null)

  const intersected = useIntersectionObserver(
    loaderRef,
    {
      threshold: 0.3,
    },
    false,
  )
  const [alert, setAlert] = useState({ title: '' })

  const [customSearch, setSearch] = useState(null)

  useEffect(() => {
    if (initLoading) return

    let newStart = minimum

    if (isSearchActive) {
      dispatch(
        replaceSearchItems(id, posId, {
          start: 0,
          end: minimum,
          categoryId: selectedCategory.id,
          search,
        }),
      )
    } else if (isCategorySelected) {
      dispatch(
        replaceCategoryItems(id, posId, {
          start: 0,
          end: minimum,
          categoryId: selectedCategory.id,
        }),
      )
    } else if (items.length === 0) {
      dispatch(
        replaceItems(id, posId, {
          start: 0,
          end: minimum,
        }),
      )
    } else {
      newStart = items.length
    }

    setStart(newStart)

    if (itemsRef.current) itemsRef.current.scrollTop = 0
  }, [selectedCategory, search])

  useEffect(() => {
    if (loading || !intersected) return

    if (isSearchActive) {
      dispatch(
        getSearchItems(id, posId, {
          start,
          end: start + step,
          categoryId: selectedCategory.id,
          search,
        }),
      )
    } else if (isCategorySelected) {
      dispatch(
        getCategoryItems(id, posId, {
          start,
          end: start + step,
          categoryId: selectedCategory.id,
        }),
      )
    } else {
      dispatch(
        getItems(id, posId, {
          start,
          end: start + step,
        }),
      )
    }

    setStart(start + step)
  }, [intersected])

  const filterByPosType = ({ type }) => {
    if (tableOrder.posType === posTypesEnum.GENERAL.type) return true
    else if (tableOrder.posType === posTypesEnum.PARKING.type) {
      if (!tableOrder.id) return type !== 5
      // DISTINTO A LA MULTA
      else return type === 5 // SOLO LAS MULTAS
    } else return true
  }

  const addItemToOrder = item => {
    const agileSelection =
      tableOrder.agileSelection &&
      !item.requiresAmountOnly &&
      (!item.recipeId || (!item.withRecipeVariation && tableOrder.noViewRecipe))

    if (
      agileSelection ||
      tableOrder.credit ||
      (tableOrder.posType === posTypesEnum.BUSINESS.type && !item.baseProductId)
    )
      return onClick(item)

    if (
      tableOrder.posType === posTypesEnum.BUSINESS.type &&
      !productLine.some(p => p.id === item.id)
    )
      dispatch(seeProductLine(item.id))

    dispatch(
      setShowItemDetail(Object.assign({}, { ...item, add: true, automatic: true })),
    )
  }

  const handleSearch = value => {
    setSearch(value)
    handlerSearchV2(value)
  }

  const handlerSearchV2 = useDebounce(value => {
    dispatch(setPOSSearch(value))
  }, 700)

  const filteredItems = usedItems.filter(filterByPosType)

  const getItemByBarcode = async barcode => {
    return await restaurantService.getItems(id, posId, {
      start: 0,
      end: 10,
      barcode,
    })
  }

  return (
    <div className={`pos-items ${selectedTab.value === 1 ? 'full-height' : ''}`}>
      <div className="pos-items-header">
        {!tableOrder.credit && filteredTabs.length > 1 && (
          <div className="pos-tabs-base">
            <div className="pos-tabs">
              {filteredTabs.map((tab, i) => (
                <div
                  key={tab.value}
                  className={`pos-tab ${tab.selected ? 'selected' : ''}`}
                  onClick={() => {
                    if (tab.selected) return
                    dispatch(setTabSelected(i))
                  }}>
                  <span className="no-select">{tab.label}</span>
                </div>
              ))}
            </div>
            <div className="pos-tabs-decorator" />
          </div>
        )}
        <div className="pos-items-title">
          <Title
            title={`${
              tableOrder.posType === posTypesEnum.GENERAL.type ||
              tableOrder.posType === posTypesEnum.BUSINESS.type
                ? 'Terminal'
                : 'Placas'
            } ${tableOrder.tableNumber || ''}`}
            actionTitle={'Responsable: '.concat(
              tableOrder.waiterData ? tableOrder.waiterData.name : '',
            )}
            action={tableOrder.waiterData}
            hideIcon
          />
          <Action
            fClassName={'w-add-button-m mb-0'}
            actionTitle={'Responsable: '.concat(
              tableOrder.waiterData ? tableOrder.waiterData.name : '',
            )}
            action={tableOrder.waiterData}
            hideIcon
            className={'d-flex row align-items-center justify-content-left mt-2'}
          />
        </div>
        {selectedTab.value === 0 && (
          <div className={'pos-items-sticky'}>
            <CustomFilter
              placeholder={''}
              showScannerButton={canUseActionBarcode}
              onScan={async barcode => {
                if (barcode !== '') {
                  let item = filteredItems.find(x =>
                    x.barcodes !== null
                      ? x.barcodes.find(b => b === barcode) !== undefined
                      : false,
                  )

                  if (item === undefined) {
                    const response = await getItemByBarcode(barcode)
                    if (response) {
                      item = response.items.find(x =>
                        x.barcodes !== null
                          ? x.barcodes.find(b => b === barcode) !== undefined
                          : false,
                      )
                    }
                  }

                  if (item) addItemToOrder(item)
                  else
                    setAlert({
                      show: true,
                      type: 'warning',
                      title: 'Producto',
                      text: 'No se encontró el producto con el codigo de barra',
                      confirmButtonText: 'Entendido',
                      confirmButtonColor: '#12A8BB',
                      onConfirm: () => setAlert({ ...alert, show: false }),
                    })
                }
              }}
              onChange={e => handleSearch(e.target.value)}
              search={customSearch}
              append={
                tableOrder.id && canUseActionTransfer ? (
                  <Icon
                    tooltip={'Transferir terminal'}
                    icon={faShare}
                    color={'accent'}
                    onClick={() => onTransferTable()}
                    style={{ width: 30 }}
                  />
                ) : null
              }
              onFocus={event => event.target.select()}
            />
            <div className={'pre-bill-button-container b-user-email'}>{description}</div>
          </div>
        )}
      </div>
      {tableOrder.credit ? (
        <TableOrderPayments />
      ) : selectedTab.value === 0 ? (
        <div ref={itemsRef} className={'pos-items-list'}>
          {initLoading || loading ? (
            <div className={'center'}>
              <Spinner animation={'grow'} />
              <Spinner animation={'grow'} />
              <Spinner animation={'grow'} />
            </div>
          ) : (
            <div>
              <div className={'infinity-screen'}>
                <div
                  data-cy="pos-items-list"
                  className={`pos-item-principal ${
                    filteredItems.length <= 3 ? 'few-items' : ''
                  }`}>
                  {filteredItems.map(i => {
                    const agileSelection =
                      tableOrder.agileSelection &&
                      !i.requiresAmountOnly &&
                      (!i.recipeId || (!i.withRecipeVariation && tableOrder.noViewRecipe))

                    const existence = getExistenceByItemId(i.id, itemsToGetExistence)

                    const seeExistence =
                      !haveAnyValue(i.recipeId) && tableOrder.seeExistence

                    return (
                      <ItemCard
                        key={i.id}
                        {...i}
                        dataCy={`pos-item-${agileSelection ? 'normal' : 'recipe'}`}
                        seeExistence={seeExistence}
                        existence={existence.existence}
                        loadingExistence={loadingExistence}
                        onClick={() => {
                          if (
                            i.price === 0 &&
                            !i.requiresAmountOnly &&
                            enabledZeroPrices.value === 'false' &&
                            !i.withRecipeVariation
                          )
                            return setAlert({
                              ...handlerError(
                                'El producto no tiene precio configurado. Por favor configúralo en la app de Ítems',
                              ),
                              onConfirm: () => setAlert({ ...alert, show: false }),
                            })

                          if (
                            agileSelection ||
                            tableOrder.credit ||
                            (tableOrder.posType === posTypesEnum.PARKING.type &&
                              !i.baseProductId)
                          ) {
                            return onClick(i, existence.totalExistence)
                          }
                          if (
                            tableOrder.posType === posTypesEnum.BUSINESS.type &&
                            !productLine.some(p => p.id === i.id)
                          ) {
                            dispatch(seeProductLine(i.id))
                          }
                          dispatch(
                            setShowItemDetail(
                              Object.assign(
                                {},
                                {
                                  ...i,
                                  show: true,
                                  add: true,
                                  existence: existence.totalExistence,
                                  seeExistence,
                                },
                              ),
                            ),
                          )
                        }}
                      />
                    )
                  })}
                </div>
                {!final && (
                  <div ref={loaderRef} className="intersect-spinner">
                    <FontAwesomeIcon
                      spin
                      size="4x"
                      icon={faSpinner}
                      style={{ color: 'rgb(108,175,199)' }}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          <Alert {...alert} />
        </div>
      ) : selectedTab.value === 1 ? (
        <TableOrderTickets />
      ) : (
        <ManufacturePOS
          tableOrderId={tableOrder.id}
          tableId={tableOrder.tableId}
          posType={tableOrder.posType}
          posName={tableOrder.posName}
          onRefresh={() => dispatch(getTableOrder(tableOrder.tableId))}
          logo={pos?.activeOrderTicketsLogo && getContextCanvasToPOSLogo(300, 175)}
        />
      )}
      <Alert {...alert} />
    </div>
  )
}
export default TableOrderItems
