import DepositsService from '../services/deposits.service'
import { executeAction } from './global.actions'
import UploadService from '../services/uploads.services'
import { imageTypes } from '../components/gallery/Gallery'

export const actionTypes = {
  GET_DEPOSIT: 'GET_DEPOSIT',
  GET_DEPOSITS: 'GET_DEPOSITS',
  GET_DEPOSITS_ADMIN: 'GET_DEPOSITS_ADMIN',
  CREATE_DEPOSIT: 'CREATE_DEPOSIT',
  UPDATE_DEPOSIT: 'UPDATE_DEPOSIT',
  GET_ASSOCIATED: 'GET_ASSOCIATED',
  CSV_MOVEMENTS: 'CSV_MOVEMENTS',
  GET_RECEIPT: 'GET_RECEIPT',
  GET_REFERENCE: 'GET_REFERENCE',
  EXPORT_EXCEL: 'EXPORT_EXCEL',
  UPDATE_DATA_DEPOSIT: 'UPDATE_DATA_DEPOSIT',
}

export const getDeposits = () => dispatch => {
  const process = async () => ({
    deposits: await DepositsService.getDeposits(),
  })
  dispatch(executeAction(actionTypes.GET_DEPOSITS, process))
}

export const getDepositsByBank = bankId => dispatch => {
  const process = async () => ({
    deposits: await DepositsService.getDepositsByBank(bankId),
  })
  dispatch(executeAction(actionTypes.GET_DEPOSITS, process))
}

export const getDepositsAdmin = () => dispatch => {
  const process = async () => ({
    deposits: await DepositsService.getDepositsAdmin(),
  })
  dispatch(executeAction(actionTypes.GET_DEPOSITS_ADMIN, process))
}

export const createDeposit = (d, images) => dispatch => {
  const process = async () => {
    const deposit = await DepositsService.createDepositsRequest(d)
    if (images && images.length > 0) {
      await UploadService.addImages(imageTypes.DEPOSITS.id, deposit.id, images)
    }
    return { deposit }
  }
  dispatch(executeAction(actionTypes.CREATE_DEPOSIT, process))
}

export const exportExcel = params => dispatch => {
  const process = async () => ({
    deposit: await DepositsService.exportExcel(params),
  })
  dispatch(executeAction(actionTypes.EXPORT_EXCEL, process))
}

export const getCompanyDeposits = params => dispatch => {
  const process = async () => ({
    deposits: await DepositsService.getDepositsCompany(params),
  })
  dispatch(executeAction(actionTypes.GET_DEPOSITS, process))
}

export const updateDeposit =
  (depositId, status, message, admin, document, withDocument) => dispatch => {
    const process = async () => {
      const deposit = await DepositsService.updateDepositStatus(depositId, status, {
        message,
        document,
        withDocument,
      })
      /*if (!admin)
            dispatch(getDepositsAdmin());*/

      return { deposit }
    }
    dispatch(executeAction(actionTypes.UPDATE_DEPOSIT, process))
  }

export const getAssociatedOrders = depositId => dispatch => {
  const process = async () => ({
    deposits: await DepositsService.getAssociatedOrders(depositId),
  })
  dispatch(executeAction(actionTypes.GET_ASSOCIATED, process))
}

export const getCSVMovements = (start, end) => dispatch => {
  const process = async () => ({
    response: await DepositsService.getCSVMovements({ start, end }),
  })
  dispatch(executeAction(actionTypes.CSV_MOVEMENTS, process))
}

export const getReceipt = receipt => dispatch => {
  const process = async () => ({
    response: await DepositsService.getReceipt(receipt),
  })
  dispatch(executeAction(actionTypes.GET_RECEIPT, process))
}

export const getReference = reference => dispatch => {
  const process = async () => ({
    response: await DepositsService.getReference(reference),
  })
  dispatch(executeAction(actionTypes.GET_REFERENCE, process))
}

/**
 * Action to update deposit
 * @param {Object} request Request of deposit
 * @returns None
 */
export const updateDepositData = (depositId, request) => dispatch => {
  const process = async () => {
    await DepositsService.updateDeposit(depositId, request)
  }
  dispatch(executeAction(actionTypes.UPDATE_DATA_DEPOSIT, process))
}

/**
 * Action to get data of the deposit
 * @param {number} depositId Deposit id
 */
export const getDeposit = depositId => dispatch => {
  const process = async () => ({
    response: await DepositsService.getDeposit(depositId),
  })
  dispatch(executeAction(actionTypes.GET_DEPOSIT, process))
}
