import TurnService from '../services/turn.service'
import { executeAction } from './global.actions'

export const actionTypes = {
  GET_TURNS: 'GET_TURNS',
  GET_TURN: 'GET_TURN',
  CREATE_TURN: 'CREATE_TURN',
  CREATE_TURN_MANUALLY: 'CREATE_TURN_MANUALLY',
  FINISH_TURN: 'FINISH_TURN',
  GET_TURN_PDF: 'GET_TURN_PDF',
  SEND_TURN_DETAILS: 'SEND_TURN_DETAILS',
  GET_MULTIPLE_TURN_DETAIL: 'GET_MULTIPLE_TURN_DETAIL',
  GET_TURN_SEGMENTATION: 'GET_TURN_SEGMENTATION',
}

export const getTurns = params => dispatch => {
  const process = async () => ({
    response: await TurnService.getTurns(params),
  })
  dispatch(executeAction(actionTypes.GET_TURNS, process))
}

export const getActiveTurn = params => dispatch => {
  const process = async () => ({
    response: await TurnService.getActiveTurn(params),
  })
  dispatch(executeAction(actionTypes.GET_TURN, process))
}

export const createTurn = userId => dispatch => {
  const process = async () => ({
    response: await TurnService.createTurn({ userId }),
  })
  dispatch(executeAction(actionTypes.CREATE_TURN, process))
}

export const createTurnManually = request => dispatch => {
  const process = async () => ({
    response: await TurnService.createTurnManually(request),
  })
  dispatch(executeAction(actionTypes.CREATE_TURN_MANUALLY, process))
}

export const getDetailsByMultipleTurn = id => dispatch => {
  const process = async () => ({
    response: await TurnService.getDetailsByMultipleTurn(id),
  })
  dispatch(executeAction(actionTypes.GET_MULTIPLE_TURN_DETAIL, process))
}

export const finishTurn = (id, userId) => dispatch => {
  const process = async () => ({
    response: await TurnService.finishTurn(id, { userId }),
  })
  dispatch(executeAction(actionTypes.FINISH_TURN, process))
}

export const getTurnPdf = id => dispatch => {
  const process = async () => ({ response: await TurnService.getTurnPdf(id) })
  dispatch(executeAction(actionTypes.GET_TURN_PDF, process))
}

export const sendTurnDetails = (id, email) => dispatch => {
  const process = async () => ({
    response: await TurnService.sendTurnDetails(id, { email }),
  })
  dispatch(executeAction(actionTypes.SEND_TURN_DETAILS, process))
}

export const getTurnSegmentation = id => dispatch => {
  const process = async () => ({
    response: await TurnService.getTurnSegmentation(id),
  })
  dispatch(executeAction(actionTypes.GET_TURN_SEGMENTATION, process))
}
