import { actionTypes } from 'src/actions/invoice.actions'
import { successState } from 'src/actions/global.actions'

const initialState: IStateInvoice = {
  itemsCreditNote: [],
  invoiceDealers: [],
  invoiceIssuedByDealer: [],
  invoicePrintInstructions: [],
}

const InvoiceReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case successState(actionTypes.GET_INVOICE_PRINT_INSTRUCTIONS):
    case successState(actionTypes.SET_INVOICE_PRINT_INSTRUCTIONS):
      return { ...state, invoicePrintInstructions: payload }
    case successState(actionTypes.GET_INVOICE_ITEMS_TO_CREDIT_NOTE):
      return { ...state, itemsCreditNote: payload }
    case successState(actionTypes.GET_INVOICE_DEALERS):
      return { ...state, invoiceDealers: payload }
    case successState(actionTypes.GET_INVOICE_ISSUED_BY_DEALER):
      return { ...state, invoiceIssuedByDealer: payload }
    default:
      return state
  }
}
export default InvoiceReducer
