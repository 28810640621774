import React, { useState } from 'react'

import {
  ProgressBar,
  Accordion,
  useAccordionButton as useAccordionToggle,
  Col,
} from 'react-bootstrap'
// import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';

import FormText from 'src/components/inputs/FormTextField/FormText'
import IconImg from 'src/components/buttons/IconImg'
import IconFilter from 'src/assets/v2/icon-filter.svg'
import BarcodeScanner from 'src/assets/v2/barcode-scanner.svg'

function CustomToggle({
  eventKey,
  search,
  placeholder,
  onChange,
  style,
  igStyle,
  mt,
  setValue,
  onScan,
  loading,
  append,
  customFilter,
  showScannerButton = true,
  mainStyle,
}) {
  const decoratedOnClick = useAccordionToggle(eventKey, () => undefined)

  return (
    <div className={'column'} style={{ ...mainStyle }}>
      <FormText
        placeholder={placeholder || 'Buscar...'}
        style={{ ...style }}
        id={'filter'}
        value={search}
        onChange={onChange}
        append={
          customFilter ? (
            <IconImg
              icon={IconFilter}
              tooltip={'Filtrar'}
              onClick={() => {
                decoratedOnClick()
                setValue(0)
              }}
            />
          ) : append ? (
            append
          ) : null
        }
        prepend={
          onScan && showScannerButton ? (
            <IconImg
              icon={BarcodeScanner}
              placement={'top'}
              tooltip={'Escanear codigos de barra'}
              onClick={() => {
                decoratedOnClick()
                setValue(1)
              }}
            />
          ) : null
        }
        aPadding
        igStyle={igStyle}
        mt={mt}
      />

      {loading && (
        <div className={'pb-custom'}>
          <ProgressBar label="Cargando" animated now={100} style={{ marginBottom: 10 }} />
        </div>
      )}
    </div>
  )
}

let interval = null

const CustomFilter = props => {
  const [value, setValue] = useState(0)

  const onScan = () => {
    return (
      <div className={'mt-2'}>
        <FormText
          style={{ width: 300 }}
          label={'Búsqueda por código de barra'}
          placeholder={'Clickeame! :D'}
          onChange={e => {
            const target = e.target
            const { value } = e.target
            clearInterval(interval)
            interval = setTimeout(() => {
              target.select()
              props.onScan(value)
            }, 600)
          }}
        />
      </div>
    )
  }

  const pageSelect = props.pageSelect && (
    <Col xs={12} sm={0} md={4}>
      {props.pageSelect}
    </Col>
  )

  return (
    <Accordion defaultActiveKey={props.defaultActive ? '0' : '1'}>
      {props.pageSelectLeft && pageSelect}
      <Col xs={12} sm={12} md={props.pageSelect ? 8 : 12}>
        <CustomToggle
          {...props}
          eventKey={'0'}
          placeholder={props.placeholder}
          onChange={props.onChange}
          search={props.search}
          setValue={v => setValue(v)}
          loading={props.loading}
          customFilter={props.customFilter}
        />
      </Col>
      {!props.pageSelectLeft && pageSelect}
      <Col xs={12} sm={0} md={12}>
        <Accordion.Collapse eventKey={'0'}>
          {value === 0 ? props.customFilter ? props.customFilter : <></> : onScan()}
        </Accordion.Collapse>
      </Col>
    </Accordion>
  )
}
export default CustomFilter
