import React, { Component } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-relative-link'
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner'
import { getColor } from 'src/utils/getColor'

export default class IconButton extends Component {
  render() {
    const {
      disabled,
      flip,
      icon,
      linkProps,
      onClick,
      tooltip,
      color,
      style,
      iconStyle,
      className,
      linkRoute,
      spin,
      size,
      placement,
      iconClass,
      rotation,
      textButton,
      dataCy,
    } = this.props
    return (
      <OverlayTrigger
        style={{ 'data-html': 'true' }}
        placement={placement ? placement : 'top'}
        overlay={tooltip ? <Tooltip id="tooltip"> {tooltip} </Tooltip> : <div />}>
        <div
          onClick={e => {
            if (!disabled && !spin && onClick) onClick(e)
          }}
          style={{ cursor: 'pointer', ...style }}
          className={className}
          data-cy={dataCy}
          data-testid={dataCy}>
          {linkRoute ? (
            <Link {...linkProps} className={'name'} to={linkRoute}>
              <FontAwesomeIcon
                className={'name'}
                icon={icon}
                style={{ color: getColor(color), ...iconStyle }}
              />
            </Link>
          ) : spin ? (
            <FontAwesomeIcon
              spin
              size={size ? size : 'sm'}
              className={'name'}
              icon={faSpinner}
              style={{ color: getColor(color), ...iconStyle }}
            />
          ) : (
            <FontAwesomeIcon
              rotation={rotation}
              className={`name ${iconClass}`}
              size={size ? size : 'sm'}
              icon={icon}
              flip={flip}
              style={{
                color: disabled ? 'gray' : getColor(color),
                ...iconStyle,
              }}
            />
          )}
          {textButton && (
            <label className={'name'} style={{ marginLeft: '5px' }}>
              {' '}
              {textButton}{' '}
            </label>
          )}
        </div>
      </OverlayTrigger>
    )
  }
}
