import { $http } from './http'
import moment from 'moment'
import { saveAs } from 'file-saver'
import UtilitiesServices from './utilities.services'

import { itemTypes } from 'src/utils/utilities'

class OrdersService {
  /*
    * search?client=<number>&deleted=<boolean>&payment=<number>&status=<number>
    &type=<number>&warehouse=<number>&sDate=<number>
    * &eDate=<number>&confirmedBy=<number>&deliveredBy=<number>*/

  getExternalOrders = async () => {
    const response = await $http.get(`/order/external`)
    return response.data
  }

  getAllOrdersAdmin = async (startDate, endDate, pending) => {
    let params = ''
    if (startDate) params += `start=${startDate}`
    if (endDate) params += (startDate ? '&' : '').concat(`end=${endDate}`)
    if (pending) params += (startDate || endDate ? '&' : '').concat(`pending=${pending}`)
    const response = await $http.get(
      `/order/admin?`.concat(params.length > 0 ? params : ''),
    )
    return response.data
  }

  getAllOrdersBySearch = async parameters => {
    let request = '/order/search?'
    Object.keys(parameters)
      .filter(p => parameters[p] !== undefined)
      .forEach((p, i) => {
        if (i > 0) request += `&`
        request += `${p}=${parameters[p]}`
      })

    const response = await $http.get(request)
    return response.data
  }

  getAllOrders = async params => {
    const response = await $http.get(`/order`, { params })
    return response.data
  }

  getOrdersSummary = async params => {
    const response = await $http.get(`/order/summary`, { params })
    return response.data
  }

  getOrderByNumber = async (number, auth) => {
    const response = await $http.get(`/order/number/${number}`, {
      params: { auth },
    })
    return response.data
  }

  getAllOrdersMap = async params => {
    const response = await $http.get(`/order/map`, { params })
    return response.data
  }

  getOrdersCount = async params => {
    const response = await $http.get(`/order/count`, { params })
    return response.data
  }

  getOrderReport = async (filters, request) => {
    const response = await $http.put(`/cotizap/reports/balance/chart`, request, {
      params: { ...filters },
    })
    return response.data
  }

  getAllStatus = async () => {
    const response = await $http.get(`/order/status`)
    return response.data
  }

  getAllOrderTypes = async () => {
    const response = await $http.get(`/order/type`)
    return response.data
  }

  getSingleOrder = async id => {
    const response = await $http.get(`/order/${id}`)
    return response.data
  }

  getOrdersByProduct = async (productId, start, end) => {
    const response = await $http.get(
      `/order/product/${productId}?start=${start}&end=${end}`,
    )
    return response.data
  }

  getOrdersByModelProduct = async (productId, start, end) => {
    const response = await $http.get(
      `/products/product/model/${productId}?start=${start}&end=${end}`,
    )
    return response.data
  }

  deleteSingleOrder = async (id, description) => {
    const response = await $http.delete(`/order/${id}`, { params: { description } })
    return response.data
  }

  createNewOrder = async request => {
    let params = ''
    if (request.name) {
      params = '?name=' + encodeURIComponent(request.name)
    }
    const response = await $http.post(`/order${params}`, request)
    return response.data
  }

  convertPresale = async (
    orderId,
    new_date,
    warehouseId,
    delivererId,
    remove,
    payed,
    custom,
  ) => {
    const response =
      await $http.put(`/order/presale/${orderId}/${new_date}/${warehouseId}/${delivererId}?removeInventory=${remove}&payed=${payed}
        ${custom ? '&user=' + custom : ''}`)
    return response.data
  }

  deliverOrder = async (id, module, request) => {
    const flagResponse = await UtilitiesServices.updateStatusConfirmationInProgress(
      itemTypes.SELL,
      id,
    )

    if (flagResponse) {
      const response = await $http.put(`/order/deliver/${id}/${module}/0/0`, request)
      return response.data
    }
  }

  dispatchOrder = async (id, request) => {
    const response = await $http.put(`/order/dispatch/${id}`, request)
    return response.data
  }

  getSingleClientHistory = async clientId => {
    const response = await $http.get(`/order/client/${clientId}`)
    return response.data
  }

  getOrderBalance = async (clientId, categories, start, end) => {
    const response = await $http.get(`/order/client/balance`, {
      params: { clientId, categories, start, end },
    })
    return response.data
  }

  getOrderBalanceExcel = async (clientId, categories, start, end) => {
    const response = await $http.get(`/order/client/balance/excel`, {
      params: { clientId, categories, start, end },
      responseType: 'blob',
    })

    const fileName = response.headers['content-disposition']
      ? response.headers['content-disposition']
          .replace('attachment; filename="', '')
          .replace('"', '')
      : moment().format('LL') + ' - Cuentas por Cobrar - .xlsx'

    const file = new Blob([response.data], {
      type: 'application/vnd.ms-excel',
    })
    saveAs(file, fileName.replace('json', 'xlsx'))
  }

  getInvoiceByOrder = async id => {
    const response = await $http.get(`/order/invoice/${id ? id : ''}`)
    return response.data
  }

  getInvoiceByDocumentType = async (documentId, documentType) => {
    const response = await $http.get(`/invoices/document/${documentId}/${documentType}`)
    return response.data
  }

  printOrder = async (id, defaultName, quote, workOrder, save = true) => {
    const response = await $http.get(`/order/print/${id}`, {
      params: { quote, workOrder },
      responseType: 'blob',
    })
    const file = new Blob([response.data], { type: 'application/pdf' })
    const today = new Date()
    const filename =
      today.getFullYear() +
      '-' +
      (today.getMonth() + 1) +
      '-' +
      today.getDate() +
      (quote ? '- Cotización -' : ' - Orden - ') +
      defaultName +
      '.pdf'
    if (save) saveAs(file, filename.replace('json', 'pdf'))
    else return file
  }

  printGroup = async (id, module, packages) => {
    const response = await $http.get(
      `/order/print/group/${id}/${module}?packages=${packages}`,
      {
        responseType: 'blob',
      },
    )
    const file = new Blob([response.data], { type: 'application/pdf' })
    const today = new Date()
    const filename =
      today.getFullYear() +
      '-' +
      (today.getMonth() + 1) +
      '-' +
      today.getDate() +
      ' - ' +
      id +
      '.pdf'
    saveAs(file, filename.replace('json', 'pdf'))
  }

  onCreateOrderVisit = async request => {
    const response = await $http.post(`/order/visit`, request)
    return response.data
  }

  onCreateOrdersVisit = async (request, params) => {
    const response = await $http.post(`/order/visit/multiple`, request, {
      params,
    })
    return response.data
  }

  onCreateOrderPayment = async request => {
    const response = await $http.post('order/payment', request)
    return response.data
  }

  onConfirmOrderPayment = async (id, client) => {
    const response = await $http.put(`order/payment/confirm/${id}`, client)
    return response.data
  }

  onConfirmOrderVisit = async (visitId, sale, module) => {
    const response = await $http.post(`order/visit/${visitId}/confirm/sale`, sale)
    return response.data
  }

  onConfirmOrderVisitNoSale = async (visitId, params) => {
    const response = await $http.post(`order/visit/confirm/${visitId}`, -1, { params })
    return response.data
  }

  getVisitsOrdersList = async () => {
    const response = await $http.get(`order/visit`)
    return response.data
  }

  getTransportistRate = async () => {
    const response = await $http.get(`invoices/transportist`)
    return response.data
  }

  getSaleOrdersBalanceCurrentUser = async () => {
    const response = await $http.get(`/payments/orders/sale/balance/current`)
    return response.data
  }

  getBIPaymentsHistoryCurrentUser = async (agreementId, startDate, endDate) => {
    const response = await $http.get(
      `/payments/bi/history/current?agreement=${agreementId}&startDate=${startDate}&endDate=${endDate}`,
    )
    return response.data
  }

  getPreSaleOrders = async () => {
    const response = await $http.get('/order/presale')
    return response.data
  }

  onCreateOrderByClient = async request => {
    const response = await $http.post('order/client', request)
    return response.data
  }

  getOrdersByClient = async () => {
    const response = await $http.get('order/client')
    return response.data
  }

  getOrderByClient = async id => {
    const response = await $http.get(`order/client/detail/${id}`)
    return response.data
  }

  getExternalOrder = async id => {
    const response = await $http.get(`order/external/${id}`)
    return response.data
  }

  getAllGOrders = async id => {
    const response = await $http.get(`order/gclient/${id}`)
    return response.data
  }

  onConfirmOrder = async (orderId, warehouseId, principal) => {
    const response = await $http.put(
      `order/client/confirm/${orderId}/${warehouseId}?principal=${principal}`,
    )
    return response.data
  }

  getOrderUsers = async () => {
    const response = await $http.get(`order/users`)
    return response.data
  }

  getAdminFilters = async () => {
    const response = await $http.get(`order/admin/filters`)
    return response.data
  }

  getExcelByOrders = async params => {
    const response = await $http.get(`order/excel`, {
      responseType: 'blob',
      params,
    })
    //{ type: 'application/vnd.ms-excel' }

    const file = new Blob([response.data], {
      type: 'application/vnd.ms-excel',
    })
    const filename = response.headers['content-disposition']
      ? response.headers['content-disposition']
          .replace('attachment; filename="', '')
          .replace('"', '')
      : moment().format('LL') + ' - ordenes - .xlsx'

    saveAs(file, filename.replace('json', 'xlsx'))
  }

  getExcelByProducts = async products => {
    const response = await $http.put(`order/excel/products`, products, {
      responseType: 'blob',
    })

    const fileName = response.headers['content-disposition']
      ? response.headers['content-disposition']
          .replace('attachment; filename="', '')
          .replace('"', '')
      : moment().format('LL') + ' - ordenes - .xlsx'

    const file = new Blob([response.data], {
      type: 'application/vnd.ms-excel',
    })
    saveAs(file, fileName.replace('json', 'xlsx'))
  }

  getUsersReport = async (sDate, eDate, categories) => {
    const response = await $http.put(`/order/report/${sDate}/${eDate}`, categories)
    //const response = await $http.get(`/order/report/${sDate}/${eDate}`);
    return response.data
  }

  getReportByRol = async (role, sDate, eDate) => {
    const response = await $http.get(`/order/report/${role}/${sDate}/${eDate}`)
    return response.data
  }

  getProductReportByRolAndUser = async (role, sDate, eDate, user, categorization) => {
    const response = await $http.get(
      `/order/report/product/${role}/${user}/${sDate}/${eDate}`,
      { params: { categorization } },
    )
    return response.data
  }

  getProductReportByRolAndUserExcel = async (
    role,
    sDate,
    eDate,
    user,
    categorization,
  ) => {
    const response = await $http.get(
      `/order/report/product/excel/${role}/${user}/${sDate}/${eDate}`,
      { params: { categorization }, responseType: 'blob' },
    )

    const fileName = response.headers['content-disposition']
      ? response.headers['content-disposition']
          .replace('attachment; filename="', '')
          .replace('"', '')
      : moment().format('LL') + ' - ProductosVendidos - .xlsx'

    const file = new Blob([response.data], {
      type: 'application/vnd.ms-excel',
    })
    saveAs(file, fileName.replace('json', 'xlsx'))
  }

  getProductReportBySeller = async (
    type,
    sDate,
    eDate,
    users,
    products,
    warehouses,
    categories,
    pos,
  ) => {
    const response = await $http.put(
      `/cotizap/reports/seller/${type}?&start=${sDate}&end=${eDate}`,
      { products, users, warehouses, categories, pos },
    )
    return response.data
  }

  onUpdateOrderParameters = async (params, orderId) => {
    const response = await $http.put(`/order/edit/parameters`, null, {
      params: { ...params, orderId },
    })
    return response.data
  }

  onUpdateOrderListParameters = async (parameters, orders) => {
    let params = ''
    Object.keys(parameters)
      .filter(f => parameters[f] != null)
      .forEach(f => {
        params += `${f}=${parameters[f]}&`
      })

    const response = await $http.put(
      `/order/edit/list/parameters?`.concat(params.length > 0 ? params : ''),
      orders,
    )
    return response.data
  }

  getBalanceReport = async params => {
    const response = await $http.get(`/order/balance`, { params })
    return response.data
  }

  updateOrderProducts = async (orderId, request) => {
    const response = await $http.put(`/order/update/products/${orderId}`, request)
    return response.data
  }

  processOrder = async request => {
    const response = await $http.post(`/order/process`, request)
    return response.data
  }

  onProcess = async request => {
    const response = await $http.put('/order/process', request)
    return response.data
  }

  getGroup = async id => {
    const response = await $http.get(`/order/group/${id}`)
    return response.data
  }

  getGroupByPackage = async id => {
    const response = await $http.get(`/order/group/${id}/packages`)
    return response.data
  }

  changeStatus = async id => {
    const response = await $http.put(`/order/product/received/${id}`)
    return response.data
  }

  changeStatusByPackage = async id => {
    const response = await $http.put(`/order/package/received/${id}`)
    return response.data
  }

  getPackage = async id => {
    const response = await $http.get(`/order/package/${id}`)
    return response.data
  }

  getProductExistenceByOrder = async (warehouseId, orders, reserved) => {
    const response = await $http.put(
      `/order/warehouse/existence/${warehouseId}`,
      orders,
      { params: { reserved } },
    )
    return response.data
  }

  getProductsOnOrders = async (start, end, params) => {
    const response = await $http.get(`/order/product/sold/${start}/${end}`, {
      params,
    })
    return response.data
  }

  getOrdersLinked = async (from, to) => {
    const response = await $http.get(`/order/linked?start=${from}&end=${to}`)
    return response.data
  }

  getTransferOrders = async () => {
    const response = await $http.get(`/order/transfer`)
    return response.data
  }

  getPaymentOrders = async params => {
    const response = await $http.get(`/order/payment`, { params })
    return response.data
  }

  getOrdersToPayByOrder = async orderId => {
    const response = await $http.get(`/order/payment/${orderId}`)
    return response.data
  }

  onCreatePaymentSpecificOrder = async (orderId, request) => {
    const response = await $http.post(`/order/payment/specific`, request)
    return response.data
  }

  rejectOrder = async (orderId, request) => {
    const response = await $http.put(`/order/client/delete/${orderId}`, request)
    return response.data
  }

  cashRegisterReport = async (start, end, userId) => {
    const response = await $http.get(`/cotizap/reports/cash/register`, {
      params: { start, end, userId },
      responseType: 'blob',
    })

    const fileName = response.headers['content-disposition']
      ? response.headers['content-disposition']
          .replace('attachment; filename="', '')
          .replace('"', '')
      : moment().format('LL') + ' - Cierre -.xlsx'

    const file = new Blob([response.data], {
      type: 'application/vnd.ms-excel',
    })
    saveAs(file, fileName.replace('json', 'xlsx'))
    return response.data
  }

  onDeferredPayment = async (orderId, paymentDate) => {
    const response = await $http.put(
      `/order/payment/deferred/${orderId}?paymentDate=${paymentDate}`,
    )
    return response.data
  }

  onNullifyPayment = async orderId => {
    const response = await $http.put(`/order/payment/nullify/${orderId}`)
    return response.data
  }

  onNullifyReceiptPayment = async orderId => {
    const response = await $http.put(`/order/payment/nullify/invoice/${orderId}`)
    return response.data
  }

  onAssignDeliveryCompany = async request => {
    const response = await $http.put('/order/assign/delivery', request)
    return response.data
  }

  addRetention = async (orderId, amount) => {
    const response = await $http.post(`/order/retention/${orderId}`, amount)
    return response.data
  }

  getRoute = async params => {
    const response = await $http.get('/order/routes', { params })
    return response.data
  }

  setPositions = async order => {
    const response = await $http.put('/order/sort/routes', order)
    return response.data
  }

  getAdminReport = async params => {
    const response = await $http.get('/order/admin/chart', { params })
    return response.data
  }

  accreditOrder = async (movementId, liquidationId) => {
    const response = await $http.put(
      `/order/accredit/${liquidationId}/${movementId}/liquidation`,
    )
    return response.data
  }

  onNullifyCardLiquidation = async liquidationId => {
    const response = await $http.delete(`/order/card/${liquidationId}/liquidation`)
    return response.data
  }

  getInvoiceTicketPDF = async (orderId, number, documentType) => {
    const response = await $http.get(`/order/invoice/ticket/${orderId}`, {
      responseType: 'blob',
      params: { documentType },
    })

    const file = new Blob([response.data], { type: 'application/pdf' })
    const fileName = 'facturas-' + (number || orderId) + '.pdf'
    saveAs(file, fileName)
  }

  payWithVoucher = async (orderId, voucherId, request) => {
    const response = await $http.put(`/order/vouchers/${orderId}/${voucherId}`, request)
    return response.data
  }

  changeDatesOrder = async request => {
    const response = await $http.put(`/order/update/date`, request)
    return response.data
  }

  createVoucher = async request => {
    const response = await $http.post(`/order/vouchers`, request)
    return response.data
  }

  deleteVoucher = async voucherId => {
    const response = await $http.delete(`/order/vouchers/${voucherId}`)
    return response.data
  }

  liberateVoucher = async voucherId => {
    const response = await $http.put(`/order/vouchers/${voucherId}`)
    return response.data
  }

  getExistenceByOrder = async (orderId, all) => {
    const response = await $http.get(`/order/existence/${orderId}`, {
      params: { all },
    })
    return response.data
  }

  changeCategorization = async (list, category, remove) => {
    const response = await $http.put(`/order/payment/categorization/${category}`, list, {
      params: { remove: remove },
    })
    return response.data
  }

  getPaymentHistory = async orderId => {
    const response = await $http.get(`/order/payment/history/${orderId}`)
    return response.data
  }

  createCardLiquidation = async request => {
    const response = await $http.post('/order/card/liquidation', request)
    return response.data
  }

  getCardLiquidation = async params => {
    const response = await $http.get('/order/card/liquidation', { params })
    return response.data
  }

  getOrdersByLiquidation = async liquidationId => {
    const response = await $http.get(`/order/card/liquidation/${liquidationId}`)
    return response.data
  }

  returnProduct = async (orderId, request, type, code, warehouseId) => {
    const response = await $http.put(`/order/return/product/${orderId}`, request, {
      params: { type, code, warehouseId },
    })
    return response.data
  }

  getInventoryAvailableByOrderDetail = async orderId => {
    const response = await $http.get(`/order/return/product/presale/${orderId}`)
    return response.data
  }

  updateListOrders = async (request, params) => {
    const response = await $http.put('/order/massive/edit', request, {
      params,
    })
    return response.data
  }

  getPaymentReceipt = async (code, params) => {
    const response = await $http.get(`/order/payment/valid/${code}`, {
      params,
    })
    return response.data
  }

  editMassivePayments = async (request, params) => {
    const response = await $http.put(`/order/massive/payment/edit`, request, {
      params,
    })
    return response.data
  }

  getArchivedPayment = async () => {
    const response = await $http.get(`/order/payment/archived`)
    return response.data
  }

  editPaymentArchived = async (request, params) => {
    const response = await $http.put(`/order/payment/archived`, request, {
      params,
    })
    return response.data
  }

  getQuoteAdjustment = async params => {
    const response = await $http.get('/order/quote/existence', { params })
    return response.data
  }

  onQuoteAdjustment = async (warehouseId, request) => {
    const response = await $http.put('/order/quote/adjust', request, {
      params: { warehouseId },
    })
    return response.data
  }

  onNullifySpecificPaymentNumber = async (code, params) => {
    const response = await $http.delete(`/order/payment/${code}/nullify`, {
      params,
    })
    return response.data
  }

  payCreditWithVoucher = async (ids, selected, type) => {
    const response = await $http.post(
      `/order/payment/voucher/`,
      { ids, selected },
      { params: { type } },
    )
    return response.data
  }

  getPaymentsByOrder = async id => {
    const response = await $http.get('/order/transactions/by/orderId', {
      params: { orderId: id },
    })
    return response.data
  }

  getAllOrdersLite = async params => {
    const response = await $http.get('/order/list/lite', { params })
    return response.data
  }

  getDocumentsByOrder = async orderId => {
    const response = await $http.get(`/order/transactions/documents/${orderId}`)
    return response.data
  }

  confirmMassiveOrders = async (ids, params) => {
    const response = await $http.post(`/order/confirm/massive`, ids, { params })
    return response.data
  }

  certifyMassiveOrders = async (ids, params) => {
    const response = await $http.post(`/order/certify/massive`, ids, { params })
    return response.data
  }

  certifySingleOrder = async (id, params) => {
    const response = await $http.post(`/order/certify/${id}`, null, { params })
    return response.data
  }

  cancelMassiveOrders = async ids => {
    const response = await $http.post(`/order/delete/massive`, ids)
    return response.data
  }

  getCpcReportCSV = async params => {
    const response = await $http.get(`/order/export/payments/csv`, { params })

    let text = response.data
      .split('\n')
      .map(line => line.split(/\s+/).join(' '))
      .join('\n')

    let csvContent = 'data:text/csv;charset=utf-8,'
    csvContent += text

    const uri = encodeURI(csvContent)
    let link = document.createElement('a')
    link.setAttribute('href', uri)
    link.setAttribute('download', 'cuentas por cobrar.csv')
    document.body.appendChild(link)
    link.click()
  }

  onCreatePaymentByCSVValues = async item => {
    const response = await $http.post('/order/payments/csv', item)
    return response.data
  }

  onChangeDateVisit = async (id, date) => {
    const response = await $http.put(`/order/visit/${id}?date=${date}`)
    return response.data
  }

  adjustVisitOrder = async (id, request) => {
    const response = await $http.put(`/order/visit/adjust/${id}`, request)
    return response.data
  }

  /**
   * Action to obtain the orders of an account through the client cotizap
   * @param {number} idClient Cotizap Client id
   * @returns {Promise} Service response
   */
  getAccountOrdersByClient = async idClient => {
    const response = await $http.get(`/order/account/${idClient}`)
    return response.data
  }

  /**
   * Service to pay for an order with a card
   * @param {number} orderId Order to pay
   */
  payOrderAccount = async orderId => {
    const response = await $http.post(`/order/account/pay?orderId=${orderId}`)
    return response.data
  }

  getVersionsOrder = async orderId => {
    const response = await $http.get(`/order/versions`, { params: { orderId } })
    return response.data
  }

  getVersionOrder = async params => {
    const response = await $http.get(`/order/version`, { params })
    return response.data
  }

  verifyOrderUseRecipe = async orderId => {
    const response = await $http.get(`/order/verify/recipe/${orderId}`)
    return response.data
  }
}

export default new OrdersService()
