import './TableOrderPage.scss'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectAllPOSUser,
  selectBillItems,
  selectDescription,
  selectGetTableOrder,
  selectPosConfigurationByType,
} from 'src/selectors/restaurant.selector'

import Alert from 'sweetalert-react'
import { Modal } from 'react-bootstrap'
import { Button, FormText, QRScanner } from 'src/components'
import ImageInput, { imageTypes } from 'src/components/inputs/ImageInput/ImageInput'
import ParkingIcon from 'src/assets/images/svg/parking.svg'
import CommandTicket from 'src/components/Print/commandTicket'

import {
  actionTypes,
  addProduct,
  getPreBillCorrelative,
  onSaveDescription,
  onSetPrintData,
  onSetSeePreBill,
  updateProductsState,
} from 'src/actions/restaurant.actions'

import { createTableOrder, initClearTerminal } from 'src/actions/restaurant.actions'
import { selectCurrentUser } from 'src/selectors/user.selector'
import { loadingSelector } from 'src/selectors/loading.selector'
import { handlerError, hasErrors } from 'src/selectors/error.selector'
import { formatDateFromMillis } from 'src/utils/formatters'
import { printTicket, getPOSDetails } from 'src/utils/utilities'
import { printCommandTicket } from 'src/components/Printer/TicketEpson'
import { onUpdateCorrelativePrint } from 'src/actions/printer.actions'
import { isAllowed } from 'src/selectors/modules.selector'

import { faCheck, faPrint, faWindowClose } from '@fortawesome/free-solid-svg-icons'
import { faGetPocket } from '@fortawesome/free-brands-svg-icons'
import { posPanelConfigurations } from 'src/enums/posPanelConfigurations'
import { terminalTypesEnum } from 'src/enums/terminalTypes'
import { posTypesEnum } from 'src/enums/posTypes'
import { getContextCanvasToPOSLogo } from 'src/content/Restaurant/POSFunctions'

import usePOSCorrelative from 'src/content/Restaurant/hooks/usePOSCorrelative'

const w = 300
const h = 175
const TableItemActions = ({ tableId, parkingModal, history, directlyDeliveryItems }) => {
  const dispatch = useDispatch()

  const user = useSelector(selectCurrentUser)
  const tableOrder = useSelector(selectGetTableOrder)
  const posUser = useSelector(selectAllPOSUser)
  const pos = posUser.find(pos => pos.id === tableOrder.posId)
  const billItems = useSelector(selectBillItems)
  const prints = useSelector(state => state.printers.all)
  const description = useSelector(selectDescription)

  const posAgile = tableOrder?.stepItems === 4
  const isDeliveryTerminal = tableOrder?.tableType === terminalTypesEnum.DELIVERY.value

  const isImmediatelyInvoice =
    tableOrder?.stepItems === 4 ||
    tableOrder.tableType === terminalTypesEnum.DELIVERY.value

  const canUseActionClear = useSelector(state =>
    selectPosConfigurationByType(state, posPanelConfigurations.actionClear),
  )
  const canUseActionAgileOrderPrint = useSelector(state =>
    selectPosConfigurationByType(state, posPanelConfigurations.actionAgileOrderPrint),
  )

  const loadingGenerate = useSelector(state =>
    loadingSelector([actionTypes.GENERATE_ORDER_IMMEDIATELY])(state),
  )
  const hasErrorGenerate = useSelector(state =>
    hasErrors([actionTypes.GENERATE_ORDER_IMMEDIATELY])(state),
  )

  useEffect(() => {
    if (loadingGenerate) setActions({ ...actions, invoice: true })
    else if (actions.invoice) {
      setActions({ ...actions, invoice: false })
      if (hasErrorGenerate) return
      setItemsToPrint(billItems)
    }
  }, [loadingGenerate])

  const loading = useSelector(state =>
    loadingSelector([
      actionTypes.GET_TABLE_ORDER,
      actionTypes.DELETE_PRODUCT,
      actionTypes.SET_PRODUCT_STATUS,
    ])(state),
  )
  const hasError = useSelector(state =>
    hasErrors([actionTypes.GET_TABLE_ORDER, actionTypes.SET_PRODUCT_STATUS])(state),
  )

  const correlative = usePOSCorrelative()

  const loadingC = useSelector(state =>
    loadingSelector([actionTypes.GET_BILL_CORRELATIVE])(state),
  )

  // PERMISSIONS
  const acceptProduction = useSelector(state => isAllowed(state, [2210]))
  const clearTerminal = useSelector(state => isAllowed(state, [2209]))

  const [alert, setAlert] = useState({ title: '' })
  const [actions, setActions] = useState({
    change: false,
    correlative: false,
    delivery: false,
    verify: false,
    delete: false,
  })
  const [itemsToPrint, setItemsToPrint] = useState([])
  const [statusValue, setStatusValue] = useState({})
  const [modalParking, setModalParking] = useState({})

  const [see, setSee] = useState(false)

  useEffect(() => {
    if (!parkingModal) return
    setModalParking({ show: true })
  }, [parkingModal])

  useEffect(() => {
    if (!directlyDeliveryItems) return
    setActions({ ...actions, delivery: true })
  }, [directlyDeliveryItems])

  useEffect(() => {
    if (!actions.delivery) return
    onActions(
      billItems.filter(bi => !bi.status),
      12,
      true,
    )
  }, [actions.delivery])

  useEffect(() => {
    if (loading) setActions({ ...actions, change: true })
    else if (actions.change) {
      setActions({ ...actions, change: false })

      if (hasError) {
        let message = hasError.message
        if (hasError.message) {
          const noInventory = hasError.message.indexOf('No hay suficiente') >= 0
          if (noInventory) {
            message +=
              '\n¿Desea  aceptar la producción sin descontar existencia? El inventario que no se encuentre podrá ser despachado con la función de despacho pendiente en la aplicación de inventario.'
            setAlert({
              ...handlerError(message),
              showCancelButton: true,
              cancelButtonText: 'Cancelar',
              confirmButtonColor: '#B35796',
              confirmButtonText: 'Continuar',
              onCancel: () => setAlert({ ...alert, show: false }),
              onConfirm: () => onActions(statusValue.items, statusValue.status, true),
            })
            return
          }
        }
        setAlert({
          ...handlerError(message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      } else if (actions.delivery) {
        setActions({ ...actions, delivery: false })
        onActions(
          billItems.filter(bi => bi.status && bi.status.id === 3),
          4,
        )
      } else if (itemsToPrint.length > 0) {
        setTimeout(() => {
          sendToPrintAutomatically(itemsToPrint)
        }, 500)
      }
    }
  }, [loading])

  useEffect(() => {
    if (!see) return
    if (isImmediatelyInvoice || !pos.sameCorrelative)
      dispatch(getPreBillCorrelative(true, pos.sameCorrelative, correlative))
    else {
      onActions(statusValue.items, statusValue.status, false, statusValue.close)
      setSee(false)
    }
  }, [see])

  useEffect(() => {
    if (!see) return
    if (loadingC) {
      setActions({ ...actions, correlative: true })
      setItemsToPrint(statusValue.items)
    } else if (actions.correlative) {
      setActions({ ...actions, correlative: false })
      setSee(false)
      if (isImmediatelyInvoice) {
        sendToPrintAutomatically(statusValue.items, false)
      } else onActions(statusValue.items, statusValue.status, false, statusValue.close)
    }
  }, [loadingC])

  const onSaveProducts = (items, withoutInventory) => {
    const request = { tableId, status: 1, clients: 0 }
    request.detail = getPOSDetails(items)
    if (tableOrder.id) {
      request.orderId = tableOrder.id
      dispatch(addProduct(request, withoutInventory))
    } else {
      request.clients = 1
      request.waiter = user.id
      request.description = modalParking.description
      request.name = modalParking.register
      request.img = modalParking.src ? modalParking.src.url : null
      dispatch(
        createTableOrder(
          request,
          tableOrder.posType === posTypesEnum.PARKING.type ? tableOrder.posId : null,
          withoutInventory,
          tableOrder.posType,
        ),
      )
    }
  }

  const onActions = (items, status, withoutInventory, close) => {
    setStatusValue({ items, status, close })
    setAlert({ ...alert, show: false })
    if (status === 11 || status === 12) {
      if (status === 11) setItemsToPrint(items)
      else setItemsToPrint([])
      return onSaveProducts(items, withoutInventory)
    }

    dispatch(
      updateProductsState(
        tableOrder.id,
        items.map(i => i.detailId),
        status,
        null,
        false,
        withoutInventory,
      ),
    )
  }

  const sendToPrintAutomatically = (itemsToPrint, newCorrelative = true) => {
    const container = document.getElementById('ticket-command').innerHTML
    if (
      prints &&
      prints.length > 0 &&
      prints.filter(p => p.categories && p.categories.length > 0).length > 0
    ) {
      const itemsPrint = []

      prints.forEach(p => {
        if (p.categories) {
          p.categories.forEach(({ id }) => {
            // Encontrar las categorias del producto por su id productId
            itemsToPrint.forEach(pt => {
              // const product = products.find((pp) => pp.productId === pt.productId);
              if (pt?.categories) {
                const zIndex = pt.categories.indexOf(id)
                if (zIndex > -1) {
                  const ip = itemsPrint.find(ip => ip.id === p.id)
                  if (ip) {
                    ip.items.push(pt)
                  } else {
                    itemsPrint.push({
                      ...p,
                      items: [pt],
                    })
                  }
                }
              }
            })
          })
        }
      })

      if (itemsPrint.length > 0) {
        itemsPrint.forEach(i => {
          i.data = sendToPrintTicket(i.items, container, i.id)
        })

        dispatch(
          onSetPrintData({
            show: true,
            itemsPrint,
            content: container,
            onClose: () => {
              if (statusValue.close) history.push('..')
            },
            logo: pos?.activeOrderTicketsLogo && pos?.ticketLogo,
          }),
        )
      } else sendToPrintTicket(itemsToPrint, container)
    } else {
      sendToPrintTicket(itemsToPrint, container, null, newCorrelative)
    } // TODO: if dont use printers

    setItemsToPrint([])
  }

  const sendToPrintTicket = (itemsToPrint, htmlToPrint, printId, newCorrelative) => {
    if (prints && prints.length > 0) {
      const data = printCommandTicket(
        itemsToPrint,
        user.name,
        { ...tableOrder, description },
        new Date().valueOf(),
        correlative + '\n',
        pos?.activeOrderTicketsLogo && getContextCanvasToPOSLogo(w, h),
      )

      if (printId) return data
      else
        dispatch(
          onSetPrintData({
            show: true,
            customData: data,
            content: htmlToPrint,
            onClose: () => {
              if (statusValue.close) history.push('..')
            },
            logo: pos?.activeOrderTicketsLogo && pos?.ticketLogo,
          }),
        )
    } else if (htmlToPrint) {
      setTimeout(() => {
        printTicket(
          htmlToPrint,
          null,
          null,
          (pos.activeOrderTicketsLogo && pos.ticketsLogo) || 'undefined',
        )
        if (newCorrelative && !pos.sameCorrelative) dispatch(onUpdateCorrelativePrint())
      }, 300)
    }
  }

  const validateButton = status => {
    switch (status) {
      case 0:
        return (
          billItems.filter(bi => !bi.status).length > 0 &&
          (!posAgile || isDeliveryTerminal || (posAgile && canUseActionAgileOrderPrint))
        )
      case 9:
        return (
          billItems.filter(bi => !bi.status || bi.status.id === 1).length ===
          billItems.length
        )
      default:
        return billItems.filter(bi => bi.status && bi.status.id === status).length > 0
    }
  }

  return (
    <div>
      <CommandTicket
        keyId={undefined}
        correlative={correlative}
        pos={pos}
        userName={user.name}
        date={formatDateFromMillis(new Date().valueOf())}
        title={`${
          tableOrder.posType === posTypesEnum.GENERAL.type ? 'Terminal' : 'Placas'
        }: ${tableOrder.tableNumber}`}
        items={itemsToPrint}
        footerPhrase={tableOrder.footerPhrase}
      />

      <div className="pos-buttons">
        {validateButton(0) && (
          <Button
            loading={loading || loadingC}
            icon={faPrint}
            color={'accent'}
            onClick={() => {
              setSee(true)
              const itemsToSave = billItems.filter(bi => !bi.status)
              setStatusValue({ items: itemsToSave, status: 11 })
            }}
            dataCy={'comandar'}>
            {tableOrder.posType === posTypesEnum.GENERAL.type
              ? isImmediatelyInvoice
                ? 'Imprimir comanda'
                : 'Comandar'
              : 'Iniciar'}
          </Button>
        )}
        {validateButton(1) && (
          <Button
            loading={loading || loadingC}
            icon={faPrint}
            color={'accent'}
            onClick={() => {
              setSee(true)
              const itemsToSave = billItems.filter(bi => bi.status && bi.status.id === 1)
              setStatusValue({ items: itemsToSave, status: 2 })
            }}>
            Comandar
          </Button>
        )}
        {validateButton(2) && acceptProduction && (
          <>
            <Button
              onClick={() => {
                onActions(
                  billItems.filter(bi => bi.status && bi.status.id === 2),
                  6,
                )
              }}
              loading={loading}
              icon={faWindowClose}
              color={'secondary'}>
              Rechazar
            </Button>
            <Button
              loading={loading}
              icon={faCheck}
              color={'primary'}
              onClick={() => {
                onActions(
                  billItems.filter(bi => bi.status && bi.status.id === 2),
                  5,
                )
              }}>
              Aprobar
            </Button>
          </>
        )}
        {validateButton(3) && (
          <Button
            loading={loading}
            icon={faGetPocket}
            color={'primary'}
            onClick={() => {
              if (
                tableOrder.posType === posTypesEnum.PARKING.type &&
                !tableOrder.skipScanner
              ) {
                const itemsToSave = billItems.filter(
                  bi => bi.status && bi.status.id === 3,
                )
                setStatusValue({ items: itemsToSave, status: 4, scanner: true })
              } else
                onActions(
                  billItems.filter(bi => bi.status && bi.status.id === 3),
                  4,
                )
            }}>
            Entregar
          </Button>
        )}

        {tableOrder.id &&
          tableOrder.posType !== posTypesEnum.PARKING.type &&
          clearTerminal &&
          canUseActionClear &&
          !isImmediatelyInvoice && (
            <Button
              color={'secondary'}
              onClick={() => dispatch(initClearTerminal(tableOrder.id))}>
              Limpiar terminal
            </Button>
          )}

        <div className={'pre-bill-button-container'}>
          <Button
            onClick={() => {
              dispatch(onSetSeePreBill(true))
            }}>
            Cuenta
          </Button>
        </div>
      </div>

      <QRScanner
        show={statusValue.scanner}
        onHide={response => {
          if (response) {
            const validated = tableOrder.code === response.response
            if (validated) onActions(statusValue.items, statusValue.status)
            else
              setAlert({
                show: true,
                title: 'Código incorrecto',
                type: 'error',
                text: 'El código escaneado no coincide en el código de la orden.',
                confirmButtonText: 'Cerrar',
                confirmButtonColor: '#B35796',
                onConfirm: () => setAlert({ ...alert, show: false }),
              })
          }
          setStatusValue({})
        }}
      />

      <Modal show={modalParking.show} centered size={'md'}>
        <Modal.Header>
          <Modal.Title>Ingreso de placas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={'center'}>
            <ImageInput
              imageType={imageTypes.POS_TERMINAL_ORDER}
              src={modalParking.src}
              placeholder={ParkingIcon}
              onSuccessFile={url => {
                setModalParking({ ...modalParking, src: { url } })
              }}
            />
          </div>
          <FormText
            label={'Placas'}
            value={modalParking.register}
            required
            changeValue={register => setModalParking({ ...modalParking, register })}
          />
          <FormText
            label={'Descripción'}
            value={modalParking.description}
            changeValue={description => setModalParking({ ...modalParking, description })}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className={'container-buttons'}>
            <Button
              color={'secondary'}
              onClick={() => {
                history.goBack()
                setModalParking({ ...modalParking, show: false })
              }}>
              Salir
            </Button>
            <Button
              disabled={!modalParking.register || modalParking.register === ''}
              onClick={() => {
                dispatch(onSaveDescription(modalParking.description))
                setModalParking({ ...modalParking, show: false })
                setSee(true)
                const itemsToSave = billItems
                  .filter(bi => !bi.status)
                  .map(bi => ({ ...bi, commentary: modalParking.register }))
                setStatusValue({ items: itemsToSave, status: 11, close: true })
              }}>
              Continuar
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <Alert {...alert} />
    </div>
  )
}
export default TableItemActions
