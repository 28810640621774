export const hasErrors = actions => state =>
  actions.reduce((prevState, value) => {
    const isError = state.error[`${value}`]
    if (isError) {
      return prevState || isError
    }
    return prevState
  }, false)

export const hasErrorsSelector = actions => state =>
  actions.reduce((prevState, value) => !!(prevState && state.error[`${value}`]), true)

export const singleErrorSelector = actions => state =>
  actions.reduce(
    (prevState, value) => {
      const error = state.error[String(value)]
      if (error) {
        if (!error.data) {
          error.data = {}
        }
        return error
      } else {
        return { message: '', data: {} }
      }
    },
    { message: '', data: {} },
  )

export const errorsSelector = actions => state =>
  actions.reduce((prevState, value) => {
    const error = state.error[String(value)]
    if (error) {
      prevState.push(error)
    }
    return prevState
  }, [])

export const handlerError = (message, info) => {
  return {
    show: true,
    title: 'Uh-Oh..',
    text: message || 'Ha ocurrido un error inesperado.',
    type: !info ? 'error' : 'info',
    confirmButtonText: 'Cerrar',
    confirmButtonColor: '#B35796',
  }
}

export const handlerSuccess = message => {
  return {
    show: true,
    title: '¡Éxito!',
    text: message || 'Operación exitosa.',
    type: 'success',
    confirmButtonText: 'Continuar',
    confirmButtonColor: '#226095',
  }
}

export const handlerInfo = (title, message) => {
  return {
    show: true,
    title,
    text: message,
    type: 'info',
    confirmButtonText: 'Cerrar',
    confirmButtonColor: '#B35796',
  }
}

export const handlerInfoWithButtons = (title, message) => {
  return {
    show: true,
    title,
    type: 'info',
    text: message,
    confirmButtonText: 'Continuar',
    confirmButtonColor: '#226095',
    showCancelButton: true,
    cancelButtonText: 'Cancelar',
  }
}

export const handlerWarningButtons = (title, message) => {
  return {
    show: true,
    title,
    type: 'warning',
    text: message,
    confirmButtonText: 'OK',
    // confirmButtonText: 'Continuar',
    // confirmButtonColor: '#226095',
    showCancelButton: true,
    cancelButtonText: 'Cancelar',
  }
}
