import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Modal, Row, Col } from 'react-bootstrap'
import {
  Button,
  Loading,
  SwitchV2,
  Paragraph,
  Checkbox,
  FormText,
  Icon,
} from 'src/components'
import {
  faCheck,
  faCheckDouble,
  faMinus,
  faPlus,
  faWindowClose,
} from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes,
  getItemsToCreditNote,
  onCreateCreditNoteBySells,
} from 'src/actions/invoice.actions'
import { selectInvoiceItemsCreditNote } from 'src/selectors/invoice.selector'

import { selectCompanyCountry } from 'src/selectors/company.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import { handlerError, handlerSuccess, hasErrors } from 'src/selectors/error.selector'

import { showAlert } from 'src/actions/alert.actions'

import { toMoney } from 'src/utils/utilities'
import { Country, countries } from 'src/enums/countries'

interface IProps {
  show: boolean
  orderId: number
  code: string
  clientId: number
  onHide: (update: boolean) => void
}

const returnTypes = [
  {
    value: 1,
    label: 'Efectivo',
    info: 'Se registrará una salida de efectivo circulante del usuario quien confirmo la orden de venta.',
  },
  {
    value: 2,
    label: 'Transferencia',
    info: 'Se creará una solicitud en la app de bancos para registrar la transferencia a realizar al cliente.',
  },
  {
    value: 3,
    label: 'Saldo a favor',
    info: 'La nota de crédito registrará un saldo a favor al cliente.',
  },
]

/** Render a modal to create a credit note by order id
 * @param {boolean} show Indicate if the componente render
 * @param {number} orderId Id of order to crate a credit note
 * @param {number} clientId Id of client from the order
 * @param {string} code Number of order to create a credit note
 * @param {function} onHide Function to close the component
 * */
const CreateCreditNoteSell = ({
  show = false,
  orderId,
  clientId,
  code,
  onHide,
}: IProps) => {
  const dispatch = useDispatch()

  const country = useSelector(selectCompanyCountry)

  const responseItems: ICreditNoteItem[] = useSelector(selectInvoiceItemsCreditNote)
  const loadingGet = useSelector(state =>
    loadingSelector([actionTypes.GET_INVOICE_ITEMS_TO_CREDIT_NOTE])(state),
  )
  const hasErrorGet = useSelector(state =>
    hasErrors([actionTypes.GET_INVOICE_ITEMS_TO_CREDIT_NOTE])(state),
  )

  const loadingCreate = useSelector(state =>
    loadingSelector([actionTypes.ON_CREATE_CREDIT_NOTE_BY_SELLS])(state),
  )
  const hasErrorCreate = useSelector(state =>
    hasErrors([actionTypes.ON_CREATE_CREDIT_NOTE_BY_SELLS])(state),
  )

  const [flags, setFlags] = useState({ get: false, create: false })
  const [itemSelected, setItemSelected] = useState<ICreditNoteItem>(undefined)

  const [items, setItems] = useState<ICreditNoteItem[]>([])
  const [isCreditNote, setIsCreditNote] = useState<boolean>(true)
  const [returnInventory, setReturnInventory] = useState<boolean>(true)
  const [returnBalance, setReturnBalance] = useState<boolean>(true)
  const [returnType, setReturnType] = useState<number>(1)
  const [partial, setPartial] = useState<boolean>(false)
  const [reason, setReason] = useState<string>(null)

  useEffect(() => {
    if (!show) return
    dispatch(getItemsToCreditNote(orderId))
  }, [show])

  useEffect(() => {
    if (loadingGet) setFlags({ ...flags, get: true })
    else if (flags.get) {
      setFlags({ ...flags, get: false })
      if (hasErrorGet)
        dispatch(
          showAlert({
            ...handlerError(hasErrorGet.message),
            onConfirm: () => onClose(false),
          }),
        )
      else setItems(responseItems.map(r => ({ ...onFormatData(r) })))
    }
  }, [loadingGet])

  useEffect(() => {
    if (loadingCreate) setFlags({ ...flags, create: true })
    else if (flags.create) {
      setFlags({ ...flags, create: false })
      const alert = hasErrorCreate
        ? {
            ...handlerError(hasErrorCreate.message),
          }
        : {
            ...handlerSuccess(),
            onConfirm: () => onClose(true),
          }

      dispatch(showAlert(alert))
    }
  }, [loadingCreate])

  const onFormatData = (item: ICreditNoteItem): ICreditNoteItem => {
    const line: ILineItem[] = item.itemData.line.map(itemLine => {
      return {
        ...itemLine,
        quantity: itemLine.productId === item.itemId ? item.quantity : 0,
      }
    })

    return {
      ...item,
      amount: item.total,
      selected: false,
      itemData: { ...item.itemData, line },
    }
  }

  const onClose = (update: boolean) => {
    onHide(update)
    setItems([])
    setIsCreditNote(true)
    setReturnInventory(true)
    setReturnBalance(true)
    setReturnType(1)
    setPartial(false)
    setReason(null)
  }

  const onSave = () => {
    const request = {
      orderId,
      clientId,
      returnInventory,
      returnBalance,
      returnType,
      partial,
      items: [],
      reason,
      creditNote: isCreditNote,
    }
    items
      .filter(i => (partial ? i.selected : true))
      .forEach(i => {
        const baseData = {
          detailId: i.detailId,
          invoiceId: i.invoiceId,
          orderId: i.orderId,
          felItemTypeId: i.felItemTypeId,
          measurementUnitId: i.measurementUnitId,
        }

        if (!partial) {
          request.items.push({
            ...baseData,
            itemId: i.itemId,
            quantity: i.quantity,
            price: i.price,
            name: i.name,
            tax: getTax(i.quantity * i.price),
          })
        } else {
          request.items.push(
            ...i.itemData.line
              .filter(subItem => subItem.quantity > 0)
              .map(subItem => {
                const price = getSubPrice(i, subItem)
                return {
                  ...baseData,
                  itemId: subItem.productId,
                  quantity: subItem.quantity,
                  price: price,
                  name: subItem?.productData?.name,
                  tax: getTax(subItem.quantity * price),
                }
              }),
          )
        }
      })

    if (request.items.length === 0)
      dispatch(
        showAlert(handlerError('Es necesario incluir al menos un ítem a la operación')),
      )
    else dispatch(onCreateCreditNoteBySells(request))
  }

  const getTax = (amount: number): number => {
    const iva = countries[country.id]?.iva
    if (country.id === Country.GT) {
      const withoutTax = amount / (1 + iva)
      return amount - withoutTax
    } else return amount * iva
  }

  const getSubPrice = (item: ICreditNoteItem, subItem: ILineItem): number => {
    const baseQuantity: number = getQuantityUsed(item)
    const factor: number = !subItem.presentationFactor ? 1 : subItem.presentationFactor

    return (factor * item.amount) / baseQuantity
  }

  const onItemUpdate = (item: ICreditNoteItem) => {
    const customItems = Object.assign([], items)
    const index = customItems.findIndex(ci => ci.detailId === item.detailId)
    customItems[index] = item
    setItemSelected(undefined)
    setItems(customItems)
  }

  const getQuantityUsed = (item: ICreditNoteItem): number =>
    item?.itemData?.line.reduce(
      (used, item) =>
        used + (!item.presentationFactor ? 1 : item.presentationFactor) * item.quantity,
      0,
    )

  const getQuantityAvailable = (item: ILineItem): number => {
    const baseQuantity: number = itemSelected.itemData.baseExistence
    const availableQuantity: number = baseQuantity - getQuantityUsed(itemSelected)
    const factor: number = !item.presentationFactor ? 1 : item.presentationFactor
    return Math.trunc(availableQuantity / factor)
  }

  const changeQuantity = (item: ILineItem, quantity: number) => {
    const customItem: ILineItem = Object.assign({}, item)
    if (!quantity) customItem.quantity = 0
    else customItem.quantity = quantity

    const customItems: ILineItem[] = Object.assign([], itemSelected.itemData.line)
    const index: number = customItems.findIndex(ci => ci.productId === item.productId)

    customItems[index] = customItem
    setItemSelected({
      ...itemSelected,
      itemData: { ...itemSelected.itemData, line: customItems },
    })
  }
  const getTotalSelected = (): number => {
    return items
      .filter(i => (partial ? i.selected : true))
      .reduce((amount, item) => {
        let newAmount
        if (country.id === Country.SV)
          newAmount = Number(item.amount) + getTax(item.amount)
        else newAmount = Number(item.amount)
        return amount + newAmount
      }, 0)
  }

  const renderTotal = (
    <div className={'space-between'} style={{ width: '100%' }}>
      <Paragraph>Total</Paragraph>
      <Paragraph>{toMoney(getTotalSelected())}</Paragraph>
    </div>
  )

  return (
    <div>
      <Modal
        show={
          show && !(itemSelected !== undefined && itemSelected?.detailId !== undefined)
        }
        centered
        size={'xl'}>
        <Modal.Header>
          <Modal.Title>{code} Crear nota de crédito</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Paragraph>
                Se le creará al cliente una nota de crédito con saldo a favor que podrá
                utilizar en otras ordenes de venta. La orden de venta cambiará de estado
                a: Acreditada con vale.
              </Paragraph>
            </Col>
            <Col xl={12}>
              <FormText
                label={'Motivo del ajuste'}
                value={reason}
                changeValue={v => setReason(v)}
                required
              />
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <SwitchV2
                disabled={loadingCreate}
                checked={isCreditNote}
                label={'Nota de crédito'}
                info={
                  'Si la opción está activa se emitirá una nota de crédito, de lo contrario se creara un vale.'
                }
                onChange={setIsCreditNote}
              />
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <SwitchV2
                disabled={loadingCreate}
                checked={returnInventory}
                label={'Devolución de inventario'}
                info={'Retorná el inventario vendido a la bodega de origen'}
                onChange={setReturnInventory}
              />
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <Row>
                <Col xl={12}>
                  <SwitchV2
                    disabled={loadingCreate}
                    checked={returnBalance}
                    label={'Devolución monetaria'}
                    info={
                      'Realizá la devolución monetaria al cliente por el total seleccionado'
                    }
                    onChange={setReturnBalance}
                  />
                </Col>
                <Col xl={12}>
                  <Col xl={12}>
                    <Row>
                      {returnTypes.map(rt => (
                        <Col xl={4} lg={4} md={6} sm={12} xs={12} key={rt.value}>
                          {/*@ts-ignore*/}
                          <Checkbox
                            radio
                            checked={rt.value === returnType}
                            topLabel
                            label={rt.label}
                            info={rt.info}
                            onChange={() => setReturnType(rt.value)}
                            disabled={!returnBalance || loadingCreate}
                          />
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Col>
              </Row>
            </Col>

            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <SwitchV2
                disabled={loadingCreate}
                checked={partial}
                label={'Nota parcial'}
                info={'¿Desea realizar la nota de crédito parcial??'}
                onChange={setPartial}
              />
            </Col>

            <Col xl={12}>
              <Loading show={loadingGet} />
              <hr />
            </Col>

            {renderTotal}

            {items.map(item => (
              <Col
                xl={12}
                key={item.detailId}
                className={'b-item column'}
                onClick={() => {
                  if (partial) {
                    if (!item.selected) setItemSelected(item)
                    else onItemUpdate({ ...item, selected: false })
                  }
                }}>
                <div className={'d-flex'}>
                  {partial && (
                    <div style={{ width: '10%' }}>
                      <Checkbox
                        checked={item.selected}
                        onChange={e => e.preventDefault()}
                      />
                    </div>
                  )}
                  <div className={'column'} style={{ width: partial ? '90%' : '100%' }}>
                    <div className={'space-between'}>
                      <div className={'column'}>
                        <Paragraph>{item.name}</Paragraph>
                        <Paragraph>Cantidad {item.quantity}</Paragraph>
                        <Paragraph>Precio u. {toMoney(item.price)}</Paragraph>
                        <Paragraph>Total. {toMoney(item.total)}</Paragraph>
                        <Paragraph>IVA. {toMoney(getTax(item.total))}</Paragraph>
                        {country.id === Country.SV && (
                          <Paragraph>
                            Total con iva. {toMoney(getTax(item.total) + item.total)}
                          </Paragraph>
                        )}
                      </div>
                    </div>

                    {partial && item.selected && (
                      <div style={{ width: '100%' }} className={'d-flex mt-2'}>
                        <Paragraph>Seleccionado</Paragraph>
                        <hr />
                      </div>
                    )}

                    {partial && item.selected && (
                      <div className={'column'}>
                        {item.itemData.line
                          .filter(subItem => subItem.quantity > 0)
                          .map(subItem => {
                            const unitPrice = getSubPrice(item, subItem)

                            return (
                              <div
                                key={subItem.productId}
                                className={'space-between mb-2'}>
                                <div className={'column'}>
                                  <Paragraph dim>{subItem?.productData?.name}</Paragraph>
                                  <Paragraph dim>{subItem?.productData?.code}</Paragraph>
                                  <Paragraph dim>Total</Paragraph>
                                </div>
                                <div className={'column'}>
                                  <Paragraph dim className={'d-flex flex-row-reverse'}>
                                    {subItem.quantity}
                                  </Paragraph>
                                  <Paragraph dim className={'d-flex flex-row-reverse'}>
                                    {toMoney(unitPrice)}
                                  </Paragraph>
                                  <Paragraph dim className={'d-flex flex-row-reverse'}>
                                    {toMoney(unitPrice * subItem.quantity)}
                                  </Paragraph>
                                </div>
                              </div>
                            )
                          })}
                        <div className={'space-between'}>
                          <div className={'column'}>
                            <Paragraph dim>Sub total</Paragraph>
                            <Paragraph dim>IVA</Paragraph>
                            {country.id === Country.SV && (
                              <Paragraph dim>Total con iva</Paragraph>
                            )}
                          </div>
                          <div className={'column'}>
                            <Paragraph dim>{toMoney(item.amount)}</Paragraph>
                            <Paragraph dim>{toMoney(getTax(item.amount))}</Paragraph>
                            {country.id === Country.SV && (
                              <Paragraph dim>
                                {toMoney(getTax(item.amount) + Number(item.amount))}
                              </Paragraph>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              disabled={loadingCreate}
              color={'secondary'}
              icon={faWindowClose}
              onClick={() => onClose(false)}>
              Cerrar
            </Button>
            <Button
              disabled={reason === null || reason === undefined || reason === ''}
              loading={loadingCreate}
              color={'primary'}
              icon={faCheckDouble}
              onClick={onSave}>
              Crear
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal
        show={itemSelected !== undefined && itemSelected?.detailId !== undefined}
        centered
        size={'lg'}>
        <Modal.Header>
          <Modal.Title>{itemSelected?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={'custom-modal-body'}>
          <Row>
            {itemSelected?.itemData?.line?.map(item => {
              const available = getQuantityAvailable(item)

              return (
                <Col xl={12} key={item.id}>
                  <FormText
                    lStyle={{ width: '100%' }}
                    label={
                      <>
                        <div style={{ float: 'left' }}>{item?.productData?.name}</div>
                        <div style={{ float: 'right' }}>{available + ' u.'}</div>
                      </>
                    }
                    value={item.quantity || 0}
                    type={'number'}
                    changeValue={(quantity, e) => {
                      if (quantity > available + 1) quantity = available + 1
                      e.preventDefault()
                      e.stopPropagation()
                      changeQuantity(item, Number(quantity))
                    }}
                    onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                    prepend={
                      <Icon
                        icon={faMinus}
                        tooltip={'Disminuir'}
                        onClick={(e: Event) => {
                          e.preventDefault()
                          e.stopPropagation()
                          changeQuantity(item, (item.quantity || 0) - 1)
                        }}
                      />
                    }
                    append={
                      <Icon
                        icon={faPlus}
                        tooltip={'Aumentar'}
                        disabled={available <= 0}
                        onClick={(e: Event) => {
                          e.preventDefault()
                          e.stopPropagation()
                          changeQuantity(item, (item.quantity || 0) + 1)
                        }}
                      />
                    }
                  />
                </Col>
              )
            })}

            <Col xl={12}>
              <FormText
                lStyle={{ width: '100%' }}
                label={
                  <>
                    <div style={{ float: 'left' }}>Monto</div>
                    <div style={{ float: 'right' }}>
                      {toMoney(itemSelected?.amount)} / {toMoney(itemSelected?.total)}
                    </div>
                  </>
                }
                value={itemSelected?.amount || 0}
                type={'number'}
                changeValue={(amount, e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  if (!amount || amount < 0) amount = 0
                  else if (amount > itemSelected.total) amount = itemSelected.total

                  setItemSelected({ ...itemSelected, amount })
                }}
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
              />
            </Col>

            <Col xl={12}>
              <FormText
                lStyle={{ width: '100%' }}
                label={'IVA'}
                disabled
                value={getTax(itemSelected?.amount || 0)}
                type={'number'}
              />
            </Col>

            {country.id === Country.SV && (
              <Col xl={12}>
                <FormText
                  lStyle={{ width: '100%' }}
                  label={'Total con iva'}
                  disabled
                  value={
                    getTax(itemSelected?.amount || 0) +
                    (Number(itemSelected?.amount) || 0)
                  }
                  type={'number'}
                />
              </Col>
            )}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              icon={faWindowClose}
              color={'secondary'}
              onClick={() => setItemSelected(undefined)}>
              Cancelar
            </Button>
            <Button
              disabled={
                getQuantityUsed(itemSelected) <= 0 ||
                !itemSelected?.amount ||
                itemSelected?.amount <= 0
              }
              icon={faCheck}
              onClick={() => onItemUpdate({ ...itemSelected, selected: true })}>
              Continuar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
export default CreateCreditNoteSell
