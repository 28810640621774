import { getFormatProductsT } from './warehouse.selector'
export const selectOrdersByClient = state => state.orders.ordersByClient
export const selectOrderByClient = state => state.orders.orderByClient
export const selectCreateNewOrder = state => state.orders.order
export const selectGetAllOrders = state => state.orders.orders
export const selectGetOrdersSummary = state => state.orders.summary
export const selectDashboardOrders = state => state.orders.dashboardOrders
export const selectGetAllOrdersMap = state => state.orders.ordersMap
export const selectGetAdminReport = state => state.orders.admin
export const selectOrdersCount = state => state.orders.counts
export const selectTotalOrders = state => state.orders.totalOrders
export const selectDTotalOrders = state => state.orders.DTotalOrders
export const selectGetAllOrdersByProduct = state => state.orders.ordersProduct
export const selectOrderReport = state => state.orders.report
export const selectGetAllOrderStatus = state =>
  state.orders.orderStatus.map(os => ({
    ...os,
    value: os.id,
    label: os.name,
  }))
export const selectGetAllOrderTypes = state => state.orders.orderTypes
export const selectGetSingleOrder = state => state.orders.order
export const selectGetSingleGroup = state => state.orders.group
export const selectGetSingleGroupByPackages = state => state.orders.packages
export const selectSinglePackage = state => state.orders.singlePackage
export const selectProductExistence = state => state.orders.existence
export const selectWarehouseHistory = state => state.orders.history
export const selectConfirmSingleOrder = state => state.orders.invoice
export const selectGetAllSingleClientHistory = state => state.orders.orders
export const selectGetOrdersWithBalance = state => state.orders.ordersWithBalance
export const selectOrderVisit = state => state.orders.orderVisit
export const selectOrderPayment = state => state.orders.orderPayment
export const selectOrderPaymentConfirm = state => state.orders.paymentConfirm
export const selectOrderVisitConfirm = state => state.orders.visitConfirm
export const selectPaymentTypes = state => state.orders.orderPayments
export const selectGetInvoiceByOrder = state => state.orders.invoice
export const selectGetTransportistRate = state => state.orders.rate
export const selectOrdersPreSales = state => state.orders.ordersPreSale
export const selectOrderUsers = state =>
  state.orders.usersOrders.map(item => ({
    value: item.id,
    label: item.name.concat(' - ', item.alphanumericId),
  }))
export const selectUsersReport = state => state.orders.usersReport
export const selectDelivererReport = state => state.orders.delivererReport
export const selectSellerReport = state => state.orders.sellerReport
export const selectBalanceReport = state => state.orders.balance
export const selectTransferOrders = state => state.orders.transfers
export const selectProductReport = state => state.orders.productReport
export const selectFormatProducts = state =>
  getFormatProductsT(state.orders.productReport)

export const selectSaleOrderBalanceCurrentUser = state =>
  state.orders.saleOrderBalanceCurrentUser
export const selectBIPaymentsHistoryCurrentUser = state =>
  state.orders.biPaymentsHistoryCurrentUser

export const selectExternalOrders = state => state.orders.externalOrders
export const selectGOrders = state => state.orders.gOrders

export const selectVisitOrdersList = state =>
  state.orders.visitOrders.filter(
    o =>
      o.order.status === 1 &&
      o.client.latitude &&
      o.client.longitude &&
      /^-?\d+(.\d+)?$/.test(o.client.latitude) &&
      /^-?\d+(.\d+)?$/.test(o.client.longitude),
  )

export const selectGetPatymentType = (state, id) => {
  const { orderPayments } = state.orders
  let type = orderPayments.find(p => p.id === id)
  if (!type) type = { name: 'N/A', id: null }
  return type
}

export const selectOrderUpdated = state => state.orders.updated
export const selectOrderLinked = state => state.orders.linked
export const selectOrderDeleted = state => state.orders.status
export const selectOrdersPayment = state => state.orders.paymentOrders
export const selectOrdersToPay = state => state.orders.ordersToPay
export const selectTotalOrdersPayment = state => state.orders.totalPaymentOrders
export const selectOrderRoute = state => state.orders.route
export const selectPaymentHistory = state => state.orders.historyPayment
export const selectPaymentReceipt = state => state.orders.paymentRepeat
export const selectArchivedPayments = state => state.orders.archived
export const selectOrderMatrix = state => state.orders.orderMatrix
export const selectPaymentsOrder = state => state.orders.paymentsOrder
export const selectPaymentsByOrder = state => state.orders.paymentsByOrder
export const selectResponseMassiveConfirmation = state =>
  state.orders.responseMassiveConfirmation
export const selectPrintInvoice = state => state.orders.printInvoice
export const selectOrderAddons = state => state.orders.ordersAddons
export const selectOrderVersions = state => {
  const { versions } = state.orders
  return versions.map(version => ({
    ...version,
    value: version.date,
    label: version.version,
  }))
}
export const selectOrderVersionItems = state => state.orders.versionItems
export const selectOrderUseRecipe = state => state.orders.orderUseRecipes
