import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Col, Container, Modal, ProgressBar, Row } from 'react-bootstrap'
import { Button, FormText, Icon, Select, Switch } from 'src/components'
import {
  faExclamationCircle,
  faPlus,
  faSave,
  faTrash,
  faWindowClose,
} from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes,
  getFelConfiguration,
  updateFelConfiguration,
} from 'src/actions/companyConfiguration.actions'
import { selectCompanyFELConfiguration } from 'src/selectors/companyConfiguration.selector'

import {
  actionTypes as typeE,
  getAllEconomicActivity,
  getExportationRegimen,
  getFiscalPrecinct,
} from 'src/actions/currencies.actions'
import {
  selectEconomicActivities,
  selectFELPhrases,
  selectExportationRegime,
  selectFiscalPrecinct,
} from 'src/selectors/currencies.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import { handlerError, handlerSuccess, hasErrors } from 'src/selectors/error.selector'
import { showAlert } from 'src/actions/alert.actions'

import { getCertifiers } from 'src/enums/getCertifiers'
import { getCommercialFields } from 'src/utils/mentorHelpers/getCommercialFields'

import { Country } from 'src/enums/countries'

const typeDocuments = [
  { label: 'FACT', value: 1 },
  { label: 'FPEQ', value: 2 },
  { label: 'FAPE', value: 3 },
]

const typeAffiliations = [
  { label: 'GEN', value: 1 },
  { label: 'PEQ', value: 2 },
  { label: 'PEE', value: 3 },
]

const defaultOption = {
  value: null,
  label: 'Sin seleccionar',
}

const FELCompanyConfig = ({
  show,
  id,
  companyName,
  onHide,
  country,
  useExportation = false,
}) => {
  const dispatch = useDispatch()

  const phrasesToGT = useSelector(selectFELPhrases)
  const phrasesToAllCountries = useSelector(selectEconomicActivities)

  const typePhrases = country === Country.GT ? phrasesToGT : phrasesToAllCountries
  const loadingPhrases = useSelector(state =>
    loadingSelector([typeE.GET_ECONOMIC_ACTIVITY])(state),
  )

  const commercialFields = getCommercialFields(country)
  const certifiers = getCertifiers(country)
  const fiscalPrecinct = useSelector(selectFiscalPrecinct)
  const exportationRegime = useSelector(selectExportationRegime)

  const certifiersObj = certifiers.reduce(
    (acc, cert) => ({
      ...acc,
      [cert.value]: { ...cert },
    }),
    {},
  )

  const response = useSelector(selectCompanyFELConfiguration)
  const loadingGet = useSelector(state =>
    loadingSelector([actionTypes.GET_FEL_CONFIGURATIONS])(state),
  )

  const loadingUpdate = useSelector(state =>
    loadingSelector([actionTypes.UPDATE_FEL_CONFIGURATIONS])(state),
  )
  const loadingExportationConfig = useSelector(state =>
    loadingSelector([typeE.GET_FISCAL_PRECINCT, typeE.GET_REGIMEN])(state),
  )
  const error = useSelector(state =>
    hasErrors([actionTypes.UPDATE_FEL_CONFIGURATIONS])(state),
  )

  const needPhrases = [Country.GT, Country.SV].some(c => c === country)

  const [flags, setFlag] = useState({ get: false, save: false, phrases: false })
  const [open, setOpen] = useState(false)
  const [config, setConfig] = useState({})
  const [commercial, setCommercial] = useState({})
  const [regimen, setRegimen] = useState({})
  const [phrases, setPhrases] = useState([])
  const [errors, setErros] = useState({})
  const [exportationConfig, setExportationConfig] = useState({
    fiscalPrecinct: defaultOption,
    regime: defaultOption,
  })

  const { certifier, useFEL } = config

  const disabled = loadingGet || loadingUpdate || !useFEL
  const loadingBar = loadingGet || loadingUpdate

  useEffect(() => {
    if (!show) return
    clearAll()
    dispatch(getAllEconomicActivity(country))
    if (useExportation) {
      dispatch(getFiscalPrecinct())
      dispatch(getExportationRegimen())
    }
  }, [show])

  useEffect(() => {
    if (!show) return
    if (loadingPhrases) setFlag({ ...flags, phrases: true })
    else if (flags.phrases) {
      setFlag({ ...flags, phrases: false })
      dispatch(getFelConfiguration(id))
    }
  }, [loadingPhrases])

  useEffect(() => {
    if (!show) return
    if (loadingGet) setFlag({ ...flags, get: true })
    else if (flags.get) {
      setFlag({ ...flags, get: false })

      const newConfig = {}
      Object.keys(response.config).forEach(k => {
        newConfig[k] = response.config[k]
      })
      newConfig.certifier = certifiers.find(c => c.value === newConfig.certifier)
      setConfig(newConfig)

      let newCommercial = {}
      Object.keys(response.commercial).forEach(k => {
        newCommercial[k] = response.commercial[k]
      })
      setCommercial(newCommercial)

      setExportationConfig({
        ...exportationConfig,
        fiscalPrecinct: fiscalPrecinct.find(f => f.code === newCommercial.fiscalPrecinct),
        regime: exportationRegime.find(f => f.code === newCommercial.regime),
      })

      let newRegimen = {
        resolutionNumber: response.regimen.resolutionNumber,
        resolutionDate: response.regimen.resolutionDate,
      }
      newRegimen.affiliation = typeAffiliations.find(
        t => t.label === response.regimen.affiliation,
      )
      newRegimen.type = typeDocuments.find(t => t.label === response.regimen.type)
      setRegimen(newRegimen)

      let newPhrases = response.regimen.phrases.map(p => {
        const phrase = typePhrases.find(tp => tp.value === p)
        if (phrase) {
          return { ...phrase, phraseId: p }
        } else return {}
      })
      setPhrases(newPhrases)
    }
  }, [loadingGet])

  useEffect(() => {
    if (!show) return
    if (loadingUpdate) setFlag({ ...flags, save: true })
    else if (flags.save) {
      setFlag({ ...flags, save: false })

      const alert = error ? { ...handlerError(error.message) } : { ...handlerSuccess() }
      dispatch(showAlert(alert))
    }
  }, [loadingUpdate])

  const clearAll = () => {
    setOpen(!open)
    setConfig({})
    setCommercial({})
    setRegimen({})
    setErros({})
  }

  const onClose = () => {
    onHide()
    clearAll()
  }

  const validateForms = () => {
    let errorsForm = {}

    if (certifier === undefined || certifier.value === undefined)
      errorsForm.certifier = 'Es necesario seleccionar a un certificador.'
    else {
      certifiersObj[certifier?.value].fields.forEach(field => {
        const f = config[field.value]
        if (!f || (f.trim && f.trim() === ''))
          errorsForm = { ...errorsForm, [field.value]: 'Es necesario llenar el campo' }
      })
    }

    commercialFields.forEach(field => {
      const f = commercial[field.value]
      if (!f || (f.trim && f.trim() === ''))
        errorsForm = { ...errorsForm, [field.value]: 'Es necesario llenar el campo' }
    })

    if (country === Country.GT) {
      if (!regimen.type || !regimen.type.value)
        errorsForm.type = 'Es necesario llenar el campo'
      if (!regimen.affiliation || !regimen.affiliation.value)
        errorsForm.affiliation = 'Es necesario llenar el campo'
    }

    if (needPhrases && phrases.some(p => !p.value))
      errorsForm.phrases = 'Es necesario asignar un valor a las frases.'

    if (phrases.some(p => p.value === 4)) {
      if (!regimen.resolutionNumber)
        errorsForm.resolutionNumber = 'Es necesario llenar el campo'
      if (!regimen.resolutionDate)
        errorsForm.resolutionDate = 'Es necesario llenar el campo'
    }

    setErros(errorsForm)
    if (Object.keys(errorsForm).length === 0) {
      const request = {
        config: { ...config, certifier: certifier?.value },
        commercial: { ...commercial },
        regimen: {
          ...regimen,
          type: regimen?.type?.label,
          affiliation: regimen?.affiliation?.label,
          phrases: phrases.map(p => p.value),
        },
      }
      dispatch(updateFelConfiguration(id, request))
    }
  }

  const onChangeCertifierFields = e => {
    const { value, name } = e.target
    setConfig({ ...config, [name]: value })
  }

  const onChangeCommercialFields = e => {
    const { value, name } = e.target
    setCommercial({ ...commercial, [name]: value })
  }

  return (
    <div>
      <Modal show={open} centered size={'xl'} onHide={onClose}>
        <Modal.Header>
          <Modal.Title>{companyName}: FEL</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className={'custom-modal-body'}>
            <Row>
              <Col xl={12}>
                {loadingBar && (
                  <ProgressBar
                    label="Cargando"
                    animated
                    now={100}
                    style={{ marginBottom: 10 }}
                  />
                )}
              </Col>

              <Col xl={12}>
                <Row>
                  <Col xl={12}>
                    <div className={'column mb-2'}>
                      <Switch
                        topLabel
                        label={'Activar FEL'}
                        checked={useFEL}
                        changeValue={v => setConfig({ ...config, useFEL: v })}
                      />
                    </div>
                  </Col>

                  <Col xl={12}>Credenciales del certificador</Col>
                  <Col xl={12}>
                    <Select
                      required
                      disabled={disabled}
                      label={'Certificador'}
                      options={certifiers}
                      value={certifier || defaultOption}
                      onChange={v => setConfig({ ...config, certifier: v })}
                      error={errors.certifier}
                    />
                  </Col>

                  {certifier?.value > 0 &&
                    certifiersObj[certifier?.value].fields.map((field, i) => (
                      <Col xl={4} lg={4} md={6} sm={12} key={i}>
                        <FormText
                          required
                          disabled={disabled}
                          label={field.label}
                          value={config?.[field.value] || ''}
                          name={field.value}
                          type={'text'}
                          onChange={onChangeCertifierFields}
                          error={errors[field.value]}
                        />
                      </Col>
                    ))}
                </Row>
              </Col>

              <Col xl={12}>
                <hr />
              </Col>

              <Col xl={12}>
                <Row>
                  <Col xl={12}>Configuración comercial</Col>
                  {commercialFields.map((field, i) => (
                    <Col key={i} xl={4} lg={4} md={6} sm={12}>
                      {field.type === 'select' ? (
                        <Select
                          required
                          disabled={disabled}
                          label={field.label}
                          options={[defaultOption, ...field.options]}
                          value={
                            field.options.find(
                              option =>
                                option.value === Number(commercial?.[field.value]),
                            ) || defaultOption
                          }
                          onChange={newValue =>
                            setCommercial({
                              ...commercial,
                              [field.value]: newValue.value,
                            })
                          }
                          error={errors[field.value]}
                        />
                      ) : (
                        <FormText
                          required
                          disabled={disabled}
                          label={field.label}
                          value={commercial?.[field.value] || ''}
                          name={field.value}
                          type={field.type}
                          onChange={onChangeCommercialFields}
                          error={errors[field.value]}
                        />
                      )}
                    </Col>
                  ))}
                  {country === Country.SV && useExportation && (
                    <>
                      <Col xl={6} lg={6} md={6} sm={12}>
                        <Select
                          required
                          disabled={loadingExportationConfig}
                          label={'Recinto fiscal '}
                          options={[defaultOption, ...fiscalPrecinct]}
                          value={exportationConfig.fiscalPrecinct}
                          onChange={fiscalPrecinct => {
                            setExportationConfig({
                              ...exportationConfig,
                              fiscalPrecinct,
                            })
                            setCommercial({
                              ...commercial,
                              fiscalPrecinct: fiscalPrecinct.code,
                            })
                          }}
                        />
                      </Col>
                      <Col xl={6} lg={6} md={6} sm={12}>
                        <Select
                          required
                          disabled={loadingExportationConfig}
                          label={'Regimen de exportación'}
                          options={[defaultOption, ...exportationRegime]}
                          value={exportationConfig.regime}
                          onChange={regime => {
                            setExportationConfig({
                              ...exportationConfig,
                              regime,
                            })
                            setCommercial({
                              ...commercial,
                              regime: regime.code,
                            })
                          }}
                        />
                      </Col>
                    </>
                  )}
                </Row>
              </Col>

              <Col xl={12}>
                <hr />
              </Col>

              <Col xl={12}>
                <Row>
                  {country === Country.GT && (
                    <Col xl={12}>
                      <Row>
                        <Col xl={12}>Configuración régimen SAT</Col>
                        <Col xl={4} lg={4} md={6} sm={12}>
                          <Select
                            required
                            disabled={disabled}
                            label={'Tipo de documento'}
                            value={regimen.type || defaultOption}
                            options={typeDocuments}
                            onChange={v => setRegimen({ ...regimen, type: v })}
                            error={errors.type}
                          />
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12}>
                          <Select
                            required
                            disabled={disabled}
                            label={'Afiliación de IVA'}
                            value={regimen.affiliation || defaultOption}
                            options={typeAffiliations}
                            onChange={v => setRegimen({ ...regimen, affiliation: v })}
                            error={errors.affiliation}
                          />
                        </Col>

                        {phrases.some(p => [4, 32, 33].some(id => id === p.value)) && (
                          <Col xl={12}>
                            <Row>
                              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <FormText
                                  required
                                  disabled={disabled}
                                  label={'Número de resolución'}
                                  value={regimen?.resolutionNumber}
                                  name={'resolutionNumber'}
                                  type={'text'}
                                  changeValue={value =>
                                    setRegimen({ ...regimen, resolutionNumber: value })
                                  }
                                  error={errors.resolutionNumber}
                                />
                              </Col>
                              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <FormText
                                  required
                                  disabled={disabled}
                                  label={'Fecha de resolución'}
                                  value={regimen?.resolutionDate}
                                  name={'resolutionDate'}
                                  type={'text'}
                                  changeValue={value =>
                                    setRegimen({ ...regimen, resolutionDate: value })
                                  }
                                  error={errors.resolutionDate}
                                />
                              </Col>
                            </Row>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  )}

                  {needPhrases && (
                    <Col xl={12}>
                      <Row>
                        <Col xl={12} style={{ marginTop: 10 }}>
                          <div className={'column'}>
                            <label className={'ftf-form-label left mt-3'}>
                              *{' '}
                              {country === Country.GT ? 'Frases' : 'Actividad económica'}
                            </label>
                            {errors.phrases && (
                              <div className={'input-error d-flex'}>
                                <Icon icon={faExclamationCircle} tooltip={'error'} />{' '}
                                {errors.phrases}
                              </div>
                            )}
                          </div>
                        </Col>

                        {phrases.map((p, i) => {
                          const phraseType =
                            typePhrases.find(tp => tp.value === p.phraseId) ||
                            defaultOption

                          return (
                            <Col xl={12} key={i}>
                              <Row>
                                <Col xl={7} lg={7} md={7}>
                                  <Select
                                    disabled={disabled}
                                    style={{ flex: 5 }}
                                    required
                                    label={
                                      country === Country.GT
                                        ? 'Frase'
                                        : 'Actividad económica'
                                    }
                                    options={typePhrases.filter(
                                      p => !phrases.find(ph => ph.phraseId === p.value),
                                    )}
                                    value={phraseType}
                                    onChange={v => {
                                      const newPhrase = { ...v, phraseId: v.value }
                                      const newPhrases = [...phrases]
                                      newPhrases[i] = newPhrase
                                      setPhrases(newPhrases)
                                    }}
                                  />
                                </Col>

                                {country === Country.GT ? (
                                  <>
                                    <Col>
                                      <FormText label={'Tipo'} value={p?.code} disabled />
                                    </Col>
                                    <Col>
                                      <FormText
                                        label={'Escenario'}
                                        value={p?.stage}
                                        disabled
                                      />
                                    </Col>
                                  </>
                                ) : (
                                  <Col>
                                    <FormText
                                      label={'Código'}
                                      value={p?.phrase}
                                      disabled
                                    />
                                  </Col>
                                )}

                                <Col>
                                  <Button
                                    disabled={disabled}
                                    style={{ flex: 1, marginTop: 48 }}
                                    color={'secondary'}
                                    onClick={() => {
                                      setPhrases(
                                        phrases.filter(ph => ph.value !== p.value),
                                      )
                                    }}>
                                    <Icon tooltip={'Eliminar'} icon={faTrash} />
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          )
                        })}

                        <Col xl={4} style={{ marginTop: 5 }}>
                          <Button
                            disabled={
                              disabled || (country === Country.SV && phrases.length >= 1)
                            }
                            icon={faPlus}
                            onClick={() => setPhrases([...phrases, {}])}>
                            Agregar
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              disabled={loadingUpdate}
              color={'secondary'}
              icon={faWindowClose}
              onClick={() => onClose()}>
              Cerrar
            </Button>

            <Button
              loading={loadingUpdate}
              color={'primary'}
              icon={faSave}
              onClick={() => validateForms()}>
              Guardar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
export default FELCompanyConfig
