import PrinterServices from '../services/printer.service'
import { executeAction } from './global.actions'

export const types = {
  GET_PRINTERS: 'GET_PRINTERS',
  CREATE_PRINTER: 'CREATE_PRINTER',
  UPDATE_PRINTER: 'UPDATE_PRINTER',
  SAVE_PRINT_CONNECT: 'SAVE_PRINT_CONNECT',
  UPDATE_CORRELATIVE: 'UPDATE_CORRELATIVE',
  GET_PRINT: 'GET_PRINT',
  ADD_TABLES: 'ADD_TABLES',
  SET_GENERAL_DATA: 'SET_GENERAL_DATA',
  SAVE_LOCAL_PRINTS: 'SAVE_LOCAL_PRINTS',
  SEND_PRINT_TO_LOCALHOST: 'SEND_PRINT_TO_LOCALHOST',
}

export const getAll = tableId => dispatch => {
  const process = async () => ({
    printers: await PrinterServices.getAll(tableId),
  })
  dispatch(executeAction(types.GET_PRINTERS, process))
}

export const createPrint = print => dispatch => {
  const process = async () => await PrinterServices.createPrint(print)
  dispatch(executeAction(types.CREATE_PRINTER, process))
}

export const updatePrint = print => dispatch => {
  const process = async () => await PrinterServices.updatePrint(print)
  dispatch(executeAction(types.UPDATE_PRINTER, process))
}

export const savePrintConnect = (id, value) => dispatch => {
  const process = async () => ({ data: { id, value } })
  dispatch(executeAction(types.SAVE_PRINT_CONNECT, process))
}

export const onUpdateCorrelativePrint = () => dispatch => {
  const process = async () => {
    await PrinterServices.onUpdateCorrelativePrint()
    dispatch(getAll())
    return {}
  }
  dispatch(executeAction(types.UPDATE_CORRELATIVE, process))
}

export const getPrint = printId => dispatch => {
  const process = async () => ({
    print: await PrinterServices.getPrint(printId),
  })
  dispatch(executeAction(types.GET_PRINT, process))
}

export const assignTablesPrint = (printId, tables) => dispatch => {
  const process = async () => await PrinterServices.assignTablesPrint(printId, tables)
  dispatch(executeAction(types.ADD_TABLES, process))
}

export const deletePrinter = printId => dispatch => {
  const process = async () => await PrinterServices.deletePrinter(printId)
  dispatch(executeAction(types.CREATE_PRINTER, process))
}

export const setGeneralData = data => dispatch => {
  const process = async () => ({ data })
  dispatch(executeAction(types.SET_GENERAL_DATA, process))
}

export const onSaveLocalPrints = printers => dispatch => {
  const process = async () => ({ printers })
  dispatch(executeAction(types.SAVE_LOCAL_PRINTS, process))
}

export const sendPrintToLocalhost = (request, host) => dispatch => {
  const process = async () => ({
    local: await PrinterServices.sendPrintToLocalhost(request, host),
  })
  dispatch(executeAction(types.SEND_PRINT_TO_LOCALHOST, process))
}
