import React from 'react'
import { useSelector } from 'react-redux'

import { Card, TableV2, Icon } from 'src/components'

import { faEye, faTrash } from '@fortawesome/free-solid-svg-icons'
import { selectResolutions } from 'src/selectors/resolutions.selector'

import { isAllowed } from 'src/selectors/modules.selector'
import { correlativePermissions } from 'src/enums/permissions'

import { loadingSelector } from 'src/selectors/loading.selector'
import { actionTypes } from 'src/actions/resolutions.actions'

import { getDocumentType } from './CorrelativeFunctions'

interface ICorrelativeListProps {
  onUpdate: (correlative: ICorrelative) => void
  onDelete: (correlative: ICorrelative) => void
}

const CorrelativeList = ({ onUpdate, onDelete }: ICorrelativeListProps) => {
  const resolutions: ICorrelative[] = useSelector(selectResolutions)

  const loading = useSelector(state =>
    loadingSelector([actionTypes.GET_RESOLUTIONS])(state),
  )

  const canDelete: boolean = useSelector(state =>
    isAllowed(state, [correlativePermissions.delete]),
  )

  const headers: ITableHeader[] = [
    { show: true, type: 'text', label: 'CAI', value: ['number'], className: 'medium' },
    {
      show: true,
      type: 'text',
      label: 'Establecimiento',
      value: ['series'],
      className: 'mini',
    },
    {
      show: true,
      type: 'text',
      label: 'Identificador',
      value: ['rangeCount'],
      className: 'mini',
    },
    {
      show: true,
      type: 'text',
      label: 'Documento',
      value: [],
      className: 'mini',
      custom: (item: ICorrelative) => {
        const documentType = getDocumentType(item.documentType)
        return documentType?.label || 'Sin documento'
      },
    },
    {
      show: true,
      type: 'text',
      label: 'Inicio',
      value: ['initialNumber'],
      className: 'mini',
    },
    {
      show: true,
      type: 'text',
      label: 'Fin',
      value: ['finalNumber'],
      className: 'mini',
    },
    {
      show: true,
      type: 'date',
      label: 'Expirará',
      value: ['expirationDate'],
      className: 'mini',
    },
    {
      show: true,
      type: 'text',
      label: 'Actual',
      value: ['currentNumber'],
      className: 'mini',
    },
    {
      show: true,
      type: 'text',
      label: 'Estado',
      value: [],
      className: 'mini',
      custom: (item: ICorrelative) => {
        let expired: boolean = item.expired
        if (!expired) {
          const exp: number = new Date(item.expirationDate).setHours(12, 0, 0, 0)
          const today: number = new Date().setHours(12, 0, 0, 0)

          expired = exp <= today
        }

        return expired ? 'Expirado' : 'Vigente'
      },
    },
    {
      show: true,
      type: '',
      label: 'Acciones',
      value: [],
      className: 'mini center',
      custom: (item: ICorrelative) => (
        <div className={'d-flex center'}>
          <Icon
            icon={faEye}
            color={'primary'}
            size={'2x'}
            tooltip={'Ver'}
            onClick={() => onUpdate(item)}
          />
          {canDelete && (
            <Icon
              className={'ml-3'}
              icon={faTrash}
              color={'secondary'}
              size={'2x'}
              tooltip={'Eliminar'}
              onClick={() => onDelete(item)}
            />
          )}
        </div>
      ),
    },
  ]

  return (
    <Card title={'Listado'} white>
      <TableV2
        // @ts-ignore
        headers={headers}
        loading={loading}
        items={resolutions}
        storageKey={'correlative'}
        mobileAuto
      />
    </Card>
  )
}
export default CorrelativeList
