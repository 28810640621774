import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { createMessenger } from '@userlike/messenger'
import Icon from 'src/components/buttons/IconButton'
import { faComments } from '@fortawesome/free-solid-svg-icons'

import { selectCurrentUser } from 'src/selectors/user.selector'
import { selectSetCurrentCompany } from 'src/selectors/company.selector'

import { CHAT_BOX_KEY } from 'src/settings/app.settings'

const version = 1

const UserLike = () => {
  const user = useSelector(selectCurrentUser)
  const company = useSelector(selectSetCurrentCompany)

  const [userLike, setUserLike] = useState<any>(null)

  useEffect(() => {
    if (user?.id && company?.id) setUp()
  }, [user?.id && company?.id])

  const createApi = async () => {
    const result = await createMessenger({ widgetKey: CHAT_BOX_KEY, version })
    if ('error' in result) {
      console.log('userLike: createApi error', result.error)
      return
    }

    const { api } = result.value
    return api
  }

  const setUp = async () => {
    const api = userLike ? userLike : createApi()
    if (api) {
      api.then(messenger =>
        messenger
          .mount()
          .then(() =>
            messenger.setContactInfo({
              name: user?.name,
              email: user?.email,
              company: company?.name,
            }),
          )
          .then(() =>
            messenger.setVisibility({
              main: true,
              button: false,
              notifications: false,
            }),
          ),
      )
      setUserLike(api)
    }
  }

  return (
    <Icon
      style={{ marginTop: 12, marginLeft: 5 }}
      color={'accent'}
      icon={faComments}
      size={'2x'}
      tooltip={'Atención al cliente'}
      placement={'bottom'}
      onClick={async () => await userLike?.then(m => m.maximize())}
    />
  )
}

export default UserLike
