import './TableOrderPage.scss'
import React, { ReactElement, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Modal } from 'react-bootstrap'
import { Button, Icon, Paragraph } from 'src/components'

import {
  faCar,
  faCashRegister,
  faCheck,
  faClock,
  faSave,
  faStepForward,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'

import { isAllowed } from 'src/selectors/modules.selector'

import { actionTypes as restaurantActions } from 'src/actions/restaurant.actions'

import { loadingSelector } from 'src/selectors/loading.selector'
import { haveAnyValue } from 'src/utils/utilitiesV2'
import { toMoney } from 'src/utils/utilities'

import { selectPosConfigurationByType } from 'src/selectors/restaurant.selector'
import { posPanelConfigurations } from 'src/enums/posPanelConfigurations'

interface Props {
  show: boolean
  item: IItemPOS
  tableOrder: any
  isImmediatelyInvoice: boolean
  onClose: () => void
  onSave: (item: IItemPOS[]) => void
}

/**
 * A React component that renders a modal for editing items in a table order within a Point of Sale (POS) system.
 * It provides functionalities to edit item details like quantities, discounts, and sub-items, with specific
 * rules based on permissions and the company's country settings.
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.show - Determines if the modal is visible.
 * @param {IItemPOS} props.item - The item to be edited, represented by an `IItemPOS` object.
 * @param {Object} props.tableOrder - Contains details about the current table order.
 * @param {boolean} props.isImmediatelyInvoice - Indicates if the invoice should be generated immediately.
 * @param {Function} props.onClose - A callback function to be called when the modal is closed.
 * @param {Function} props.onSave - A callback function to save the edited items, receives an array of `IItemPOS`.
 * @returns React component to edit table order items within a modal interface.
 */
const TableOrderItemSelected = ({
  show,
  item = {},
  tableOrder = {},
  isImmediatelyInvoice,
  onClose,
  onSave,
}: Props) => {
  const loadingGet = useSelector(state =>
    loadingSelector([
      restaurantActions.GET_TABLE_ORDER,
      restaurantActions.SET_PRODUCT_STATUS,
    ])(state),
  )

  const canDelete = useSelector(state => isAllowed(state, [2206]))
  const canDiscount = useSelector(state => isAllowed(state, [2203]))
  const canUseDiscounts =
    useSelector(state =>
      selectPosConfigurationByType(state, posPanelConfigurations.discounts),
    ) && canDiscount

  const [itemDetail, setItemDetail] = useState<IItemPOS>({})

  const getInputComponent = (
    value: number,
    name: string,
    placeHolder: string,
    key: number,
    decimals?: number,
    max?: number,
  ): ReactElement => {
    return (
      <input
        type={'number'}
        placeholder={placeHolder}
        value={value}
        min={0}
        max={max}
        style={{ width: 100 }}
        onChange={({ target }) => {
          let value = haveAnyValue(target.value) ? Number(target.value) : 0
          if (value < 0) value = 0
          else if (value > max) value = max

          if (decimals) value = Number(value.toFixed(decimals))

          itemDetail.subItems[key][name] = value
          itemDetail.subItems[key].edited = true
          setItemDetail({ ...itemDetail, edited: true })
        }}
        onFocus={e => e.target.select()}
      />
    )
  }

  useEffect(() => {
    if (!show) setItemDetail({})
    else setItemDetail(JSON.parse(JSON.stringify(item)))
  }, [show])

  return (
    <div>
      <Modal show={show} centered onHide={() => onClose()}>
        <Modal.Header closeButton>
          <Modal.Title>
            {itemDetail.code} {itemDetail.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={'custom-modal-body'}>
          <div className={'b-item summary'}>
            {!itemDetail.requiresAmountOnly && (
              <Paragraph>Cantidades: {itemDetail.quantity}</Paragraph>
            )}
            <Paragraph>Subtotal: {toMoney(itemDetail.subtotal)}</Paragraph>
          </div>
          <Paragraph>Detallado</Paragraph>
          {itemDetail?.subItems
            ?.filter(si => !si.deleted)
            ?.map((si: IItemPOS) => {
              const canUpdateQuantity = !si.status

              let statusName: string
              let statusIcon
              let statusColor = '#12a8bb'

              if (si.payed) {
                statusName = 'Pagado'
                statusIcon = faCheck
                statusColor = '#8d9'
              } else if (si.status) {
                if (si?.status?.id === 3 && tableOrder?.posType === 2) {
                  statusName = 'Entregar Vehículo'
                  statusIcon = faCar
                } else {
                  statusName = si.status.name
                  if (statusName === 'Entregada') {
                    statusIcon = faCashRegister
                  } else if (statusName === 'En Preparación') {
                    statusIcon = faClock
                  } else if (statusName === 'En Espera') {
                    statusIcon = faClock
                  } else {
                    statusIcon = faStepForward
                  }
                }
              } else {
                statusName = isImmediatelyInvoice ? 'Por facturar' : 'Por comandar'
                statusIcon = faStepForward
              }

              const subItemId = si.groupValue || si.detailId

              const key = itemDetail.subItems.findIndex(
                ip => (ip.groupValue || ip.detailId) === subItemId,
              )

              const itemSubtotal = si.quantity * si.price
              const itemDiscount = si.discount
              const subtotalWithDiscount = itemSubtotal - itemDiscount
              const tax = 0 // withTaxDetailed ? getTaxes(subtotalWithDiscount, country.id) : 0

              const itemTotal = subtotalWithDiscount + tax
              return (
                <div className={'b-item column'} key={si.detailId}>
                  <div className="space-between">
                    <div className={'column'}>
                      <Paragraph bold>
                        {itemDetail.name} {itemDetail.code}
                      </Paragraph>
                      <Paragraph dim>{si.suppliesText}</Paragraph>
                    </div>

                    {si.status &&
                      (si.status.id <= 2 || (si.status.id >= 3 && canDelete)) && (
                        <Icon
                          icon={faTrash}
                          color={'secondary'}
                          tooltip={'Eliminar'}
                          size={'lg'}
                          onClick={() => {
                            itemDetail.subItems[key].deleted = true
                            itemDetail.subItems[key].edited = true
                            setItemDetail({ ...itemDetail, edited: true })
                          }}
                        />
                      )}
                  </div>

                  {!si.requiresAmountOnly && (
                    <div className={'space-between'}>
                      <Paragraph>Cantidad:</Paragraph>
                      {canUpdateQuantity ? (
                        getInputComponent(
                          si.quantity,
                          'quantity',
                          'Cantidad',
                          key,
                          si.decimals || 0,
                        )
                      ) : (
                        <Paragraph bold>{si.quantity}u</Paragraph>
                      )}
                    </div>
                  )}

                  <div className={'space-between'}>
                    <Paragraph>Subtotal</Paragraph>
                    <Paragraph bold>{toMoney(itemSubtotal)}</Paragraph>
                  </div>

                  <div className={'space-between'}>
                    <Paragraph>Descuento</Paragraph>

                    {(!si.status || si.status.id <= 4) && canUseDiscounts ? (
                      getInputComponent(
                        si.discount,
                        'discount',
                        'Descuento',
                        key,
                        null,
                        itemSubtotal,
                      )
                    ) : (
                      <Paragraph bold>{toMoney(itemDiscount)}</Paragraph>
                    )}
                  </div>

                  {tax > 0 && (
                    <div className={'space-between'}>
                      <Paragraph>IVA</Paragraph>
                      <Paragraph bold>{toMoney(tax)}</Paragraph>
                    </div>
                  )}

                  <div className={'space-between'}>
                    <Paragraph>Total</Paragraph>
                    <Paragraph bold>{toMoney(itemTotal)}</Paragraph>
                  </div>

                  <div className={'space-between'}>
                    <div />
                    <div className="status d-flex">
                      <div className="b-user-email mr-2">{statusName}</div>
                      <Icon
                        icon={statusIcon}
                        color={statusColor}
                        style={{ cursor: 'default' }}
                      />
                    </div>
                  </div>
                </div>
              )
            })}
        </Modal.Body>
        <Modal.Footer>
          <div className={'container-buttons'}>
            <Button
              loading={loadingGet}
              icon={faSave}
              disabled={!itemDetail.edited}
              onClick={() =>
                onSave(
                  itemDetail.subItems
                    ?.filter(si => si.edited)
                    .map(subItem => ({
                      ...subItem,
                      deleted: subItem.deleted || subItem.quantity <= 0,
                    })),
                )
              }>
              Guardar cambios
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
export default TableOrderItemSelected
