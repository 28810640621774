import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Modal, Row, Col } from 'react-bootstrap'
import { Button, FormText, NumberField, SwitchV2 } from 'src/components'
import { faWindowClose } from '@fortawesome/free-solid-svg-icons'

import { haveAnyValue } from 'src/utils/utilitiesV2'
import { loadingSelector } from 'src/selectors/loading.selector'

import { actions } from 'src/actions/taxes.actions'

interface ITaxFormProps {
  show: boolean
  tax: Tax
  onClose: () => void
  onResponse: (tax: Tax) => void
}

const TaxForm = ({ show, tax, onClose, onResponse }: ITaxFormProps) => {
  const loading: boolean = useSelector(state =>
    loadingSelector([actions.UPDATE_TAX_OR_CREATE_TAX])(state),
  )

  const [data, setData] = useState<Tax>({})
  const [errors, setErrors] = useState<TaxError>({})

  useEffect(() => {
    if (!show) {
      onReset()
      return
    }
    setData({ ...tax, rate: tax.rate ? tax.rate * 100 : 0 })
  }, [show])

  const onReset = () => {
    setData({})
    setErrors({})
  }

  const onHide = () => {
    if (loading) return
    onReset()
    onClose()
  }

  const onValidate = () => {
    const errors: TaxError = {}

    if (!haveAnyValue(data.name)) errors.name = 'El nombre es requerido'

    if (!haveAnyValue(data.rate)) errors.rate = 'El valor es requerido'

    if (Object.keys(errors).length > 0) {
      setErrors(errors)
      return
    }

    onResponse(data)
  }

  return (
    <div>
      <Modal show={show} centered size={'lg'} onHide={onHide}>
        <Modal.Header closeButton={!loading}>
          <Modal.Title>
            {tax.id ? `Impuesto: ${tax.name}` : 'Creación de impuesto'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={6} lg={6} md={12} sm={12}>
              <FormText
                required
                label={'Nombre'}
                value={data.name}
                changeValue={(value: string) => setData({ ...data, name: value })}
                error={errors.name}
                disabled={loading}
              />
            </Col>
            <Col xl={6} lg={6} md={12} sm={12}>
              <NumberField
                style={{ marginTop: '20px' }}
                required
                label={'Valor %'}
                value={data.rate}
                onValueChange={(value: number) => setData({ ...data, rate: value })}
                decimals={2}
                max={100}
                min={0}
                error={errors.rate}
                disabled={loading}
              />
            </Col>
            {haveAnyValue(tax.id) && (
              <Col xl={6} lg={6} md={12} sm={12}>
                <SwitchV2
                  label={'Estado'}
                  checked={data.active}
                  onChange={(value: boolean) => setData({ ...data, active: value })}
                  info={
                    'Si se desactiva este impuesto no se tomará en cuenta en las transacciones de la aplicación'
                  }
                  disabled={loading}
                />
              </Col>
            )}
            <Col xl={6} lg={6} md={12} sm={12}>
              <SwitchV2
                label={'Activar como impuesto principal'}
                checked={data.defaultValue}
                onChange={(value: boolean) => setData({ ...data, defaultValue: value })}
                info={
                  'Si se selecciona este impuesto como principal se tomará como el impuesto por defecto en las transacciones de la aplicación para los ítems que no tengan asignado un impuesto'
                }
                disabled={loading}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              color={'secondary'}
              icon={faWindowClose}
              onClick={onHide}
              disabled={loading}>
              Cerrar
            </Button>
            <Button color={'primary'} onClick={onValidate} loading={loading}>
              Guardar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
export default TaxForm
