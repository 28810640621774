import React from 'react'
import { useSelector } from 'react-redux'

import { Card, TableV2, Icon } from 'src/components'
import { selectAllTaxes } from 'src/selectors/taxes.selector'
import { faEdit, faSync, faTrash } from '@fortawesome/free-solid-svg-icons'

import { loadingSelector } from 'src/selectors/loading.selector'
import { actions } from 'src/actions/taxes.actions'
import { isAllowed } from 'src/selectors/modules.selector'
import { taxesPermissions } from 'src/enums/permissions'

interface ITaxesListProps {
  onRefresh: () => void
  onUpdate: (tax: Tax) => void
  onDelete: (tax: Tax) => void
}

const TaxesList = ({ onRefresh, onUpdate, onDelete }: ITaxesListProps) => {
  const taxes: Tax[] = useSelector(selectAllTaxes)

  const loading: boolean = useSelector(state =>
    loadingSelector([actions.GET_ALL_TAXES])(state),
  )

  const canUpdate: boolean = useSelector(state =>
    isAllowed(state, [taxesPermissions.update]),
  )
  const canDelete: boolean = useSelector(state =>
    isAllowed(state, [taxesPermissions.delete]),
  )

  const onActions = (tax: Tax) => {
    return (
      <div className={'d-flex center'}>
        {canUpdate && (
          <Icon
            className={'mr-2'}
            tooltip={'Ver'}
            icon={faEdit}
            onClick={() => onUpdate(tax)}
            size={'lg'}
            disabled={loading}
          />
        )}
        {canDelete && (
          <Icon
            tooltip={'Eliminar'}
            icon={faTrash}
            onClick={() => onDelete(tax)}
            size={'lg'}
            disabled={loading}
          />
        )}
      </div>
    )
  }

  const headers: ITableHeader[] = [
    {
      show: true,
      type: 'text',
      label: 'Nombre',
      value: ['name'],
      className: 'medium',
    },
    {
      show: true,
      type: 'number',
      label: 'Valor',
      value: ['rate'],
      className: 'medium',
      custom: (tax: Tax) => `% ${tax.rate * 100}`,
    },
    {
      show: true,
      type: '',
      label: 'Principal',
      value: [],
      className: 'medium',
      custom: (tax: Tax) => (tax.defaultValue ? 'Si' : 'No'),
    },
    {
      show: true,
      type: '',
      label: 'Estado',
      value: [],
      className: 'medium',
      custom: (tax: Tax) => (tax.active ? 'Activo' : 'Inactivo'),
    },
    {
      show: canUpdate || canDelete,
      type: '',
      label: 'Acciones',
      value: [],
      className: 'mini center',
      custom: item => onActions(item),
    },
  ]

  return (
    <div>
      <Card
        title={'Listado'}
        white
        button={
          <Icon
            icon={faSync}
            size={'lg'}
            tooltip={'Actualizar'}
            disabled={loading}
            onClick={() => onRefresh()}
          />
        }>
        <TableV2
          // @ts-ignore
          headers={headers}
          loading={loading}
          items={taxes}
          storageKey={'taxes'}
          mobileAuto
        />
      </Card>
    </div>
  )
}
export default TaxesList
