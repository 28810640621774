import React from 'react'

import { Row, Col } from 'react-bootstrap'
import Card from 'src/components/cards/Card'
import Paragraph from 'src/components/Paragraph/Paragraph'
import Icon from 'src/components/buttons/IconButton'

import { toMoney } from 'src/utils/utilities'
import { faEye, faListOl } from '@fortawesome/free-solid-svg-icons'
import { haveAnyValue } from 'src/utils/utilitiesV2'
import { Tag } from 'src/components'
import { ListVariations } from 'src/content/Production/Categorization/Variation/ListVariations'

interface IProps {
  item: IPurchaseItem
  canSeeInventory: boolean
  seeMoneyValues: boolean
  toSeeInventory: (detail: IPurchaseDetail, serie?: string) => void
  toSeeSeries: (detail: IPurchaseDetail) => void
}

/** Show purchase item detail information
 * @param {IPurchaseItem} item To see information
 * @param {boolean} canSeeInventory Indicate if the user can see inventory
 * @param {boolean} seeMoneyValues Indicate if the user can see money values
 * @param {function} toSeeInventory Function to see inventory
 * @param {function} toSeeSeries Function to see item series
 * **/
const PurchaseDetailItem: React.FC<IProps> = ({
  item,
  canSeeInventory,
  seeMoneyValues,
  toSeeInventory,
  toSeeSeries,
}) => {
  // @ts-ignore
  return (
    <div>
      <Card white title={item.warehouseName || 'ítems'}>
        <Row>
          {item.items.map(i => (
            <Col xl={12} key={i.productId}>
              <div className={'column'}>
                <div className={'d-flex'}>
                  <div
                    style={{
                      width: canSeeInventory || i.series?.length > 0 ? '93%' : '100%',
                    }}
                    className={'px-1'}>
                    <div className={'space-between'}>
                      <div className={'column'}>
                        <Paragraph bold>
                          {i.code} {i.productName}
                        </Paragraph>

                        {haveAnyValue(i.listCategories) && (
                          <div className={'ml-2'}>
                            {/* @ts-ignore */}
                            <ListVariations data={[]} selected={i.listCategories || []} />
                          </div>
                        )}

                        {seeMoneyValues && (
                          <>
                            <Paragraph>Costo unitario</Paragraph>
                            <Paragraph>Total</Paragraph>
                          </>
                        )}
                      </div>
                      <div className={'column flex-row-reverse'}>
                        <Paragraph bold>{i.quantity}u</Paragraph>
                        {seeMoneyValues && (
                          <>
                            <Paragraph bold>{toMoney(i.price)}</Paragraph>
                            <Paragraph bold>{toMoney(i.subtotal)}</Paragraph>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  {(canSeeInventory || i.series?.length > 0) && (
                    <div className={'column flex-row-reverse'} style={{ width: '7%' }}>
                      {canSeeInventory && (
                        <Icon
                          icon={faEye}
                          size={'1_5x'}
                          tooltip={'Ver inventario'}
                          onClick={() => toSeeInventory(i)}
                          dataCy={'iconInventory'}
                        />
                      )}
                      {i.series?.length > 0 && (
                        <Icon
                          icon={faListOl}
                          size={'1_5x'}
                          tooltip={'Ver series'}
                          onClick={() => toSeeSeries(i)}
                          dataCy={'iconSeries'}
                        />
                      )}
                    </div>
                  )}
                </div>
                <hr style={{ width: '100%' }} />
              </div>
            </Col>
          ))}
        </Row>
      </Card>
    </div>
  )
}
export default PurchaseDetailItem
