import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'

import { Col, Row } from 'react-bootstrap'
import {
  Card,
  CustomSummary,
  Select,
  SelectedDates,
  TableV2,
  Title,
  Icon,
  Button,
} from 'src/components'

import {
  actionTypes,
  getBranchReport,
  getBranchReportExcel,
} from 'src/actions/report.actions'
import { selectBranchReport } from 'src/selectors/report.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import { formatNumberWithCommas } from 'src/utils/formatters'

import { faFileExcel, faSearch, faSync } from '@fortawesome/free-solid-svg-icons'
import { toMoney } from 'src/utils/utilities'
import { PaymentType } from 'src/enums/paymentTypes'

const reportOptions = [
  { value: 1, label: 'Bodegas' },
  { value: 2, label: 'Puntos de venta' },
  { value: 3, label: 'Usuarios' },
]

const paymentTypes = [
  { value: null, label: 'Todos' },
  { value: PaymentType.CASH, label: 'Efectivo' },
  { value: PaymentType.CREDIT, label: 'Crédito' },
  { value: PaymentType.BANK_TRANSFER, label: 'Transferencia' },
  { value: PaymentType.CARD, label: 'Tarjeta' },
]

const BranchesReport = () => {
  const dispatch = useDispatch()

  const report = useSelector(selectBranchReport)

  const loading = useSelector(state =>
    loadingSelector([actionTypes.GET_BRANCH_REPORT])(state),
  )

  const loadingDownload = useSelector(state =>
    loadingSelector([actionTypes.GET_BRANCH_REPORT_EXCEL])(state),
  )

  const [filters, setFilters] = useState({
    start: new Date().setHours(0, 0, 0, 0).valueOf(),
    end: new Date().setHours(23, 59, 59, 99).valueOf(),
    branchType: 1,
    paymentType: { value: null, label: 'Todos' },
  })

  const reportOption = reportOptions.find(option => option.value === filters.branchType)

  useEffect(() => {
    setUp(false)
  }, [])

  const setUp = excel => {
    const params = {
      ...filters,
      paymentType: filters.paymentType.value,
    }

    if (excel) dispatch(getBranchReportExcel(params))
    else dispatch(getBranchReport(params))
  }

  const renderButtons = (
    <Row className={'container-buttons'}>
      <Icon
        icon={faSync}
        spin={loading}
        tooltip={'Actualizar'}
        onClick={() => setUp()}
        className={'mr-3'}
      />
      <Icon
        icon={faFileExcel}
        disabled={loading}
        onClick={() => setUp(true)}
        loading={loadingDownload}
        tooltip={'Descargar'}
      />
    </Row>
  )

  const getSummary = () => {
    const total = report.reduce((acc, item) => acc + item.total, 0)
    const cost = report.reduce((acc, item) => acc + item.cost, 0)
    const utility = report.reduce((acc, item) => acc + item.utility, 0)
    const profitability =
      report.reduce((acc, item) => acc + item.profitability, 0) /
      report.filter(r => r.profitability > 0).length

    return [
      {
        show: true,
        title: 'Total de ventas',
        value: toMoney(total),
      },
      {
        show: true,
        title: 'Costo de ventas',
        value: toMoney(cost),
      },
      {
        show: true,
        title: 'Utilidad',
        value: toMoney(utility),
      },
      {
        show: true,
        title: 'Rentabilidad',
        value: `${formatNumberWithCommas(profitability || 0)}%`,
      },
      {
        show: true,
        title: 'Propina',
        value: toMoney(report.reduce((acc, item) => acc + item.tip, 0)),
      },
    ]
  }

  const headers = [
    {
      label: 'Nombre',
      value: ['name'],
      type: 'text',
      show: true,
    },
    {
      label: 'Total de ventas',
      value: ['total'],
      type: 'currency',
      show: true,
    },
    {
      label: 'Costo de ventas',
      value: ['cost'],
      type: 'currency',
      show: true,
    },
    {
      label: 'Utilidad',
      value: ['utility'],
      type: 'currency',
      show: true,
    },
    {
      label: 'Rentabilidad',
      value: ['profitability'],
      type: 'percentage',
      show: true,
      custom: item => <div>{formatNumberWithCommas(item.profitability)} %</div>,
    },
    {
      label: 'Propina',
      value: ['tip'],
      type: 'currency',
      show: true,
    },
  ]

  return (
    <div>
      <Title title={'Reporte de Ventas por Sucursal'} />
      <Row>
        <Col xs={12}>
          <Card title={'Filtros'} white button={renderButtons}>
            <Row>
              <Col xs={12}>
                <SelectedDates
                  setValueDates
                  nonExecute
                  onDateChange={(start, end) => {
                    setFilters({ ...filters, start, end })
                  }}
                  initialPreset={{ value: 0, label: 'Hoy' }}
                />
              </Col>
              <Col xl={6} lg={6} md={12} sm={12}>
                <Select
                  className={'mb-2'}
                  disabled={loading}
                  label={'Reporte de ventas por:'}
                  value={reportOption}
                  options={reportOptions}
                  required
                  onChange={branchType => {
                    setFilters({ ...filters, branchType: branchType.value })
                  }}
                />
              </Col>
              <Col xl={6} lg={6} md={12} sm={12}>
                <Select
                  label={'Por tipo de pago'}
                  disabled={loading}
                  options={paymentTypes}
                  value={filters.paymentType}
                  onChange={v => setFilters({ ...filters, paymentType: v })}
                />
              </Col>
              <Col xl={12}>
                <Row className={'container-buttons'}>
                  <Button color={'accent'} icon={faSearch} onClick={() => setUp(false)}>
                    Aplicar Filtros
                  </Button>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={12}>
          <Card>
            <CustomSummary items={getSummary()} />
            <TableV2
              loading={loading}
              headers={headers}
              items={report}
              customClass={'scroll-x-without-height'}
            />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default BranchesReport
