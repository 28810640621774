import './ItemsToSelect.scss'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Row, Col, Modal, OverlayTrigger, Tooltip, ProgressBar } from 'react-bootstrap'
import {
  Card,
  Checkbox,
  Button,
  CustomCreate,
  TableV2,
  Icon,
  CustomFilter,
  FormText,
  Select,
  Money,
  Commentary,
  Switch,
  CustomDate,
  Title,
} from '../index'
import {
  faBarcode,
  faComment,
  faEdit,
  faMinus,
  faPercent,
  faPlus,
  faPlusCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import Placeholder from 'src/assets/images/newDesign/placeholder.svg'

import { Media } from 'react-breakpoints'

import WarehouseService from '../../services/warehouse.service'
import {
  actionTypes,
  getItemsToSelect,
  getLineItemsToSelect,
  getItemsToSelectDetail,
  getInventoryWithPrices,
  getWarehouseInventoryVariation,
  getWarehouseLite,
} from 'src/actions/warehouse.actions'
import {
  selectInventoryWithPrices,
  selectItemsToSelect,
  selectItemsToSelectDetail,
  selectLineItemsToSelect,
  selectSellItemsToSelect,
  selectOwnWarehouseLite as selectWarehouseLite,
} from 'src/selectors/warehouse.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import { selectDiscounts } from 'src/selectors/restaurant.selector'
import { isAllowed } from 'src/selectors/modules.selector'
import { selectCurrentCurrency } from 'src/selectors/currencies.selector'
import { capitalizeString, getExchangeRate, toMoney } from 'src/utils/utilities'
import {
  selectCompanyFieldEditPricePurchase,
  selectCompanyFieldById,
  selectCompanyFieldProductRepeat,
  selectFelItemTypes,
  selectCompanyCountry,
} from 'src/selectors/company.selector'
import {
  getCompanyFieldEditPricePurchase,
  getCompanyFieldProductRepeat,
} from 'src/actions/company.actions'
import { getAllCurrencies } from 'src/actions/currencies.actions'

import { formatNumberWithCommas } from 'src/utils/formatters'
import { TaxFree } from 'src/components/Items/TaxFree/TaxFree'
import { ModalTaxFree } from 'src/components/Items/TaxFree/ModalTaxFree'
import { LineQuantites } from 'src/components/Items/LineProduct/LineQuantites'
import { useLocation } from 'react-router-dom'
import { getUniversalItem } from 'src/utils/getUniversalItem'
import { ListVariations } from 'src/content/Production/Categorization/Variation/ListVariations'
import { SerieItem } from 'src/components/Items/Series/SerieItem'
import { WarehouseVariatons as SelectVariations } from 'src/content/Warehouse/WarehouseVariatons'

import { getCategorizationByType } from 'src/actions/categorization.actions'
import CSVLoaderV2, { CATEGORY_UPLOAD } from 'src/components/CSVLoader/CSVLoaderV2'
import { Country } from 'src/enums/countries'

import ItemCRUD from 'src/content/Products/ItemCRUD'

let interval
const ItemsToSelect = ({
  type,
  warehouseId,
  inWarehouse,
  onSelected,
  disabled,
  loading,
  client,
  onWarehouse,
  balancesPermission,
  details,
  allInventory,
  importation,
  order,
}) => {
  const dispatch = useDispatch()

  const country = useSelector(selectCompanyCountry)
  const isGT = country.id === Country.GT

  const fieldRepeat = useSelector(selectCompanyFieldProductRepeat)
  const fieldEditPrice = useSelector(selectCompanyFieldEditPricePurchase)
  const felItemTypes = useSelector(selectFelItemTypes)

  const inventory = useSelector(
    type === itemTypes.SELL ? selectSellItemsToSelect : selectItemsToSelect,
  )
  const lineItems = useSelector(selectLineItemsToSelect)
  const inventoryDetails = useSelector(selectItemsToSelectDetail)
  const inventoryBarcode = useSelector(selectInventoryWithPrices)
  const loadingItems = useSelector(state =>
    loadingSelector([actionTypes.GET_ITEMS_TO_SELECT])(state),
  )

  const loadingLineItems = useSelector(state =>
    loadingSelector([actionTypes.GET_LINE_ITEMS_TO_SELECT])(state),
  )

  const loadingItemsDetails = useSelector(state =>
    loadingSelector([actionTypes.GET_ITEMS_TO_SELECT_DETAIL])(state),
  )

  const loadingInventorySingle = useSelector(state =>
    loadingSelector([actionTypes.GET_INVENTORY_WITH_PRICE])(state),
  )

  const [flags, setFlags] = useState({ get: false, getLine: false })
  const [flags2, setFlags2] = useState({ get: false })
  const [flagsItems, setFlagsItems] = useState({ get: false })

  const [items, setItems] = useState([])
  const [selected, setSelected] = useState([])
  const [listDeleted, setListDeleted] = useState([])
  const [search, setSearch] = useState(null)
  const [modalItems, setModalItems] = useState(false)
  const [commentaryShow, setCommentaryShow] = useState(false)
  const [modalItem, setModalItem] = useState({})

  const [warehouse, setWarehouse] = useState({ value: null, label: 'Sin seleccionar' })
  const warehouses = useSelector(selectWarehouseLite)
  const loadingW = useSelector(state =>
    loadingSelector([actionTypes.GET_WAREHOUSE_LITE])(state),
  )

  const [taxFree, setTaxFree] = useState(false)
  const [showIVA, setShowIVA] = useState(false)
  const [discountAll, setDiscountAll] = useState(false)
  const [discount, setDiscount] = useState(null)
  const [isGeneral, setIsGeneral] = useState(false)
  const discountsS = useSelector(selectDiscounts)
  const [discounts, setDiscounts] = useState([])
  const [indexChange, setIndexChange] = useState(0)
  const maxDiscount = useSelector(state => selectCompanyFieldById(state, 89))
  const multiplePrices = useSelector(state => isAllowed(state, [1131]))
  const savePrice = useSelector(state => isAllowed(state, [1132]))

  const currentCurrency = useSelector(selectCurrentCurrency)
  const [listDetails, setList] = useState([])
  const [isEdit] = useState(useLocation().pathname.includes('/editar'))
  const [isQuote] = useState(useLocation().pathname.includes('/cotizaciones')) // TODO: Remove when quote use a ItemSelectedTable.
  const [mobileVariation, setMobileVariation] = useState(false)
  const [mobileVariationOuts, setMobileVariationOuts] = useState(false)

  const [showCreateProduct, setShowCreateProduct] = useState(false)

  let sumDiscount = 0

  /* ############# LOCATION ################ */

  const seeWarehouseLocations = useSelector(state => selectCompanyFieldById(state, 123))

  /* ############# PURCHASE ################*/

  const [individualTotal, setIndividualTotal] = useState(false)
  const [withoutIVA, setWithoutIVA] = useState({ active: false, value: 12 })

  const currenciesOwn = useSelector(state =>
    Object.assign(
      [],
      state.currencies.allCurrencies.map(c => {
        return {
          ...c,
          label: capitalizeString(`${c.name} (${c.code})`),
          value: c.id,
          symbol: c.symbol,
        }
      }),
    ),
  )
  const [activateLocalCurrency, setActivateLocalCurrency] = useState(false)
  const [localCurrency, setLocalCurrency] = useState(false)
  const [exchangeRate, setExchangeRate] = useState(
    currenciesOwn.find(c => c.code === currentCurrency.code),
  )
  const [conversion, setConversion] = useState(7.6)
  const [mobile, setMobile] = useState(true)

  const disabledDiscounts = useSelector(state => selectCompanyFieldById(state, 84))

  useEffect(() => {
    dispatch(getWarehouseLite())
    dispatch(getCategorizationByType(21))
    dispatch(getCompanyFieldEditPricePurchase())
    dispatch(getCompanyFieldProductRepeat())
    dispatch(getAllCurrencies())
    if (itemTypes.PURCHASE === type || itemTypes.EXPENSE === type) setUp()
  }, [])

  useEffect(() => {
    if (loadingInventorySingle) setFlags({ ...flags, get: true })
    else if (flags.get) {
      setFlags({ ...flags, get: false })

      if (inventoryBarcode.length > 0 && items && items.length > 0) {
        let item = inventoryBarcode[0].inventory
        let key = selected.findIndex(s => s.value === item.productId)
        if (key === -1) {
          key = selected.length
          const params = {
            warehouseId,
            productId: item.productId,
            type:
              type === itemTypes.SELL
                ? 1
                : type === itemTypes.PRODUCTION
                ? 3
                : type === itemTypes.WASTE
                ? 2
                : type === itemTypes.PURCHASE
                ? 4
                : type === itemTypes.EXPENSE
                ? 5
                : 6,
          }
          setIndexChange(key)
          const itemResponse = setUp(item.productCode, true)
          itemResponse.then(response => {
            if (response.length > 0) {
              let value = response.find(r => r.id === item.productId) || response[0]
              const i = {
                ...value,
                value: value.id,
                label: value.code + ' - ' + value.name,
                includeIva: true,
                productPrice: value.price,
                productId: value.id,
              }
              selected[key] = { ...Object.assign({}, i), key }
              dispatch(getLineItemsToSelect({}, params))
            }
          })
        } else {
          selected[key].lineItems[0].quantity =
            (selected[key].lineItems[0].quantity || 0) + 1
          selected[key].subTotal =
            (selected[key].lineItems[0].quantity || 1) *
              (selected[key].lineItems[0].productData.price || 0) -
            parseFloat(selected[key].discount || 0)
          setSelected([...selected])
        }
      }
    }
  }, [loadingInventorySingle])

  useEffect(() => {
    if (warehouse.value && type === itemTypes.SELL) {
      if (onWarehouse) onWarehouse(warehouse)
    }
  }, [warehouse.value])

  // useEffect(() => {
  //   if (!warehouseId) return
  //     setUp()
  // }, [warehouseId])

  useEffect(() => {
    if (!warehouseId) return
    setUp()
  }, [warehouseId])

  // useEffect(() => {
  //   if (warehouses.length > 0 && warehouse.value === null) setWarehouse(warehouses[0])
  // }, [warehouses])

  useEffect(() => {
    if (!warehouses) return
    if (warehouses.length > 0 && warehouse.value === null) setWarehouse(warehouses[0])
  }, [warehouses])

  useEffect(() => {
    if (selected.length === 0 && details && details.length > 0) {
      let list = ''
      if (isEdit) {
        details.forEach(d => {
          if (d.product) list += d.product.id + ','
        })
      }
      list = list.slice(0, list.length - 1)

      const newDetails = details.map(detail =>
        detail.product?.code === 'UNIX'
          ? getUniversalItem({
              ...detail,
              commentary: detail.product.name,
              price: detail.subtotal / detail.quantity,
            })
          : {
              commentary: detail.commentary,
              discount: detail.discount,
              price: detail.subtotal / detail.quantity,
              quantity: detail.quantity,
              id: detail.product.id,
              categories: detail.categories,
              locations: detail.listLocations,
              detailId: detail.id,
            },
      )
      setList(newDetails)

      if (inWarehouse.value !== warehouse.value) {
        setWarehouse(inWarehouse)
      }
      const params = {
        warehouseId: inWarehouse.value,
        withZero: true,
        withCategories: true,
        withExistence: true,
        type,
        search,
        list,
      }
      dispatch(getItemsToSelectDetail(params))
    }
  }, [details])

  useEffect(() => {
    if (discountsS.length > 0) {
      setDiscounts(
        discountsS.map(x => {
          return { label: x.percentage + '%  ' + x.alias, value: x.percentage }
        }),
      )
    }
  }, [discountsS])

  useEffect(() => {
    if (loadingItems) setFlagsItems({ ...flagsItems, get: true })
    else if (flagsItems.get) {
      setFlagsItems({ ...flagsItems, get: false })
      setItems(getItems(formatItems(inventory)))
      if (selected.length === 0 && !isEdit && !mobile) {
        setSelected([
          ...selected,
          {
            value: null,
            label: 'Seleccionar',
            key: new Date().valueOf(),
          },
        ])
      }
    }
  }, [loadingItems])

  useEffect(() => {
    if (loadingItemsDetails) setFlags2({ ...flags2, get: true })
    else if (flags2.get) {
      setFlags2({ ...flags2, get: false })
      const sel = fillSelectDetails(inventoryDetails)
      let lastItems = selected.splice(indexChange, selected.length)
      lastItems.splice(0, 1)
      setSelected(selected)
      setSelected(selected.concat(sel).concat(lastItems))
      // if (details && details.length > 0) {

      // } else {
      //   let lastItems = selected.splice(indexChange, selected.length)
      //   lastItems.splice(0, 1)
      //   setSelected(selected)
      //   setSelected(selected.concat(sel).concat(lastItems))
      // }
      setList([])
      setUp()
    }
  }, [loadingItemsDetails])

  useEffect(() => {
    if (onSelected) {
      onSelected(
        selected.filter(s => s.value !== undefined),
        importation ? conversion : warehouse,
        localCurrency,
        listDeleted,
      )
    }
  }, [JSON.stringify(selected), localCurrency])

  useEffect(() => {
    selected.forEach(item => {
      item.lineItems &&
        item.lineItems.forEach(line => {
          if (withoutIVA.active) {
            if (importation) {
              line.purchasePrice = Number(line.ownPrice) - (line.tax || 0)
            } else {
              line.purchasePrice =
                Number(line.ownPrice) -
                Number(line.ownPrice) * ((withoutIVA.value || 0) / 100)
            }
          } else {
            line.purchasePrice = undefined
          }
        })
    })

    setSelected([...selected])
  }, [withoutIVA])

  useEffect(() => {
    if (!search || search === '') return
    clearInterval(interval)
    interval = setTimeout(() => {
      setUp(search)
    }, 700)
  }, [search])

  const addNewProductInTable = async productName => {
    if (productName !== '') {
      let indexS = selected.length
      const response = await setUp(productName, true)
      if (response && response.length > 0) {
        let value = response[0]
        const item = {
          ...value,
          value: value.id,
          label: value.code + ' - ' + value.name,
          includeIva: true,
          productPrice: value.price,
          productId: value.id,
        }
        // selected.push(item)
        const newValue = JSON.parse(JSON.stringify(item))
        selected[indexS] = { ...newValue, key: indexS }
        setIndexChange(indexS)
        setSelected([...selected])

        setTimeout(function () {
          if (
            item.id !== 1 &&
            item.listCategorization &&
            item.listCategorization !== null
          ) {
            if (
              type === itemTypes.SELL ||
              type === itemTypes.TRANSFER ||
              type === itemTypes.WASTE
            ) {
              selected[indexS].showVariationsOuts = true
              dispatch(
                getWarehouseInventoryVariation(
                  warehouseId || warehouse.value,
                  selected[indexS].value,
                  {
                    limit: true,
                    type: type,
                  },
                ),
              )
            } else selected[indexS].showVariations = true
            setSelected([...selected])
          } else {
            getItemValue(item)
          }
        }, 1000)
      }
    }
  }

  const formatItems = data => {
    if (!data) return []
    return data.map(d => ({
      ...d,
      lineItems: d.line, // Item universal
      value: d.id,
      label: d.code + ' - ' + d.name,
      includeIva: true,
      productPrice: d.price,
      productId: d.id,
    }))
  }

  const fillDetail = (detail, aux, key) => {
    let newDetail = JSON.parse(JSON.stringify(detail))
    newDetail.lineItems.line[0].quantity = aux.quantity
    if (aux.discount) {
      newDetail.lineItems.line[0].discount = aux.discount
      newDetail.lineItems.line[0].percentDiscount = aux.percentDiscount
      newDetail.lineItems.line[0].percent = aux.percent
    }
    if (aux.selectedPrice || (aux.price && aux.price !== -1)) {
      const selectedPrice = aux.selectedPrice || {
        value: Number(aux.price),
        label: toMoney(aux.price),
        alias: 'Precio',
      }
      newDetail.lineItems.line[0].price = aux.price
      newDetail.lineItems.line[0].productPrice = aux.productPrice || aux.price
      newDetail.lineItems.line[0].selectedPrice = selectedPrice
      newDetail.lineItems.line[0].save = aux.save
      newDetail.lineItems.line[0].subTotal = aux.quantity * aux.price

      newDetail.selectedPrice = selectedPrice
      newDetail.subTotal = (aux.quantity || 0) * aux.price - (aux.discount || 0)
    } else if (detail.price) {
      newDetail.lineItems.line[0].price = detail.price
      newDetail.lineItems.line[0].productPrice = detail.price
      newDetail.selectedPrice = {
        value: Number(detail.price),
        label: toMoney(detail.price),
        alias: 'Precio',
      }
      newDetail.lineItems.line[0].subTotal = aux.quantity * detail.price
      newDetail.subTotal = (aux.quantity || 0) * detail.price - (aux.discount || 0)
    }
    return {
      ...newDetail,
      haveQuantities: true,
      listVariations: aux.categories
        ? Array.isArray(aux.categories)
          ? aux.categories
          : aux.listCategorization
        : aux.listCategorization
        ? aux.listCategorization
        : aux.listCategories,
      listLocations: aux.locations
        ? Array.isArray(aux.locations)
          ? aux.locations
          : aux.listLocations
        : aux.listLocations,
      categories: aux.categories,
      lineItems: newDetail.lineItems.line,
      existence: newDetail.lineItems.existence,
      baseProductQuantity: aux.baseProductQuantity || newDetail.lineItems.baseExistence,
      subProductValue: newDetail.lineItems.subProductValue,
      value: newDetail.id || newDetail.productId,
      label: newDetail.code + '-' + (newDetail.label || newDetail.productName),
      includeIva: true,
      productPrice: newDetail.price,
      productId: newDetail.id || newDetail.productId,
      price: aux.outs ? aux.price : detail.price, // aux.outs : come from location mobile
      discount: aux.discount,
      subTotalWithoutDiscount:
        aux.subTotalWithoutDiscount || (aux.quantity || 0) * (detail.price || 0),
      commentary: aux.commentary,
      quantity: aux.quantity,
      key: aux.key || key,
      id: newDetail.id || newDetail.productId,
      detailId: aux.detailId || null,
      edited: aux.edited || null,
      list: newDetail.lineItems.list,
    }
  }

  const getOptions = (aux, key) => {
    let newDetail = JSON.parse(JSON.stringify(aux))
    newDetail.lineItems.line[0].quantity = aux.quantity
    if (aux.price) {
      newDetail.lineItems.line[0].ownPrice = parseFloat(aux.price).toFixed(2)
      newDetail.lineItems.line[0].price = parseFloat(aux.price).toFixed(2)
      newDetail.lineItems.line[0].subTotal = aux.quantity * aux.price
    }
    if (aux.series !== null) {
      newDetail.active = true // series
      newDetail.lineItems.line[0].series = aux.series
        ? aux.series.map(s => s.serie)
        : null
    }
    return {
      ...newDetail,
      haveQuantities: true,
      listVariations: aux.listCategories,
      lineItems: newDetail.lineItems.line,
      value: newDetail.id || newDetail.productId,
      label: newDetail.code + '-' + newDetail.productName,
      includeIva: true,
      productPrice: newDetail.price,
      key: aux.key || key,
      id: newDetail.id || newDetail.productId,
      expirationDate: aux.expirationDate ? new Date(aux.expirationDate) : null,
      tax: aux.tax,
      subTotal: Number(aux.subtotal).toFixed(2),
      //active: !!l.series,
    }
  }

  const fillProductByCSV = data => {
    if (!data) return []
    let key = selected.length + 1
    const filledDetails = []
    data.forEach(aux => {
      filledDetails.push(getOptions(aux, key))
      key += 1
    })

    return filledDetails
  }

  const fillSelectDetails = data => {
    if (!data) return []
    let key = 0
    const filledDetails = []
    listDetails.forEach(aux => {
      const detail = data.find(x => x.id === aux.id)
      if (detail !== null && detail !== undefined) {
        filledDetails.push(fillDetail(detail, aux, key))
        key += 1
      }
    })
    // data.forEach(detail => {
    //   const aux = listDetails.filter(x => x.id === detail.id)
    //   if (aux.length === 0) return null
    //   if (aux.length > 1) {
    //     aux.forEach(it => {
    //       filledDetails.push(fillDetail(detail, it, key))
    //       key += 1
    //     })
    //   } else {
    //     filledDetails.push(fillDetail(detail, aux[0], key))
    //     key += 1
    //   }
    // })

    const extraDetails = listDetails
      .filter(detail => detail.product?.code === 'UNIX')
      .map(detail => ({
        ...detail,
        productPrice: detail.price,
        productId: 1,
      }))
    return filledDetails.concat(extraDetails)
  }

  const setUp = async (search, service) => {
    const params = {
      warehouseId: warehouseId || warehouse.value,
      withZero: true,
      withCategories: true,
      type,
      search,
      withExistence: mobile,
      allInventory: allInventory || false,
    }
    if (service) {
      const result = await WarehouseService.getItemsToSelect(params)
      if (
        'UNIX'.includes(search?.toUpperCase()) ||
        'UNIVERSAL'.includes(search?.toUpperCase())
      )
        result.unshift(getUniversalItem())

      return result
    } else {
      dispatch(getItemsToSelect(params))
    }
  }

  useEffect(() => {
    if (loadingLineItems) setFlags({ ...flags, getLine: true })
    else if (flags.getLine) {
      setFlags({ ...flags, getLine: false })
      if (lineItems) {
        let existence =
          lineItems.line?.length === 1 ? lineItems.baseExistence : lineItems.existence

        if (type === itemTypes.SELL || type === itemTypes.EXPENSE) {
          if (lineItems.line[0]) lineItems.line[0].quantity = 1
        } else if (type === itemTypes.PRODUCTION)
          lineItems.line[0].ownPrice = selected[indexChange]?.productionPrice

        if (selected.length > 0 && !modalItem.show && selected[indexChange]) {
          selected[indexChange].variations = lineItems.variations
          selected[indexChange].existence = existence
          selected[indexChange].lineItems = lineItems.line
          selected[indexChange].baseProductQuantity = lineItems.baseExistence
          selected[indexChange].subProductValue = lineItems.subProductValue
          selected[indexChange].list = lineItems.list
          if (lineItems.listLocations && lineItems.listLocations !== null)
            selected[indexChange].listLocations = lineItems.listLocations
          setSelected([...selected])
        }
        setModalItem({
          ...modalItem,
          existence,
          list: lineItems.list,
          variations: lineItems.variations,
          lineItems: lineItems.line,
          listLocations: lineItems.listLocations || [],
          baseProductQuantity: lineItems.baseExistence,
          subProductValue: lineItems.subProductValue,
          selectedPrice:
            modalItem.price != null
              ? {
                  value: Number(modalItem.price),
                  label: modalItem.price,
                }
              : undefined,
        })
      }
      //setSelect([...selected])
    }
  }, [loadingLineItems])

  const updateTaxFree = (item, type) => {
    selected.forEach(product => {
      if (isEdit) product.edited = true
      if (item.newIncludeIva || type) {
        product.includeIva = item.newIncludeIva
        product.newIncludeIva = false
      } else {
        product.phraseReference = item.phraseReference
        product.includeIva = false
        product.newIncludeIva = true
      }
    })
    setTaxFree(false)
    setShowIVA(false)
    setSelected([...selected])
  }

  const changeDiscount = (value, item) => {
    if (isEdit) item.edited = true
    item.error = null
    if (!isNaN(value)) {
      let discount = value
      if (!discount || discount < 0) discount = 0
      else if (discount > Number.parseFloat(item.maxDiscount) && item.id !== 1) {
        item.error = 'El valor ingresado supera el descuento maximo'
        discount = item.maxDiscount
      } else if (discount > item.subTotalWithoutDiscount) {
        item.error = 'El valor ingresado supera el subtotal'
        discount = item.subTotalWithoutDiscount
      }
      item.discount = discount
      item.subTotal = getSubTotalV2(item)

      if (modalItem.outs) {
        listDetails.forEach(x => (x.discount = discount))
      }
      setModalItem({ ...item })
      setSelected([...selected])
    }
  }

  const changeAllDiscount = (value, isPercent, type) => {
    if (value) {
      selected.forEach(item => {
        item.error = null
        if (!item.value) {
          return
        }
        if (isPercent) {
          if (!type) {
            percentDiscount(item, value, true)
          } else {
            percentDiscount(item, value, discountAll)
          }
        } else {
          item.percentDiscount = isPercent
          let descuento = getNewDiscount(item, value.value)
          changeDiscount(descuento, item)

          // changeDiscount(value.value , item);
        }
      })
      setSelected([...selected])
    }
  }
  const getTotalWithOutDiscount = () => {
    let total = 0
    selected.map(item => (total += item.subTotalWithoutDiscount))
    return total
  }
  const getNewDiscount = (item, discount) => {
    let total = getTotalWithOutDiscount()
    let descuento = (item.subTotalWithoutDiscount * discount) / total
    return descuento
  }
  const percentDiscount = (item, discount, isPercent) => {
    if (isEdit) item.edited = true
    item.percentDiscount = isPercent
    item.error = null
    if (!isNaN(discount.value)) {
      let subtotal = 0
      if (isPercent) {
        item.percent = discount
        item.discount = (item.subTotalWithoutDiscount / 100) * item.percent.value
        if (!discount.value || discount.value < 0) discount.value = 0
        else if (item.discount > Number.parseFloat(item.maxDiscount)) {
          item.discount = item.maxDiscount
          item.percent = discounts.find(
            x => x.value === (item.maxDiscount / item.subTotalWithoutDiscount) * 100,
          )
          item.percentDiscount = !isPercent
          item.error = 'El porcentaje excede el descuento maximo'
        }
        subtotal = getSubTotalV2(item, false)
      } else {
        item.discount = discount.value
        subtotal = getSubTotalV2(item)
      }
      if (item.quantity > 0) item.subTotal = subtotal

      // Para items con variaciones en agregar a ventas
      if (modalItem.outs) {
        listDetails.forEach(x => {
          x.percentDiscount = isPercent
          x.percent = item.percent
          x.discount = item.discount
        })
      }

      setModalItem({ ...item })
      setSelected([...selected])
    }
  }

  const getSubTotalV2 = item => {
    return item.subTotalWithoutDiscount - (item.discount || 0)
  }

  const customFilter = () => {
    return (
      <Row className={'container-buttons'}>
        {itemTypes.SELL === type && (
          <Col xl={12}>
            <Row>
              {selected && selected.length > 0 && (
                <Col xl={12}>
                  <Row className={'container-buttons'}>
                    {isGT && (
                      <div className={'column'}>
                        <span>Exentos de iva</span>

                        <div className={'d-flex'}>
                          <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip id={`tooltip`}>
                                Marcar todos los items como exentos de iva
                              </Tooltip>
                            }>
                            <span className="d-inline-block">
                              <Button
                                style={{ flex: 1 }}
                                color={'primary'}
                                onClick={() => {
                                  setTaxFree(true)
                                  setShowIVA(true)
                                }}>
                                Exento de IVA
                              </Button>
                            </span>
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip id={`tooltip`}>
                                Quitar exentos de iva a todos los items
                              </Tooltip>
                            }>
                            <span className="d-inline-block">
                              <Button
                                style={{ flex: 1 }}
                                color={'secondary'}
                                onClick={() => {
                                  updateTaxFree({ newIncludeIva: true })
                                }}>
                                Quitar Exento de IVA
                              </Button>
                            </span>
                          </OverlayTrigger>
                        </div>
                      </div>
                    )}

                    <div className={'column ml-3'}>
                      <span>Descuentos</span>

                      <div className={'d-flex'}>
                        {discountAll ? (
                          <Select
                            mt={0}
                            style={{ width: 120, flex: 1 }}
                            withoutLabel
                            onChange={value => {
                              setDiscount(value)
                              changeAllDiscount(value, discountAll, true)
                            }}
                            options={discounts}
                          />
                        ) : (
                          <FormText
                            style={{ width: 100 }}
                            mt={1}
                            type={'number'}
                            onChange={({ target }) => {
                              if (target.value === '') setIsGeneral(false)
                              else if (!isGeneral) setIsGeneral(true)

                              setDiscount(target.value === '' ? null : target.value)
                              changeAllDiscount(
                                { value: target.value === '' ? 0 : target.value },
                                discountAll,
                                true,
                              )
                            }}
                          />
                        )}

                        <div className={'column'}>
                          <Checkbox
                            type="checkbox"
                            id="idchk"
                            name="chk"
                            label={'%'}
                            topLabel
                            checked={discountAll}
                            onChange={({ target }) => {
                              if (!target.checked) setIsGeneral(false)
                              else setIsGeneral(true)
                              setDiscountAll(target.checked)
                              changeAllDiscount(
                                { label: '0%', value: 0 },
                                target.checked,
                                false,
                              )
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Row>
                </Col>
              )}
            </Row>
          </Col>
        )}
        {itemTypes.PRODUCTION === type && (
          <Col xl={12} md={12} xs={12}>
            <CSVLoaderV2
              category={CATEGORY_UPLOAD.LOAD_PRODUCT_PRODUCTION}
              title={'Cargar Productos'}
              onFinishLoad={data => {
                const sel = fillProductByCSV(data.list)
                setSelected(selected.concat(sel))
              }}
              warehouseId={warehouseId}
            />
          </Col>
        )}
        {(itemTypes.PURCHASE === type || itemTypes.EXPENSE === type) && (
          <Col xl={12} md={12} xs={12}>
            <Row className={'container-buttons'}>
              <Col xl={6} md={12} xs={12}>
                <CSVLoaderV2
                  category={CATEGORY_UPLOAD.LOAD_PRODUCT_PURCHASE}
                  title={'Cargar Productos'}
                  onFinishLoad={data => {
                    const sel = fillProductByCSV(data.list)
                    setSelected(selected.concat(sel))
                  }}
                  warehouseId={warehouseId}
                  type={0}
                />
              </Col>

              <div className={'column'}>
                <span>Articulos sin IVA</span>

                <div className={'d-flex'}>
                  <Switch
                    checked={withoutIVA.active}
                    style={{ marginTop: 5 }}
                    onChange={({ target }) => {
                      const { checked } = target
                      setWithoutIVA({ active: checked, value: 12 })
                    }}
                    info={
                      'Aplicar valores monetarios de manera individual a cada item de un árbol de ítems'
                    }
                  />
                  {withoutIVA.active && !importation && (
                    <FormText
                      mt={0}
                      //type={'number'}
                      name={'sin_iva'}
                      value={withoutIVA.value}
                      placeholder={'12'}
                      append={<Icon icon={faPercent} />}
                      onChange={({ target }) => {
                        let { value } = target
                        value = isNaN(value) ? 0 : Number(value)
                        if (!value || value < 0) value = 0
                        else if (value > 100) value = 100
                        setWithoutIVA({ ...withoutIVA, value: value })
                      }}
                      //  error={purchase.checked && errors.serie}
                      //  required={purchase.checked}
                    />
                  )}
                </div>
              </div>
              <Col>
                <Switch
                  checked={individualTotal}
                  label={'Valores monetarios individuales'}
                  topLabel
                  onChange={({ target }) => {
                    const { checked } = target
                    setIndividualTotal(checked)
                    selected.forEach(item => {
                      if (
                        item.subProductId !== null &&
                        item.individualTotal !== checked
                      ) {
                        item.individualTotal = checked
                        item.subTotal = 0
                        item.lineItems.map(x => (x.subTotal = 0))
                      }
                    })
                  }}
                  info={
                    'Aplicar valores monetarios de manera individual a cada item de un árbol de ítems'
                  }
                />
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    )
  }

  const getTotal = (param, tax, custom) => {
    let response = 0
    selected &&
      selected.forEach(s => {
        if (
          (type === itemTypes.PURCHASE || type === itemTypes.EXPENSE) &&
          s.individualTotal
        ) {
          s.lineItems.forEach(x => {
            response +=
              custom && x.customPrice
                ? x.customPrice / conversion
                : Number(x.subTotal || 0) + parseFloat(x.tax || 0)
          })
        } else if (s[param]) {
          if (tax && !s.expense)
            response +=
              custom && s.customPrice
                ? s.customPrice / conversion
                : parseFloat(s[param]) + parseFloat(s.tax || 0)
          else response += Number(s[param]) || 0
        }
      })
    return response
  }

  const getTotalModal = () => {
    let response = 0
    if (
      (type === itemTypes.PURCHASE || type === itemTypes.EXPENSE) &&
      modalItem.individualTotal
    ) {
      modalItem.lineItems.forEach(l => {
        response += l.customPrice
          ? l.customPrice / conversion
          : Number(l.subTotal || 0) + parseFloat(l.tax || 0)
      })
    } else {
      response += modalItem.customPrice
        ? modalItem.customPrice / conversion
        : parseFloat(modalItem.subTotal || 0) + parseFloat(modalItem.tax || 0)
    }

    return response
  }

  const onDiscardItem = key => {
    if (isEdit) {
      const item = selected.find(s => s.key === key)
      item.deleted = true
      setListDeleted(listDeleted.concat(item))
    }
    setSelected(selected.filter(s => s.key !== key))
  }

  const updatePriceIVA = (item, show) => {
    if (isEdit) item.edited = true
    let newValue
    if (item.includeIva !== item.newIncludeIva) {
      let unitPrice = item.productPrice
      if (item.selectedPrice) unitPrice = item.selectedPrice.value

      let alias = ''
      let label = ''

      label = toMoney(unitPrice)
      item.productPrice = unitPrice
      item.price = unitPrice
      item.includeIva = item.newIncludeIva
      item.selectedAlias = alias

      if (item.selectedPrice) {
        item.selectedPrice = {
          alias,
          label,
          value: unitPrice,
        }
      }
      newValue = item
      if (type !== itemTypes.SELL) setModalItem({ ...modalItem, quantity: item.quantity })
      else {
        setModalItem({ ...item })
        setSelected([...selected])
      }
    } else {
      newValue = { ...selected[item.edit], phraseReference: item.phraseReference }
    }
    if (!show) {
      if (item.edit >= 0) selected[item.edit] = newValue
      else selected.push(newValue)
      setSelected([...selected])
      setShowIVA(false)
      setModalItem({})
    }
  }

  /* ####### COMPRAR ############*/

  const getItems = data => {
    if (fieldRepeat) return data
    return data.filter(d => selected.findIndex(s => s.id === d.id) === -1)
  }

  const getGrid = (isTrue, isFalse) => {
    if (type !== itemTypes.PURCHASE && type !== itemTypes.EXPENSE) return isTrue
    return isFalse
  }

  const getCurrentSymbol = () => {
    return importation
      ? activateLocalCurrency
        ? exchangeRate.symbol
        : '$'
      : currentCurrency.symbol
  }

  const getHeaders = () => {
    let headers = [
      { show: true },
      {
        show: true,
        label: (
          <Row
            style={{
              minWidth:
                type === itemTypes.EXPENSE || type === itemTypes.PURCHASE
                  ? 1230
                  : getGrid(1200, haveLocations() ? 1000 : 800),
            }}>
            <Col
              xl={
                haveLocations() ? (type === itemTypes.PRODUCTION ? 8 : 6) : getGrid(8, 6)
              }
              md={
                haveLocations() ? (type === itemTypes.PRODUCTION ? 8 : 6) : getGrid(8, 6)
              }
              xs={
                haveLocations() ? (type === itemTypes.PRODUCTION ? 8 : 6) : getGrid(8, 6)
              }>
              Item
            </Col>
            {haveLocations() &&
              type !== itemTypes.PURCHASE &&
              type !== itemTypes.EXPENSE &&
              type !== itemTypes.PRODUCTION && (
                <Col xl={getGrid(2, 0)} md={getGrid(2, 0)} xs={getGrid(2, 0)}>
                  Ubicación
                </Col>
              )}
            <Col xl={getGrid(4, 6)} md={getGrid(4, 6)} xs={getGrid(4, 6)}>
              <Row>
                <Col>
                  <Col
                    xl={
                      type === itemTypes.PRODUCTION
                        ? 7
                        : haveLocations()
                        ? 4
                        : getGrid(12, 4)
                    }
                    md={
                      type === itemTypes.PRODUCTION
                        ? 7
                        : haveLocations()
                        ? 4
                        : getGrid(12, 4)
                    }
                    xs={
                      type === itemTypes.PRODUCTION
                        ? 7
                        : haveLocations()
                        ? 4
                        : getGrid(12, 4)
                    }>
                    Cantidad
                  </Col>

                  {(type === itemTypes.PURCHASE ||
                    type === itemTypes.EXPENSE ||
                    type === itemTypes.PRODUCTION) && (
                    <>
                      <Col
                        xl={type === itemTypes.PRODUCTION ? 5 : fieldEditPrice ? 4 : 3}
                        md={type === itemTypes.PRODUCTION ? 5 : fieldEditPrice ? 4 : 3}
                        xs={type === itemTypes.PRODUCTION ? 5 : fieldEditPrice ? 4 : 3}>
                        Costo unitario ({getCurrentSymbol()})
                      </Col>
                      {type !== itemTypes.PRODUCTION && (
                        <Col
                          xl={getGrid(7, fieldEditPrice ? 4 : 5)}
                          md={getGrid(7, fieldEditPrice ? 4 : 5)}
                          xs={getGrid(7, fieldEditPrice ? 4 : 5)}>
                          Subtotal ({getCurrentSymbol()})
                        </Col>
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        ),
        className: '',
      },
      {
        label: 'Fecha de vencimiento',
        show: type === itemTypes.PURCHASE || type === itemTypes.EXPENSE,
      },
      {
        label: `Tasa de impuestos (${getCurrentSymbol()})`,
        show: (type === itemTypes.PURCHASE || type === itemTypes.EXPENSE) && importation,
      },
      {
        label: `Total (${exchangeRate !== undefined ? exchangeRate.symbol : 'Q'})`,
        show: (type === itemTypes.PURCHASE || type === itemTypes.EXPENSE) && importation,
      },
      { label: 'Precio', show: type === itemTypes.SELL },
      { label: 'Descuento', show: type === itemTypes.SELL },
      { label: 'SubTotal', show: type === itemTypes.SELL },
    ]

    headers.push({ show: true, label: 'Acciones' })
    return headers
  }

  const getItemValue = item => {
    const params = {
      warehouseId,
      productId: item.value,
      type:
        type === itemTypes.SELL
          ? 1
          : type === itemTypes.PRODUCTION
          ? 3
          : type === itemTypes.WASTE
          ? 2
          : type === itemTypes.PURCHASE
          ? 4
          : type === itemTypes.EXPENSE
          ? 5
          : 6,
    }
    dispatch(getLineItemsToSelect({}, params))
  }

  const actionsSelect = (v, key, item) => {
    // if (item.detailId && isEdit) {
    //   const value = selected.find(s => s.key === key)
    //   value.deleted = true
    //   setListDeleted(listDeleted.concat(value))
    // }
    const indexS = selected.findIndex(s => s.key === key)
    if (indexS >= 0) {
      const newValue = v.id !== 1 ? Object.assign({}, v) : JSON.parse(JSON.stringify(v))
      selected[indexS] = {
        ...newValue,
        key,
        detailId: item.detailId && isEdit ? item.detailId : undefined,
        edited: item.detailId && isEdit ? true : undefined,
      }
      setIndexChange(indexS)
      if (
        selected[indexS].id !== 1 &&
        ((selected[indexS].listCategorization &&
          selected[indexS].listCategorization !== null) ||
          seeWarehouseLocations.value !== '0')
      ) {
        if (
          type === itemTypes.SELL ||
          type === itemTypes.TRANSFER ||
          type === itemTypes.WASTE
        ) {
          selected[indexS].showVariationsOuts = true
          dispatch(
            getWarehouseInventoryVariation(
              warehouseId || warehouse.value,
              selected[indexS].value,
              {
                limit: true,
                type: type,
              },
            ),
          )
        } else if (
          (type === itemTypes.PURCHASE ||
            type === itemTypes.PRODUCTION ||
            type === itemTypes.EXPENSE) &&
          !selected[indexS].listCategorization &&
          selected[indexS].listCategorization === null
        ) {
          getItemValue(v)
        } else {
          selected[indexS].showVariations = true
        }
        setSelected([...selected])
      } else if (selected[indexS].id !== 1) getItemValue(v)

      if (v.value === 1) setCommentaryShow(true)
    }
  }

  const getSelect = (item, key) => {
    return (
      <CustomCreate
        disabled={disabled}
        mt={0}
        withoutLabel
        async
        dontClear
        onCreateOption={() => undefined}
        options={getItems(items)}
        isLoading={loadingItems || loadingLineItems}
        placeholder={'Selecciona un ítem'}
        textLabel={'Sin resultados: '}
        value={item}
        onChange={v => {
          actionsSelect(v, key, item)
        }}
        loadOptions={async searchItem => {
          if (!(searchItem === null || searchItem === undefined || searchItem === '')) {
            let data = await setUp(searchItem, true)
            data = formatItems(data)
            if (data.length > 0)
              setItems([
                {
                  id: 1,
                  value: 1,
                  label: 'Universal',
                  name: 'Universal',
                  felItemTypeId: 1,
                  list: [],
                  lineItems: [
                    {
                      id: 1,
                      productId: 1,
                      productData: {
                        active: true,
                        code: 1,
                        id: 1,
                        productId: 1,
                        discountLimit: false,
                        discountLimitedNumber: null,
                      },
                    },
                  ],
                  key: new Date().valueOf(),
                  activeUpdatable: true,
                },
                ...data,
              ])
            return getItems(data)
          } else return getItems(items)
        }}
      />
    )
  }

  const haveLocations = () => {
    return seeWarehouseLocations && seeWarehouseLocations.value !== '0'
  }

  const renderDesktop = (item, index) => {
    const { key, value, quantity, url } = item
    if (discount !== null && item.value !== undefined) {
      let newDiscount = getNewDiscount(item, discount)
      if (!isNaN(newDiscount) && newDiscount < item.maxDiscount) {
        item.error = null
        sumDiscount = Number(sumDiscount) + parseFloat(newDiscount.toFixed(2))
        if (index === selected.length - 1) {
          if (Number(sumDiscount.toFixed(3)) > Number(discount)) {
            newDiscount = newDiscount.toFixed(2) - 0.01
          } else if (Number(sumDiscount.toFixed(3)) < Number(discount)) {
            newDiscount = parseFloat(newDiscount.toFixed(2)) + 0.01
          }
          sumDiscount = 0
        }
        item.discount = newDiscount.toFixed(2)
        let subtotal = getSubTotalV2(item)
        if (item.quantity > 0) item.subTotal = subtotal
      }
    }
    return (
      <tr className={'data'} key={key}>
        <td className={'mini text-center'}>
          <div className={'t-item-img'}>
            <img src={url || Placeholder} alt={'img'} />
          </div>
        </td>

        <td className={'mini'}>
          <div
            style={{
              width:
                type === itemTypes.EXPENSE || type === itemTypes.PURCHASE
                  ? 1230
                  : getGrid(1200, haveLocations() ? 1200 : 1000),
            }}>
            <LineQuantites
              select={getSelect(item, key)}
              loading={loadingLineItems}
              currentItem={item}
              line={item.lineItems || []}
              maxDiscount={disabledDiscounts?.value === 'false' ? maxDiscount : null}
              isSell={false}
              disabled={value === undefined || disabled}
              onChangeQuantity={(item, isQuatity) => {
                if (type !== itemTypes.SELL) setModalItem({ ...modalItem, quantity })
                else {
                  if (isEdit && isQuatity) {
                    item.edited = true
                  }
                  setModalItem({ ...item })
                }
              }}
              type={type}
              onCheckSubtotal={value => {
                setModalItem({ ...modalItem, individualTotal: value })
              }}
              onChangeSubTotal={itemLine => {
                if (withoutIVA.active)
                  itemLine.purchasePrice =
                    Number(itemLine.ownPrice) -
                    Number(itemLine.ownPrice) * ((withoutIVA.value || 0) / 100)
                let index = item.lineItems.findIndex(x => x.id === itemLine.id)
                item.lineItems[index] = itemLine

                // item.subTotal +=  itemLine.subTotal ? itemLine.subTotal : 0
                setModalItem({ ...item })
              }}
              currency={getCurrentSymbol()}
              importation={importation}
              haveLocations={seeWarehouseLocations && seeWarehouseLocations.value !== '0'}
              locations={item.listLocations || []}
              fieldEditPrice={fieldEditPrice}
            />
            {item.id !== 1 &&
              (item.listCategorization !== null || seeWarehouseLocations.value !== '0') &&
              item.id !== undefined &&
              renderVariations(item, index)}
            {/* {item.id !== 1 &&
              seeWarehouseLocations.value !== '0' &&
              getLocations(item, index)} */}

            {(type === itemTypes.SELL || type === itemTypes.TRANSFER) &&
              item.id !== undefined &&
              getCommentaryComponent(item, index)}

            {type === itemTypes.SELL && item.id !== undefined && item.id !== 1 && (
              <TaxFree
                index={index}
                item={item}
                toSelect={item}
                taxFree={taxFree}
                updateTaxFree={(item, show) => {
                  updateTaxFree(item, show)
                }}
                updatePriceIVA={(item, show) => {
                  updatePriceIVA(item, show)
                }}
              />
            )}
            {type === itemTypes.PURCHASE && item.showSeries && (
              <SerieItem
                toSelect={item}
                changeItem={() => setSelected([...selected])}
                modal
                type={type}
                importation={importation}
                onAdd={() => {
                  if (item.edit >= 0) selected[item.edit] = item
                  else selected.push(item)
                  setSelected([...selected])
                }}
              />
            )}
          </div>
        </td>

        {type === itemTypes.SELL && (
          <>
            <td
              className={`${
                (savePrice && item.activeUpdatable) ||
                (multiplePrices && item.list && item.list.length > 1)
                  ? 'medium'
                  : 'mini'
              } center`}>
              {savePrice && item.activeUpdatable ? (
                <CustomCreate
                  placeholder={'Buscar o seleccionar'}
                  value={
                    item.selectedPrice || {
                      value: item.productPrice,
                      label: item.productPrice,
                    }
                  }
                  textLabel={`Agregar precio ${currentCurrency.symbol}`}
                  mt={0}
                  style={{ width: 150, flex: 1 }}
                  withoutLabel
                  disabled={item.add}
                  options={
                    item.list
                      ? item.list.map(l => ({
                          value: Number(l.price),
                          alias: l.alias,
                          label: `${l.alias} - ${toMoney(l.price)}`,
                        }))
                      : []
                  }
                  alias={selected[index].selectedAlias}
                  onCreateOption={value => {
                    if (!isNaN(value)) {
                      if (value < 0) value = 0
                      selected[index].productPrice = value
                      selected[index].price = value
                      selected[index].selectedPrice = {
                        value: Number(value),
                        label: toMoney(value),
                        alias: 'Nuevo precio',
                      }
                      selected[index].selectedAlias = 'Nuevo precio'

                      if (isEdit) selected[index].edited = true
                      if (
                        selected[index].productData &&
                        selected[index].productData.price === undefined
                      )
                        selected[index].productData.price = value

                      selected[index].save = false
                      if (!selected[index].includeIva) {
                        updatePriceIVA({
                          ...selected[index],
                          edit: index,
                          includeIva: !selected[index].includeIva,
                          newIncludeIva: selected[index].includeIva,
                        })
                        setTimeout(() => {
                          setModalItem({ ...selected[index] })
                          setSelected([...selected])

                          //onChangeQuantity(item.quantity, selected[index], false)
                        }, 300)
                      } else {
                        setModalItem({ ...selected[index] })
                        setSelected([...selected])
                        //onChangeQuantity(item.quantity, selected[index], false)
                      }
                    }
                  }}
                  onChange={value => {
                    if (value) {
                      let comp = `${value.value || 0}`
                      if (comp.match(/[0-9]+/g)) {
                        if (value.value < 0)
                          value = {
                            value: Number(value.value),
                            label: toMoney(value.value),
                          }

                        if (isEdit) selected[index].edited = true
                        selected[index].price = value.value
                        selected[index].productPrice = value.value
                        selected[index].selectedPrice = {
                          ...value,
                          label: toMoney(value.value),
                        }
                        selected[index].selectedAlias = value.alias
                        selected[index].save = false
                        if (!selected[index].includeIva) {
                          updatePriceIVA({
                            ...selected[index],
                            edit: index,
                            includeIva: !selected[index].includeIva,
                            newIncludeIva: selected[index].includeIva,
                          })

                          setTimeout(() => {
                            //onChangeQuantity(item.quantity, selected[index], false)
                            setModalItem({ ...selected[index] })
                            setSelected([...selected])
                          }, 300)
                        } else {
                          setModalItem({ ...selected[index] })
                          setSelected([...selected])
                          // onChangeQuantity(item.quantity, selected[index], false)
                        }
                      }
                    }
                  }}
                />
              ) : multiplePrices && item.list && item.list.length > 0 ? (
                <Select
                  value={
                    item.selectedPrice || {
                      value: item.productPrice,
                      label: item.productPrice,
                    }
                  }
                  options={item.list.map(l => ({
                    value: Number(l.price),
                    alias: l.alias,
                    label: `${l.alias} - ${toMoney(l.price)}`,
                  }))}
                  onChange={value => {
                    if (isEdit) selected[index].edited = true
                    selected[index].selectedPrice = {
                      ...value,
                      label: toMoney(value.value),
                    }
                    selected[index].selectedAlias = value.alias
                    selected[index].price = value.value

                    if (type !== itemTypes.SELL)
                      setModalItem({ ...modalItem, quantity: item.quantity })
                    else {
                      setModalItem({ ...selected[index] })
                      setSelected([...selected])
                    }
                    //onChangeQuantity(item.quantity, selected[index], false)
                  }}
                  mt={0}
                  style={{ width: 150, flex: 1 }}
                  withoutLabel
                  disabled={item.add}
                  alias={selected[index].selectedAlias}
                />
              ) : (
                toMoney(item.productPrice)
              )}
            </td>
            <td className={`medium center`}>
              <div className={'d-flex justify-between'}>
                <Col>
                  {item.percentDiscount ? (
                    <>
                      <Select
                        mt={0}
                        style={{ width: 90, flex: 1 }}
                        value={item.percent}
                        withoutLabel
                        onChange={value => {
                          percentDiscount(item, value, true)
                        }}
                        options={discounts}
                        error={item.error}
                        disabled={isGeneral}
                      />
                      <label> Q.{formatNumberWithCommas(item.discount || 0)}</label>
                    </>
                  ) : (
                    <FormText
                      style={{ width: 90 }}
                      mt={1}
                      disabled={item.value === undefined || isGeneral}
                      type={'number'}
                      value={item.percentDiscount ? item.percent : item.discount}
                      onChange={({ target }) => {
                        changeDiscount(Number(target.value), selected[index])
                        setSelected([...selected])
                      }}
                      error={item.error}
                    />
                  )}
                </Col>
                <Col>
                  <label>
                    <b> %</b>
                  </label>
                  <input
                    disabled={isGeneral}
                    type="checkbox"
                    id="idchk"
                    name="chk"
                    checked={item.percentDiscount}
                    onChange={e => {
                      percentDiscount(
                        item,
                        { label: '0%', value: 0 },
                        e.currentTarget.checked,
                      )
                    }}
                  />
                </Col>
              </div>
            </td>
            <td>
              <div className={'b-user-name mt-2'}>
                <Money className={'short center'}>{item.subTotal}</Money>
              </div>
            </td>
          </>
        )}
        {(type === itemTypes.PURCHASE || type === itemTypes.EXPENSE) && (
          <>
            <td>
              <div
                style={{
                  width: 150,
                }}>
                <CustomDate
                  mt={1}
                  disabled={!item.value}
                  value={item.expirationDate}
                  disabledDays={[]}
                  onDayChange={d => {
                    selected[index].expirationDate = d
                    setSelected([...selected])
                  }}
                />
              </div>
            </td>
            {importation && (
              <>
                <td>
                  {item.lineItems &&
                    item.lineItems
                      .filter((l, i) => {
                        if (item.individualTotal) return true
                        else return i === 0
                      })
                      .map((val, j) => (
                        <Row
                          style={{
                            marginTop: j > 0 ? (j === 1 ? '43px' : '23px') : '13px',
                          }}
                          key={j}>
                          <Col key={j}>
                            <input
                              value={!item.individualTotal ? item.tax || 0 : val.tax || 0}
                              disabled={
                                !item.individualTotal
                                  ? Number(item.subTotal || 0) === 0
                                  : !val.subTotal || Number(val.subTotal || 0) === 0
                              }
                              style={{ width: '100%' }}
                              type="number"
                              onChange={({ target }) => {
                                let { value } = target
                                if (!value || value < 0) value = 0
                                if (!item.individualTotal) {
                                  selected[index].tax = target.valueAsNumber
                                  val.tax = value
                                } else val.tax = value
                                if (withoutIVA.active)
                                  selected[index].purchasePrice =
                                    Number(item.ownPrice) - Number(value)
                                setSelected([...selected])
                              }}
                            />
                          </Col>
                        </Row>
                      ))}
                </td>
                <td style={{ minWidth: '120px' }}>
                  {item.lineItems &&
                    item.lineItems
                      .filter((l, i) => {
                        if (item.individualTotal) return true
                        else return i === 0
                      })
                      .map((val, j) => {
                        return (
                          <Row
                            style={{
                              marginTop: j > 0 ? (j === 1 ? '43px' : '23px') : '13px',
                              minWidth: 150,
                            }}
                            key={j}>
                            <Col key={j}>
                              <input
                                value={
                                  !item.individualTotal
                                    ? item.customPrice
                                      ? item.customPrice
                                      : parseFloat(
                                          (Number(item.subTotal || 0) +
                                            Number(item.tax || 0)) *
                                            conversion,
                                        ).toFixed(2)
                                    : val.customPrice ||
                                      parseFloat(
                                        (Number(val.subTotal || 0) +
                                          Number(val.tax || 0)) *
                                          conversion,
                                      ).toFixed(2)
                                }
                                disabled={
                                  !item.individualTotal
                                    ? Number(item.subTotal || 0) === 0
                                    : !val.subTotal || Number(val.subTotal || 0) === 0
                                }
                                style={{ width: '100%' }}
                                type="number"
                                onChange={({ target }) => {
                                  let { value } = target
                                  if (!value) value = undefined
                                  else if (value < 0) value = 0
                                  if (!item.individualTotal)
                                    selected[index].customPrice = value
                                  else val.customPrice = value
                                  setSelected([...selected])
                                }}
                              />
                            </Col>
                          </Row>
                        )
                      })}
                </td>
              </>
            )}
          </>
        )}
        <td>
          <div>
            {type === itemTypes.PURCHASE && !importation && item.decimals === null && (
              <Button
                color={'accent'}
                onClick={() => {
                  item.lineItems &&
                    item.lineItems.forEach(x => {
                      let series = Array.apply(null, Array(Number(x.quantity || 0))).map(
                        () => '',
                      )
                      if (x.series) {
                        series = series.map((s, i) => x.series[i])
                      }
                      x.series = series
                    })
                  item.showSeries = true
                  item.onlySeries = true
                  item.edit = index
                  setSelected([...selected])
                }}>
                <Icon tooltip={'Números de Serie'} icon={faBarcode} />
              </Button>
            )}
            <Button disabled={disabled} onClick={() => onDiscardItem(key)}>
              <Icon color={'white'} icon={faTrash} tooltip={'Descartar'} />
            </Button>
          </div>
        </td>
      </tr>
    )
  }

  const renderMobile = item => {
    const {
      id,
      key,
      label,
      commentary,
      code,
      subProductId,
      subProductData,
      subQuantity,
      url,
    } = item
    return (
      <div className={'b-item'} key={key}>
        <div className={'d-flex'}>
          <div style={{ width: '93%' }} className={'d-flex'}>
            <div className={'t-item-img'}>
              <img src={url || Placeholder} alt={'img'} />
            </div>
            <div className={'column pl-1'}>
              <div className={'column'}>
                <div className={'d-flex'}>
                  <div className={'b-user-email'}>
                    {getQuantityLine(item.lineItems) || 0}u
                  </div>
                  <div className={'b-user-email'} style={{ marginLeft: 3 }}>
                    {code}
                  </div>
                </div>
                <div className={'b-user-name'} style={{ marginLeft: 3 }}>
                  {label}
                </div>
              </div>
              {id === 1 && (
                <div className={'column'}>
                  <div className={'d-flex'}>
                    <div className={'b-user-name'}>{commentary}</div>
                  </div>
                  <div className={'b-user-email'}>{code}</div>
                </div>
              )}
              {subProductId && subQuantity > 0 && (
                <div className={'column'}>
                  <div className={'d-flex'}>
                    <div className={'b-user-email'}>{subQuantity || 0}u</div>
                    <div className={'b-user-name'} style={{ marginLeft: 3 }}>
                      {subProductData?.name}
                    </div>
                  </div>
                  <div className={'b-user-email'}>{subProductData?.code}</div>
                </div>
              )}
            </div>
          </div>
          <div style={{ width: '7%', position: 'relative' }} className={'column center'}>
            <div style={{ position: 'absolute', top: '25%' }}>
              <Icon
                icon={faTrash}
                color={'red'}
                tooltip={'Descartar'}
                onClick={() => onDiscardItem(key)}
              />
              <Icon
                icon={faEdit}
                color={'green'}
                tooltip={'Editar'}
                onClick={() => {
                  setModalItem({ ...item, show: true })
                }}
              />
            </div>
          </div>
        </div>
        {getSimpleListVariation(item.listVariations)}
      </div>
    )
  }

  const getSimpleListVariation = (variations, name, hide = true, islocation) => {
    return (
      <ListVariations
        selected={variations}
        groupName={name}
        hideMessage={hide}
        isLocations={islocation}
      />
    )
  }

  // const getLocations = (item, index) => {
  //   return (
  //     <LocationComponent
  //       modeSelection={true}
  //       onConfirm={loc => {
  //         selected[index].listLocations = loc.listLocations
  //         selected[index].showLocations = false
  //         setSelected([...selected])
  //         const params = {
  //           warehouseId,
  //           productId: selected[index].value,
  //           type:
  //             type === itemTypes.SELL
  //               ? 1
  //               : type === itemTypes.PRODUCTION
  //               ? 3
  //               : type === itemTypes.WASTE
  //               ? 2
  //               : type === itemTypes.PURCHASE
  //               ? 4
  //               : type === itemTypes.EXPENSE
  //               ? 5
  //               : 6,
  //         }
  //         let list = selected[index].listVariations || []
  //         let listV = []
  //         list.forEach(item => {
  //           listV.push(item.id)
  //         })

  //         let listL = loc.listLocations
  //         let listLoc = []
  //         listL.forEach(item => {
  //           listLoc.push(item.value)
  //         })

  //         dispatch(
  //           getLineItemsToSelect({ categories: listV, locations: listLoc }, params),
  //         )
  //         setSelected([...selected])
  //       }}
  //       value={{ ...item, show: item.showLocations }}
  //     />
  //   )
  // }

  const renderVariations = (item, index) => {
    if (
      ((type === itemTypes.SELL ||
        type === itemTypes.TRANSFER ||
        type === itemTypes.WASTE) &&
        !selected[index].showVariations &&
        !selected[index].listVariations) ||
      (seeWarehouseLocations.value !== '0' &&
        !selected[index].listVariations &&
        !selected[index].showVariations)
    )
      return (
        <SelectVariations
          id={warehouseId}
          isQuote
          baseItemData={selected[index]}
          productId={selected[index].value}
          show={selected[index].showVariationsOuts}
          name={selected[index].name}
          onHide={() => {
            selected[index].showVariationsOuts = false
            setSelected([...selected])
          }}
          forOuts
          typeTransaction={type}
          onSave={(items, noInventory) => {
            selected[index].showVariationsOuts = false
            setIndexChange(index)
            setSelected([...selected])
            let count = index - 1

            if (isEdit && noInventory) selected[index].edited = true

            let it = noInventory
              ? [{ ...selected[index], listCategorization: [] }]
              : items
                  .filter(x => x.quantity > 0)
                  .map((item, i) => {
                    count += 1
                    return {
                      ...item,
                      price: -1,
                      id: selected[index].value,
                      locations: item.listLocations,
                      categories: item.listCategorization,
                      key: count,
                      detailId: i === 0 ? selected[index].detailId : undefined,
                      edited: i === 0 && isEdit ? true : undefined,
                    }
                  })
            setList(it)
            const params = {
              warehouseId: warehouseId,
              withZero: true,
              withCategories: true,
              withExistence: true,
              type,
              search,
              list: selected[index].value,
            }
            dispatch(getItemsToSelectDetail(params))
          }}
          manualVariations={() => {
            selected[index].showVariationsOuts = false
            selected[index].showVariations = true
            setSelected([...selected])
          }}
          dontGetCategorization
        />
      )
    else if (item.listCategorization !== null)
      return (
        <div className="ml-2">
          <ListVariations
            show={selected[index].showVariations}
            onSave={list => {
              setIndexChange(index)
              selected[index].showVariations = false
              if (
                (type === itemTypes.SELL ||
                  type === itemTypes.TRANSFER ||
                  type === itemTypes.WASTE) &&
                seeWarehouseLocations.value !== '0'
              ) {
                selected[index].listVariations = undefined
                selected[index].showVariationsOuts = true

                if (isEdit) selected[index].edited = true
                let response = ''
                list.forEach(x => {
                  response = response + x.id
                })

                setSelected([...selected])
                dispatch(
                  getWarehouseInventoryVariation(
                    warehouseId || warehouse.value,
                    selected[index].value,
                    {
                      limit: true,
                      type: type,
                      categories: response,
                    },
                  ),
                )
              } else {
                // if (seeWarehouseLocations.value !== '0') {
                //   selected[index].listVariations = list
                //   selected[index].showLocations = true
                //   setSelected([...selected])
                // } else {
                selected[index].listVariations = list
                const params = {
                  warehouseId,
                  productId: selected[index].value,
                  type:
                    type === itemTypes.SELL
                      ? 1
                      : type === itemTypes.PRODUCTION
                      ? 3
                      : type === itemTypes.WASTE
                      ? 2
                      : type === itemTypes.PURCHASE
                      ? 4
                      : type === itemTypes.EXPENSE
                      ? 5
                      : 6,
                }
                let listV = []
                list.forEach(item => {
                  listV.push(item.id)
                })
                dispatch(getLineItemsToSelect({ categories: listV }, params))
                setSelected([...selected])
                //}
              }
            }}
            onHide={() => {
              selected[index].showVariations = false
              setSelected([...selected])
            }}
            modeSelection
            data={item.listCategorization || []}
            selected={item.listVariations && item.listVariations}
            onClick={() => {
              selected[index].showVariations = true
              setSelected([...selected])
            }}
          />
        </div>
      )
  }

  const getQuantityLine = line => {
    if (line) {
      let response = ''
      line.forEach(line => {
        response += (line.quantity || 0) + ', '
      })
      return response
    }
    return 0
  }

  const renderValuesLocations = price => {
    modalItem.price = price
    modalItem.subTotal = price * modalItem.quantity
    modalItem.subTotalWithoutDiscount = price * modalItem.quantity
    modalItem.maxDiscount = (modalItem.subTotalWithoutDiscount * maxDiscount) / 100
    if (modalItem.discount > modalItem.maxDiscount) {
      modalItem.discount = modalItem.maxDiscount
    }
  }

  const getCommentaryComponent = (item, index) => (
    <Commentary
      value={selected[index].commentary}
      currentValue={selected[index].commentary}
      show={commentaryShow}
      onChange={value => {
        if (isEdit) selected[index].edited = true
        selected[index].commentary = value
        setSelected([...selected])
      }}
      onHide={() => {
        setCommentaryShow(false)
      }}
      textCommentary={item.label}
      edit
      max={item.id === 1 ? 100 : 1000}
      label={item.id === 1 && 'Nombre del producto'}
      commentaryLabel={item.id === 1 && 'Nombre del producto'}
      prepend={
        item.id === 1 && (
          <Col xl={12}>
            <Select
              label={'Bien o Servicio'}
              info={'La propiedad "Bien o Servicio" se usará para la factura electrónica'}
              required
              value={felItemTypes.find(
                ({ value }) => value === selected[index].felItemTypeId,
              )}
              options={felItemTypes}
              onChange={felItemType => {
                selected[index].felItemTypeId = felItemType.value
                setSelected([...selected])
              }}
            />
          </Col>
        )
      }
      textarea={item.id !== 1}
    />
  )
  return (
    <Media>
      {({ breakpoints, currentBreakpoint }) => {
        const mobile = breakpoints[currentBreakpoint] <= breakpoints.mobileLandscape
        setMobile(mobile)
        return (
          <div>
            {type === itemTypes.SELL && mobile && (
              <Col style={{ alignSelf: 'flex-end' }} md={6} xs={6}>
                <Card bodyStyle={{ paddingTop: 18, paddingBottom: 18 }}>
                  <div className={'justify-center column'}>
                    <span>Total</span>
                    <Money component="h4">
                      <strong>{getTotal('subTotal')}</strong>
                    </Money>
                  </div>
                </Card>
              </Col>
            )}
            {type === itemTypes.SELL && mobile && (
              <Col style={{ alignSelf: 'flex-end' }} md={6} xs={6}>
                <Card>
                  <div className={'justify-center column'}>
                    <span>Descuento</span>
                    <Money component="h4">
                      <strong>{getTotal('discount')}</strong>
                    </Money>
                  </div>
                </Card>
              </Col>
            )}

            <Col md={12} xs={12} xl={12}>
              <Title
                action={
                  type === itemTypes.EXPENSE ||
                  type === itemTypes.PURCHASE ||
                  type === itemTypes.SELL
                }
                actionTitle={'Crear producto'}
                dontHide
                onClick={() => setShowCreateProduct(true)}
              />
              <Card>
                <Row>
                  {type === itemTypes.SELL && (
                    <Col>
                      <Select
                        label={'Bodegas'}
                        value={warehouse}
                        disabled={!client}
                        onChange={value => setWarehouse(value)}
                        options={warehouses}
                        loading={loadingW}
                        subText={
                          type.ORDERS === type &&
                          warehouse &&
                          warehouse?.posId &&
                          `Punto de venta: ${warehouse.posName}`
                        }
                      />
                    </Col>
                  )}
                  {balancesPermission && (
                    <Col
                      style={{ alignSelf: 'flex-end' }}
                      xs={mobile && importation && 12}>
                      <div className={'justify-center column'}>
                        <span>Total</span>
                        {importation ? (
                          activateLocalCurrency ? (
                            <Money component="h4">
                              <strong>{Number(getTotal('subTotal', true, true))}</strong>
                            </Money>
                          ) : (
                            <h4>
                              <strong>
                                ${' '}
                                {toMoney(Number(getTotal('subTotal', true, false)), true)}
                                &nbsp; /{' '}
                                {exchangeRate
                                  ? exchangeRate.symbol
                                  : currentCurrency.symbol}
                                {toMoney(
                                  Number(getTotal('subTotal', true, true)) * conversion,
                                  true,
                                )}
                              </strong>
                            </h4>
                          )
                        ) : (
                          <Money component="h4">
                            <strong>{Number(getTotal('subTotal'))}</strong>
                          </Money>
                        )}
                      </div>
                    </Col>
                  )}
                  {!mobile && type === itemTypes.SELL && (
                    <Col style={{ alignSelf: 'flex-end' }}>
                      <div className={'justify-center column'}>
                        <span>Descuento</span>
                        <Money component="h4">
                          <strong>{Number(getTotal('discount'))}</strong>
                        </Money>
                      </div>
                    </Col>
                  )}

                  {!order &&
                    type === itemTypes.PURCHASE &&
                    importation &&
                    currentCurrency.code !== 'USD' && (
                      <>
                        <Col style={{ alignSelf: 'flex-end' }}>
                          <div className={'justify-center column'}>
                            <Switch
                              topLabel
                              name={'withExistence'}
                              checked={activateLocalCurrency}
                              onChange={e => {
                                setActivateLocalCurrency(!activateLocalCurrency)
                                if (!e.target.checked) {
                                  setExchangeRate(
                                    currenciesOwn.find(c => c.code === 'GTQ'),
                                  )
                                  let money = getExchangeRate('GTQ')
                                  setConversion(Number(money.exchange))
                                  selected.conversion = Number(money.exchange)
                                  if (currentCurrency.code === 'USD') {
                                    setLocalCurrency(true)
                                  } else {
                                    setLocalCurrency(false)
                                  }
                                } else {
                                  setExchangeRate(currentCurrency)
                                  setConversion(1)
                                  selected.conversion = Number(1)

                                  if (currentCurrency.code === 'USD') {
                                    setLocalCurrency(false)
                                  } else {
                                    setLocalCurrency(true)
                                  }
                                }
                                setSelected([...selected])
                              }}
                              label={`Moneda Local (${currentCurrency.symbol})`}
                              info={'Manejar todo con moneda local'}
                            />
                          </div>
                        </Col>
                        <Col>
                          {!activateLocalCurrency && (
                            <div className={'justify-center column'}>
                              <FormText
                                label={'Tasa de conversión'}
                                type={'number'}
                                prepend={'Q'}
                                value={conversion}
                                onChange={({ target }) => {
                                  setConversion(Number(target.value))
                                  setSelected([...selected])
                                }}
                              />
                            </div>
                          )}
                        </Col>
                      </>
                    )}
                </Row>

                <Row>
                  <Col>
                    <TableV2
                      loading={
                        loading ? loading || loadingItemsDetails : loadingItemsDetails
                      }
                      customClass={'scroll-x-without-height'}
                      headers={mobile ? undefined : getHeaders()}
                      items={selected.filter(item => !item.deleted)}
                      customFilter={customFilter()}
                      renderRow={mobile ? renderMobile : renderDesktop}
                      buttonTitle={'Agregar ítem'}
                      onScan={barcode => {
                        if (barcode !== '') {
                          dispatch(
                            getInventoryWithPrices(
                              warehouse.value,
                              '',
                              '',
                              false,
                              type,
                              true,
                              null,
                              null,
                              null,
                              barcode,
                            ),
                          )
                        }
                      }}
                      hideEmpty
                    />
                  </Col>
                  <Col xl={12}>
                    <div className={'f-actions'}>
                      <div>
                        <div className={'f-actions-body'}>
                          <div className={'f-actions-button pl-2 pr-2'}>
                            <Button
                              disabled={
                                itemTypes.PURCHASE === type || itemTypes.EXPENSE === type
                                  ? loadingItems ||
                                    disabled ||
                                    (loading ? loading : false)
                                  : warehouse.value === null ||
                                    loadingItems ||
                                    disabled ||
                                    (loading ? loading : false)
                              }
                              className={'f-button-width'}
                              icon={faPlus}
                              onClick={() => {
                                if (items.length < 3) setUp()
                                if (mobile) setModalItems(true)
                                else
                                  setSelected([
                                    ...selected,
                                    {
                                      value: null,
                                      label: 'Seleccionar',
                                      key: new Date().valueOf(),
                                    },
                                  ])
                              }}>
                              Agregar Ítem
                            </Button>
                          </div>

                          {type === 1 && (
                            <div className={'f-actions-total pl-2 pr-2'}>
                              <div className={'column'}>
                                <div className={'space-between'}>
                                  <div className={'b-user-name'}>Descuento</div>
                                  <div className={'b-user-email'}>
                                    <Money>{getTotal('discount')}</Money>
                                  </div>
                                </div>
                                <div className={'space-between'}>
                                  <div className={'b-user-name'}>Total</div>
                                  <div className={'b-user-email'}>
                                    <Money>{getTotal('subTotal')}</Money>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Modal
              show={modalItems && !modalItem.show}
              centered
              size={'xl'}
              onHide={() => setModalItems(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Selección de ítems</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col xl={12}>
                    <CustomFilter
                      customFilter={customFilter()}
                      search={search}
                      onChange={({ target }) => setSearch(target.value)}
                      placeholder={'Buscar por sku/nombre'}
                      loading={loadingItems}
                    />
                  </Col>
                  <Col xl={12}>
                    <div className={'column t-items-body'}>
                      <div>
                        {getItems(items).map((item, index) => (
                          <div className={'d-flex b-item'} key={index}>
                            <div style={{ width: '90%' }} className={'d-flex'}>
                              <div className={'t-item-img'}>
                                <img src={item.url || Placeholder} alt={'img'} />
                              </div>
                              <div className={'column pl-1'}>
                                <div className={'column'}>
                                  <div className={'b-user-name'}>{item.label}</div>
                                  <div className={'b-user-email'}>{item.code}</div>
                                  {!item.subProductId && (
                                    <div className={'b-user-email'}>
                                      Existencia:{' '}
                                      {item.productExistence || item.existence || 0}u
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div style={{ width: '10%', position: 'relative' }}>
                              <div
                                className={'p-absolute'}
                                style={{ top: '25%', position: 'absolute' }}>
                                <Icon
                                  icon={faPlusCircle}
                                  size={'2x'}
                                  color={'accent'}
                                  tooltip={'Agregar'}
                                  onClick={() => {
                                    if (
                                      item.listCategorization !== null ||
                                      seeWarehouseLocations.value !== '0'
                                    ) {
                                      setModalItem({
                                        ...item,
                                        show: false,
                                        key: new Date().valueOf(),
                                      })
                                      if (
                                        type === itemTypes.SELL ||
                                        type === itemTypes.TRANSFER ||
                                        type === itemTypes.WASTE
                                      ) {
                                        setMobileVariationOuts(true)
                                        dispatch(
                                          getWarehouseInventoryVariation(
                                            warehouseId,
                                            item.value,
                                            {
                                              limit: true,
                                              type: type,
                                            },
                                          ),
                                        )
                                      } else {
                                        setMobileVariation(true)
                                      }
                                    } else {
                                      setModalItem({
                                        ...item,
                                        selectedPrice: {
                                          value: item.price,
                                          label: item.price,
                                        },
                                        show: true,
                                        key: new Date().valueOf(),
                                      })
                                      setIndexChange(selected.length)
                                      getItemValue(item)
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Row className={'container-buttons'}>
                  <Button
                    onClick={() => {
                      setModalItems(false)
                      setModalItem({})
                    }}
                    color={'secondary'}>
                    Cerrar
                  </Button>
                </Row>
              </Modal.Footer>
            </Modal>
            <SelectVariations
              id={warehouseId}
              isQuote
              //singleWarehouse ={singleWarehouse}
              baseItemData={modalItem}
              productId={modalItem.value}
              show={mobileVariationOuts}
              name={modalItem.name}
              onHide={() => {
                setMobileVariationOuts(false)
              }}
              forOuts
              typeTransaction={type}
              onSave={(items, noInventory) => {
                setMobileVariationOuts(false)
                setIndexChange(modalItem.key)
                let count = selected.length - 1
                if (!noInventory && !isQuote) {
                  modalItem.outs = true
                  modalItem.quantity = 0
                  setList(
                    items
                      .filter(x => x.quantity > 0)
                      .map(item => {
                        count += 1
                        modalItem.quantity += Number(item.quantity)
                        return {
                          ...item,
                          id: modalItem.value,
                          categories: item.listCategorization,
                          quantity: Number(item.quantity),
                          subTotal: (item.price || 0) * (item.quantity || 0),
                          key: count,
                        }
                      }),
                  )

                  if (type === itemTypes.SELL) {
                    renderValuesLocations(modalItem.price)
                    setModalItem({ ...modalItem, show: true })
                  } else if (type === itemTypes.WASTE || type === itemTypes.TRANSFER) {
                    setIndexChange(selected.length)
                    const params = {
                      warehouseId: warehouseId,
                      withZero: true,
                      withCategories: true,
                      withExistence: true,
                      type,
                      search,
                      list: modalItem.value,
                    }
                    dispatch(getItemsToSelectDetail(params))
                  }
                } else {
                  modalItem.noInventory = true
                  setModalItem({
                    ...modalItem,
                    show: true,
                    key: new Date().valueOf(),
                  })
                  setIndexChange(selected.length)
                  getItemValue(modalItem)
                }
              }}
              manualVariations={() => {
                setMobileVariationOuts(false)
                setMobileVariation(true)
              }}
              mobileOuts
              dontGetCategorization
            />
            <ListVariations
              show={mobileVariation}
              onSave={list => {
                setMobileVariation(false)
                if (
                  (type === itemTypes.SELL ||
                    type === itemTypes.TRANSFER ||
                    type === itemTypes.WASTE) &&
                  seeWarehouseLocations.value !== '0'
                ) {
                  modalItem.listVariations = undefined

                  setMobileVariationOuts(true)

                  let response = ''
                  list.forEach((x, i) => {
                    response = response + x.id
                    if (i < list.length - 1) response = response + ','
                  })

                  setSelected([...selected])
                  dispatch(
                    getWarehouseInventoryVariation(
                      warehouseId || warehouse.value,
                      modalItem.value,
                      {
                        limit: true,
                        type: type,
                        categories: response,
                      },
                    ),
                  )
                } else {
                  // if (seeWarehouseLocations.value !== '0') {
                  //   modalItem.listVariations = list
                  //   modalItem.showLocations = true
                  //   setModalItem({ ...modalItem })
                  // } else {
                  modalItem.show = true
                  modalItem.listVariations = list
                  if (modalItem.categories === null) modalItem.categories = list
                  setModalItem({ ...modalItem })
                  const params = {
                    warehouseId,
                    productId: modalItem.value,
                    type:
                      type === itemTypes.SELL
                        ? 1
                        : type === itemTypes.PRODUCTION
                        ? 3
                        : type === itemTypes.WASTE
                        ? 2
                        : type === itemTypes.PURCHASE
                        ? 4
                        : type === itemTypes.EXPENSE
                        ? 5
                        : 6,
                  }
                  let listV = []
                  list.forEach(item => {
                    listV.push(item.id)
                  })
                  dispatch(getLineItemsToSelect({ categories: listV }, params))
                  //}
                }
              }}
              onHide={() => {
                setMobileVariation(false)
              }}
              modeSelection
              data={modalItem.listCategorization}
              selected={modalItem.listVariations && modalItem.listVariations}
              hideMessage
              hideVariations
            />
            <Modal
              show={modalItem.show}
              centered
              size={'xl'}
              onHide={() => {
                setModalItem({})
              }}>
              <Modal.Header closeButton>
                <Modal.Title>{modalItem.label}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  {type === itemTypes.PURCHASE && (
                    <Col xl={12} md={12} className={'space-between'}>
                      <div className={'pi-body-title  left'}>Subtotal</div>
                      <span>
                        {getCurrentSymbol()} {formatNumberWithCommas(getTotalModal())}
                      </span>
                    </Col>
                  )}
                  {type === itemTypes.SELL && (
                    <>
                      <Col xl={12} md={12} className={'space-between'}>
                        <div className={'pi-body-title  left'}>Subtotal</div>
                        <Money component="span">{modalItem.subTotal}</Money>
                      </Col>

                      <Col>
                        <TaxFree
                          index={0}
                          item={modalItem}
                          toSelect={modalItem}
                          taxFree={false}
                          updateTaxFree={(item, show) => {
                            updateTaxFree(item, show)
                          }}
                          updatePriceIVA={item => {
                            if (isEdit) item.edited = true
                            updatePriceIVA(item, true)
                          }}
                        />
                      </Col>
                    </>
                  )}

                  {modalItem.categories !== null && !modalItem.outs && (
                    <Col xl={12}>
                      <ListVariations
                        selected={modalItem.listVariations}
                        onClick={() => setMobileVariation(true)}
                      />
                    </Col>
                  )}
                  {modalItem.listLocations !== null &&
                    !modalItem.outs &&
                    haveLocations() && (
                      <Col xl={12}>
                        <ListVariations
                          selected={modalItem.listLocations}
                          hideMessage
                          groupName={'Ubicaciones'}
                        />
                      </Col>
                    )}
                  <Col xl={12}>
                    {loadingLineItems ? (
                      <Row>
                        <Col>
                          <div className={'pb-custom'}>
                            <ProgressBar
                              label="Cargando"
                              animated
                              now={100}
                              style={{ marginBottom: 10 }}
                            />
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      ((!modalItem.outs && listDetails.length === 0) ||
                        modalItem.noInventory) && (
                        <LineQuantites
                          line={modalItem.lineItems}
                          currentItem={modalItem}
                          modal
                          maxDiscount={maxDiscount}
                          disabled={disabled}
                          onChangeQuantity={item => {
                            if (isEdit) modalItem.edited = true
                            setModalItem({ ...item })
                            setSelected([...selected])
                          }}
                          onCheckSubtotal={value => {
                            setModalItem({ ...modalItem, individualTotal: value })
                          }}
                          onChangeSubTotal={itemLine => {
                            if (itemLine) {
                              let index = modalItem.lineItems.findIndex(
                                x => x.id === itemLine.id,
                              )
                              modalItem.lineItems[index] = itemLine
                            }
                            setModalItem({ ...modalItem })
                            // item.subTotal +=  itemLine.subTotal ? itemLine.subTotal : 0
                          }}
                          type={type}
                          currency={
                            importation
                              ? activateLocalCurrency
                                ? exchangeRate.symbol
                                : '$'
                              : currentCurrency.symbol
                          }
                          fieldEditPrice={fieldEditPrice}
                          conversion={conversion}
                          importation={importation}
                          haveLocations={
                            seeWarehouseLocations && seeWarehouseLocations.value !== '0'
                          }
                          //locations={modalItem.listLocations || []}
                        />
                      )
                    )}
                  </Col>
                  {(type === itemTypes.PURCHASE || type === itemTypes.EXPENSE) && (
                    <>
                      <Col xl={12} md={12}>
                        <CustomDate
                          style={{ position: 'relative' }}
                          label={'Fecha de Vencimiento'}
                          value={modalItem.expirationDate}
                          disabledDays={[]}
                          onDayChange={e =>
                            setModalItem({ ...modalItem, expirationDate: e })
                          }
                        />
                      </Col>
                    </>
                  )}
                  {(type === itemTypes.SELL ||
                    type === itemTypes.TRANSFER ||
                    type === itemTypes.WASTE) && (
                    <Col xl={12} md={12}>
                      {modalItem.outs &&
                        listDetails.map((item, i) => (
                          <>
                            {' '}
                            <hr />
                            <Row key={i}>
                              <Col xs={{ span: 10, offset: 1 }}>
                                <div className="space-between">
                                  <div>
                                    {item.listLocations &&
                                      haveLocations() &&
                                      getSimpleListVariation(
                                        item.listLocations,
                                        'Ubicacion',
                                        false,
                                      )}
                                    {getSimpleListVariation(
                                      item.listCategorization,
                                      'Variacion',
                                      item.listLocations,
                                      true,
                                    )}
                                  </div>
                                  <div style={{ marginTop: 5 }}>{item.quantity}</div>
                                </div>
                              </Col>
                            </Row>
                          </>
                        ))}
                    </Col>
                  )}
                  {type === itemTypes.SELL && (
                    <>
                      {savePrice && modalItem.activeUpdatable ? (
                        <Col xl={12} md={12}>
                          <CustomCreate
                            label={'Precio'}
                            value={
                              modalItem.selectedPrice || {
                                value: modalItem.productPrice,
                                label: modalItem.productPrice,
                              }
                            }
                            textLabel={`Agregar precio ${currentCurrency.symbol}.`}
                            disabled={modalItem.add}
                            placeholder={'Buscar o seleccionar'}
                            options={
                              modalItem.list !== undefined &&
                              modalItem?.list.map(l => ({
                                value: Number(l.price),
                                label: `${l.alias} - ${toMoney(l.price)}`,
                              }))
                            }
                            onCreateOption={value => {
                              if (!isNaN(value)) {
                                if (value < 0) value = 0

                                if (isEdit) modalItem.edited = true
                                modalItem.price = value
                                modalItem.productPrice = value
                                modalItem.selectedPrice = {
                                  value: Number(value),
                                  label: toMoney(value),
                                }
                                modalItem.save = true

                                if (modalItem.outs) {
                                  listDetails.forEach(x => {
                                    x.price = value
                                    x.productPrice = value
                                    x.selectedPrice = {
                                      value: Number(value),
                                      label: toMoney(value),
                                    }
                                    x.save = true
                                  })
                                  renderValuesLocations(value)
                                  setList([...listDetails])
                                }

                                if (!modalItem.includeIva) {
                                  updatePriceIVA(
                                    {
                                      ...modalItem,
                                      includeIva: !modalItem.includeIva,
                                      newIncludeIva: modalItem.includeIva,
                                    },
                                    true,
                                  )
                                } else {
                                  if (isNaN(modalItem.quantity)) modalItem.quantity = 0

                                  modalItem.quantity = Number(modalItem.quantity)
                                  if (modalItem.quantity < 0) modalItem.quantity = 0

                                  setModalItem({
                                    ...modalItem,
                                    quantity: modalItem.quantity,
                                  })

                                  //onChangeQuantity(modalItem.quantity, modalItem, true)
                                }
                              }
                            }}
                            onChange={value => {
                              if (value) {
                                let comp = `${value.value || 0}`
                                if (comp.match(/[0-9]+/g)) {
                                  if (value.value < 0)
                                    value = {
                                      value: Number(value.value),
                                      label: toMoney(value.value),
                                    }
                                  if (isEdit) modalItem.edited = true
                                  modalItem.price = value.value
                                  modalItem.productPrice = value.value
                                  modalItem.selectedPrice = value
                                  modalItem.save = false

                                  if (modalItem.outs) {
                                    listDetails.forEach(x => {
                                      x.price = value.value
                                      x.productPrice = value.value
                                      x.selectedPrice = value
                                      x.save = false
                                      x.subTotal = value.value * x.quantity
                                    })
                                    renderValuesLocations(value.value)
                                    setList([...listDetails])
                                  }

                                  if (!modalItem.includeIva) {
                                    updatePriceIVA(
                                      {
                                        ...modalItem,
                                        includeIva: !modalItem.includeIva,
                                        newIncludeIva: modalItem.includeIva,
                                      },
                                      true,
                                    )
                                  } else
                                    setModalItem({
                                      ...modalItem,
                                      quantity: modalItem.quantity,
                                    })
                                }
                              }
                            }}
                          />
                        </Col>
                      ) : (
                        multiplePrices &&
                        modalItem.list &&
                        modalItem.list.length > 0 && (
                          <Col xl={12} md={12}>
                            <Select
                              label={'Precio'}
                              value={
                                modalItem.selectedPrice || {
                                  value: Number(modalItem.productPrice),
                                  label: modalItem.productPrice,
                                }
                              }
                              options={modalItem.list.map(l => ({
                                value: Number(l.price),
                                label: `${l.alias} - ${toMoney(l.price)}`,
                              }))}
                              onChange={value => {
                                if (isEdit) modalItem.edited = true

                                modalItem.selectedPrice = value
                                if (modalItem.outs) {
                                  listDetails.forEach(x => {
                                    x.selectedPrice = value
                                    x.price = value.value
                                    x.subTotal = value.value * x.quantity
                                  })
                                  renderValuesLocations(value.value)
                                  setList([...listDetails])
                                }

                                if (isNaN(modalItem.quantity)) modalItem.quantity = 0

                                modalItem.quantity = Number(modalItem.quantity)
                                if (modalItem.quantity < 0) modalItem.quantity = 0

                                setModalItem({
                                  ...modalItem,
                                  quantity: modalItem.quantity,
                                })

                                //onChangeQuantity(modalItem.quantity, modalItem, true)
                              }}
                            />
                          </Col>
                        )
                      )}
                      {!isGeneral ? (
                        <Col xl={12} md={12} className={'space-between'}>
                          <div style={{ marginTop: 5, display: 'flex', width: '100%' }}>
                            <Switch
                              mt={0}
                              disabled={isGeneral}
                              id={'discountPercent'}
                              cStyle={{ alignSelf: 'flex-end' }}
                              name={'checkDiscount'}
                              checked={modalItem.percentDiscount}
                              onChange={e =>
                                percentDiscount(
                                  modalItem,
                                  { label: '0%', value: 0 },
                                  e.currentTarget.checked,
                                )
                              }
                              label={'%'}
                              topLabel
                            />
                            {modalItem.percentDiscount ? (
                              <Select
                                style={{ flex: 1 }}
                                value={modalItem.percent}
                                onChange={value =>
                                  percentDiscount(modalItem, value, true)
                                }
                                label={`Porcentaje de descuento Q.${formatNumberWithCommas(
                                  modalItem.discount || 0,
                                )}`}
                                options={discounts}
                                error={modalItem.error}
                              />
                            ) : (
                              <FormText
                                id={'discountMobile'}
                                name={'discountMobile'}
                                disabled={isGeneral}
                                label={`Descuento (${toMoney(modalItem.maxDiscount)})`}
                                type={'number'}
                                value={modalItem.discount}
                                onClick={e => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                }}
                                prepend={
                                  <Icon
                                    icon={faMinus}
                                    onClick={e => {
                                      e.preventDefault()
                                      e.stopPropagation()
                                      changeDiscount(
                                        (modalItem.discount
                                          ? parseInt(modalItem.discount)
                                          : 0) - 1,
                                        modalItem,
                                        true,
                                      )
                                    }}
                                  />
                                }
                                append={
                                  <Icon
                                    icon={faPlus}
                                    onClick={e => {
                                      e.preventDefault()
                                      e.stopPropagation()
                                      changeDiscount(
                                        (modalItem.discount
                                          ? parseInt(modalItem.discount)
                                          : 0) + 1,
                                        modalItem,
                                        true,
                                      )
                                    }}
                                  />
                                }
                                onChange={({ target }) =>
                                  changeDiscount(target.value, modalItem, true)
                                }
                                error={modalItem.error}
                              />
                            )}
                          </div>
                        </Col>
                      ) : (
                        <>
                          <label>Hay un descuento General activo</label>
                        </>
                      )}
                      {modalItem.id === 1 && (
                        <Col xl={12}>
                          <Select
                            label={'Bien o Servicio'}
                            info={
                              'La propiedad "Bien o Servicio" se usará para la factura electrónica'
                            }
                            required
                            value={felItemTypes.find(
                              ({ value }) => value === modalItem.felItemTypeId,
                            )}
                            options={felItemTypes}
                            onChange={felItemType => {
                              if (isEdit) modalItem.edited = true
                              modalItem.felItemTypeId = felItemType.value
                              setSelected([...selected])
                            }}
                          />
                        </Col>
                      )}
                    </>
                  )}
                  {(type === itemTypes.SELL || type === itemTypes.TRANSFER) && (
                    <Col xl={12} md={12}>
                      <FormText
                        label={'Comentario'}
                        value={modalItem.commentary}
                        prepend={<Icon icon={faComment} />}
                        onChange={({ target }) =>
                          setModalItem({
                            ...modalItem,
                            commentary: target.value,
                            edited: isEdit ? true : undefined,
                          })
                        }
                        as={'textarea'}
                        rows={5}
                        max={1000}
                      />
                    </Col>
                  )}
                  {type === itemTypes.PURCHASE &&
                    !importation &&
                    modalItem.decimals === null && (
                      <SerieItem
                        toSelect={modalItem}
                        changeItem={() => setModalItem({ ...modalItem })}
                        type={type}
                        importation={importation}
                        onAdd={() => {
                          if (modalItem.edit >= 0) selected[modalItem.edit] = modalItem
                          else selected.push(modalItem)
                          setModalItem({ ...modalItem })
                        }}
                      />
                    )}
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Row className={'container-buttons'}>
                  <Button
                    disabled={!modalItem.haveQuantities && !modalItem.outs}
                    color={'accent'}
                    onClick={() => {
                      if (modalItem.outs) {
                        let unitDiscount = modalItem.discount / modalItem.quantity
                        listDetails.forEach(x => {
                          x.discount = unitDiscount
                          x.subTotal = x.price * x.quantity - unitDiscount
                          x.subTotalWithoutDiscount = x.price * x.quantity
                          x.outs = true
                        })
                        setIndexChange(selected.length)
                        const params = {
                          warehouseId: warehouseId,
                          withZero: true,
                          withCategories: true,
                          withExistence: true,
                          type,
                          search,
                          list: modalItem.value,
                        }
                        dispatch(getItemsToSelectDetail(params))
                      } else {
                        const index = selected.findIndex(s => s.key === modalItem.key)
                        modalItem.show = false
                        if (index >= 0) {
                          selected[index] = modalItem
                          setSelected([...selected])
                        } else {
                          setSelected([
                            ...selected,
                            {
                              ...modalItem,
                            },
                          ])
                        }
                      }
                      setModalItem({})
                    }}>
                    Guardar
                  </Button>
                </Row>
              </Modal.Footer>
            </Modal>
            <ModalTaxFree
              show={showIVA}
              item={{}}
              onClose={() => setShowIVA(false)}
              taxFree={taxFree}
              updateTaxFree={updateTaxFree}
              updatePriceIVA={updatePriceIVA}
            />

            <ItemCRUD
              show={showCreateProduct}
              createdByTransaction={type}
              onHide={(created, itemCreated) => {
                setShowCreateProduct(false)

                if (created) {
                  setSelected([...selected])
                  addNewProductInTable(itemCreated.name)
                }
              }}
            />
          </div>
        )
      }}
    </Media>
  )
}
export default ItemsToSelect

export const itemTypes = {
  SELL: 1,
  WASTE: 2,
  PRODUCTION: 3,
  PURCHASE: 4,
  EXPENSE: 5,
  TRANSFER: 6,
}
