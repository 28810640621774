import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Modal, Row, Col } from 'react-bootstrap'
import { FormText, Select, Button, CustomDate, NumberField } from 'src/components'
import { faEdit, faSave, faWindowClose } from '@fortawesome/free-solid-svg-icons'

import { actionTypes, onCreateResolution } from 'src/actions/resolutions.actions'

import { selectCompanyCountry } from 'src/selectors/company.selector'

import { selectAllPOS } from 'src/selectors/restaurant.selector'

import { handlerError, handlerSuccess, hasErrors } from 'src/selectors/error.selector'
import { showAlert } from 'src/actions/alert.actions'
import { loadingSelector } from 'src/selectors/loading.selector'

import { haveAnyValue } from 'src/utils/utilitiesV2'
import { getFelDocumentType } from 'src/enums/felDocumentTypes'

import { isAllowed } from 'src/selectors/modules.selector'
import { correlativePermissions } from 'src/enums/permissions'
import { getDocumentType } from './CorrelativeFunctions'

interface IDataErrors {
  documentType?: string
  date?: string
  number?: string
  series?: string
  initial?: string
  final?: string
  rangeCount?: string
}

interface Props {
  show: boolean
  onClose: (update: boolean) => void
  correlative?: ICorrelative
}

const defaultOption = { value: null, label: 'Sin seleccionar' }

const CorrelativeDetail = ({ show, onClose, correlative = {} }: Props) => {
  const dispatch = useDispatch()

  const country = useSelector(selectCompanyCountry)
  const pos = useSelector(selectAllPOS)

  const loading: boolean = useSelector(state =>
    loadingSelector([actionTypes.ON_CREATE_RESOLUTION])(state),
  )
  const hasError = useSelector(state =>
    hasErrors([actionTypes.ON_CREATE_RESOLUTION])(state),
  )

  const canUpdate: boolean = useSelector(state =>
    isAllowed(state, [correlativePermissions.update]),
  )

  const documentTypes: IFelDocumentType[] = getFelDocumentType(country.id)

  const [data, setData] = useState<ICorrelative>({})
  const [errors, setErrors] = useState<IDataErrors>({})
  const [flag, setFlag] = useState<boolean>(false)

  const isUpdate: boolean = haveAnyValue(correlative?.id)
  const disabled: boolean = loading || (isUpdate && !canUpdate)

  useEffect(() => {
    if (!show) return
    setData(correlative)
  }, [show])

  useEffect(() => {
    if (loading) setFlag(true)
    else if (flag) {
      setFlag(false)
      const alert = hasError
        ? {
            ...handlerError(hasError.message),
          }
        : {
            ...handlerSuccess(),
            onConfirm: () => {
              onHide(true)
            },
          }
      dispatch(showAlert(alert))
    }
  }, [loading])

  const onHide = (update = false) => {
    onClose(update)
    setData({})
    setErrors({})
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { id, value } = e.currentTarget
    setData({ ...data, [id]: value })
  }

  const onValidate = () => {
    const error: IDataErrors = {}
    const genericMessageError = 'Es requerido'
    if (!haveAnyValue(data.documentType)) error.documentType = genericMessageError
    if (!haveAnyValue(data.expirationDate)) error.date = genericMessageError
    if (!haveAnyValue(data.number)) error.number = genericMessageError
    if (!haveAnyValue(data.series)) error.series = genericMessageError
    if (!haveAnyValue(data.rangeCount)) error.rangeCount = genericMessageError

    const haveInitial = haveAnyValue(data.initialNumber)
    const haveFinal = haveAnyValue(data.finalNumber)
    if (!haveInitial) error.initial = genericMessageError
    if (!haveFinal) error.final = genericMessageError
    if (haveFinal && haveFinal) {
      if (data.initialNumber >= data.finalNumber) {
        console.log(data)
        const numberError = 'El valor final debe ser mayor al valor inicial'
        error.initial = numberError
        error.final = numberError
      }
    }
    setErrors(error)
    if (Object.keys(error).length === 0) {
      const request: IResolutionRequest = {
        series: data.series,
        resolutionNumber: data.number,
        initialNumber: data.initialNumber,
        finalNumber: data.finalNumber,
        doctype: data.documentType,
        endDate: data.expirationDate,
        rangeCount: data.rangeCount,
        posId: data?.pos?.id,
      }

      dispatch(onCreateResolution(correlative.id, request))
    }
  }

  return (
    <div>
      <Modal show={show} centered size={'xl'} onHide={() => onHide()}>
        <Modal.Header closeButton>
          <Modal.Title>{isUpdate ? correlative.number : 'Crear correlativo'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={6} lg={6} mg={6} sm={12} xs={12}>
              <Select
                required
                disabled={disabled}
                label={'Tipo de documento'}
                options={documentTypes}
                value={getDocumentType(data.documentType)}
                onChange={({ value }) => setData({ ...data, documentType: value })}
                error={errors.documentType}
                testId={'cd-doc-type'}
              />
            </Col>
            <Col xl={6} lg={6} mg={6} sm={12} xs={12}>
              <CustomDate
                disabled={disabled}
                required
                label={'Fecha limite de emisión'}
                value={
                  haveAnyValue(data.expirationDate) ? new Date(data.expirationDate) : null
                }
                onDayChange={(date: Date) =>
                  setData({ ...data, expirationDate: date?.valueOf() })
                }
                rangePicker={{}}
                error={errors.date}
              />
            </Col>
            <Col xl={6} lg={6} mg={6} sm={12} xs={12}>
              <Select
                disabled={disabled}
                label={'Punto de venta o sucursal'}
                value={
                  data.pos ? { value: data.pos.id, label: data.pos.name } : defaultOption
                }
                options={[defaultOption, ...pos]}
                onChange={(value: IPOS) => setData({ ...data, pos: value })}
                testId={'cd-pos'}
              />
            </Col>
            <Col xl={6} lg={6} mg={6} sm={12} xs={12}>
              <FormText
                disabled={disabled}
                required
                label={'Código de Autorización de Impresión'}
                value={data.number}
                id={'number'}
                onChange={handleChange}
                error={errors.number}
              />
            </Col>
            <Col xl={6} lg={6} mg={6} sm={12} xs={12}>
              <FormText
                disabled={disabled}
                required
                label={'Código de Establecimiento'}
                value={data.series}
                id={'series'}
                type={'text'}
                onChange={handleChange}
                error={errors.series}
                data-testid={'cd-series'}
              />
            </Col>
            <Col xl={6} lg={6} mg={6} sm={12} xs={12}>
              <NumberField
                disabled={disabled}
                required
                label={'Identificador de Rango de Numeración'}
                value={data.rangeCount}
                id={'rangeCount'}
                onValueChange={rangeCount => setData({ ...data, rangeCount })}
                error={errors.rangeCount}
                data-testid={'cd-range-count'}
                min={0}
                decimals={0}
              />
            </Col>
            <Col xl={6} lg={6} mg={6} sm={12} xs={12}>
              <NumberField
                disabled={disabled}
                required
                label={'Inicio'}
                value={data.initialNumber}
                id={'initialNumber'}
                onValueChange={initialNumber => setData({ ...data, initialNumber })}
                error={errors.initial}
                data-testid={'cd-initial'}
                min={0}
                decimals={0}
              />
            </Col>
            <Col xl={6} lg={6} mg={6} sm={12} xs={12}>
              <NumberField
                disabled={disabled}
                required
                label={'Fin'}
                value={data.finalNumber}
                id={'finalNumber'}
                onValueChange={finalNumber => setData({ ...data, finalNumber })}
                error={errors.final}
                data-testid={'cd-final'}
                min={0}
                decimals={0}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              dataCy={'cd-button-close'}
              disabled={loading}
              color={'secondary'}
              icon={faWindowClose}
              onClick={() => onHide()}>
              Cerrar
            </Button>
            <Button
              dataCy={'cd-button-confirm'}
              disabled={disabled}
              loading={loading}
              icon={isUpdate ? faEdit : faSave}
              onClick={() => onValidate()}>
              {isUpdate ? 'Actualizar' : 'Crear'}
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
export default CorrelativeDetail
