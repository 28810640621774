import './Turns.scss'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'

import Alert from 'sweetalert-react'
import { Col, Modal, Row } from 'react-bootstrap'
import {
  Button,
  Card,
  Dropdown,
  FormText,
  Icon,
  Select,
  TableV2,
  Title,
  Switch,
  FAB,
  CustomDatePickerWithHour,
  SelectedDatesWithHour,
} from 'src/components'
import {
  faClock,
  faEllipsisV,
  faEnvelope,
  faInfoCircle,
  faMapMarker,
  faSdCard,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { faBuffer } from '@fortawesome/free-brands-svg-icons'
import * as moment from 'moment'

import {
  actionTypes as turnActions,
  createTurn,
  createTurnManually,
  finishTurn,
  getActiveTurn,
  getDetailsByMultipleTurn,
  getTurnPdf,
  getTurns,
  getTurnSegmentation,
  sendTurnDetails,
} from 'src/actions/turn.actions'
import {
  selectAllTurns,
  selectDetailsMultiple,
  selectTotalTurns,
  selectTurn,
  selectTurnSegmentation,
} from 'src/selectors/turns.selector'

import { getUsersChildrenByModule } from 'src/actions/modules.actions'
import {
  isAllowed,
  selectCurrentUserRoles,
  selectUsers,
} from 'src/selectors/modules.selector'

import { handlerError, handlerSuccess, hasErrors } from 'src/selectors/error.selector'
import { loadingSelector } from 'src/selectors/loading.selector'

import {
  formatDateFromMillis,
  formatHourFromMillis,
  formatNumberWithCommas,
} from 'src/utils/formatters'
import { selectCurrentCurrency } from 'src/selectors/currencies.selector'

import { toMoney, valEmail } from 'src/utils/utilities'

import TurnTicket from './TurnTicket'

import { ConfigNotificationFields } from 'src/content/Turns/Modal/ConfigNotificationFields'
import { selectActivePlans } from 'src/selectors/plans.selector'
import { onSetPrintData } from 'src/actions/restaurant.actions'

let interval

const Turns = ({ modal }) => {
  const dispatch = useDispatch()

  const roles = useSelector(selectCurrentUserRoles)

  const user = useSelector(state => ({
    ...state.user.currentUser,
    value: state.user.currentUser.id,
    label: state.user.currentUser.name,
  }))
  const users = useSelector(selectUsers)
  const currentCurrency = useSelector(selectCurrentCurrency)

  const turns = useSelector(selectAllTurns)
  const total = useSelector(selectTotalTurns)
  const turn = useSelector(selectTurn)
  const multipleDetails = useSelector(selectDetailsMultiple)
  const segmentation = useSelector(selectTurnSegmentation)

  const plan = useSelector(selectActivePlans)

  const loadingTurns = useSelector(state =>
    loadingSelector([turnActions.GET_TURNS])(state),
  )

  const loadingMultipleDetail = useSelector(state =>
    loadingSelector([turnActions.GET_MULTIPLE_TURN_DETAIL])(state),
  )

  const loadingCreate = useSelector(state =>
    loadingSelector([turnActions.CREATE_TURN])(state),
  )
  const hasErrorCreate = useSelector(state => hasErrors([turnActions.CREATE_TURN])(state))

  const loadingCreateManually = useSelector(state =>
    loadingSelector([turnActions.CREATE_TURN_MANUALLY])(state),
  )
  const hasErrorCreateManually = useSelector(state =>
    hasErrors([turnActions.CREATE_TURN_MANUALLY])(state),
  )

  const loadingFinish = useSelector(state =>
    loadingSelector([turnActions.FINISH_TURN])(state),
  )
  const hasErrorFinish = useSelector(state => hasErrors([turnActions.FINISH_TURN])(state))

  const loadingSendingDetails = useSelector(state =>
    loadingSelector([turnActions.SEND_TURN_DETAILS])(state),
  )
  const loadingDownloadingPdf = useSelector(state =>
    loadingSelector([turnActions.GET_TURN_PDF])(state),
  )

  const hasErrorSendingDetails = useSelector(state =>
    hasErrors([turnActions.SEND_TURN_DETAILS])(state),
  )
  const hasErrorDownloadingPdf = useSelector(state =>
    hasErrors([turnActions.GET_TURN_PDF])(state),
  )

  const loadingTurnSegmentation = useSelector(state =>
    loadingSelector([turnActions.GET_TURN_SEGMENTATION])(state),
  )
  const hasErrorTurnSegmentation = useSelector(state =>
    hasErrors([turnActions.GET_TURN_SEGMENTATION])(state),
  )

  /* Permissions */
  const canSeeAll = useSelector(state => isAllowed(state, [6601]))
  const canSeeOthers = useSelector(state => isAllowed(state, [6602]))
  const canRegister = useSelector(state => isAllowed(state, [6603]))
  const canRegisterOthers = useSelector(state => isAllowed(state, [6604]))
  const canRegisterManually = useSelector(state => isAllowed(state, [6605]))
  const canAssignNotification = useSelector(state => isAllowed(state, [6606]))
  const canRegisterMultipleTurns = useSelector(state => isAllowed(state, [6607]))

  const turnUsers = canRegisterOthers ? [user, ...users] : [user]

  const [actions, setActions] = useState({
    get: false,
    create: false,
    finish: false,
    email: false,
    pdf: false,
    turnId: null,
  })
  const [turnModal, setTurnModal] = useState({})
  const [errors, setErrors] = useState({})
  const [emailModal, setEmailModal] = useState({})
  const [, setChange] = useState([])
  const [filters, setFilters] = useState({
    search: '',
    skip: 0,
    size: 10,
    start: null,
    end: null,
    user: { value: null, label: 'Todos' },
    view: { value: null, label: 'Todos' },
  })
  const [textFilter, setTextFilter] = useState('')
  const [alert, setAlert] = useState({})
  const [turnData, setTurnData] = useState({})
  const [assigned, setAssigned] = useState([])

  const [showNotifications, setShowNotifications] = useState(false)
  const [showDetail, setShowDetail] = useState(false)

  useEffect(() => {
    dispatch(getUsersChildrenByModule(1000, true))
    interval = setInterval(() => {
      refresh()
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (!roles || roles.length === 0) return
    setTextFilter(JSON.stringify(filters))
  }, [roles])

  useEffect(() => {
    if (textFilter === '' || textFilter === JSON.stringify(filters)) return
    setTextFilter(JSON.stringify(filters))
  }, [filters])

  useEffect(() => {
    if (textFilter !== JSON.stringify(filters)) return
    setup()
  }, [textFilter])

  useEffect(() => {
    if (loadingCreate) setActions({ ...actions, create: true })
    else if (actions.create) {
      setActions({ ...actions, create: false })
      if (!hasErrorCreate) {
        setAlert({
          ...handlerSuccess('Registro creado'),
          onConfirm: () => setAlert({ show: false }),
        })
        dispatch(getActiveTurn())
        setup()
      }
    }
  }, [loadingCreate])

  useEffect(() => {
    if (loadingTurnSegmentation) setActions({ ...actions, segmentation: true })
    else if (actions.segmentation) {
      setActions({ ...actions, segmentation: false })
      if (hasErrorTurnSegmentation) {
        setAlert({
          ...handlerError(
            hasErrorTurnSegmentation.message ||
              'Error al obtener el reporte de segmentación',
          ),
          onConfirm: () => setAlert({ show: false }),
        })
      } else setTurnData({ ...turnData, segmentation, show: true })
    }
  }, [loadingTurnSegmentation])

  useEffect(() => {
    if (loadingCreateManually) setActions({ ...actions, create: true })
    else if (actions.create) {
      setActions({ ...actions, create: false })
      if (hasErrorCreateManually) {
        setAlert({
          ...handlerError(hasErrorCreateManually.message || 'Error al crear el registro'),
          onConfirm: () => setAlert({ show: false }),
        })
      } else {
        setAlert({
          ...handlerSuccess('Registro Manual creado'),
          onConfirm: () => setAlert({ show: false }),
        })
        dispatch(getActiveTurn())
        setup()
      }
    }
  }, [loadingCreateManually])

  useEffect(() => {
    if (loadingSendingDetails) setActions({ ...actions, email: true })
    else if (actions.email) {
      setActions({ ...actions, email: false })
      if (hasErrorSendingDetails) {
        setAlert({
          ...handlerError(
            hasErrorSendingDetails.message || 'Error al enviar el correo electrónico',
          ),
          onConfirm: () => setAlert({ show: false }),
        })
      } else {
        setAlert({
          ...handlerSuccess('Detalles del turno enviados exitosamente'),
          onConfirm: () => setAlert({ show: false }),
        })
      }
    }
  }, [loadingSendingDetails])

  useEffect(() => {
    if (loadingDownloadingPdf) setActions({ ...actions, email: true })
    else if (actions.email) {
      setActions({ ...actions, email: false })
      if (hasErrorDownloadingPdf) {
        setAlert({
          ...handlerError(
            hasErrorDownloadingPdf.message || 'Error al descargar los detalles del turno',
          ),
          onConfirm: () => setAlert({ show: false }),
        })
      } else {
        setAlert({
          ...handlerSuccess('Detalles del turno descargados exitosamente'),
          onConfirm: () => setAlert({ show: false }),
        })
      }
    }
  }, [loadingDownloadingPdf])

  useEffect(() => {
    if (loadingFinish) setActions({ ...actions, finish: true })
    else if (actions.finish) {
      setActions({ ...actions, finish: false })
      if (!hasErrorFinish) {
        dispatch(getActiveTurn())
        setup()
      }
    }
  }, [loadingFinish])

  useEffect(() => {
    if (!turnData.show) return
    const {
      created,
      finished,
      startDate,
      endDate,
      balanceStart,
      balanceEnd,
      creditStart,
      paymentsEnd,
      cashEnd,
      creditEnd,
      depositsEnd,
      expensesEnd,
      name,
      tip,
      totalSellQuantity,
      totalCommandsQuantity,
      creditNotes,
      vouchers,
      sentCashTransfers,
      receivedCashTransfers,
    } = turnData
    const data = [
      { type: 2, value: 2 },
      { type: 3, value: true },
      { type: 4, value: 1 },
      { type: 5, value: 'Turno Finalizado\n' },
      { type: 7 },
      { type: 4, value: 2 },
      { type: 5, value: `${name} ha finalizado su turno\n` },
      { type: 7 },
      { type: 3, value: false },
      { type: 11, value: `Iniciado Por:`, otherValue: created },
      { type: 11, value: `Finalizado Por:`, otherValue: finished },
      {
        type: 11,
        value: `Fecha inicio:`,
        otherValue: formatDateFromMillis(startDate),
      },
      {
        type: 11,
        value: `Fecha fin:`,
        otherValue: formatDateFromMillis(endDate),
      },
      {
        type: 11,
        value: `Efectivo Inicial:`,
        otherValue: toMoney(balanceStart),
      },
      {
        type: 11,
        value: `Efectivo Final:`,
        otherValue: toMoney(balanceEnd),
      },
      {
        type: 11,
        value: `Total de Ventas:`,
        otherValue: `${totalSellQuantity || 0}`,
      },
      {
        type: 11,
        value: `Total de Comandas:`,
        otherValue: `${totalCommandsQuantity || 0}`,
      },
      {
        type: 11,
        value: `V. en Efectivo:`,
        otherValue: toMoney(cashEnd),
      },
      {
        type: 11,
        value: `V. con Tarjeta:`,
        otherValue: toMoney(creditEnd),
      },
      { type: 11, value: `Propina:`, otherValue: toMoney(tip) },
      {
        type: 11,
        value: `V. al Crédito:`,
        otherValue: toMoney(creditStart),
      },
      {
        type: 11,
        value: `Pagos Recibidos:`,
        otherValue: toMoney(paymentsEnd),
      },
      {
        type: 11,
        value: `Depósitos:`,
        otherValue: toMoney(depositsEnd),
      },
      {
        type: 11,
        value: `Gastos:`,
        otherValue: toMoney(expensesEnd),
      },
      {
        type: 11,
        value: `Notas de crédito:`,
        otherValue: toMoney(creditNotes),
      },
      {
        type: 11,
        value: `Vales:`,
        otherValue: toMoney(vouchers),
      },
      {
        type: 11,
        value: `Efectivo env:`,
        otherValue: toMoney(sentCashTransfers),
      },
      {
        type: 11,
        value: `Efectivo rec:`,
        otherValue: toMoney(receivedCashTransfers),
      },
      { type: 2, value: 2 },
      { type: 3, value: true },
    ]

    if (segmentation && segmentation.length > 0) {
      data.push(
        { type: 5, value: 'Ventas del Turno:\n' },
        { type: 2, value: 1 },
        { type: 3, value: false },
      )
      segmentation.forEach(s => data.push(...formatSegmentation(s)))
    }

    dispatch(
      onSetPrintData({
        show: true,
        element: 'ticket_turn',
        customData: data,
        onHide: () => setTurnData({}),
      }),
    )
  }, [turnData.show])

  const formatSegmentation = (segmentation, indent = 0) => {
    const data = []
    const { name, total, subSegmentation, items } = segmentation
    data.push(
      {
        type: 3,
        value: true,
      },
      {
        type: 5,
        value: `${' '.repeat(indent)}${name}: ${toMoney(total)}\n`,
      },
      {
        type: 3,
        value: false,
      },
    )
    if (subSegmentation && subSegmentation.length > 0) {
      subSegmentation.forEach(child => {
        data.push(...formatSegmentation(child, indent + 4))
      })
    } else {
      items?.forEach(item => {
        data.push({
          type: 5,
          value: `${' '.repeat(indent + 4)}${item.quantity} ${item.name}: ${toMoney(
            item.total,
          )}\n`,
        })
      })
    }
    return data
  }

  const refresh = () => {
    setChange([{ asd: true }])
  }

  const setup = () => {
    dispatch(
      getTurns({
        userId: canSeeOthers ? filters.user?.value : user.id,
        skip: filters.skip,
        size: filters.size,
        search: filters.search,
        start: filters.start,
        end: filters.end,
      }),
    )
  }

  const getUsers = item => {
    let encargados = ''
    item.users.map(p => (encargados += `${p.name}<br/>`))
    return { __html: encargados }
  }

  const headers = [
    {
      label: 'Código',
      show: true,
      value: ['code'],
      type: 'text',
    },
    {
      label: 'Usuario',
      show: canSeeOthers,
      value: ['name'],
      type: 'text',
      custom: item =>
        item.type === 2 ? (
          <label
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '0px',
            }}>
            {item.name}
            <Icon
              style={{ alignItems: 'center', display: 'flex', marginLeft: '4px' }}
              tooltip={<div dangerouslySetInnerHTML={getUsers(item)} />}
              icon={faInfoCircle}
              size={'0.25x'}
            />
          </label>
        ) : (
          item.name
        ),
    },
    {
      label: 'Tipo',
      show: true,
      value: ['typeC'],
      type: 'text',
      custom: item => (item.typeC = item.type === 1 ? 'Individual' : 'Multiple'),
    },
    { label: 'Fecha Inicio', show: true, value: ['startDate'], type: 'date' },
    { label: 'Fecha Fin', show: true, value: ['endDate'], type: 'date' },
    {
      label: 'Tiempo transcurrido',
      show: true,
      value: ['time'],
      type: 'number',
      custom: item => formatHourFromMillis(getTime(item), true),
    },
    {
      label: 'Iniciado por',
      show: true,
      value: ['created'],
      type: 'text',
    },
    {
      label: 'Finalizado por',
      show: true,
      value: ['finished'],
      type: 'text',
    },
    {
      label: `Efectivo circulante inicial (${currentCurrency.symbol})`,
      show: plan?.id !== 9,
      value: ['balanceStart'],
      type: 'currency',
    },
    {
      label: `Efectivo circulante final (${currentCurrency.symbol})`,
      show: plan?.id !== 9,
      value: ['balanceEnd'],
      type: 'currency',
    },
    {
      label: `Cantidad de ventas`,
      show: true,
      value: ['totalSellQuantity'],
      type: 'wholeNumber',
    },
    {
      label: `Cantidad de comandas`,
      show: true,
      value: ['totalCommandsQuantity'],
      type: 'wholeNumber',
    },
    {
      label: 'Ventas al crédito',
      show: plan?.id !== 9,
      value: ['creditStart'],
      type: 'currency',
    },
    {
      label: `Pagos recibidos (${currentCurrency.symbol})`,
      show: plan?.id !== 9,
      value: ['paymentsEnd'],
      type: 'currency',
    },
    {
      label: `Ventas en efectivo (${currentCurrency.symbol})`,
      show: true,
      value: ['cashEnd'],
      type: 'currency',
    },
    {
      label: `Ventas con tarjeta (${currentCurrency.symbol})`,
      show: true,
      value: ['creditEnd'],
      type: 'currency',
    },
    {
      label: `Propina (${currentCurrency.symbol})`,
      show: true,
      value: ['tip'],
      type: 'currency',
    },
    {
      label: `Depósitos (${currentCurrency.symbol})`,
      show: plan?.id !== 9,
      value: ['depositsEnd'],
      type: 'currency',
    },
    {
      label: `Gastos (${currentCurrency.symbol})`,
      show: true,
      value: ['expensesEnd'],
      type: 'currency',
    },
    {
      label: `Notas de crédito (${currentCurrency.symbol})`,
      show: true,
      value: ['creditNotes'],
      type: 'currency',
    },
    {
      label: `Vales (${currentCurrency.symbol})`,
      show: true,
      value: ['vouchers'],
      type: 'currency',
    },
    {
      label: 'Efectivo enviado',
      className: 'medium',
      show: true,
      value: ['sentCashTransfers'],
      type: 'text',
    },
    {
      label: 'Efectivo recibido',
      className: 'medium',
      show: true,
      value: ['receivedCashTransfers'],
      type: 'text',
    },
    {
      config: true,
      show: canSeeAll,
      label: '',
      selected: true,
      className: 'mini center',
      custom: item => getActions(item),
    },
  ]

  const getActions = item => {
    return (
      <Dropdown
        loading={
          (loadingFinish ||
            loadingDownloadingPdf ||
            loadingSendingDetails ||
            loadingTurnSegmentation) &&
          actions.turnId === item.id
        }
        disabled={
          (loadingFinish || loadingDownloadingPdf || loadingSendingDetails) &&
          actions.turnId !== item.id
        }
        items={[
          {
            show: !item.endDate,
            title: 'Finalizar',
            action: () => {
              setActions({ ...actions, turnId: item.id })
              dispatch(finishTurn(item.id, item.userId))
            },
          },
          {
            show: Boolean(item.endDate),
            title: 'Descargar PDF',
            action: () => {
              dispatch(getTurnPdf(item.id))
              setActions({ ...actions, turnId: item.id })
            },
          },
          {
            show: Boolean(item.endDate),
            title: 'Enviar al correo',
            action: () => {
              setActions({ ...actions, turnId: item.id })
              setEmailModal({ ...emailModal, item, show: true })
            },
          },
          {
            show: Boolean(item.endDate),
            title: 'Imprimir reporte',
            action: () => {
              setTurnData({ ...item, show: false })
              dispatch(getTurnSegmentation(item.id))
            },
          },
          {
            show: Boolean(item.endDate && item.type === 2), // item.type === "multiple"
            title: 'Ver detalle',
            action: () => {
              dispatch(getDetailsByMultipleTurn(item.id))
              setShowDetail(true)
            },
          },
        ]}
      />
    )
  }

  const getTime = item => {
    return (item.endDate ? item.endDate : moment.utc().valueOf()) - item.startDate
  }

  const element = (
    <div>
      <SelectedDatesWithHour
        show
        start={filters.start}
        end={filters.end}
        onDateChange={(start, end) => setFilters({ ...filters, start, end })}
        onApplyFilters={(start, end) => setFilters({ ...filters, start, end })}
      />

      {turn.id && (
        <Card title={`Turno activo ${turn.code}`}>
          <Row
            style={{
              'margin-top': '10px',
            }}>
            <Col
              sm={12}
              md={12}
              lg={2}
              xl={2}
              className={'d-flex'}
              style={{ alignItems: 'center' }}>
              <div className={'column d-flex'} style={{ alignSelf: 'center' }}>
                <Icon icon={faClock} size={'2x'} className={'mr-2 mb-0'} />
                <h6 className={'mb-0'} style={{ alignSelf: 'center', color: 'black' }}>
                  {formatHourFromMillis(getTime(turn), true)}
                </h6>
              </div>
            </Col>
            <Col
              style={{ width: '45%', alignItems: 'center' }}
              className="text-center d-flex">
              <h6 style={{ color: 'black' }} className={'mr-2 mb-0'}>
                Fecha de inicio:
              </h6>
              <h6 className={'mb-0'}>{formatDateFromMillis(turn.startDate)}</h6>
            </Col>
            <Col
              style={{ width: '45%', alignItems: 'center' }}
              className={'text-center d-flex'}>
              <h6 style={{ color: 'black' }} className={'mr-2 mb-0'}>
                Efectivo inicial:
              </h6>
              <h6 className={'mb-0'}>{formatNumberWithCommas(turn.balanceStart)}</h6>
            </Col>
            <Col
              style={{ width: '45%', justifyContent: 'flex-end' }}
              className={'text-center d-flex'}>
              <Button
                loading={loadingFinish}
                color={'secondary'}
                onClick={() => {
                  dispatch(finishTurn(turn.id, user.id))
                }}>
                Finalizar
              </Button>
            </Col>
          </Row>
        </Card>
      )}

      <Card
        bodyClass="turn-card-body"
        title={'Registros de turnos'}
        noItemsLegend={`No hay registros con los filtros aplicados`}
        white>
        <TableV2
          loading={loadingTurns}
          headers={headers}
          total={total}
          items={turns}
          handleChange={search =>
            setTimeout(() => {
              setFilters({ ...filters, search })
            }, 1000)
          }
          customFilter={
            canSeeOthers && (
              <div>
                <Row>
                  <Col className={'borderRight mb-1'}>
                    <Row>
                      <Col xl={4} md={6} sm={6} xs={12}>
                        <Select
                          label={'Por usuario'}
                          value={filters.user}
                          info={'Filtra los registros por usuario'}
                          options={[{ value: null, label: 'Todos' }, ...users]}
                          onChange={value => setFilters({ ...filters, user: value })}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            )
          }
          storageKey={`turns-${user.id}`}
          getPagination={(skip, size, search) =>
            setFilters({ ...filters, skip, size, search })
          }
          stopCicle={true}
        />
      </Card>

      <Modal
        show={turnModal.show}
        centered
        size={'md'}
        onHide={() => {
          setTurnModal({ show: false })
          setAssigned([])
        }}>
        <Modal.Header closeButton>
          <Modal.Title>
            {turnModal.multiple ? 'Turno multiple' : 'Registrar turno manualmente'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12}>
              <Select
                options={turnUsers}
                value={turnModal.user}
                placeholder={'Seleccione un usuario'}
                onChange={user => {
                  if (turnModal.multiple) {
                    setAssigned(assigned.filter(x => x.value !== user.value))
                  }
                  setTurnModal({ ...turnModal, user })
                }}
                label={'Usuario Principal'}
              />
            </Col>

            {canRegisterManually && (
              <Col xl={12}>
                <Switch
                  mt={1}
                  topLabel
                  label={'Ingresar fecha y hora de inicio'}
                  checked={turnModal.setStartDate}
                  onChange={({ target }) => {
                    const { checked } = target
                    const newTurnModal = {
                      ...turnModal,
                      setStartDate: checked,
                    }
                    if (checked) newTurnModal.startDate = Date.now()
                    else newTurnModal.setEndDate = null

                    setTurnModal(newTurnModal)
                  }}
                />
              </Col>
            )}

            {turnModal.setStartDate && (
              <Col xl={12}>
                <CustomDatePickerWithHour
                  mt={1}
                  value={turnModal.startDate}
                  onChange={value => {
                    setTurnModal({
                      ...turnModal,
                      startDate: value,
                    })
                  }}
                />
              </Col>
            )}

            {canRegisterManually && turnModal.setStartDate && (
              <Col xl={12}>
                <Switch
                  mt={1}
                  topLabel
                  label={'Ingresar fecha y hora de finalización'}
                  checked={turnModal.setEndDate}
                  onChange={({ target }) => {
                    const { checked } = target
                    const newTurnModal = {
                      ...turnModal,
                      setEndDate: checked,
                    }
                    if (checked) newTurnModal.endDate = turnModal.startDate + 3600000
                    else newTurnModal.setEndDate = null

                    setTurnModal(newTurnModal)
                  }}
                />
              </Col>
            )}

            {turnModal.setEndDate && (
              <Col xl={12}>
                <CustomDatePickerWithHour
                  mt={1}
                  value={turnModal.endDate}
                  disabled={!turnModal.startDate}
                  onChange={value => {
                    setTurnModal({
                      ...turnModal,
                      endDate: value,
                    })
                  }}
                />
              </Col>
            )}

            {turnModal.multiple && (
              <>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Select
                    label={'Asignados'}
                    value={{ value: null, label: 'Usuarios asignados' }}
                    options={turnUsers.filter(
                      s =>
                        assigned.filter(f => f.value === s.value).length === 0 &&
                        s.id !== turnModal.user.id,
                    )}
                    error={errors.assigned}
                    onChange={selectedUser => {
                      assigned.push(selectedUser)
                      setAssigned([...assigned])
                      setErrors({ ...errors, assigned: '' })
                    }}
                  />
                </Col>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Row className={'pl-1 mt-2'}>
                    {assigned?.map((p, i) => (
                      <div className={'user-tag  ml-2'} key={i}>
                        <label className={'label-user-tag'}>{p.label}</label>

                        <Icon
                          className={'delete-user-tag'}
                          icon={faTimes}
                          tooltip={'Quitar'}
                          color={'white'}
                          onClick={() =>
                            setAssigned([...assigned.filter(d => p.id !== d.value)])
                          }
                        />
                      </div>
                    ))}
                  </Row>
                </Col>
              </>
            )}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={
              !turnModal.user ||
              (turnModal.setStartDate && !turnModal.startDate) ||
              (turnModal.setEndDate && !turnModal.endDate) ||
              (turnModal.setStartDate && turnModal.startDate > Date.now()) ||
              (turnModal.setEndDate && turnModal.startDate >= turnModal.endDate)
            }
            onClick={() => {
              if (turnModal.multiple && assigned.length === 0) {
                setErrors({ ...errors, assigned: 'Es requerido' })
                return
              }

              const request = {
                userId: turnModal.user.value,
                startDate: turnModal.setStartDate && turnModal.startDate,
                endDate: turnModal.setEndDate && turnModal.endDate,
                users: assigned?.map(d => d.id),
                type: turnModal.multiple ? 2 : 1,
              }

              dispatch(createTurnManually(request))

              setTurnModal({ show: false })
            }}>
            Registrar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={emailModal.show}
        centered
        size={'md'}
        onHide={() => setEmailModal({ ...emailModal, show: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Enviar al siguiente email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormText
            value={emailModal.email}
            placeholder={'Ingrese un email'}
            onChange={ev => setEmailModal({ ...emailModal, email: ev.target.value })}
            error={
              emailModal.email && !valEmail(emailModal.email) && 'El correo no es válido'
            }
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={!emailModal.email}
            onClick={() => {
              setActions({ ...actions, turnId: emailModal.item.id })
              dispatch(sendTurnDetails(emailModal.item.id, emailModal.email))
              setEmailModal({ ...emailModal, show: false })
            }}>
            Enviar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDetail} centered size={'xl'} onHide={() => setShowDetail(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Listado de turnos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TableV2
            loading={loadingMultipleDetail}
            headers={headers}
            items={multipleDetails}
            storageKey={`turns-${user.id}`}
          />
        </Modal.Body>
      </Modal>

      <FAB
        show={canRegister || canRegisterOthers || canRegisterManually}
        items={[
          {
            style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
            text: turn.id ? 'Finalizar turno' : 'Iniciar turno',
            icon: faSdCard,
            action: () => {
              if (!turn.id) dispatch(createTurn(user.id))
              else dispatch(finishTurn(turn.id, {}))
            },
            show: canRegister,
          },
          {
            style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
            text: 'Registrar turno manualmente',
            icon: faMapMarker,
            action: () => setTurnModal({ show: true, user, multiple: false }),
            show: canRegisterOthers || canRegisterManually,
          },
          {
            style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
            text: 'Configurar notificaciones',
            icon: faEnvelope,
            action: () => setShowNotifications(true),
            show: canAssignNotification,
          },
          {
            style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
            text: 'Registrar turno multiple',
            icon: faBuffer,
            action: () => {
              setTurnModal({ show: true, user, multiple: true })
              setAssigned([])
            },
            show: canRegisterMultipleTurns,
          },
        ]}
        icon={faEllipsisV}
      />

      <Alert {...alert} />

      <TurnTicket {...turnData} />

      <ConfigNotificationFields
        onHide={() => setShowNotifications(false)}
        show={showNotifications}
      />
    </div>
  )

  return modal ? (
    <Modal>
      <Modal.Header>
        <Modal.Title>Turnos</Modal.Title>
      </Modal.Header>
      <Modal.Body>{element}</Modal.Body>
    </Modal>
  ) : (
    <>
      <Title title={'Turnos'} />
      {element}
    </>
  )
}

export default Turns
