// @ts-nocheck
import React from 'react'
import { useSelector } from 'react-redux'

import { style } from 'src/components/Print/TicketStyles'
import { selectInvoicePrintInstructions } from 'src/selectors/invoice.selector'
import { haveAnyValue } from 'src/utils/utilitiesV2'

interface IHTMLBuilderProps {
  id: string
  logo?: string
}

const HTMLBuilder = ({ id = 'ticket-html-invoice', logo }: IHTMLBuilderProps) => {
  const instructions: IPrintInstruction[] = useSelector(selectInvoicePrintInstructions)

  const { cursive, title, subtitle, text, right, dFlex, body, spaceBetween, bold } = style

  const itemsOfInvoice = (items: PrintInstructionTableItem[]): React.JSX.Element => {
    return (
      <div style={{ width: '100%' }}>
        {/* HEADERS */}

        <div style={{ ...dFlex, ...bold, ...text }}>
          <div style={{ width: '11%' }}>Cant</div>
          <div style={{ width: '49%' }}>Descripción</div>
          <div style={{ width: '20%', ...right }}>Precio</div>
          <div style={{ width: '20%', ...right }}>Monto</div>
        </div>

        {/* ITEMS */}

        {items.map((item: IPrintInstructionTableItem, index) => (
          <div style={{ ...dFlex, ...text }} key={index}>
            <div style={{ width: '11%' }}>{item.quantity}</div>
            <div style={{ width: '49%' }}>
              {item.description}
              {haveAnyValue(item.commentary) && (
                <div style={{ ...cursive }}>{item.commentary}</div>
              )}
            </div>
            <div style={{ width: '20%', ...right }}>{item.price}</div>
            <div style={{ width: '20%', ...right }}>{item.amount}</div>
          </div>
        ))}
      </div>
    )
  }

  return (
    <div style={{ display: 'none' }}>
      <div id={id} style={{ paddingRight: 50, width: '100%', ...body }}>
        <div style={{ ...body }}>
          {instructions?.map((instruction, index) => {
            switch (instruction.type) {
              case 'title':
                return (
                  <div
                    key={index}
                    style={{
                      textAlign: instruction.align,
                      ...title,
                      ...{ bold: instruction.bold ? bold : {} },
                    }}>
                    {instruction.value}
                  </div>
                )
              case 'sub_title':
                return (
                  <div
                    key={index}
                    style={{
                      textAlign: instruction.align,
                      ...subtitle,
                      ...{ bold: instruction.bold ? bold : {} },
                    }}>
                    {instruction.value}
                  </div>
                )
              case 'text': {
                if (instruction.align === 'space_between')
                  return (
                    <div
                      key={index}
                      style={{
                        ...spaceBetween,
                      }}>
                      <div style={{ ...text }}>{instruction.value}</div>
                      <div style={{ ...text }}>{instruction.secondValue}</div>
                    </div>
                  )
                return (
                  <div
                    key={index}
                    style={{
                      textAlign: instruction.align,
                      ...text,
                      ...{ bold: instruction.bold ? bold : {} },
                    }}>
                    {instruction.value}
                  </div>
                )
              }

              case 'separator':
                return (
                  <div
                    key={index}
                    style={{
                      textAlign: instruction.align,
                      width: '100%',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      padding: '2px',
                    }}>
                    {Array(100).fill('').join('')}
                  </div>
                )
              case 'table':
                return itemsOfInvoice(instruction.items)
              default:
                return <></>
            }
          })}
        </div>
      </div>

      <div style={{ display: 'none' }}>
        <canvas id={'invoice-canvas'} width={300} height={275} />
        {logo && (
          <img
            id={'invoice-ticket-logo'}
            alt={'logo'}
            src={`data:image/bmp;base64,${logo}`}
          />
        )}
      </div>
    </div>
  )
}
export default HTMLBuilder
