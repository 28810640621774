import './ManufacturePOS.scss'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Alert from 'sweetalert-react'
import { w3cwebsocket as W3CWebSocket } from 'websocket'
import { Col, ProgressBar, Row } from 'react-bootstrap'
import { CustomFilter, Empty, Icon } from 'src/components'
import CommandTicket from 'src/components/Print/commandTicket'
import { faCheckCircle, faPrint, faWindowClose } from '@fortawesome/free-solid-svg-icons'

import { WSS_URL } from 'src/settings/app.settings'

import {
  acceptTransfer,
  actionTypes as typeW,
  rejectTransfer,
} from 'src/actions/warehouse.actions'

import { selectAllTransfers } from 'src/selectors/warehouse.selector'

import { selectCurrentCompany, selectCurrentUser } from 'src/selectors/user.selector'
import { loadingSelector } from 'src/selectors/loading.selector'

import { handlerError, hasErrors } from 'src/selectors/error.selector'
import { formatDateFromMillis, formatHourFromMillis } from 'src/utils/formatters'
import { colorArray } from '../../Reports/options'
import { selectSetCurrentCompany } from 'src/selectors/company.selector'

import { printCommandTicket } from 'src/components/Printer/TicketEpson'
import {
  getPosTransfersTransformations,
  onSetPrintData,
} from 'src/actions/restaurant.actions'
import { selectAllPOSUser, selectGetTableOrder } from 'src/selectors/restaurant.selector'
import { setGeneralData } from 'src/actions/printer.actions'

let client

const ManufacturePOS = ({
  tableOrderId,
  onRefresh,
  tableId,
  posType,
  posName,
  description,
  footerPhrase,
  logo,
}) => {
  const dispatch = useDispatch()

  const companyId = useSelector(selectCurrentCompany)
  const company = useSelector(selectSetCurrentCompany)
  const user = useSelector(selectCurrentUser)
  const posUser = useSelector(selectAllPOSUser)
  const tableOrder = useSelector(selectGetTableOrder)

  const pos = posUser.find(pos => pos.id === tableOrder.posId)

  const prints = useSelector(state => state.printers.all)

  const loadingT = useSelector(state => loadingSelector([typeW.GET_TRANSFERS])(state))
  const items = useSelector(selectAllTransfers)

  const loadingR = useSelector(state => loadingSelector([typeW.REJECT_TRANSFER])(state))
  const hasErrorR = useSelector(state => hasErrors([typeW.REJECT_TRANSFER])(state))

  const loadingA = useSelector(state => loadingSelector([typeW.ACCEPT_TRANSFER])(state))
  const hasErrorA = useSelector(state => hasErrors([typeW.ACCEPT_TRANSFER])(state))

  const [flags, setFlags] = useState({
    get: false,
    update: false,
    approve: false,
    reject: false,
  })
  const [action, setAction] = useState({})

  const [alert, setAlert] = useState({ title: '' })
  const [refreshCount, setRefreshCount] = useState(0)
  const [current, setCurrent] = useState(0)
  const [filters] = useState({
    warehouse: { value: null, label: '- Todas -' },
    state: { value: 1, label: 'Nueva' },
    search: '',
    skip: null,
    size: null,
    recolect: false,
    user: { value: null, label: '-- Todos --' },
    start: new Date().setHours(0, 0, 0, 0).valueOf(),
    end: new Date().setHours(23, 59, 59, 59).valueOf(),
  })
  const [search, setSearch] = useState(null)

  useEffect(() => {
    setTimeout(() => {
      setRefreshCount(new Date().valueOf())
    }, 1000)
  }, [refreshCount])

  useEffect(() => {
    setTimeout(
      () => {
        webSocket()
        setCurrent(new Date().valueOf())
      },
      current === 0 ? 10 : 30000,
    )
  }, [current])

  useEffect(() => {
    if (loadingT) setFlags({ ...flags, get: true })
    else if (flags.get) {
      setFlags({ ...flags, get: false, update: false })
    }
  }, [loadingT])

  const webSocket = () => {
    if (client && client.close) {
      client.close()
    }
    client = new W3CWebSocket(`${WSS_URL}/report/${companyId + 100002}`)
    client.onopen = () => undefined
    client.onmessage = () => {
      setFlags({ ...flags, update: true })
    }
    client.onclose = () => undefined
  }

  useEffect(() => {
    if (!flags.update) return
    setUp()
  }, [flags.update])

  useEffect(() => {
    if (loadingA) setFlags({ ...flags, approve: true })
    else if (flags.approve) {
      setFlags({ ...flags, approve: false })
      if (hasErrorA)
        setAlert({
          ...handlerError(hasErrorA.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else {
        if (onRefresh) onRefresh()
        webSocket()
      }
    }
  }, [loadingA])

  useEffect(() => {
    if (loadingR) setFlags({ ...flags, reject: true })
    else if (flags.reject) {
      setFlags({ ...flags, reject: false })
      if (hasErrorR)
        setAlert({
          ...handlerError(hasErrorR.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else {
        if (onRefresh) onRefresh()
        webSocket()
      }
    }
  }, [loadingR])

  const setUp = () => {
    setFlags({ ...flags, update: false })
    dispatch(
      getPosTransfersTransformations(
        tableOrderId !== undefined ? null : filters.start,
        tableOrderId !== undefined ? null : filters.end,
        filters.skip,
        filters.size,
        false,
        false,
        tableOrderId !== undefined ? null : filters.state.value,
        filters.search,
        filters.warehouse.value,
        false,
        true,
        filters.user.value,
        16,
        true,
        1,
        tableOrderId,
      ),
    )
  }

  const filterSearch = item => {
    if (!search || search === '') return true
    const obj = {
      number: item.number,
      reference: item.reference,
      details: item.details
        ? item.details.map(id => ({
            name: id.productName,
            code: id.productCode,
          }))
        : [],
    }
    const exp = new RegExp(search.toUpperCase(), 'g')
    return Object.keys(obj).some(p =>
      `${JSON.stringify(obj[p])}`.toUpperCase().match(exp),
    )
  }

  const getItems = () => {
    return items.filter(filterSearch).sort((a, b) => a.id - b.id)
  }

  const getItemsByTicket = items => {
    const response = []
    if (items) {
      response.push(
        ...items.map(i => {
          const item = {
            name: i.productName,
            quantity: i.quantity,
            supplies: [],
            groupValue: i.groupValue,
            commentary: i.commentary,
            tag: i.tag,
          }

          if (i.description !== undefined && i.description !== '') {
            i.description
              ?.split(',')
              .filter(descSplit => descSplit && descSplit.trim().length !== 0)
              .forEach(descSplit => {
                const subItem = descSplit.trim()
                const values = subItem.split(' ')
                const subQuantity = Number(values[0])
                const subName = subItem.slice(values[0].length, subItem.length)
                item.supplies.push({ quantity: subQuantity, name: subName })
              })
          }

          return item
        }),
      )
    }
    return response
  }

  return (
    <div className="pos-manufacture">
      {/* <Title title={'Comandas'} /> */}

      <CustomFilter
        customFilter={<div />}
        search={search}
        onChange={({ target }) => {
          const { value } = target
          setSearch(value)
        }}
        placeholder={'# ticket / terminal / ítem / sku'}
      />
      {loadingT && (
        <div className={'pb-custom'}>
          <ProgressBar label="Cargando" animated now={100} style={{ marginBottom: 10 }} />
        </div>
      )}

      {getItems().length <= 0 ? (
        <Empty
          loading={loadingT}
          onClick={() => {
            webSocket()
          }}
        />
      ) : (
        <Row className="pos-manufacture-items">
          {getItems().map((item, index) => {
            const itemsByTicket = getItemsByTicket(item.details)

            return (
              <Col key={index} xl={3} lg={4} md={4} sm={6} xs={12}>
                <div className={'manufacture-container'}>
                  <div
                    className={'manufacture-head'}
                    style={{ backgroundColor: colorArray[index] }}>
                    <div className={'column'}>
                      <div className={'b-user-name white space-between'}>
                        <div>#{item.number}</div>
                        <div>{item.reference}</div>
                      </div>
                      <div className={'b-user-email white space-between'}>
                        <div>{formatDateFromMillis(item.createdAt)}</div>
                        <div>
                          {item.statusId === 1
                            ? formatHourFromMillis(
                                new Date().valueOf() - item.createdAt,
                                true,
                              )
                            : formatHourFromMillis(
                                item.reviewedAt - item.createdAt,
                                true,
                              )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={'manufacture-body'}>
                    {item.details &&
                      item.details.map((id, i) => (
                        <div key={i} className={'b-item d-flex b-bottom'}>
                          <div className={'b-user-name mr-2'}>{id.quantity}</div>
                          <div className={'column'}>
                            <div className={'b-user-name'}>{id.productName}</div>
                            <div className={'b-user-email'}>{id.commentary}</div>
                            {id.description && (
                              <div className={'column'}>
                                {id.description.split(',').map((desc, i) => (
                                  <div className={'b-user-email'} key={i}>
                                    {desc}
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>

                          <CommandTicket
                            keyId={item.id}
                            correlative={item.number}
                            pos={pos}
                            userName={user.name}
                            date={formatDateFromMillis(item.createdAt)}
                            title={item.reference}
                            items={itemsByTicket}
                          />
                        </div>
                      ))}
                  </div>
                  <div className={'manufacture-footer'}>
                    <Row className={'container-buttons'}>
                      {item.stateId === 1 ? (
                        <>
                          <Icon
                            spin={loadingR && action.id === item.id}
                            disabled={loadingA || (loadingR && action.id !== item.id)}
                            onClick={() => {
                              setAction({ type: 1, id: item.id })
                              dispatch(rejectTransfer(item.id))
                            }}
                            icon={faWindowClose}
                            size={'2x'}
                            style={{ marginRight: 15, color: 'red' }}
                            tooltip={'Rechazar'}
                          />
                          <Icon
                            spin={loadingA && action.id === item.id}
                            disabled={loadingR || (loadingA && action.id !== item.id)}
                            onClick={() => {
                              setAction({ type: 2, id: item.id })
                              dispatch(acceptTransfer(item.id))
                            }}
                            icon={faCheckCircle}
                            size={'2x'}
                            tooltip={'Aprobar'}
                            style={{ color: 'green' }}
                          />
                        </>
                      ) : (
                        <div>{item.stateName}</div>
                      )}
                      <Icon
                        disabled={loadingA || (loadingR && action.id !== item.id)}
                        icon={faPrint}
                        size={'2x'}
                        style={{ marginLeft: 15, color: 'gray' }}
                        tooltip={'Imprimir'}
                        onClick={() => {
                          if (prints && prints.length > 0) {
                            const data = printCommandTicket(
                              itemsByTicket,
                              item.solicitedBy || user.name,
                              {
                                posType: posType || 1,
                                posName: posName || company.name,
                                tableNumber: item.reference,
                                description,
                                footerPhrase,
                              },
                              item.createdAt,
                              item.number,
                              logo,
                            )
                            dispatch(
                              onSetPrintData({
                                show: true,
                                customData: data,
                                element: `ticket-command-${item.id}`,
                                noPrinters: tableId !== undefined,
                              }),
                            )
                          } else
                            dispatch(
                              onSetPrintData({
                                show: true,
                                element: `ticket-command-${item.id}`,
                                noPrinters: tableId !== undefined,
                              }),
                            )
                          dispatch(
                            setGeneralData({
                              tableId,
                            }),
                          )
                        }}
                      />
                    </Row>
                  </div>
                </div>
              </Col>
            )
          })}
        </Row>
      )}

      <Alert {...alert} />
    </div>
  )
}
export default ManufacturePOS
