export const selectAllCompanies = state =>
  state.company.allCompanies.map(c => ({ ...c, label: c.name, value: c.id }))
export const selectPromotorCompanies = state => state.company.promotorCompanies
export const selectCompaniesByType = state => state.company.companiesByType
export const selectGetSingleCompany = state => state.company.company || {}
export const selectDeleteSingleCompany = state => state.company.compDeleted
export const selectUpdateSingleCompany = state => state.company.company
export const selectCreateAdminNewCompany = state => state.company.newCompany
export const selectGetCompanyFields = state => state.company.editableFields
export const selectSetCompanyFieldsValues = state => state.company.fields
export const selectCorrelativeField = state => state.company.correlative
export const selectSetCurrentCompany = state => state.company.company
export const selectCompanyIsLimit = state => {
  const { company } = state.company
  if (company) {
    return company.plan === 1
  }
  return true
}

export const selectCurrentCompany = state => state.company.currentCompany
export const selectOtherCompany = state => state.company.otherCompany
export const selectPatrimonialReport = state => state.company.report

export const selectCompaniesByTypeForSelect = state =>
  state.company.companiesByType.map(item => ({
    value: item.id,
    label: item.alphanumericId + ' - ' + item.name,
  }))
export const selectCompaniesByTypeWithAllForSelect = state =>
  [{ value: 0, label: 'Todas las Empresas' }].concat(
    state.company.companiesByType == null
      ? []
      : state.company.companiesByType.map(item => ({
          value: item.id,
          label: item.alphanumericId + ' - ' + item.name,
        })),
  )
export const selectCompaniesByClient = state => state.company.producersByClient
export const selectCompanyNS = state => state.company.companyNS
export const selectCompaniesByOrder = state => {
  if (!state.company.companiesByOrder) return []
  return state.company.companiesByOrder.map(c => ({
    ...c,
    label: c.name,
    value: c.id,
  }))
}

export const selectCompanyField = state => state.company.field
// TODO: revisar este
export const selectCompanyFieldById = (state, id) =>
  state.company.fields?.find(field => field.id === id) || {}

const parserFunctions = {
  string: value => String(value),
  long: value => Number(value),
  boolean: value => value === 'true' || value === '1',
  int: value => Number(value),
  number: value => Number(value),
}

export const selectCompanyFieldValue = (state, id) => {
  const field = selectCompanyFieldById(state, id)
  const parserFunction = parserFunctions[field.fieldType]
  return parserFunction ? parserFunction(field.value) : field.value
}

export const selectCompanyFieldEditPricePurchase = state => {
  if (state.company.editPricesPurchase) {
    const { value } = state.company.editPricesPurchase
    if (value) {
      return value === 'true'
    } else return false
  }
  return false
}
export const selectCompanyFieldStepPOS = state => state.company.fieldStepPOS
export const selectBadges = state => state.company.badges
export const selectCompanyBadges = state => state.company.badgesAssigned
export const selectCompanyForms = state => state.company.forms
export const selectMaxDiscount = state => state.company.maxDiscount
export const selectDTEReport = state => state.company.reportDTE
export const selectGetCompaniesMentor = state => state.company.companiesMentor
export const selectInvoicePackages = state => state.company.invoicePackages
export const selectCompanyFieldProductRepeat = state => {
  const { value } = state.company.productRepeat
  if (value) {
    return value === 'true'
  } else return false
}

export const selectFelItemTypes = state =>
  state.company.felItemTypes.map(item => ({ ...item, value: item.id, label: item.name }))
export const selectCompanyCountry = state => state.company.country
export const selectIdentificationTypes = state => state.company.identificationTypes
export const selectIdentificationType = (state, identificationTypeId) =>
  state.company.identificationTypes.find(type => type.id === identificationTypeId)
