// @ts-nocheck
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Row, Col } from 'react-bootstrap'
import {
  Title,
  Card,
  Dropdown,
  CustomTabs,
  TableV2,
  Icon,
  IconImg,
  Select,
  SelectedDates,
  FAB,
  Button,
  ListCategories,
  NumberField,
} from 'src/components'
import Folder, { categoryType } from 'src/components/folders/Folder'

import {
  faArchive,
  faBoxes,
  faDownload,
  faEdit,
  faEllipsisV,
  faEye,
  faHistory,
  faListOl,
  faListUl,
  faMoneyBill,
  faReceipt,
  faSave,
  faSearch,
  faSlidersH,
  faTag,
  faTimes,
  faTree,
} from '@fortawesome/free-solid-svg-icons'
import holder from 'src/assets/images/svg/food.svg'

import {
  actionTypes,
  getProductList,
  deleteProducts,
  exportCSV,
  updateMultipleProducts,
  assignCategorizationProduct,
  getProductVariations,
} from 'src/actions/products.actions'
import {
  selectAllTotalProducts,
  selectProductList,
  selectVariations,
} from 'src/selectors/products.selector'

import { getProviders } from 'src/actions/producer.actions'
import { selectAllProviders } from 'src/selectors/producer.selector'

import { itemsPermissions } from 'src/enums/permissions'
import { isAllowed } from 'src/selectors/modules.selector'
import { loadingSelector } from 'src/selectors/loading.selector'

import {
  handlerError,
  handlerInfoWithButtons,
  handlerSuccess,
  hasErrors,
} from 'src/selectors/error.selector'
import { showAlert } from 'src/actions/alert.actions'

import { getMeasurementSymbol, toMoney } from 'src/utils/utilities'

import RecipeVariable from 'src/content/Conversions/RecipeVariable/RecipeVariable'
import GeneralExistence from 'src/content/Inventory/Items/GeneralExistence'
import ItemsArchived from './Archived/ItemsArchived'
import TransferInventoryByItems from '../Inventory/Items/TransferInventoryByItems'
import ItemConfigs from './ItemConfigs'

import {
  actionTypes as actionCategories,
  assignCategorizationToEntity,
  getAllCategorizations,
  getCategorizationByType,
  getCategorizationsByEntity,
} from 'src/actions/categorization.actions'
import {
  selectAllCategorizations,
  selectCategorizationByType,
} from 'src/selectors/categorizations.selector'

import { ListVariations } from '../Production/Categorization/Variation/ListVariations'
import { LineItemsModal } from './Features/LineItemsModal'
import ItemCRUD from './ItemCRUD'

import ProductHistoryPrice from './ProductHistoryPrice/ProductHistoryPrice'
import ModalLog from 'src/components/modal/ModalLog'
import RecipeDetail from 'src/components/custom/RecipeDetail/RecipeDetailV2'
import DataSheet from 'src/components/DataSheet/DataSheet'
import CSVLoaderV2, { CATEGORY_UPLOAD } from '../../components/CSVLoader/CSVLoaderV2'
import companyFields from 'src/enums/companyFields'
import { selectCompanyFieldById } from 'src/selectors/company.selector'

const configObject = {
  active: 'productActive',
  online: 'activeOnline2',
  purchase: 'activePurchase',
  expense: 'activeExpenses',
  sell: 'activeSell',
  waste: 'activeWaste',
  increase: 'activeProduction',
  withoutInventory: 'withoutInventory',
  discountLimited: 'discountLimited',
  activeInventoryRecipe: 'activeInventoryRecipe',
  theoreticExistence: 'teoricExistence',
  updatePrice: 'activeUpdatable',
  notManufacture: 'notManufacture',
  enableSubRecipes: 'enableSubRecipes',
}

/**
 * App de ítems: Lista los ítems de la cuenta y permite realizar multiples acciones y configuraciones.
 * @file
 * */
const Items = () => {
  const dispatch = useDispatch()

  const response = useSelector(selectProductList)
  const total = useSelector(selectAllTotalProducts)

  const providers = useSelector(selectAllProviders)

  const categories = useSelector(selectAllCategorizations)
  const categoriesVariation = useSelector(selectCategorizationByType)
  const variations = useSelector(selectVariations)
  const seeMeasureUnit = useSelector(state =>
    selectCompanyFieldById(state, companyFields.seeMeasureUnit),
  )
  const loadingVariation = useSelector(state =>
    loadingSelector([actionTypes.GET_VARIATIONS])(state),
  )

  const loading = useSelector(state =>
    loadingSelector([actionTypes.GET_PRODUCT_LIST])(state),
  )
  const loadingArchive = useSelector(state =>
    loadingSelector([actionTypes.DELETE])(state),
  )
  const loadingExportCsv = useSelector(state =>
    loadingSelector([actionTypes.EXPORT_CSV])(state),
  )

  const loadingPrices = useSelector(state =>
    loadingSelector([actionTypes.UPDATE_MULTIPLE_PRODUCTS_REQUEST])(state),
  )
  const errorPrices = useSelector(state =>
    hasErrors([actionTypes.UPDATE_MULTIPLE_PRODUCTS_REQUEST])(state),
  )

  const loadingAddCategories = useSelector(state =>
    loadingSelector([actionCategories.ADD_ENTITY_CATEGORIZATION])(state),
  )
  const errorAddCategories = useSelector(state =>
    hasErrors([actionCategories.ADD_ENTITY_CATEGORIZATION])(state),
  )

  const [flags, setFlags] = useState({
    init: true,
    get: false,
    prices: false,
    categories: false,
    itemId: null,
  })

  // Permissions
  const create = useSelector(state => isAllowed(state, [itemsPermissions.create]))
  const update = useSelector(state => isAllowed(state, [itemsPermissions.update]))
  const archive = useSelector(state => isAllowed(state, [itemsPermissions.archive]))
  const updateConfig = useSelector(state =>
    isAllowed(state, [itemsPermissions.updateConfig]),
  )
  const seeMoneyValues = useSelector(state =>
    isAllowed(state, [itemsPermissions.seeMoneyValue]),
  )
  const updatePrices = useSelector(state =>
    isAllowed(state, [itemsPermissions.updatePrice]),
  )

  const [items, setItems] = useState([])

  // GENERAL ACTIONS
  const [recipeVariation, setRecipeVariation] = useState(false)
  const [generalExistence, setGeneralExistence] = useState({
    show: false,
    items: [],
    single: true,
  })
  const [archiveModal, setArchive] = useState({ show: false, type: 1 })
  const [transferModal, setTransferModal] = useState(false)
  const [editPrices, setEditPrices] = useState(false)

  // PRINCIPAL OPTIONS
  const [all, setAll] = useState(false)
  const [selected, setSelected] = useState([])
  const [configModal, setConfig] = useState({ show: false, id: null, config: {} })

  // INDIVIDUAL OPTIONS
  const [seeDetail, setSeeDetail] = useState({
    id: null,
    name: null,
    variation: false,
    tree: false,
    history: false,
    binnacle: false,
  })

  const [itemCRUD, setItemCRUD] = useState({
    show: false,
    id: null,
    name: null,
  })

  const [seeRecipe, setSeeRecipe] = useState({ show: false, recipeId: null, name: null })
  const [dataSheet, setDataSheet] = useState({
    show: false,
    id: null,
    itemId: null,
  })

  const [showCategories, setShowCategories] = useState({ show: false, filter: false })
  const [selectedCategories, setSelectedCategories] = useState([])

  const [filters, setFilters] = useState({
    active: { value: 1, label: 'Activos' },
    sort: { value: 1, label: 'Fecha de creación mas reciente' },
    provider: { value: null, label: 'Todos' },
    start: new Date().valueOf(),
    end: new Date().valueOf(),
  })
  const [coreFilters, setCoreFilters] = useState({
    skip: 0,
    size: 10,
    search: null,
    typeId: 0,
    getTotal: true,
  })
  const [coreFiltersText, setCoreFiltersText] = useState(null)

  const { skip, size, search, typeId, getTotal } = coreFilters
  const { active, sort, provider, start, end } = filters

  useEffect(() => {
    dispatch(getProviders())
    dispatch(getAllCategorizations(3))
    dispatch(getCategorizationByType(20))
    setCoreFiltersText(JSON.stringify(coreFilters))
  }, [])

  useEffect(() => {
    if (coreFiltersText == null || coreFiltersText === JSON.stringify(coreFilters)) return
    setCoreFiltersText(JSON.stringify(coreFilters))
  }, [coreFilters])

  useEffect(() => {
    if (coreFiltersText === JSON.stringify(coreFilters)) setUp(false)
  }, [coreFiltersText])

  useEffect(() => {
    if (loading) setFlags({ ...flags, get: true })
    else if (flags.get) {
      setFlags({ ...flags, get: false })
      setItems(JSON.parse(JSON.stringify(response)))
    }
  }, [loading])

  useEffect(() => {
    if (loadingPrices) setFlags({ ...flags, prices: true })
    else if (flags.prices) {
      setFlags({ ...flags, prices: false })
      let alert
      if (errorPrices) alert = { ...handlerError(errorPrices.message) }
      else
        alert = {
          ...handlerSuccess(),
          onConfirm: () => {
            setEditPrices(false)
            setUp(false)
          },
        }
      dispatch(showAlert(alert))
    }
  }, [loadingPrices])

  useEffect(() => {
    if (loadingAddCategories) setFlags({ ...flags, categories: true })
    else if (flags.categories) {
      setFlags({ ...flags, categories: false })
      if (errorAddCategories)
        dispatch(showAlert({ ...handlerError(errorAddCategories.message) }))
      else if (seeDetail.show) getCategorizationsByItem(seeDetail.id)
    }
  }, [loadingAddCategories])

  const setUp = (csv?: boolean) => {
    setFlags({ ...flags, init: false })
    if (!csv)
      dispatch(
        getProductList(
          null,
          skip,
          size,
          search,
          active?.value,
          null,
          1,
          1,
          typeId === 6,
          selectedCategories.map(sc => sc.id).join(),
          sort?.value,
          start,
          end,
          typeId !== 0 && typeId !== 6 ? typeId : null,
          provider?.value,
          getTotal,
        ),
      )
    else {
      dispatch(exportCSV(params, selected))
    }
  }

  const params: IItemFiltersParams = {
    active: active.value === 1,
    composed: typeId === 6,
    categories: selectedCategories.map(sc => sc.id).join(),
    activeId: typeId !== 0 && typeId !== 6 ? typeId : null,
    search: search,
    providerId: provider?.value,
    sort: sort?.value,
    start,
    end,
    all,
  }

  /**
   * @function Guarda los precios editados de los ítems
   * */
  const saveMultiplePrices = () => {
    const request = []
    selected.forEach(id => {
      const item = items.find(i => i.id === id)
      if (item)
        request.push({
          productId: item.id,
          price: item.customPrice !== undefined ? item.customPrice : item.productPrice,
        })
    })
    const alert = {
      ...handlerInfoWithButtons(
        'Edición de precios',
        '¿Desea actualizar los precios de los ítems seleccionados?',
      ),
      onConfirm: () => dispatch(updateMultipleProducts(request)),
    }
    dispatch(showAlert(alert))
  }

  const customFilter = () => (
    <Row className={'mb-3'}>
      <Col xl={6} lg={6} md={6} sm={12}>
        <Select
          label={'Estado del item'}
          info={'Filtra los items por su estado'}
          value={active}
          options={[
            { value: null, label: '-- Todos --' },
            { value: 1, label: 'Activos' },
            { value: 2, label: 'Inactivos' },
          ]}
          onChange={v => setFilters({ ...filters, active: v })}
        />
      </Col>
      <Col xl={6} lg={6} md={6} sm={12}>
        <Select
          label={'Ordenar Por'}
          info={'Ordena los items según el criterio seleccionado'}
          value={sort}
          options={[
            { value: 1, label: 'Fecha de creación mas reciente' },
            { value: 2, label: 'Fecha de creación mas antigua' },
            { value: 3, label: 'Mayor venta' },
            { value: 4, label: 'Menor venta' },
          ]}
          onChange={v => setFilters({ ...filters, sort: v })}
        />
      </Col>
      <Col xl={6} lg={6} md={6} sm={12}>
        <Select
          label={'Por Proveedor'}
          info={'Filtra los items por proveedor'}
          value={provider}
          options={[{ value: null, label: '- Todos -' }, ...providers]}
          onChange={v => setFilters({ ...filters, provider: v })}
        />
      </Col>
      <Col xl={12} lg={12} md={12} sm={12}>
        <SelectedDates
          initialPreset={0}
          legend={'Se habilita al ordenar los items por ventas'}
          disabled={sort.value !== 3 && sort.value !== 4}
          onDateChange={(start, end) => {
            if (!flags.init) setFilters({ ...filters, start, end })
          }}
        />
      </Col>
      <Col xl={12}>
        <div className={'space-between'}>
          <Button
            disabled={loading}
            onClick={() => setShowCategories({ show: true, filter: true })}>
            Por Categorías
          </Button>

          <Button
            loading={loading}
            color={'accent'}
            icon={faSearch}
            onClick={() => setUp(false)}>
            Aplicar Filtros
          </Button>
        </div>
      </Col>
      <Col xl={12} className={'mt-2'}>
        <ListCategories
          items={selectedCategories}
          onRemove={onAssignCategorizationToFilter}
        />
      </Col>
      <Col xl={12}>
        <hr />
      </Col>
      <Col xl={12} className={'mb-3'}>
        <CSVLoaderV2
          category={CATEGORY_UPLOAD.UPDATE_PRODUCTS}
          title={'Actualización Masiva de items'}
          onSuccess={() => setUp(false)}
        />
      </Col>
    </Row>
  )

  const getGeneralActions = () => (
    <Dropdown
      tooltip={'Opciones generales'}
      items={[
        {
          title: 'Ver Variaciones de recetas',
          tooltip: 'Permite visualizar las variaciones de las recetas',
          action: () => setRecipeVariation(true),
          icon: faListOl,
        },
        {
          title: 'Ver existencias generales',
          tooltip: 'Permite visualizar Todas las existencias de los items',
          action: () =>
            setGeneralExistence({
              show: true,
              items: items.map(i => i.productId),
              single: false,
            }),
          icon: faBoxes,
        },
        {
          title: 'Administrar nombres para precios',
          tooltip:
            'Permite visualizar los nombres generales que pueden ser asignados a los precios de los items',
          action: () => undefined,
          show: false,
          icon: faMoneyBill,
        },
        {
          show: archive,
          title: 'Mostrar items archivados',
          tooltip: 'Permite visualizar los items con estado archivado',
          action: () => setArchive({ show: true, type: 1 }),
          icon: faArchive,
        },
      ]}
    />
  )

  const getActionsItem = item => (
    <Dropdown
      disabled={editPrices}
      loading={loadingArchive && flags.itemId === item.productId}
      onClick={() => setFlags({ ...flags, itemId: item.productId })}
      items={[
        {
          title: 'Detalle completo',
          icon: faEye,
          action: () => {
            setItemCRUD({
              id: item.productId,
              name: `${item.productCode} ${item.productName}`,
              show: true,
            })
            /*getCategorizationsByItem(item.productId)
            setSeeDetail({
              ...seeDetail,
              show: true,
              id: item.productId,
              name: `${item.productCode} ${item.productName}`,
            })*/
          },
        },
        {
          title: 'Detalle de existencias',
          icon: faBoxes,
          action: () => {
            setGeneralExistence({
              show: true,
              items: [item.productId],
              single: true,
            })
          },
        },
        {
          title: 'Ver variaciones',
          icon: faListUl,
          action: () => {
            dispatch(getProductVariations(item.productId))
            setSeeDetail({
              ...seeDetail,
              variation: true,
            })
          },
        },
        {
          title: 'Ver árbol de ítems',
          show: item.decimals === null,
          icon: faTree,
          action: () =>
            setSeeDetail({
              ...seeDetail,
              tree: true,
            }),
        },
        {
          title: 'Ver historial de precios',
          icon: faHistory,
          action: () =>
            setSeeDetail({
              ...seeDetail,
              history: true,
              name: `${item.productCode} ${item.productName}`,
            }),
        },
        {
          title: 'Bitácora del ítem',
          icon: faHistory,
          action: () =>
            setSeeDetail({
              ...seeDetail,
              binnacle: true,
              name: `${item.productCode} ${item.productName}`,
            }),
        },
        {
          title: item.notManufacture ? 'Ver Combo' : 'Ver receta',
          icon: faReceipt,
          action: () =>
            setSeeRecipe({
              show: true,
              recipeId: item.enumerationId,
              name: `${item.productCode} ${item.productName}`,
              decimals: item.decimals,
              notManufacture: item.notManufacture,
            }),
        },
        {
          title: 'Ver ficha técnica',
          divide: true,
          icon: faReceipt,
          action: () => {
            dispatch(getAllCategorizations(15))
            setDataSheet({
              show: true,
              id: item.datasheetId,
              itemId: item.productId,
            })
          },
        },
        {
          show: archive,
          title: 'Archivar ítem',
          icon: faArchive,
          action: () => onArchiveItems([item.productId]),
        },
      ]}
    />
  )

  const isSelected = item => {
    const select = selected.find(s => item.id === s)
    return select !== undefined
  }

  const onSelect = item => {
    setAll(false)
    const cs = Object.assign([], selected)
    if (isSelected(item)) setSelected(cs.filter(s => s !== item.id))
    else {
      cs.push(item.id)
      setSelected(cs)
    }
  }

  const onArchiveItems = toArchive => {
    setArchive({ show: true, type: 2 })
    dispatch(deleteProducts(toArchive, true, false))
  }

  /**
   * @function Agrega o Remueve categorías seleccionadas para el filtrado de los ítems
   * @param {ICategory} item categoría seleccionada por el componente Folder
   * */
  const onAssignCategorizationToFilter = item => {
    const customCategories = Object.assign([], selectedCategories)
    const index = customCategories.findIndex(sc => sc.id === item.id)
    if (index === -1) customCategories.push(item)
    else customCategories.splice(index, 1)
    setSelectedCategories(customCategories)
  }

  /**
   * @function Agrega o Remueve categorías seleccionadas para el o los ítems seleccionados
   * @param {number} categorization Id de la categoría seleccionada por el componente Folder
   * */
  const onAssignCategorizationToItems = categorization => {
    if (selected.length === 0 && !all)
      dispatch(
        assignCategorizationToEntity('products', categorization, seeDetail.id, true),
      )
    else dispatch(assignCategorizationProduct(categorization, selected, false, all))
  }

  const getCategorizationsByItem = itemId =>
    dispatch(getCategorizationsByEntity('products', itemId))

  const table = (
    <TableV2
      customFilter={customFilter()}
      loading={loading}
      items={items}
      total={total}
      getPagination={(skip, size, search) =>
        setCoreFilters({ ...coreFilters, skip, size, search })
      }
      handleChange={search => setCoreFilters({ ...coreFilters, search })}
      isSelected={item => (all ? true : isSelected(item))}
      onCheck={value => {
        if (!editPrices) {
          setAll(value)
          setSelected([])
        }
      }}
      storageKey={'items-table'}
      onClickTr={(e, item) => {
        if (!editPrices) onSelect(item)
      }}
      headers={[
        {
          label: '',
          show: true,
          checked: all,
          type: 'text',
          className: 'mini',
          custom: item => (
            <IconImg
              style={{ justifyContent: null }}
              imgStyle={{ width: 50, height: 50 }}
              icon={item.image ? item.image.url : holder}
            />
          ),
        },
        {
          label: 'SKU / Código',
          show: true,
          type: 'text',
          value: ['productCode'],
          className: 'mini',
        },
        {
          label: 'Nombre',
          show: true,
          type: 'text',
          value: ['productName'],
          className: 'medium',
        },
        {
          label: 'Medida',
          show: seeMeasureUnit?.value === 'true',
          type: 'text',
          className: 'mini center',
          custom: item =>
            item.measurementValue == null
              ? '---'
              : `${item.measurementValue} ${getMeasurementSymbol(
                  item.measurementUnitId,
                )}`,
        },
        {
          label: 'Proveedor',
          show: true,
          type: 'text',
          value: ['providerName'],
          className: 'mini',
        },
        {
          label: 'Fecha de creación',
          show: true,
          type: 'date',
          value: ['createdAt'],
          className: 'mini',
        },
        {
          label: 'Precio',
          show: seeMoneyValues,
          type: 'currency',
          value: ['productPrice'],
          className: 'mini',
          custom: item => {
            if (!editPrices || !isSelected(item)) return toMoney(item.productPrice)
            else
              return (
                <div>
                  <NumberField
                    disabled={loadingPrices}
                    value={
                      item.customPrice !== undefined
                        ? item.customPrice
                        : item.productPrice
                    }
                    style={{
                      width: '75px',
                    }}
                    onValueChange={value => {
                      const itemIndex = items.findIndex(i => i.id === item.id)
                      if (itemIndex >= 0) {
                        const customItems = Object.assign([], items)
                        customItems[itemIndex].customPrice = value
                        setItems([...customItems])
                      }
                    }}
                    decimals={2}
                  />
                </div>
              )
          },
        },
        {
          show: true,
          label: 'Medida',
          type: 'text',
          value: ['measurementUnit'],
          className: 'mini',
        },
        {
          label: 'Total ventas',
          show: sort?.value === 3 || sort?.value === 4,
          type: 'number',
          value: ['productSale'],
          className: 'mini',
        },
        {
          show: true,
          config: true,
          type: 'text',
          value: ['button'],
          label: '',
          className: 'mini',
          custom: item => (
            <div className={'d-flex'}>
              {updateConfig && (
                <Icon
                  disabled={editPrices}
                  style={{ marginRight: '5px' }}
                  icon={faSlidersH}
                  size={'2x'}
                  onClick={() => {
                    const config = {}
                    Object.keys(configObject).forEach(c => {
                      config[c] = item[configObject[c]]
                    })

                    setConfig({
                      show: true,
                      id: item.productId,
                      config,
                    })
                  }}
                />
              )}
              {getActionsItem(item)}
            </div>
          ),
        },
      ]}
    />
  )

  return (
    <div>
      <Title
        title={'Ítems'}
        action={create}
        onClick={() => setItemCRUD({ show: true, id: null, name: null })}
        actionTitle={'Crear ítem'}
        dontHide
      />

      <Card white title={'Listado'} button={getGeneralActions()}>
        <CustomTabs
          setKey={key =>
            setCoreFilters({
              ...coreFilters,
              typeId: Number(key),
            })
          }
          items={[
            {
              title: 'Todos',
              component: table,
            },
            {
              title: 'Ventas',
              component: table,
            },
            {
              title: 'Reducción de inventario',
              component: table,
            },
            {
              title: 'Ingreso de Inventario',
              component: table,
            },
            {
              title: 'Gastos',
              component: table,
            },
            {
              title: 'Compras',
              component: table,
            },
            {
              title: 'Recetas y Combos',
              component: table,
            },
          ]}
        />
      </Card>

      <RecipeVariable show={recipeVariation} onHide={() => setRecipeVariation(false)} />
      <GeneralExistence
        show={generalExistence.show}
        items={generalExistence.items}
        single={generalExistence.single}
        onHide={() =>
          setGeneralExistence({ ...generalExistence, show: false, single: false })
        }
      />
      <ItemsArchived
        type={archiveModal.type}
        show={archiveModal.show}
        onHide={update => {
          setArchive({ show: false, type: 1 })
          if (update) setUp(false)
        }}
      />
      <TransferInventoryByItems
        show={transferModal}
        items={selected}
        params={params}
        onHide={() => setTransferModal(false)}
      />
      <ItemConfigs
        modal
        show={configModal.show}
        onHide={(newConfig, update) => {
          setSelected([])
          setConfig({ show: false, id: null, config: {} })
          if (update) {
            // @ts-ignore
            if (newConfig.active === configModal.config.active) {
              const config = {}
              Object.keys(configObject).forEach(c => {
                config[configObject[c]] = newConfig[c]
              })

              const index = items.findIndex(i => i.productId === configModal.id)
              const customItems = Object.assign([], items)
              customItems[index] = { ...customItems[index], ...config }
              setItems(customItems)
            } else setUp(false)
          }
        }}
        initialConfig={configModal.config}
        itemId={configModal.id}
      />

      <ListVariations
        show={seeDetail.variation}
        data={categoriesVariation}
        loading={loadingVariation}
        hideVariations
        onlySee
        hideMessage
        onHide={() => setSeeDetail({ ...seeDetail, variation: false })}
        onSave={() => undefined}
        selected={variations.map(x => {
          return { id: parseInt(x) }
        })}
      />
      <LineItemsModal
        show={seeDetail.tree}
        onHide={() => setSeeDetail({ ...seeDetail, tree: false })}
        productId={flags.itemId}
      />
      {seeDetail.history && (
        <ProductHistoryPrice
          modal
          show={seeDetail.history}
          productId={flags.itemId}
          name={seeDetail.name}
          onHide={() => setSeeDetail({ ...seeDetail, history: false })}
        />
      )}
      {seeDetail.binnacle && (
        <ModalLog
          id={flags.itemId}
          title={seeDetail.name}
          onHide={() => setSeeDetail({ ...seeDetail, binnacle: false })}
          product
          modal
        />
      )}
      <RecipeDetail
        show={seeRecipe.show}
        recipeId={seeRecipe.recipeId}
        title={(seeRecipe.notManufacture ? 'Combo' : 'Receta').concat(
          ` del item: ${seeRecipe.name}`,
        )}
        productId={flags.itemId}
        type={2}
        productName={seeRecipe.name}
        decimals={seeRecipe.decimals}
        notVariations={seeRecipe.notManufacture}
        onHide={update => {
          setSeeRecipe({ ...seeRecipe, show: false })
          if (update) setUp(false)
        }}
        isRecipe
      />

      {dataSheet.show && (
        <DataSheet
          modal
          show={dataSheet.show}
          onHide={() => {
            setDataSheet({ ...dataSheet, show: false })
            dispatch(getAllCategorizations(3))
          }}
          id={dataSheet.id}
          product={dataSheet.itemId}
          company
          update={() => {
            setDataSheet({ ...dataSheet, show: false })
            setUp(false)
          }}
          categories={categories[1]?.children}
        />
      )}

      <ItemCRUD
        {...itemCRUD}
        onHide={(update: boolean) => {
          setItemCRUD({ ...itemCRUD, show: false })
          if (update) setUp()
        }}
      />

      <Folder
        show={showCategories.show}
        type={categoryType.PRODUCTS}
        noMessage
        onHide={() => setShowCategories({ show: false, filter: false })}
        onAssign={item => {
          if (!showCategories.filter) onAssignCategorizationToItems(item.id)
          else onAssignCategorizationToFilter(item)
        }}
        list={selectedCategories.map(p => p.id)}
        data1={categories && categories.children ? categories.children[0] : {}}
        data2={categories && categories.children ? categories.children[1] : {}}
      />

      <FAB
        show={all || selected.length > 0}
        loading={loadingArchive || loadingExportCsv || loadingPrices}
        icon={editPrices ? faEdit : faEllipsisV}
        items={[
          {
            text: 'Archivar ítems',
            icon: faArchive,
            action: () => onArchiveItems(selected),
            show: archive && !editPrices,
          },
          {
            text: 'Exportar csv',
            icon: faDownload,
            action: () => setUp(true),
            show: !editPrices,
          },
          {
            text: 'Transferir inventario',
            icon: faBoxes,
            action: () => setTransferModal(true),
            show: !editPrices,
          },
          {
            text: 'Añadir categorización',
            icon: faTag,
            action: () => setShowCategories({ show: true, filter: false }),
            show: update && !editPrices,
          },
          {
            text: 'Editar precios',
            icon: faEdit,
            action: () => setEditPrices(true),
            show: seeMoneyValues && updatePrices && selected.length > 0 && !editPrices,
          },
          {
            text: 'Guardar',
            icon: faSave,
            action: () => saveMultiplePrices(),
            show: editPrices,
          },
          {
            text: 'Cancelar',
            icon: faTimes,
            action: () => {
              setEditPrices(false)
              setItems(JSON.parse(JSON.stringify(response)))
            },
            show: editPrices,
          },
        ]}
      />
    </div>
  )
}
export default Items
