import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Row, Col } from 'react-bootstrap'

import Button from 'src/components/buttons/Button'
import FormTextField from 'src/components/inputs/FormTextField/FormTextField'
import { loadingSelector } from 'src/selectors/loading.selector'
import { actionTypes as type } from 'src/actions/user.actions'
import { handlerSuccess } from 'src/selectors/error.selector'
import Alert from 'sweetalert-react'

class NewUser extends Component {
  state = {
    user: {},
    errors: {},
    alert: { show: false },
  }

  change = event => {
    const { name, value } = event.target
    let { user } = this.state
    user[name] = value
    this.setState({ user })
  }

  componentDidMount() {
    if (this.props.user) {
      this.setState({ user: this.props.user })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    const { loading } = this.props

    if (loading && !nextProps.loading) {
      let alert = handlerSuccess('Datos modificados')
      alert.onConfirm = () => {
        this.setState({ alert: { show: false } })
      }
      this.setState({ alert })
    }
  }

  validate = () => {
    const { name, email, dpi, phone1, phone2 } = this.state.user
    let errors = {}
    if (!name) {
      errors.name = 'Es requerido'
    }
    if (!email) {
      errors.email = 'Es requerido'
    } else if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      errors.email = 'Email invalido'
    }
    if (dpi && (!/^([0-9])*$/.test(dpi) || dpi.length !== 13)) {
      errors.dpi = 'DPI incorrecto'
    }
    if (phone1 && (!/^([0-9])*$/.test(phone1) || phone1.length !== 8)) {
      errors.phone1 = 'Telefono invalido'
    }
    if (phone2 && (!/^([0-9])*$/.test(phone2) || phone2.length !== 8)) {
      errors.phone2 = 'Telefono invalido'
    }
    if (Object.keys(errors).length === 0) {
      this.props.save(this.state.user)
    }
    this.setState({ errors })
  }

  render() {
    const { user, errors, alert } = this.state
    return (
      <div>
        <Row>
          <Col lg={4} md={4} sm={4}>
            <FormTextField
              id={'name'}
              label={'Nombre'}
              placeholder={'pj. Juan López'}
              type={'text'}
              name={'name'}
              value={user.name}
              error={errors.name}
              onChange={this.change}
              required
            />
          </Col>
          <Col lg={4} md={4} sm={4}>
            <FormTextField
              id={'email'}
              label={'Correo electronico'}
              placeholder={'pj. usuario@gmail.com'}
              type={'email'}
              name={'email'}
              disabled={this.props.disabled}
              value={user.email}
              error={errors.email}
              onChange={this.change}
              required
            />
          </Col>
          <Col lg={4} md={4} sm={4}>
            <FormTextField
              id={'dpi'}
              label={'Documento de identificación'}
              placeholder={'pj. 2020 80800 0101'}
              type={'text'}
              name={'dpi'}
              value={user.dpi}
              error={errors.dpi}
              onChange={this.change}
            />
          </Col>
          <Col lg={4} md={4} sm={4}>
            <FormTextField
              id={'phon1'}
              label={'Telefono principal'}
              placeholder={'pj. 24208600'}
              type={'text'}
              name={'phone1'}
              value={user.phone1}
              error={errors.phone1}
              onChange={this.change}
            />
          </Col>
          <Col lg={4} md={4} sm={4}>
            <FormTextField
              id={'phon2'}
              label={'Telefono secundario'}
              placeholder={'pj. 24208600'}
              type={'text'}
              name={'phone2'}
              value={user.phone2}
              error={errors.phone2}
              onChange={this.change}
            />
          </Col>
          <Col lg={4} md={4} sm={4}>
            <FormTextField
              id={'address'}
              label={'Dirección'}
              placeholder={'pj. 4ta avenida, colonia bella, zona 4'}
              type={'text'}
              name={'address'}
              value={user.address}
              onChange={this.change}
            />
          </Col>
        </Row>
        <Row className={'container-buttons'}>
          <Button
            color={'primary'}
            loading={this.props.loading}
            onClick={() => this.validate()}
          >
            {this.props.user ? 'Guardar' : 'Crear usuario'}
          </Button>
        </Row>
        <Alert {...alert} />
      </div>
    )
  }
}
const mapStateToProps = state => ({
  loading: loadingSelector([type.UPDATE_PROFILE])(state),
})
const mapDispatchToProps = dispatch => ({})
export default connect(mapStateToProps, mapDispatchToProps)(NewUser)
