import React, { Component } from 'react'
import {
  selectCategorization,
  selectCategorizationTypeForSelect,
} from 'src/selectors/categorizations.selector'
import {
  getCategorization,
  actionTypes,
  createCategorization,
  getAllTypes,
} from 'src/actions/categorization.actions'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import Card from 'src/components/cards/Card'
import Button from 'src/components/buttons/Button'
import { loadingSelector } from 'src/selectors/loading.selector'
import FormTextField from 'src/components/inputs/FormTextField/FormTextField'
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index.es'
import { hasErrorsSelector, singleErrorSelector } from 'src/selectors/error.selector'
import SweetAlert from 'sweetalert-react'
import CustomSelect from 'src/components/inputs/Select/CustomSelect'

import { selectCurrentModule } from 'src/selectors/user.selector'
import Gallery, { imageTypes } from 'src/components/gallery/Gallery'
import CarouselGalery from 'src/components/CarouselGallery/CarouselGallery'

class CategorizationCreate extends Component {
  state = {
    categorization: {},
    errors: {},
    showSuccess: false,
    showError: false,
    successAlert: {
      show: false,
      type: 'success',
      showCancelButton: true,
      confirmButtonText: 'Ir a detalles.',
      confirmButtonColor: 'green',
      cancelButtonText: 'Atras',
      title: '¡Yeah!',
      text: 'Categorización creada satisfactoriamente.\n\n¿Desea ir al detalle de la categorización?',
      onConfirm: () => console.log('confirm'),
      onCancel: () => console.log('cancel'),
    },
    alertBase: {
      data: {},
      show: false,
      title: 'default',
      text: 'default',
      type: 'info',
      onConfirm: () => console.log('default'),
    },
    selected: { value: 0, label: 'Seleccione un tipo' },
    images: [],
    banner: [],
    icon: [],
  }

  componentDidMount() {
    this.props.getAllTypes()
  }

  UNSAFE_componentWillReceiveProps(next) {
    const { createIsLoading, createHasError, history, module } = this.props
    const { successAlert } = this.state
    const { categorization } = next

    if (createIsLoading && !next.createIsLoading) {
      if (!createHasError && next.createHasError) {
        this.setState({ showSuccess: false, showError: true })
      } else {
        successAlert.show = true
        successAlert.text = `${
          this.state.categorization.type === 2
            ? 'Gasto creado satisfactoriamente'
            : 'Categorización creada satisfactoriamente.'
        } \n\n¿Desea ver el detalle?`
        successAlert.onConfirm = () => {
          this.setState({ successAlert: { ...successAlert, show: false } })
          history.replace(
            `/${
              module === 1000 ? 'distribucion' : module === 2000 ? 'produccion' : 'admin'
            }/categorizaciones/detalle/${categorization.id}`,
          )
        }
        successAlert.onCancel = () => {
          this.setState({ successAlert: { ...successAlert, show: false } })
          history.goBack()
        }
      }
      this.setState({ successAlert })
    }
  }

  resetForm() {
    this.setState({
      categorization: {},
      errors: {},
      showSuccess: false,
      showError: false,
    })
  }

  change = event => {
    const { name, value } = event.target
    let { categorization } = this.state
    categorization[name] = value
    this.setState({ categorization })
  }

  onTypesChange = e => {
    let { categorization } = this.state
    categorization.type = e.value
    this.setState({ selected: e, categorization })
  }

  save = () => {
    const { categorization, images, banner, icon } = this.state
    const errors = {}
    if (!categorization.name) {
      errors.name = 'El nombre es requerido'
    }
    if (!categorization.description) {
      errors.description = 'La descripción es requerida'
    }
    if (!categorization.type) {
      errors.selected = 'El tipo es requerido'
    }

    if (Object.keys(errors).length === 0) {
      this.props.createCategorization(categorization, images, banner, icon)
    }
    this.setState({ errors })
  }

  handleCheckBoxChange = event => {
    const { name, checked } = event.target
    let { categorization } = this.state
    categorization[name] = checked
    this.setState({ categorization })
  }

  uploadImage = (url, name) => {
    this.setState({
      images: [...this.state.images, { url, original: url, thumbnail: url, name: name }],
    })
  }

  deleteImage = (image, index) => {
    const { images } = this.state
    images.splice(index, 1)
    this.setState({ images: new Array(...images) })
  }

  uploadBanner = (url, name) => {
    this.setState({
      banner: [...this.state.banner, { url, original: url, thumbnail: url, name: name }],
    })
  }

  deleteBanner = (image, index) => {
    const { banner } = this.state
    banner.splice(index, 1)
    this.setState({ banner: new Array(...banner) })
  }

  uploadIcon = (url, name) => {
    this.setState({
      icon: [...this.state.icon, { url, original: url, thumbnail: url, name: name }],
    })
  }

  deleteIcon = (image, index) => {
    const { icon } = this.state
    icon.splice(index, 1)
    this.setState({ icon: new Array(...icon) })
  }

  render() {
    const {
      categorization,
      showSuccess,
      showError,
      errors,
      alertBase,
      successAlert,
      selected,
      images,
      banner,
      icon,
    } = this.state
    const { createError, types, module } = this.props

    return (
      <div>
        <Row>
          <Col sm={12} xs={12} md={12}>
            <div>
              <div>
                <h4 className={'kolo-title'} style={{ width: 'auto' }}>
                  CREAR CATEGORIZACIÓN
                </h4>
              </div>
              {module === 11000 && (
                <Card>
                  <div>
                    <h4 className={'kolo-company'}>Banner</h4>
                  </div>
                  <CarouselGalery
                    imageType={imageTypes.CATEGORY_BANNER}
                    images={banner}
                    onUploadSuccess={this.uploadBanner}
                    onDelete={this.deleteBanner}
                    maxWidth={200}
                    limit={1}
                  />
                </Card>
              )}
            </div>
            <div>
              <Row>
                {module === 11000 && (
                  <Col lg={4} md={4} sm={12}>
                    <Card>
                      <div>
                        <h4 className={'kolo-company'}>Logo</h4>
                      </div>
                      <Row>
                        <Col>
                          <Gallery
                            imageType={imageTypes.CATEGORY}
                            images={images}
                            onUploadSuccess={this.uploadImage}
                            onDelete={this.deleteImage}
                            title={'Agregar Logo'}
                            limit={1}
                          />
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                )}
                <Col
                  lg={module === 11000 ? 8 : 12}
                  md={module === 11000 ? 8 : 12}
                  sm={12}
                >
                  <Card>
                    <div>
                      <h4 className={'kolo-company'}>Información</h4>
                    </div>
                    <Row>
                      <Col lg={4} sm={4}>
                        <Gallery
                          imageType={imageTypes.CATEGORY_ICON}
                          images={icon}
                          onUploadSuccess={this.uploadIcon}
                          onDelete={this.deleteIcon}
                          title={'Agregar ícono'}
                          limit={1}
                          maxWidth={256}
                        />
                      </Col>
                      <Col lg={8} sm={8}>
                        <Row>
                          <Col lg={4} md={4} sm={4}>
                            <FormTextField
                              id={'name'}
                              label={'Nombre'}
                              placeholder={'Nombre'}
                              type={'text'}
                              name={'name'}
                              value={categorization.name}
                              onChange={this.change}
                              required
                              error={errors.name}
                            />
                          </Col>
                          <Col lg={4} md={4} sm={4}>
                            <FormTextField
                              id={'description'}
                              label={'Descripción'}
                              placeholder={'Descripción'}
                              type={'text'}
                              name={'description'}
                              value={categorization.description}
                              onChange={this.change}
                              required
                              error={errors.description}
                            />
                          </Col>
                          <Col lg={4} md={4} sm={4}>
                            <CustomSelect
                              value={selected}
                              options={
                                module === 11000
                                  ? types.filter(
                                      t =>
                                        t.value !== 9 && t.value !== 4 && t.value !== 14,
                                    )
                                  : types.filter(
                                      t =>
                                        t.value !== 4 &&
                                        t.value !== 9 &&
                                        t.value !== 11 &&
                                        t.value !== 13 &&
                                        t.value !== 14,
                                    )
                              }
                              onChange={this.onTypesChange}
                              label={'Tipo de categorización'}
                              error={errors.selected}
                            />
                          </Col>
                          <Col xs={4} sm={4} md={4} lg={4}>
                            <FormTextField
                              id={'color'}
                              label={'Color'}
                              type={'color'}
                              name={'color'}
                              value={categorization.color}
                              onChange={this.change}
                            />
                          </Col>
                          {module === 11000 && (
                            <Col lg={6} md={6} sm={6}>
                              <label className={'checkbox-label'}>
                                <input
                                  type={'checkbox'}
                                  name={'publicCategorization'}
                                  checked={categorization.publicCategorization}
                                  onChange={this.handleCheckBoxChange}
                                />
                                Todas las empresas podrán usar esta categorización
                              </label>
                            </Col>
                          )}
                          {/*                          {module === 11000 && (
                            <Col>
                              <label className={"checkbox-label"}>
                                <input
                                  type={"checkbox"}
                                  name={"principal"}
                                  checked={categorization.principal}
                                  onChange={this.handleCheckBoxChange}
                                />
                                Seleccionar como categoría principal
                              </label>
                            </Col>
                          )}*/}
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row>
          <Col style={{ alignSelf: 'flex-end', textAlign: 'right' }}>
            <Button
              className={'align-bottom'}
              onClick={() => this.props.history.push('/produccion/categorizaciones')}
              variant={'danger'}
            >
              <FontAwesomeIcon icon={faTimes} /> Cancelar
            </Button>
            <Button className={'align-bottom'} onClick={this.save}>
              <FontAwesomeIcon icon={faSave} /> Guardar
            </Button>
          </Col>
        </Row>
        <SweetAlert
          show={showSuccess}
          title={'¡Exito!'}
          text={'Categorización guardada exitosamente.'}
          type={'success'}
          onConfirm={() => this.resetForm()}
        />
        <SweetAlert
          show={showError}
          title={'¡Uh-Oh!'}
          text={
            createError.message ||
            'Error en los datos trasmitidos, por favor revise e intente de nuevo.'
          }
          type={'error'}
          onConfirm={() => this.setState({ showError: false })}
        />
        <SweetAlert {...alertBase} />
        <SweetAlert {...successAlert} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  categorization: selectCategorization(state),
  types: selectCategorizationTypeForSelect(state),
  categorizationLoading: loadingSelector([actionTypes.GET_CATEGORIZATION])(state),
  createIsLoading: loadingSelector([actionTypes.CREATE_CATEGORIZATION])(state),
  createHasError: hasErrorsSelector([actionTypes.CREATE_CATEGORIZATION])(state),
  createError: singleErrorSelector([actionTypes.CREATE_CATEGORIZATION])(state),
  module: selectCurrentModule(state),
})
const mapDispatchToProps = dispatch => ({
  getCategorization: categorizationId => dispatch(getCategorization(categorizationId)),
  createCategorization: (categorization, images, banner, icon) =>
    dispatch(createCategorization(categorization, images, banner, icon)),
  getAllTypes: () => dispatch(getAllTypes()),
})
export default connect(mapStateToProps, mapDispatchToProps)(CategorizationCreate)
