import React, { useContext, useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { Button, FormText, FormTextField, Select, Switch } from 'src/components'
import { useDispatch, useSelector } from 'react-redux'

import { updateAccount as editAccount } from 'src/actions/banks.actions'
import { BankContext } from 'src/providers/BankContext'
import { selectorGetUsersSubmodule } from 'src/selectors/modules.selector'
import { loadingSelector } from 'src/selectors/loading.selector'
import { actionTypes as typeT } from 'src/actions/banks.actions'

import { showAlert } from 'src/actions/alert.actions'
import { selectGetAllCurrencies, selectUpdateAccount } from 'src/selectors/banks.selector'
import { handlerError, handlerSuccess } from 'src/selectors/error.selector'
import { transformDataUpdatedAccount } from '../../../utils/bankHelpers/bankHelpers'

interface EditAccountModalProps {
  show?: boolean
  onHide?: () => void
}

interface actionState {
  editAccount?: boolean
}

const EditAccountModal: React.FC<EditAccountModalProps> = ({
  show,
  onHide,
}: EditAccountModalProps) => {
  const dispatch = useDispatch()

  const { accounnt, updateAccount } = useContext(BankContext) || {}
  const updatedAccount = useSelector(selectUpdateAccount)
  const currencies = useSelector(selectGetAllCurrencies)
  const usersSubmodule = useSelector(state => selectorGetUsersSubmodule(state))
  const updatedAccountFlag = useSelector(state =>
    loadingSelector([typeT.UPDATE_ACCOUNT])(state),
  )

  const [accountData, setAccountData] = useState<IBankAccount>(accounnt)
  const [actionsDone, setActionsDone] = useState<actionState>({ editAccount: false })

  const idForCurrency = currencies.findIndex(curr => curr.id === accountData?.currency.id)
  const currentOwner = usersSubmodule?.find(user => user.value === accountData?.asigned)

  useEffect(() => {
    setAccountData(accounnt)
  }, [accounnt])

  useEffect(() => {
    if (updatedAccountFlag) {
      setActionsDone({ ...actionsDone, editAccount: true })
    }
  }, [updatedAccountFlag])
  useEffect(() => {
    if (actionsDone.editAccount) {
      onHide()

      setActionsDone({ ...actionsDone, editAccount: false })
      dispatch(showAlert({ ...handlerSuccess('Cambios guardados') }))
    }
  }, [actionsDone.editAccount])

  const handleAccountData = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { id, value } = e.currentTarget

    setAccountData(prevData => ({
      ...prevData,
      [id]: value,
    }))
  }

  const saveAccountUpdates = () => {
    setAccountData({ ...accountData, accountNumber: accounnt.accountNumber })

    if (
      !accountData.name ||
      !accountData.name.trim() ||
      !accountData.toName ||
      !accountData.toName.trim() ||
      !accountData.asigned ||
      !accountData.module
    ) {
      dispatch(showAlert(handlerError('Revise los datos obligatorios del formulario')))
    } else {
      dispatch(
        editAccount({
          id: accountData.id,
          deleted: accountData.deleted,
          accountNumber: accountData.accountNumber,
          asigned: accountData.asigned,
          balance: accountData.balance,
          currency: accountData.currency.id,
          module: accountData.module,
          name: accountData.name,
          report: accountData.report,
          toName: accountData.toName,
          transformation: accountData.transformation,
          editable: false,
        }),
      )
    }
  }

  return (
    <Modal show={show} onHide={onHide} centered size={'lg'}>
      <Modal.Header closeButton>
        <Modal.Title>Editar Cuenta </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row data-testid={'edit-account-form'}>
          <Col>
            <FormText
              label={'Nombre'}
              type={'text'}
              id={'name'}
              value={accountData?.name}
              data-testid={'name'}
              placeholder={accounnt.name}
              name={'name'}
              onChange={handleAccountData}
              error={''}
              required
            />
          </Col>
          <Col>
            <FormText
              label={'A nombre de '}
              type={'text'}
              id={'toName'}
              value={accountData?.toName}
              onChange={handleAccountData}
              placeholder={'Nombre del propietario'}
              error={''}
              data-testid={'toName'}
              required
            />
          </Col>
          <Col>
            <Select
              label={'Encargado'}
              options={usersSubmodule}
              id={'subModule'}
              value={currentOwner}
              dataCy={'subModule'}
              onChange={data => {
                // @ts-ignore
                setAccountData({ ...accountData, asigned: data.value })
              }}
            />
          </Col>

          <Col>
            <Select
              label={'Moneda'}
              options={currencies}
              value={currencies[idForCurrency]}
              dataCy={'currencies'}
              onChange={data => {
                setAccountData({ ...accountData, currency: data })
              }}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <Switch
            checked={accountData.report}
            onChange={() =>
              setAccountData({ ...accountData, report: !accountData.report })
            }
            label={'Afectar reporte patrimonial'}
            info={
              'Al activar esta opción, las actualizaciones sobre los saldos de esta cuenta se reflejarán en el reporte patrimonial.'
            }
            placement={'top'}
            dataCy={'shouldAccount'}
            id={'reportAccount'}
          />
        </div>
        <div>
          <Button
            onClick={saveAccountUpdates}
            style={{ maxWidth: '100%' }}
            loading={updatedAccountFlag}>
            Guardar
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default EditAccountModal
