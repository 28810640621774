import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  TableV2,
  SelectedDates,
  Card,
  Icon,
  Button,
  CustomSummary,
} from '../../components'
import { Modal, Row, Col } from 'react-bootstrap'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { faEye, faFileExcel, faList } from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes,
  downloadProductsExcel,
  downloadTransactionsExcel,
  getProductsClient,
  getTransactionClient,
} from 'src/actions/clients.actions'
import { selectProducts, selectTransactions } from 'src/selectors/clients.selector'

import {
  actionTypes as typeO,
  onSetPaymentId,
  getPaymentsByOrder,
} from 'src/actions/orders.actions'

import { loadingSelector } from 'src/selectors/loading.selector'
import { onSetModalOrder, setModalOrderPayment } from 'src/actions/utilities.actions'

import { formatDateFromMillis, formatNumberWithCommas } from 'src/utils/formatters'
import { toMoney } from 'src/utils/utilities'
import { selectPaymentsByOrder } from 'src/selectors/orders.selector'
import { selectModalOrderPayment } from 'src/selectors/utilities.selector'

const ClientTransactions = ({
  show,
  onHide,
  id,
  name,
  balancePermission,
  canDownload,
}) => {
  const dispatch = useDispatch()

  const options = [
    { label: 'Estado de cuenta', name: 'Ventas', id: 0, seeValues: true },
    { label: 'Ventas', name: 'Ventas', id: 1, seeValues: true },
    { label: 'Recibos', name: 'Recibos', id: 3, seeValues: true },
    { label: 'Vales y notas de crédito', name: 'N/C', id: 4, seeValues: true },
    { label: 'Ítems vendidos', name: 'N', id: 5, seeValues: true },
  ]

  const modalOrderPayment = useSelector(selectModalOrderPayment)

  const data = useSelector(selectTransactions)
  const paymentOrders = useSelector(selectPaymentsByOrder)
  const loading = useSelector(state =>
    loadingSelector([actionTypes.GET_TRANSACTIONS, typeO.GET_PAYMENTS_BY_ORDER])(state),
  )

  const products = useSelector(selectProducts)
  const loadingP = useSelector(state =>
    loadingSelector([actionTypes.GET_PRODUCTS_CLIENT])(state),
  )

  const loadingET = useSelector(state =>
    loadingSelector([
      actionTypes.DOWNLOAD_EXCEL_TRANSACTIONS,
      actionTypes.GET_EXCEL_PRODUCTS,
    ])(state),
  )

  const [open, setOpen] = useState(false)
  const [paramsText, setParamsText] = useState('')
  const [params, setParams] = useState({
    start: null,
    end: null,
    skip: 0,
    size: 10,
    search: null,
    transactionType: 0,
  })
  const [paymentsByOrder, setPaymentByOrder] = useState({})

  useEffect(() => {
    if (!show || !id) {
      setParamsText('')
    } else setOpen(true)
  }, [show])

  useEffect(() => {
    if (!open) return
    setParamsText(JSON.stringify(params))
  }, [open])

  useEffect(() => {
    if (paramsText === '' || paramsText === JSON.stringify(params)) return
    setParamsText(JSON.stringify(params))
  }, [params])

  useEffect(() => {
    if (paramsText !== JSON.stringify(params)) return
    setUp()
  }, [paramsText])

  const setUp = () => {
    const { transactionType, start, end } = params
    if (transactionType === 5) dispatch(getProductsClient(id, { start, end }))
    else {
      dispatch(
        getTransactionClient(
          {
            ...params,
          },
          id,
        ),
      )
    }
  }

  const onClose = () => {
    setParamsText('')
    setParams({ ...params, skip: 0, size: 10 })
    setOpen(false)
    if (onHide) onHide()
  }

  const getTransactionHeader = id => {
    const headers = []
    if (id !== 5) {
      headers.push(
        ...[
          { label: 'Tipo', show: id === 0 },
          { label: 'Sub Tipo', show: id === 0 },
          { label: 'Código', show: true, type: 'text' },
          { label: 'Estado', show: true, type: 'text' },
          { label: 'Fecha', show: true, type: 'date' },
          { label: 'Encargado', show: true, type: 'text' },
          { label: 'Total', show: true, type: 'currency' },
          { label: 'Pagos', show: id === 0 },
          { label: 'N/C', show: id === 0 },
          { label: 'Saldo', show: id === 0 },
          { show: true },
        ],
      )
    } else {
      headers.push(
        ...[
          { label: 'SKU / Código', show: true, type: 'text' },
          { label: 'Ítem', show: true, type: 'text' },
          { label: 'Unidades', show: true, type: 'number' },
          { label: 'Precio promedio', show: true, type: 'currency' },
          { label: 'Último precio', show: true, type: 'currency' },
          { label: 'Ultima compra', show: true, type: 'date' },
        ],
      )
    }

    return headers
  }

  const getBalance = (item, payments) => {
    let response = Number(item.total || 0)
    if (payments) {
      response -= payments.creditNotes
      response -= payments.payments
    }
    return response
  }

  const getType = ({ type }) => {
    let response = ''
    switch (type) {
      case 1:
        response = 'Venta'
        break
      case 2:
        response = 'CxC'
        break
      case 3:
        response = 'Recibos'
        break
      case 4:
        response = 'Vales | N/C'
        break
      default:
    }
    return response
  }

  const renderItems = (item, index, itemType) => {
    const {
      id,
      documentId,
      number,
      statusData,
      date,
      total,
      userData,
      type,
      paymentsResponse,
      referenceId,
    } = item

    return (
      <tr key={index} className={'data'}>
        {itemType === 0 && <td>{referenceId ? '-' : getType(item)}</td>}
        {itemType === 0 && <td>{!referenceId ? '-' : getType(item)}</td>}
        <td>{number}</td>
        <td>{statusData?.name}</td>
        <td>{formatDateFromMillis(date)}</td>
        <td>{userData?.name}</td>
        <td>{toMoney(itemType === 0 ? (referenceId ? 0 : total) : total)}</td>

        {itemType === 0 && (
          <>
            <td>
              {formatNumberWithCommas(
                !referenceId ? paymentsResponse?.payments || 0 : type === 3 ? total : 0,
              )}
            </td>
            <td>
              {formatNumberWithCommas(
                !referenceId
                  ? paymentsResponse?.creditNotes || 0
                  : type === 4
                  ? total
                  : 0,
              )}
            </td>
            <td>
              {formatNumberWithCommas(
                !referenceId ? getBalance(item, paymentsResponse) : 0,
              )}
            </td>
          </>
        )}

        <td>
          {type !== 4 && (
            <div className={'d-flex'}>
              <Icon
                icon={faEye}
                tooltip={'Ver detalle'}
                onClick={() => {
                  if (type === 1) dispatch(onSetModalOrder(documentId))
                  else dispatch(setModalOrderPayment({ show: true, id: documentId }))
                }}
              />

              {type === 1 && (
                <Icon
                  style={{ marginLeft: 15 }}
                  icon={faList}
                  tooltip={'Ver pagos'}
                  onClick={() => {
                    setPaymentByOrder({ show: true, number })
                    dispatch(getPaymentsByOrder(id))
                  }}
                />
              )}
            </div>
          )}
        </td>
      </tr>
    )
  }

  const renderProducts = (item, index) => {
    const { code, name, quantity, total, price, date } = item

    return (
      <tr key={index} className={'data'}>
        <td className={'medium'}>{code}</td>
        <td className={'medium'}>{name}</td>
        <td className={'mini'}>{quantity}</td>
        <td className={'currency'}>{formatNumberWithCommas(total / quantity)}</td>
        <td className={'currency'}>{formatNumberWithCommas(price)}</td>
        <td className={'medium'}>{formatDateFromMillis(date)}</td>
      </tr>
    )
  }

  const renderTable = (type, items) => {
    return (
      <Card>
        <TableV2
          customClass={'scroll-x-without-height'}
          loading={loading || loadingP}
          headers={getTransactionHeader(type)}
          items={items}
          total={type <= 4 ? data.total : null}
          getPagination={
            type <= 4
              ? (skip, size, search) => setParams({ ...params, skip, size, search })
              : null
          }
          handleChange={type <= 4 ? search => setParams({ ...params, search }) : null}
          renderRow={(item, index) =>
            type !== 5 ? renderItems(item, index, type) : renderProducts(item, index)
          }
          footerText={
            balancePermission !== undefined && balancePermission === false ? (
              <></>
            ) : (
              type <= 4 && (
                <CustomSummary
                  items={[
                    {
                      show: type === 0 || type === 1,
                      title: 'Ventas',
                      value: formatNumberWithCommas(data?.value || 0),
                    },
                    {
                      show: type === 0 || type === 3,
                      value: formatNumberWithCommas(data?.payments || 0),
                      title: 'Recibos',
                    },
                    {
                      show: type === 0 || type === 4,
                      title: 'Vales | N/C',
                      value: formatNumberWithCommas(data?.creditNote || 0),
                    },
                    {
                      show: type === 0,
                      title: 'Saldo',
                      value: formatNumberWithCommas(
                        getBalance(
                          { total: data?.value || 0 },
                          {
                            payments: Number(data?.payments || 0),
                            creditNotes: Number(data?.creditNote || 0),
                          },
                        ),
                      ),
                    },
                  ]}
                />
              )
            )
          }
          balancePermission={balancePermission}
        />
      </Card>
    )
  }

  return (
    <div>
      <Modal
        show={open && !paymentsByOrder.show && !modalOrderPayment.show}
        size={'xl'}
        centered
        onHide={() => {
          onClose()
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Transacciones del cliente: {name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12}>
              <SelectedDates
                withOptionNull
                onDateChange={(start, end) => setParams({ ...params, start, end })}
              />
            </Col>
            <Col xl={12}>
              <Tabs
                defaultIndex={0}
                onSelect={index => {
                  const t = options[index]
                  setParams({
                    ...params,
                    transactionType: t.id,
                    skip: 0,
                    size: 10,
                    search: null,
                  })
                }}>
                <div>
                  <TabList>
                    {options.map((item, index) => (
                      <Tab key={index}>
                        <div>{item.label}</div>
                      </Tab>
                    ))}
                  </TabList>
                </div>

                {options.map((op, index) => (
                  <TabPanel key={index}>
                    {renderTable(
                      params.transactionType,
                      params.transactionType !== 5
                        ? data && data.list
                          ? data.list
                          : []
                        : products,
                    )}
                  </TabPanel>
                ))}
              </Tabs>
            </Col>
          </Row>
        </Modal.Body>
        {(params.transactionType === 0 || params.transactionType === 5) && canDownload && (
          <Modal.Footer>
            <Row className={'container-buttons'}>
              <Button
                loading={loadingET}
                icon={faFileExcel}
                onClick={() => {
                  if (params.transactionType === 5) {
                    dispatch(
                      downloadProductsExcel(id, {
                        start: params.start,
                        end: params.end,
                      }),
                    )
                  } else
                    dispatch(
                      downloadTransactionsExcel(id, {
                        start: params.start,
                        end: params.end,
                      }),
                    )
                }}>
                Exportar
              </Button>
            </Row>
          </Modal.Footer>
        )}
      </Modal>

      <Modal
        show={paymentsByOrder.show && !modalOrderPayment.show}
        size={'xl'}
        centered
        onHide={() => setPaymentByOrder({})}>
        <Modal.Header closeButton>
          <Modal.Title>Abonos: {paymentsByOrder.number}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12}>{renderTable(6, paymentOrders)}</Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default ClientTransactions
