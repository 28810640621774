import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Modal } from 'react-bootstrap'
import { Button, TableV2 } from 'src/components'

import { onSetPrintData } from 'src/actions/restaurant.actions'
import { selectPrintData } from 'src/selectors/restaurant.selector'

import {
  types,
  getAll,
  savePrintConnect,
  onSaveLocalPrints,
  sendPrintToLocalhost,
} from 'src/actions/printer.actions'
import {
  selectAllPrinters,
  selectGeneralData,
  selectPrinterData,
  selectPrintLocalResponse,
} from 'src/selectors/printer.selector'

import { printTicket } from 'src/utils/utilities'
import {
  connect,
  sendToPrint,
  disconnect,
  getEscPosCommands,
} from 'src/content/Prints/PrintFunctions'

import { loadingSelector } from 'src/selectors/loading.selector'
import { handlerError, hasErrors } from 'src/selectors/error.selector'

import { PrintConnectionTypeEnum } from 'src/enums/printerEnum'

import Printer from 'src/content/Prints/Printer'
import { showAlert } from '../../actions/alert.actions'

/**
 * @component Modal to show all printers and print ticket or invoice
 */
const Printers = () => {
  const dispatch = useDispatch()

  const loadingPrintLocal = useSelector(state =>
    loadingSelector([types.SEND_PRINT_TO_LOCALHOST])(state),
  )
  const hasErrorPrintLocal = useSelector(state =>
    hasErrors([types.SEND_PRINT_TO_LOCALHOST])(state),
  )
  const printLocalResponse = useSelector(selectPrintLocalResponse)

  const loading = useSelector(state => loadingSelector([types.GET_PRINTERS])(state))
  const loadingConnect = useSelector(state =>
    loadingSelector([types.SAVE_PRINT_CONNECT])(state),
  )

  const iframeRef = useRef(null)
  const printData: IPrintData = useSelector(selectPrintData)
  const printers: IPrinter[] = useSelector(selectAllPrinters) || []
  const data = useSelector(selectPrinterData)
  const generalData: IGeneralDataPrints = useSelector(selectGeneralData)

  const { content, element, customData, itemsPrint, logo, pdfBlob, size } = printData
  const { tableId, pos } = generalData

  const [index, setIndex] = useState(-1)
  const [actions, setActions] = useState({
    all: false,
    connect: false,
    localPrint: false,
  })
  const [printerIdToPrint, setPrinterId] = useState<number>(null)

  useEffect(() => {
    window.addEventListener('popstate', onClose)
    return () => {
      window.removeEventListener('popstate', onClose)
    }
  }, [])

  useEffect(() => {
    if (!printData.show) return
    if (pdfBlob) {
      printWeb()
      onClose()
    } else if (printers.length <= 0) dispatch(getAll(tableId))
  }, [printData.show])

  useEffect(() => {
    if (!printData.show) return
    if (loading) setActions({ ...actions, all: true })
    else if (actions.all) {
      setActions({ ...actions, all: false })
      if (printers.length === 0) {
        printWeb()
        onClose()
      }
    }
  }, [loading])

  useEffect(() => {
    if (!printData.show) return
    if (itemsPrint.length === 0) return
    if (itemsPrint && itemsPrint.length > 0) {
      const printersCopy = [...printers]
      itemsPrint.forEach(print => {
        const index = printersCopy.findIndex(pc => pc.id === print.id)
        printersCopy[index] = { ...print, data: null }
      })
      dispatch(onSaveLocalPrints(printersCopy))
      setIndex(0)
    }
  }, [itemsPrint?.length > 0])

  useEffect(() => {
    if (loadingConnect) setActions({ ...actions, connect: true })
    else if (actions.connect) {
      setActions({ ...actions, connect: false })
      const { id, value } = data
      dispatch(
        onSaveLocalPrints(
          Object.assign(
            [],
            printers.map(print => ({
              ...print,
              printValue: print.id === id ? value : print?.printValue,
            })),
          ),
        ),
      )
    }
  }, [loadingConnect])

  useEffect(() => {
    if (index === -1) return

    if (itemsPrint && itemsPrint.length > 0) {
      if (itemsPrint.length < index + 1) {
        setIndex(-1)
        return
      }
      individualPrint(itemsPrint[index])
    } else setIndex(-1)
  }, [index])

  useEffect(() => {
    if (!printData.show) return
    if (loadingPrintLocal) setActions({ ...actions, localPrint: true })
    else if (actions.localPrint) {
      setActions({ ...actions, localPrint: false })
      const { status, message } = printLocalResponse
      let connected = true
      let messageError: string
      if (hasErrorPrintLocal) {
        connected = false
        messageError = 'No fue posible conectarse al servidor de impresión.'
      } else messageError = message
      const item = printers.find(p => p.id === printerIdToPrint)
      setPrinterId(null)
      setPrintData({
        ...item,
        cLoading: false,
        connected: connected,
        error: !status,
        e: messageError,
      })
      if (index > -1) setIndex(index + 1)
    }
  }, [loadingPrintLocal])

  const setPrintData = (printEdited: IPrinter) => {
    dispatch(
      onSaveLocalPrints(
        printers.map(printer => (printer.id === printEdited.id ? printEdited : printer)),
      ),
    )
  }

  const printWeb = () => {
    if (pdfBlob) {
      const pdfURL = URL.createObjectURL(pdfBlob)

      const iframe = iframeRef.current
      iframe.src = pdfURL

      iframe.onload = function () {
        try {
          iframe.contentWindow.focus()
          iframe.contentWindow.print()
        } catch (error) {
          dispatch(
            showAlert(
              handlerError(
                'Para asegurarte de que puedas realizar impresiones correctamente, es necesario que habilites las ventanas emergentes en tu navegador. Esto permitirá que el sitio muestre la vista previa de impresión sin interrupciones. Por favor, verifica la configuración de tu navegador y permite las ventanas emergentes para este sitio. Si necesitas ayuda adicional, consulta la sección de ayuda de tu navegador o contáctanos para más asistencia. ¡Gracias!',
              ),
            ),
          )
        } finally {
          URL.revokeObjectURL(pdfURL)
        }
      }
    } else {
      printTicket(
        content || document.getElementById(element)?.innerHTML,
        null,
        null,
        logo || (pos?.activePreTicketsLogo ? pos?.ticketsLogo : 'undefined'),
      )
    }
    if (index > -1) setIndex(index + 1)
  }

  const getData = (printer: IPrinter): IDataPrint[] => {
    let response: IDataPrint[] = null
    if (itemsPrint && itemsPrint.length > 0) {
      const item = itemsPrint.find(i => i.id === printer.id)
      if (item) response = item.data || customData
    }
    if (response === null) response = printer.data || customData
    return response
  }

  const individualPrint = (item: IPrinter) => {
    if (item.ip === '0.0.0.0') printWeb()
    else {
      setPrinterId(item.id)

      if (item.connectionType === PrintConnectionTypeEnum.ESC_POS) {
        const data: IDataPrint[] = getData(item)
        getEscPosCommands(data, size || item.textSize, commands => {
          dispatch(
            sendPrintToLocalhost(
              {
                ip: item.ip,
                data: commands,
                logo: logo,
              },
              item.host,
            ),
          )
        })
      } else {
        if (item.printer) toPrint(item)
        else {
          connect(
            item.ip,
            response => {
              const { ok, error, printer, ePosDev } = response
              const printConnected: IPrinter = {
                ...item,
                printLoading: false,
                connected: ok,
                error: !ok,
                exception: error,
                e: error,
                printer,
                ePosDev,
              }
              if (!ok) {
                setPrinterId(null)
                if (index > -1) setIndex(index + 1)
              }
              setPrintData(printConnected)
              toPrint(printConnected)
            },
            () => {
              setPrinterId(null)
              if (index > -1) setIndex(index + 1)
            },
          )
        }
      }
    }
  }

  const toPrint = (item: IPrinter) => {
    if (item.connected) {
      const data = getData(item)
      if (data) sendToPrint(item.printer, data, size || item.textSize)
    } else if (index > -1) setIndex(index + 1)
  }

  const onDisconnectPrint = (item: IPrinter) => {
    const disconnectToPrint = () => {
      setPrintData({
        ...item,
        printer: undefined,
        ePosDev: undefined,
        printLoading: false,
        cLoading: false,
        connected: false,
        error: false,
      })
      setPrinterId(null)
      setIndex(-1)
    }
    if (item.printer) disconnect(item.ePosDev, item.printer)
    disconnectToPrint()
  }

  const onClose = () => {
    setPrinterId(null)
    if (printData.onClose) printData.onClose()
    dispatch(onSetPrintData({}))
  }

  const listPrints = (
    <div className="mt-3 column">
      <TableV2
        /* @ts-expect-error JS*/
        items={printers}
        renderRow={(item: IPrinter) => {
          const spin: boolean = item.id === printerIdToPrint

          return (
            <Printer
              key={item.id}
              print={item}
              loading={spin}
              disabled={spin}
              onPrint={() => individualPrint(item)}
              onConnect={host => {
                window.open(
                  `https://${host}`,
                  item.alias,
                  'width=550,height=550,scrollbars=NO',
                )
                dispatch(savePrintConnect(item.id, true))
              }}
              onDisconnectPrint={() => onDisconnectPrint(item)}
            />
          )
        }}
        footerText={
          <>
            <Button
              color={'secondary'}
              onClick={onClose}
              disabled={index > -1 || printerIdToPrint}>
              Cerrar
            </Button>
          </>
        }
      />
    </div>
  )

  return (
    <>
      <Modal
        show={printData.show && printers.length > 0 && !pdfBlob}
        size={'lg'}
        centered
        onHide={index > -1 || printerIdToPrint ? () => undefined : onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Impresoras</Modal.Title>
        </Modal.Header>
        <Modal.Body>{listPrints}</Modal.Body>
      </Modal>
      <div>
        <iframe ref={iframeRef} title="Imprimir PDF" style={{ display: 'none' }} />
      </div>
    </>
  )
}
export default Printers
