import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Row, Col, Modal } from 'react-bootstrap'
import {
  Button,
  CustomTabs,
  FormText,
  Select,
  NumberField,
  MoneyField,
  ListCategories,
  SwitchV2,
  FormTextArea,
  CustomCreate,
} from 'src/components'
import CSVLoaderV2, { CATEGORY_UPLOAD } from 'src/components/CSVLoader/CSVLoaderV2'
import Folder, { categoryType } from 'src/components/folders/Folder'
import ImageInput, { imageTypes } from 'src/components/inputs/ImageInput/ImageInput'
import { ListVariations } from 'src/content/Production/Categorization/Variation/ListVariations'
import {
  faEdit,
  faExclamationTriangle,
  faPlus,
  faSave,
  faWindowClose,
} from '@fortawesome/free-solid-svg-icons'

import { toMoney } from 'src/utils/utilities'

import { showAlert } from 'src/actions/alert.actions'
import { loadingSelector } from 'src/selectors/loading.selector'

import { itemsPermissions } from 'src/enums/permissions'
import { itemTypes } from 'src/components/Items/ItemsToSelect'
import { isAllowed } from 'src/selectors/modules.selector'

import {
  handlerError,
  handlerInfoWithButtons,
  handlerSuccess,
  hasErrors,
} from 'src/selectors/error.selector'

import { selectCompanyCountry, selectFelItemTypes } from 'src/selectors/company.selector'

import {
  actionTypes,
  getMeasurementUnits,
  getProductPricesNames,
  getItemDetail,
  onCreateOrUpdateItem,
} from 'src/actions/products.actions'
import {
  selectMeasurementUnits,
  selectPricesList,
  selectProductItemDetail,
  selectProductItemResponse,
} from 'src/selectors/products.selector'
import {
  assignCategorizationToEntity,
  getAllCategorizations,
  getCategorizationByType,
} from 'src/actions/categorization.actions'
import {
  selectAllCategorizations,
  selectCategorizationByType,
} from 'src/selectors/categorizations.selector'

import { Country } from 'src/enums/countries'

import ItemConfigs from './ItemConfigs'
import ProductBarcodes from './ProductBarcodes'
import { selectActivePlans } from 'src/selectors/plans.selector'
import { PlanEnum } from 'src/enums/planTypes'
import { getAllTaxes } from 'src/actions/taxes.actions'
import { selectAllTaxesActive } from 'src/selectors/taxes.selector'
import { haveAnyValue } from 'src/utils/utilitiesV2'

interface IProps {
  show: boolean
  id?: number
  name?: string
  onHide: (update: boolean, response) => void
  createdByTransaction?: number
}

interface IErrors {
  general: IItemDetail
  advanced: IItemDetail
}

interface IFlags {
  get: boolean
  createOrUpdate: boolean
}

const defaultOption: ISelect = { value: null, label: 'Sin seleccionar' }

const returnPriceValue = (object): ISelect => ({
  value: object.price,
  label: toMoney(object.price),
  name: object.alias,
})

const ItemCRUD = ({ show = false, id, name, onHide, createdByTransaction }: IProps) => {
  const dispatch = useDispatch()

  /* PERMISSIONS */
  const create: boolean = useSelector(state =>
    isAllowed(state, [itemsPermissions.create]),
  )
  const update: boolean = useSelector(state =>
    isAllowed(state, [itemsPermissions.update]),
  )

  /* EFFECTS */
  const itemResponse = useSelector(selectProductItemResponse)
  const loadingCreateOrUpdate: boolean = useSelector(state =>
    loadingSelector([actionTypes.ON_CREATE_OR_UPDATE_ITEM])(state),
  )
  const errorCreateOrUpdate = useSelector(state =>
    hasErrors([actionTypes.ON_CREATE_OR_UPDATE_ITEM])(state),
  )

  const itemDetailResponse = useSelector(selectProductItemDetail)
  const loadingGetItem: boolean = useSelector(state =>
    loadingSelector([actionTypes.GET_ITEM_DETAIL])(state),
  )
  const errorGetItem = useSelector(state =>
    hasErrors([actionTypes.GET_ITEM_DETAIL])(state),
  )

  const [flags, setFlags] = useState<IFlags>({ get: false, createOrUpdate: false })

  /* SELECTORS */
  const country = useSelector(selectCompanyCountry)
  const felItemTypes = useSelector(selectFelItemTypes)
  const measurementUnits = useSelector(selectMeasurementUnits)
  const priceNames = useSelector(selectPricesList)
  const categories = useSelector(selectAllCategorizations)
  const variations = useSelector(selectCategorizationByType)
  const plan = useSelector(selectActivePlans)
  const taxes = useSelector(selectAllTaxesActive)

  /* CONST */
  const disabledInputs = loadingCreateOrUpdate || loadingGetItem || (id && !update)

  const measurementUnitsOptions: ISelect[] = [defaultOption].concat(
    measurementUnits.map(item => ({
      label: `${item.name}${item.symbol ? ` (${item.symbol})` : ''}`,
      value: item.id,
    })),
  )

  /* VARIABLES */
  const [item, setItem] = useState<IItemDetail>({
    felItemType: defaultOption,
    measurementUnit: defaultOption,
    measurementValue: 1,
    prices: [],
    image: { url: null },
    withoutInventory: plan?.id === PlanEnum.FREE,
  })
  const [initialVisibility, setInitialVisibility] = useState<boolean>(false)
  const [showCategorization, setShowCategorization] = useState<boolean>(false)
  const [isCommissionPercentage, setIsCommissionPercentage] = useState<boolean>(false)
  const [showNewPrice, setNewPrice] = useState({
    show: false,
    label: null,
    price: 0,
  })
  const [showVariations, setShowVariations] = useState<boolean>(false)
  const [errors, setErrores] = useState<IErrors>({
    general: {},
    advanced: {},
  })

  useEffect(() => {
    if (!show) return
    dispatch(getAllTaxes())
    dispatch(getMeasurementUnits())
    dispatch(getProductPricesNames())
    dispatch(getAllCategorizations(3))
    if (id) dispatch(getItemDetail(id))
    else if (createdByTransaction) {
      let initialData
      if (createdByTransaction === itemTypes.EXPENSE) {
        initialData = onAssignPrice(
          {
            price: 0,
            alias: 'Precio por defecto',
          },
          true,
        )
      } else initialData = {}

      initialData.visibility = {
        purchase: createdByTransaction === itemTypes.PURCHASE,
        expense: createdByTransaction === itemTypes.EXPENSE,
        sell: createdByTransaction === itemTypes.SELL,
        increase: createdByTransaction === itemTypes.PRODUCTION,
        waste: createdByTransaction === itemTypes.WASTE,
      }
      setItem({ ...item, ...initialData })

      setTimeout(() => {
        setInitialVisibility(true)
      }, 100)
    }
  }, [show])

  useEffect(() => {
    if (loadingGetItem) setFlags({ ...flags, get: true })
    else if (flags.get) {
      setFlags({ ...flags, get: false })
      if (errorGetItem) dispatch(showAlert({ ...handlerError(errorGetItem.message) }))
      else {
        setItem({
          ...itemDetailResponse,
          measurementValue: itemDetailResponse.measurementValue || 1,
          image: itemDetailResponse.image
            ? { ...itemDetailResponse.image, url: itemDetailResponse.image.label }
            : { url: null },
          defaultPrice: {
            value: itemDetailResponse.price,
            label: toMoney(itemDetailResponse.price),
          },
          visibility: {
            online: itemDetailResponse.online,
            purchase: itemDetailResponse.purchase,
            expense: itemDetailResponse.expense,
            sell: itemDetailResponse.sell,
            increase: itemDetailResponse.increase,
            waste: itemDetailResponse.waste,
          },
        })
        setInitialVisibility(true)
        setIsCommissionPercentage(itemDetailResponse?.commission === null)
      }
    }
  }, [loadingGetItem])

  useEffect(() => {
    if (loadingCreateOrUpdate) setFlags({ ...flags, createOrUpdate: true })
    else if (flags.createOrUpdate) {
      setFlags({ ...flags, createOrUpdate: false })
      const alert = errorCreateOrUpdate
        ? { ...handlerError(errorCreateOrUpdate.message) }
        : {
            ...handlerSuccess('Operación realizada con éxito.'),
            onConfirm: () => onClose(true, itemResponse),
          }
      dispatch(showAlert(alert))
    }
  }, [loadingCreateOrUpdate])

  const onClose = (update?: boolean, response?: object) => {
    onHide(update, response)
    setItem({
      felItemType: defaultOption,
      measurementUnit: defaultOption,
      prices: [],
    })
    setInitialVisibility(false)
  }

  const onSuccessFile = url => {
    setItem({ ...item, image: { url } })
  }

  const onAssignCategorization = (category: ISelect) => {
    const categories: ISelect[] = Object.assign([], item.categories || [])
    const index = categories.findIndex(c => c.id === category.id)
    if (index === -1) categories.push(category)
    else categories.splice(index, 1)
    setItem({ ...item, categories })
    if (id) dispatch(assignCategorizationToEntity('products', category.id, id, true))
  }

  const onAssignPrice = (
    price: IItemPrice,
    onlyReturn?: boolean,
  ): { prices: IItemPrice[]; defaultPrice: ISelect } => {
    const prices: IItemPrice[] = Object.assign([], item.prices || [])
    const index = prices.findIndex(p => p.price === price.price)
    if (index === -1) prices.push(price)
    else prices.splice(index, 1)

    let principalPrice = prices.find(p => p.price === item?.defaultPrice?.value)
    if (!principalPrice && prices.length === 1) principalPrice = prices[0]

    const defaultPrice: ISelect = {
      value: principalPrice?.price,
      label: !principalPrice ? 'Sin seleccionar' : toMoney(principalPrice?.price),
    }

    if (!onlyReturn)
      setItem({
        ...item,
        prices,
        defaultPrice,
      })
    return { prices, defaultPrice }
  }

  const onValidate = () => {
    const validation: IErrors = { general: {}, advanced: {} }
    const genericError = 'Es necesario.'

    if (!item.name) validation.general.name = genericError
    if (!item.felItemType?.value) validation.general.felItemType = { value: genericError }
    if (country.id === Country.SV) {
      if (!item.measurementUnit?.value)
        validation.general.measurementUnit = { value: genericError }
    }
    if (isNaN(Number(item.defaultPrice?.value)) || Number(item.defaultPrice?.value) < 0)
      validation.general.defaultPrice = { value: genericError }

    if (item.useDecimals && !item.decimals)
      validation.advanced.decimalsError = genericError
    if (
      item.measurementUnit?.value != null &&
      (item.measurementValue == null || item.measurementValue <= 0)
    )
      validation.general.measurementValueError = 'Debe ser mayor a 0'

    setErrores(validation)
    if (Object.keys({ ...validation.general, ...validation.advanced }).length > 0) return

    const request = {
      ...item,
      ...item.visibility,
      price: item.defaultPrice?.value,
      felItemTypeId: item.felItemType?.value,
      measurementUnitId: item.measurementUnit?.value,
      measurementValue:
        item.measurementUnit?.value != null ? item.measurementValue : null,
      categoriesRequest: item.categories?.map(c => c.id),
      variationsRequest: item.variations?.map(v => v.id),
      barcodesRequest: item.barcodes?.map(v => v.label),
    }

    const images = []
    if (!id && item.image && item.image.url)
      images.push({ url: item.image.url, name: `principal-img-${item.name}` })

    const paramsToRemove = [
      'felItemType',
      'measurementUnit',
      'visibility',
      'categories',
      'variations',
      'barcodes',
      'defaultPrice',
      'image',
    ]
    if (item.automaticallyBarcode) paramsToRemove.push('barcodesRequest')

    paramsToRemove.forEach(param => {
      delete request[param]
    })

    dispatch(onCreateOrUpdateItem(id, request, images))
  }

  const getTax = (taxId: number) => {
    if (!haveAnyValue(taxId)) return defaultOption
    const tax = taxes.find((t: Tax) => t.id === taxId)
    if (haveAnyValue(tax))
      return { value: tax.id, label: `${tax.name} %${tax.rate * 100}` }
    else return defaultOption
  }

  const generalInfo = (
    <Row>
      <Col xl={12} lg={12} md={12} sm={12} className={'mb-1'}>
        <ListCategories
          label={'Categorías'}
          items={item.categories}
          disabled={disabledInputs}
          onRemove={onAssignCategorization}
          addItem={() => setShowCategorization(true)}
          seeAddItem={!disabledInputs}
        />
      </Col>
      <Col xl={4} lg={4} md={12} sm={12}>
        <ImageInput
          src={item.image}
          onSuccessFile={onSuccessFile}
          imageType={imageTypes.PRODUCT}
          entityId={id}
          gallery={undefined}
          disabled={disabledInputs}
          placeholder={undefined}
          style={{ margin: 'auto' }}
        />
      </Col>
      <Col xl={8} lg={8} md={12} sm={12} className={'mb-1'}>
        <Row>
          <Col xl={5} lg={5} md={6} sm={12}>
            <FormText
              mt={0}
              label={'SKU/Código'}
              placeholder={'Ingrese un SKU propio o kolo le generara uno.'}
              value={item.code}
              changeValue={code => setItem({ ...item, code })}
              disabled={disabledInputs}
            />
          </Col>

          <Col xl={7} lg={7} md={6} sm={12}>
            <FormText
              mt={0}
              required
              label={'Nombre'}
              placeholder={'Nombre del ítem'}
              value={item.name}
              changeValue={name => setItem({ ...item, name })}
              disabled={disabledInputs}
              error={errors.general.name}
            />
          </Col>

          <Col xl={12} lg={12} md={12} sm={12}>
            <FormTextArea
              label={'Descripción'}
              value={item.description}
              name={'description'}
              id={'description'}
              rows={'3'}
              disabled={disabledInputs}
              onChange={({ target }) => setItem({ ...item, description: target.value })}
            />
          </Col>
        </Row>
      </Col>
      <Col xl={12}>
        <Row>
          <Col xl={6} lg={6} md={6} sm={12}>
            <Row>
              <Col xl={6} lg={6} md={12} sm={12}>
                <Select
                  required
                  label={'Bien o servicio'}
                  value={item.felItemType}
                  mt={2}
                  disabled={disabledInputs}
                  options={felItemTypes}
                  onChange={felItemType => setItem({ ...item, felItemType })}
                  error={errors.general?.felItemType?.value}
                />
              </Col>
              <Col xl={6} lg={6} md={12} sm={12}>
                <MoneyField
                  label={'Costo unitario'}
                  value={item.cost}
                  containerStyle={{ marginTop: 13 }}
                  onValueChange={cost => setItem({ ...item, cost })}
                  min={0}
                  disabled={disabledInputs}
                />
              </Col>
              <Col xl={6} lg={6} md={12} sm={12}>
                <Select
                  required={country.id === Country.SV}
                  label={'Unidad de medida'}
                  value={item.measurementUnit}
                  mt={2}
                  disabled={disabledInputs}
                  options={measurementUnitsOptions}
                  onChange={measurementUnit => setItem({ ...item, measurementUnit })}
                  error={errors.general?.measurementUnit?.value}
                />
              </Col>
              <Col xl={6} lg={6} md={12} sm={12}>
                <NumberField
                  decimals={6}
                  min={0}
                  measurementUnit={Number(item.measurementUnit?.value)}
                  appendBefore
                  label={'Equivalencia de medida'}
                  value={item.measurementValue}
                  placeholder="1"
                  containerStyle={{ marginTop: 13 }}
                  onValueChange={measurementValue =>
                    setItem({ ...item, measurementValue })
                  }
                  disabled={disabledInputs || item.measurementUnit?.value == null}
                  error={errors.general.measurementValueError}
                />
              </Col>
            </Row>
          </Col>
          <Col xl={6} lg={6} md={6} sm={12}>
            <ListCategories
              seeAddItem={!disabledInputs}
              items={item.prices?.map(returnPriceValue)}
              onRemove={price =>
                onAssignPrice({ price: price.value, alias: price.label })
              }
              addItem={() => setNewPrice({ show: true, label: null, price: 0 })}
              label={'Precios de venta'}
            />
            <Select
              disabled={disabledInputs}
              required
              label={'Precio de venta por defecto'}
              value={item.defaultPrice}
              options={item.prices?.map(returnPriceValue)}
              onChange={defaultPrice => setItem({ ...item, defaultPrice })}
              error={errors.general?.defaultPrice?.value}
            />
          </Col>
          <Col xl={6} lg={6} md={6} sm={12}>
            <Select
              disabled={disabledInputs}
              label={'Impuesto'}
              value={getTax(item.taxId)}
              options={[
                defaultOption,
                ...taxes.map((tax: Tax) => ({
                  value: tax.id,
                  label: `${tax.name} %${tax.rate * 100}`,
                })),
              ]}
              onChange={(taxSelected: ISelectNumber) =>
                setItem({ ...item, taxId: taxSelected.value })
              }
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )

  const advanceConfig = (
    <Row className={'mt-3'}>
      <Col xl={6} lg={6} md={6} sm={12}>
        <Row>
          <Col xl={4} lg={4} md={4} sm={12}>
            <Button
              disabled={disabledInputs}
              color={'accent'}
              icon={faPlus}
              onClick={() => {
                setShowVariations(true)
                dispatch(getCategorizationByType(20))
              }}>
              Variaciones
            </Button>
          </Col>
          <Col xl={8} lg={8} md={8} sm={12}>
            {/* @ts-ignore */}
            <ListVariations
              show={showVariations}
              data={variations}
              selected={item.variations || []}
              onHide={() => setShowVariations(false)}
              onSave={variations => {
                setShowVariations(false)
                setItem({ ...item, variations })
              }}
            />
          </Col>
        </Row>
      </Col>

      <Col xl={6} lg={6} md={6} sm={12}>
        <SwitchV2
          checked={item.updatePrice}
          label={'Actualizar precio durante la venta'}
          info={
            'Al activar está opción le permitirá al usuario actualizar el precio del item durante la creación de la orden de venta.'
          }
          onChange={updatePrice => setItem({ ...item, updatePrice })}
          disabled={disabledInputs}
        />
      </Col>

      <Col xl={6} lg={6} md={6} sm={12}>
        <ProductBarcodes
          barcodes={item.barcodes}
          disabled={disabledInputs}
          onChange={barcodes => setItem({ ...item, barcodes })}
        />
      </Col>
      <Col xl={6} lg={6} md={6} sm={12}>
        <SwitchV2
          checked={item.automaticallyBarcode}
          label={'Generar automáticamente un código de barra'}
          info={
            'Al activar está opción se le asignara al ítem un código de barra auto generado.'
          }
          onChange={automaticallyBarcode => setItem({ ...item, automaticallyBarcode })}
          disabled={disabledInputs}
        />
      </Col>

      <Col xl={6} lg={6} md={6} sm={12}>
        <SwitchV2
          checked={item.useDecimals}
          label={'Uso de decimales'}
          info={
            'Al activar está opción podrá realizar transacciones con cantidades decimales.'
          }
          onChange={useDecimals => {
            if (useDecimals)
              dispatch(
                showAlert({
                  ...handlerInfoWithButtons(
                    'Efectos en el item al activar decimales',
                    'Una vez el item sea configurado y guardado con decimales, esta acción no podrá ser revertida y tampoco podrá ser configurado para usar un árbol de ítems.',
                  ),
                  onConfirm: () => setItem({ ...item, useDecimals }),
                }),
              )
            else setItem({ ...item, useDecimals })
          }}
          disabled={disabledInputs || (id && item.useDecimals)}
        />
      </Col>

      <Col xl={6} lg={6} md={6} sm={12}>
        <NumberField
          label={'Cantidad de decimales'}
          value={item.decimals}
          min={1}
          max={6}
          onValueChange={decimals => setItem({ ...item, decimals })}
          disabled={disabledInputs || !item.useDecimals}
          error={errors.advanced.decimalsError}
        />
      </Col>

      <Col xl={6} lg={6} md={6} sm={12}>
        <SwitchV2
          checked={item.useFactor}
          label={'Precio dinámico para el item presentación'}
          info={
            'Al activar esta opción el precio de los item presentación será el precio de este item dividido por el factor de conversión, siempre que se venda como item presentación'
          }
          onChange={useFactor => setItem({ ...item, useFactor })}
          disabled={disabledInputs}
        />
      </Col>

      <Col xl={6} lg={6} md={6} sm={12}>
        <SwitchV2
          checked={item.useExistenceTheory}
          label={'Usar existencia teórica'}
          info={
            'Al activar la configuración el item mostrará la existencia teórica según la existencia de los insumos que lo componen en su receta.'
          }
          onChange={useExistenceTheory => setItem({ ...item, useExistenceTheory })}
          disabled={disabledInputs}
        />
      </Col>

      <Col xl={6} lg={6} md={6} sm={12}>
        <SwitchV2
          checked={item.useDiscountLimit}
          label={'Aplicar limite de descuento'}
          info={
            'Al activar esta opción el sistema tomara el valor del limite de descuento para validar el descuento dado en ordenes de venta.'
          }
          onChange={useDiscountLimit => setItem({ ...item, useDiscountLimit })}
          disabled={disabledInputs}
        />
      </Col>

      <Col xl={6} lg={6} md={6} sm={12}>
        <MoneyField
          label={'Limite de descuento'}
          value={item.discountLimit}
          onValueChange={discountLimit => setItem({ ...item, discountLimit })}
          disabled={disabledInputs || !item.useDiscountLimit}
        />
      </Col>

      <Col xl={6} lg={6} md={6} sm={12}>
        <SwitchV2
          checked={item.withoutInventory}
          label={'No usar inventario'}
          info={
            'Al activar está opción el ítem no necesitara inventario para transacciones en manufacturas y ventas.'
          }
          onChange={withoutInventory => {
            let requiresAmountOnly = item.requiresAmountOnly
            if (!withoutInventory) requiresAmountOnly = false
            setItem({ ...item, withoutInventory, requiresAmountOnly })
          }}
          disabled={plan?.id === PlanEnum.FREE || disabledInputs}
        />
      </Col>

      <Col xl={6} lg={6} md={6} sm={12}>
        <SwitchV2
          checked={item.requiresAmountOnly}
          label={'Venta por Monto'}
          info={
            'Activa esta opción para que el ítem sea vendido por un monto específico, sin requerir la introducción de una cantidad. Únicamente válido para ventas realizadas a través de la app "Puntos de venta".'
          }
          onChange={requiresAmountOnly => setItem({ ...item, requiresAmountOnly })}
          disabled={
            plan?.id === PlanEnum.FREE || disabledInputs || !item.withoutInventory
          }
        />
      </Col>

      <Col xl={6} lg={6} md={6} sm={12}>
        <SwitchV2
          checked={item.notManufacture}
          label={'Combo'}
          info={
            'Al activar la opción, se aplicará el descuento de inventario directamente de los componentes que conforman el ítem. Como resultado, no se efectuará una manufactura adicional del ítem.'
          }
          onChange={notManufacture => setItem({ ...item, notManufacture })}
          disabled={plan?.id === PlanEnum.FREE || disabledInputs}
        />
      </Col>

      <Col xl={6} lg={6} md={6} sm={12}>
        <SwitchV2
            checked={item.enableSubRecipes}
            label={'Descuento de sub recetas'}
            info={
              'Al activar la opción, si un ítem o sus variaciones en la receta del item también tienen recetas, al activarlas, también se descontarán automáticamente los productos utilizados en esas recetas.'
            }
            onChange={enableSubRecipes => setItem({ ...item, enableSubRecipes })}
            disabled={plan?.id === PlanEnum.FREE || disabledInputs}
        />
      </Col>

      <Col xl={6} lg={6} md={6} sm={12}>
        <Col xl={5} lg={5} md={5} sm={6}>
          <SwitchV2
            checked={isCommissionPercentage}
            label={'%'}
            info={'Aplicar comisión porcentual'}
            onChange={checked => {
              setIsCommissionPercentage(checked)
              setItem({ ...item, commission: null, commissionPercentage: null })
            }}
            disabled={disabledInputs}
          />
        </Col>

        <Col xl={7} lg={7} md={7} sm={6}>
          <NumberField
            label={'Comisión'}
            isMoney={!isCommissionPercentage}
            appendBefore
            append={'%'}
            value={isCommissionPercentage ? item.commissionPercentage : item.commission}
            onValueChange={commission =>
              setItem({
                ...item,
                commission: isCommissionPercentage ? null : commission,
                commissionPercentage: isCommissionPercentage ? commission : null,
              })
            }
            disabled={disabledInputs}
          />
        </Col>
      </Col>
    </Row>
  )

  const visibility = (
    <ItemConfigs
      disabled={disabledInputs}
      show={initialVisibility}
      initialConfig={item.visibility}
      onHide={(visibility: IItemConfig) => setItem({ ...item, visibility })}
    />
  )

  return (
    <div>
      <Modal show={show} size={'xl'} centered onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>{id ? `${name}` : 'Nuevo Ítem'}</Modal.Title>
        </Modal.Header>

        <Modal.Body className={'custom-modal-body'}>
          <Row>
            {!id && !createdByTransaction && (
              <Col xl={12} className={'mb-3'}>
                <Row className={'container-buttons'}>
                  {/* @ts-ignore */}
                  <CSVLoaderV2
                    info
                    category={CATEGORY_UPLOAD.PRODUCTS}
                    title={'Carga Masiva de items'}
                    onSuccess={() => onClose(true)}
                  />
                </Row>
              </Col>
            )}
            <Col xl={12}>
              <CustomTabs
                loading={loadingGetItem}
                noCard
                items={[
                  {
                    title: 'Información general',
                    icon: faExclamationTriangle,
                    info: Object.keys(errors.general).length > 0 && 'Errores encontrados',
                    component: generalInfo,
                  },
                  {
                    title: 'Configuraciones avanzadas',
                    icon: faExclamationTriangle,
                    info:
                      Object.keys(errors.advanced).length > 0 && 'Errores encontrados',
                    component: advanceConfig,
                  },
                  { title: 'Visibilidad', component: visibility },
                ]}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              icon={faWindowClose}
              color={'secondary'}
              onClick={onClose}
              disabled={loadingGetItem || loadingCreateOrUpdate}>
              Cerrar
            </Button>

            {(id ? update : create) && (
              <Button
                disabled={loadingGetItem}
                icon={id ? faEdit : faSave}
                color={'primary'}
                onClick={onValidate}
                loading={loadingCreateOrUpdate}>
                {id ? 'Actualizar' : 'Crear'}
              </Button>
            )}
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showNewPrice.show}
        centered
        onHide={() => setNewPrice({ ...showNewPrice, show: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Nuevo precio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12}>
              <CustomCreate
                label={'Alias para el precio'}
                options={[{ value: null, label: 'Precio por defecto' }, ...priceNames]}
                value={showNewPrice.label}
                placeholder={'Selecciona o ingresa un alias nuevo.'}
                textLabel={'Nuevo alias: '}
                onChange={label => setNewPrice({ ...showNewPrice, label })}
                onCreateOption={label =>
                  setNewPrice({ ...showNewPrice, label: { value: label, label } })
                }
              />
            </Col>
            <Col xl={12}>
              <MoneyField
                label={'Precio'}
                value={showNewPrice.price}
                onValueChange={price => setNewPrice({ ...showNewPrice, price })}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              icon={faPlus}
              disabled={!(showNewPrice.price >= 0)}
              onClick={() => {
                onAssignPrice({
                  price: showNewPrice.price,
                  alias: showNewPrice.label?.label,
                })
                setNewPrice({ ...showNewPrice, show: false })
              }}>
              Agregar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      {/* @ts-ignore */}
      <Folder
        show={showCategorization}
        type={categoryType.PRODUCTS}
        list={item?.categories?.map(c => c.id)}
        noMessage
        onHide={() => setShowCategorization(false)}
        data1={categories && categories.children ? categories.children[0] : {}}
        data2={categories && categories.children ? categories.children[1] : {}}
        onAssign={onAssignCategorization}
      />
    </div>
  )
}
export default ItemCRUD
