import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Row, Col, Modal } from 'react-bootstrap'
import Alert from 'sweetalert-react'

import {
  Card,
  Button,
  Select,
  FormText,
  CustomTabs,
  SelectedDates,
  Dropdown as DropdownCustom,
  Title,
  Switch,
  TableV2,
  Money,
  Icon,
} from 'src/components'
import CreateCreditNoteSell from '../CreditNote/sell/CreateCreditNoteSell'

import {
  actionTypes as typesO,
  createVoucher,
  deleteVoucher,
  getAllOrders,
  liberateVoucher,
  onPrintInvoice,
} from 'src/actions/orders.actions'

import {
  actionTypes as clientActions,
  actionTypes,
  getClientsSimple,
  getCreditNotesExcel,
  getVouchersByCustomers,
} from 'src/actions/clients.actions'

import { isAllowed } from 'src/selectors/modules.selector'
import { selectVouchers, simpleClients } from 'src/selectors/clients.selector'
import { loadingSelector } from 'src/selectors/loading.selector'
import { onSetModalOrder } from 'src/actions/utilities.actions'

import {
  handlerError,
  handlerSuccess,
  hasErrorsSelector,
  singleErrorSelector,
} from 'src/selectors/error.selector'
import CustomDatePicker from 'src/components/CustomDatePicker/CustomDatePicker'
import { actionTypes as actionPDF } from 'src/actions/resolutions.actions'
import { creditNoteSellPermissions, orderVoucherPermissions } from 'src/enums/permissions'
import SelectClient from '../../components/custom/Selector/SelectClient'
import { selectGetAllOrders } from '../../selectors/orders.selector'
import { formatDateFromMillis } from '../../utils/formatters'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons/faFileExcel'
import { toMoney } from '../../utils/utilities'

const Vouchers = props => {
  const isVouchers = window.location.href.includes('/vales')
  const dispatch = useDispatch()

  const allClients = useSelector(simpleClients)
  const vouchers = useSelector(selectVouchers)
  const orders = useSelector(selectGetAllOrders)

  const canCreateVoucher = useSelector(state =>
    isAllowed(state, [
      isVouchers ? orderVoucherPermissions.create : creditNoteSellPermissions.create,
    ]),
  )
  const canDeleteVoucher = useSelector(state =>
    isAllowed(state, [
      isVouchers ? orderVoucherPermissions.nullify : creditNoteSellPermissions.nullify,
    ]),
  )
  const canFreeVoucher = useSelector(state =>
    isAllowed(state, [orderVoucherPermissions.release]),
  )

  const loadingVouchers = useSelector(state =>
    loadingSelector([actionTypes.GET_VOUCHERS_CLIENT])(state),
  )
  const loadingClients = useSelector(state =>
    loadingSelector([actionTypes.GET_ALL_CLIENTS])(state),
  )

  const loadingCreate = useSelector(state =>
    loadingSelector([typesO.CREATE_VOUCHER])(state),
  )
  const hasErrorCreate = useSelector(state =>
    hasErrorsSelector([typesO.CREATE_VOUCHER])(state),
  )
  const errorCreate = useSelector(state =>
    singleErrorSelector([typesO.CREATE_VOUCHER])(state),
  )

  const loadingDelete = useSelector(state =>
    loadingSelector([typesO.DELETE_VOUCHER])(state),
  )
  const hasErrorDelete = useSelector(state =>
    hasErrorsSelector([typesO.DELETE_VOUCHER])(state),
  )

  const loadingFree = useSelector(state =>
    loadingSelector([typesO.LIBERATE_VOUCHER])(state),
  )
  const hasErrorFree = useSelector(state =>
    hasErrorsSelector([typesO.LIBERATE_VOUCHER])(state),
  )
  const loadingPDF = useSelector(state =>
    loadingSelector([actionPDF.DOWNLOAD_PRINTABLE_INVOICE_BY_ORDER])(state),
  )

  const loadingExcel = useSelector(state =>
    loadingSelector([actionTypes.GET_CREDIT_NOTE_EXCEL])(state),
  )

  const [clients, setClients] = useState([])
  const [actions, setActions] = useState({ get: false, delete: false, create: false })
  const [alert, setAlert] = useState({ title: 'title' })
  const [modalNew, setModalNew] = useState(false)
  const [newVoucher, setNewVoucher] = useState({})
  const [creditInfo, setCreditInfo] = useState({})
  const [createCreditNote, setCreateCreditNote] = useState({
    show: false,
    orderId: null,
    clientId: null,
    number: null,
  })

  const [filtersText, setFiltersText] = useState('')
  const [filters, setFilters] = useState({
    clientId: null,
    sDate: null,
    eDate: null,
    use: null,
    withoutClient: null,
    isVouchers,
  })
  const [selectF, setF] = useState({ value: null, label: 'Todos' })
  const [actionSelect, setActionSelect] = useState({ id: null })

  useEffect(() => {
    setFiltersText(JSON.stringify(filters))
    dispatch(getClientsSimple({ withCreditNotes: !isVouchers }))
  }, [])

  useEffect(() => {
    if (filtersText === '' || filtersText === JSON.stringify(filters)) return
    setFiltersText(JSON.stringify(filters))
  }, [filters])

  useEffect(() => {
    if (filtersText !== JSON.stringify(filters)) return
    setUp()
  }, [filtersText])

  useEffect(() => {
    setClients(
      allClients
        .filter(client =>
          isVouchers
            ? client.vouchers > 0
            : client.creditNotes > 0 || client.totalCreditNotes > 0,
        )
        .filter(
          (client, i, clients) =>
            clients.findIndex(otherClient => otherClient.id === client.id) === i,
        ),
    )
  }, [allClients])

  useEffect(() => {
    if (loadingCreate) setActions({ ...actions, create: true })
    else if (actions.create) {
      setActions({ ...actions, create: false })
      if (hasErrorCreate)
        setAlert({
          ...handlerError(errorCreate.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else
        setAlert({
          ...handlerSuccess('Vale creado exitosamente'),
          onConfirm: () => {
            setModalNew(false)
            setAlert({ ...alert, show: false })
            setNewVoucher({})
            setUp()
          },
        })
    }
  }, [loadingCreate])

  useEffect(() => {
    if (loadingDelete) setActions({ ...actions, delete: true })
    else if (actions.delete) {
      setActions({ ...actions, delete: false })
      if (hasErrorDelete)
        setAlert({
          ...handlerError('No se pudo eliminar el vale'),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else
        setAlert({
          ...handlerSuccess('Vale eliminado exitosamente'),
          onConfirm: () => {
            setModalNew(false)
            setAlert({ ...alert, show: false })
            setUp()
          },
        })
    }
  }, [loadingDelete])

  useEffect(() => {
    if (loadingFree) setActions({ ...actions, liberate: true })
    else if (actions.liberate) {
      setActions({ ...actions, liberate: false })
      if (hasErrorFree)
        setAlert({
          ...handlerError('No se pudo liberar el vale'),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else
        setAlert({
          ...handlerSuccess('Vale liberado exitosamente'),
          onConfirm: () => {
            setModalNew(false)
            setAlert({ ...alert, show: false })
            setUp()
          },
        })
    }
  }, [loadingFree])

  useEffect(() => {
    if (!newVoucher.client) return

    if (!creditInfo.documentType?.value) {
      dispatch(
        getAllOrders({
          client: newVoucher.client.value,
          allPreSale: true,
          type: 1,
          pending: true,
          linked: false,
          stateId: 3,
          invoiced: true,
          dateType: 1,
        }),
      )
    }
  }, [creditInfo.documentType, newVoucher.client])

  const setUp = excel => {
    const params = {
      clientId: filters.clientId,
      sDate: filters.sDate,
      eDate: filters.eDate,
      use: filters.use,
      isVouchers,
    }

    if (excel) dispatch(getCreditNotesExcel(params))
    else dispatch(getVouchersByCustomers(params))
  }

  const onRowClicked = data => {
    let clientId
    if (data.id === filters.clientId) clientId = undefined
    else clientId = data.id
    setFilters({ ...filters, clientId })
  }

  function formatDate(date) {
    const now = new Date(date)
    let month = String(now.getMonth() + 1)
    let day = String(now.getDate())
    const year = now.getFullYear()

    if (month.length < 2) month = `0${month}`
    if (day.length < 2) day = `0${day}`

    return [year, month, day].join('-')
  }

  const createNewVoucher = () => {
    dispatch(
      createVoucher({
        ...newVoucher,
        client: newVoucher.client ? newVoucher.client.value : null,
        info: newVoucher.creditNote
          ? {
              ...creditInfo,
              total: newVoucher.amount,
              dateEmision: formatDate(creditInfo.dateEmision),
            }
          : null,
        paper: creditInfo?.documentType?.value,
      }),
    )
  }

  const renderUserRow = (item, index) => {
    const activeClassName = item.id === filters.clientId ? 'active' : ''

    return (
      <div
        className={`b-user-item ${activeClassName}`}
        key={index}
        onClick={() => onRowClicked(item)}>
        <div
          className={'mini'}
          style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
          <input
            type={'checkbox'}
            name={index}
            checked={item.id === filters.clientId}
            onChange={() => undefined}
          />
        </div>
        <div style={{ width: '100%' }}>
          <div className={'align-items-start bu-en'} style={{ textAlign: 'left' }}>
            <div className={`b-user-name ${activeClassName}`}>{item.companyName} </div>
            <div className={`b-user-email ${activeClassName}`}>
              {item.nit ? item.nit : 'CF'}
            </div>
          </div>
          {!isVouchers ? (
            <div
              style={{
                margin: 4,
                borderRadius: 5,
                display: 'flex',
                cursor: 'pointer',
              }}>
              <div
                className={'justify-content-start align-items-start bu-en'}
                style={{ textAlign: 'left' }}>
                <div className={`b-user-email `}>Total de notas</div>
                <div className={`b-user-email `}>Total usado</div>
                <div className={`b-user-email `}>Total pendiente</div>
              </div>
              <div className={'mr-2 bu-b'} style={{ textAlign: 'right' }}>
                <div className={`b-user-email ${activeClassName}`}>
                  {toMoney(item.totalCreditNotes || 0)}
                </div>
                <div className={`b-user-email ${activeClassName}`}>
                  {toMoney(item.value || 0)}
                </div>
                <div className={`b-user-email ${activeClassName}`}>
                  {toMoney((item.totalCreditNotes || 0) - item.value)}
                </div>
              </div>
            </div>
          ) : (
            <div
              className={'d-flex row align-items-center mr-2 bu-b'}
              style={{ textAlign: 'right' }}>
              <Money className={`b-user-balance mr-2 `}>{item.value}</Money>
            </div>
          )}
        </div>
      </div>
    )
  }

  const headers = [
    { label: 'Código', show: true, value: ['number'], type: 'text', className: 'mini' },
    {
      label: 'Cliente',
      show: true,
      value: ['clientC'],
      type: 'text',
      className: 'medium',
      custom: item =>
        (item.clientC = item.clientId ? item.clientData.companyName : 'Liberado'),
    },
    {
      label: 'Descripción',
      show: true,
      value: ['description'],
      type: 'text',
      className: 'medium',
    },
    {
      label: 'Fecha',
      show: true,
      value: ['createdAt'],
      type: 'date',
      className: 'mini',
    },
    {
      label: 'Total',
      show: true,
      value: ['total'],
      type: 'currency',
      className: 'mini',
    },
    {
      label: 'Responsable',
      show: true,
      value: ['responsableC'],
      type: 'text',
      className: 'medium',
      custom: item =>
        (item.responsableC = item.responsibleId
          ? item.responsibleData.name
          : 'Desconocido'),
    },
    {
      label: 'Estado',
      show: true,
      value: ['estadoC'],
      type: 'text',
      className: 'mini',
      custom: item =>
        (item.estadoC = item.referenceOrder ? 'No disponible' : 'Disponible'),
    },
    {
      config: true,
      show: true,
      label: '',
      className: 'mini center',
      custom: item => (
        <DropdownCustom
          loading={loadingPDF && item.id === actionSelect.id}
          items={[
            {
              title: 'Ver procedencia',
              show: item.documentType !== 0,
              action: () => {
                dispatch(onSetModalOrder(item.orderId))
              },
            },
            {
              title: 'Ver documento',
              show: item.documentType !== 0,
              action: async () => {
                setActionSelect({ id: item.id })
                if (item.documentType === 2) {
                  const { match } = props
                  const url = `${match.url}/false/${item.id}/${item.number}`
                  window.open(url, '_blank')
                } else
                  dispatch(
                    onPrintInvoice({
                      active: true,
                      orderId: item.id,
                      print: false,
                      documentType: 4,
                      isCreditNote: true,
                      module: 1000,
                    }),
                  )
              },
            },
            {
              title: 'Liberar',
              show: isVouchers && canFreeVoucher && !item.referenceOrder && item.clientId,
              action: () => dispatch(liberateVoucher(item.id)),
            },
            {
              title: 'Anular',
              show: canDeleteVoucher && !item.referenceOrder,
              action: () => dispatch(deleteVoucher(item.id)),
            },
          ]}
        />
      ),
    },
  ]

  const getActions = item => {
    return (
      <DropdownCustom
        items={[
          {
            title: 'Detalle completo',
            action: () => dispatch(onSetModalOrder(item.id)),
          },
          {
            show: !item.deleted && (item.status === 3 || item.status === 5),
            title: 'Generar nota de crédito o Vale',
            action: () =>
              setCreateCreditNote({
                show: true,
                orderId: item.id,
                number: item.number,
                clientId: item.clientId,
              }),
          },
        ]}
      />
    )
  }

  return (
    <div>
      <Title title={isVouchers ? 'Vales' : 'Notas de Crédito'} />
      <Row>
        <Col xl={4} lg={4} md={5} sm={12} xs={12}>
          <Card title={'Clientes'}>
            <TableV2
              loading={loadingClients}
              items={clients.map(s => ({
                companyName: s.companyName,
                nit: s.nit,
                value: isVouchers ? s.vouchers : s.creditNotes,
                id: s.id,
                totalCreditNotes: s.totalCreditNotes,
              }))}
              renderRow={renderUserRow}
            />
            <br />
            {canCreateVoucher && (
              <Button
                className={'mt-3 big-button'}
                onClick={() => {
                  setModalNew(true)
                  if (!isVouchers)
                    setNewVoucher({
                      creditNote: true,
                      info: {
                        documentType: { value: false, label: 'Factura electrónica' },
                      },
                    })
                }}
                style={{ maxWidth: '100%' }}>
                Crear {isVouchers ? 'vales' : 'notas de crédito'}
              </Button>
            )}
          </Card>
        </Col>

        <Col xl={8} lg={8} md={7} sm={12} xs={12}>
          <Card
            title={'Filtros'}
            white
            button={
              <Icon
                tooltip={'Descargar en Excel'}
                icon={faFileExcel}
                spin={loadingExcel}
                onClick={() => setUp(true)}></Icon>
            }>
            <SelectedDates
              withOptionNull
              onDateChange={(start, end) =>
                setFilters({ ...filters, sDate: start, eDate: end })
              }
            />
          </Card>

          <CustomTabs
            items={[
              {
                title: `${isVouchers ? 'Vales' : 'Notas de crédito'} (${
                  vouchers.vouchers ? vouchers.vouchers.length : 0
                })`,
                info: `${isVouchers ? 'Vales' : 'Notas de crédito'}`,
                component: (
                  <TableV2
                    loading={loadingVouchers}
                    items={vouchers.vouchers}
                    customFilter={
                      <div>
                        <Row>
                          <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                            <Select
                              label={'Filtrar por estado'}
                              info={'Filtra por el estado del vale'}
                              value={selectF}
                              options={[
                                { value: null, label: 'Todos' },
                                {
                                  value: 1,
                                  label: `${isVouchers ? 'Vales' : 'Notas'} utilizadas`,
                                },
                                {
                                  value: 2,
                                  label: `${isVouchers ? 'Vales' : 'Notas'} sin utilizar`,
                                },
                                { value: 3, label: `Anuladas` },
                              ]}
                              onChange={data => {
                                setFilters({ ...filters, use: data.value })
                                setF(data)
                              }}
                            />
                          </Col>
                        </Row>
                        <hr />
                      </div>
                    }
                    headers={headers}
                    mobileAuto
                    storageKey={`voucher`}
                  />
                ),
              },
            ]}
          />
        </Col>
      </Row>

      <Modal
        show={modalNew}
        size={'md'}
        centered
        onHide={() => {
          setCreditInfo({})
          setModalNew(false)
        }}>
        <Modal.Header closeButton>
          <Modal.Title>{isVouchers ? 'Nuevo Vale' : 'Nueva Nota de Crédito'}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col xl={12} lg={12} md={12} sm={12}>
              <SelectClient
                actionType={clientActions.GET_CLIENT_POLYGON}
                label={'Cliente'}
                onChange={data => setNewVoucher({ ...newVoucher, client: data })}
                value={newVoucher.client}
                required={newVoucher.invoice}
                allClients
                init
                noCreate
              />
            </Col>
            {!isVouchers && (
              <Col xl={12} lg={12} md={12} sm={12}>
                <Select
                  label={'Tipo de documento'}
                  required
                  options={[
                    { value: false, label: 'Factura electrónica' },
                    { value: true, label: 'Factura impresa o face' },
                  ]}
                  value={creditInfo.documentType}
                  onChange={value =>
                    setCreditInfo({ ...creditInfo, documentType: value })
                  }
                />
              </Col>
            )}
            {newVoucher.creditNote &&
              creditInfo.documentType &&
              !creditInfo.documentType?.value && (
                <Col xl={12} lg={12} md={12} sm={12}>
                  <TableV2
                    pSize={0}
                    items={orders || []}
                    headers={[
                      {
                        label: 'Código',
                        show: true,
                        value: ['number'],
                        type: 'code',
                        classNameCustom: item =>
                          `text-center ${item.status === 7 ? 'kolo_blue' : ''}`,
                      },

                      {
                        label: 'Bodega',
                        show: true,
                        value: ['warehouse', 'name'],
                        type: 'text',
                        custom: item =>
                          item.warehouse ? item.warehouse.name : 'Desconocido',
                      },
                      {
                        label: 'Fecha',
                        show: true,
                        value: ['fechaC'],
                        type: 'text',
                        custom: item =>
                          (item.fechaC = formatDateFromMillis(
                            item.enabledAt || item.createdAt,
                          )),
                      },
                      {
                        label: 'Descripción',
                        show: true,
                        value: ['description'],
                        type: 'text',
                        custom: item => item.description || 'Sin descripción',
                      },
                      {
                        label: 'Total',
                        show: true,
                        value: ['total'],
                        type: 'currency',
                      },

                      {
                        show: true,
                        config: true,
                        type: 'text',
                        value: ['button'],
                        label: 'Acción',
                        custom: getActions,
                      },
                    ]}
                  />
                </Col>
              )}
            {(isVouchers ||
              (newVoucher.creditNote && creditInfo.documentType?.value)) && (
              <Col xl={12} lg={12} md={12} sm={12}>
                <FormText
                  prependMoneySymbol
                  label={'Monto'}
                  required
                  type={'number'}
                  placeholder={'Monto'}
                  value={newVoucher.amount}
                  onChange={({ target }) => {
                    let { value } = target
                    if (!value || value < 0) value = 0
                    setNewVoucher({ ...newVoucher, amount: value })
                  }}
                />
              </Col>
            )}
            {isVouchers && (
              <Col xl={6} lg={6} md={6} sm={6}>
                <Switch
                  topLabel
                  label={'Emitir una factura '}
                  info={
                    'Al activar la opción se emitirá una factura electrónica que se asociará al vale creado'
                  }
                  checked={newVoucher.invoice}
                  changeValue={newValue =>
                    setNewVoucher({
                      ...newVoucher,
                      invoice: newValue,
                      creditNote: false,
                    })
                  }
                />
              </Col>
            )}

            {(newVoucher.invoice ||
              (newVoucher.creditNote && creditInfo.documentType?.value)) && (
              <Col xl={12} lg={12} md={12} sm={12}>
                <FormText
                  required
                  label={'Concepto a facturar'}
                  value={newVoucher.concept}
                  changeValue={newValue => {
                    setNewVoucher({ ...newVoucher, concept: newValue })
                  }}
                />
              </Col>
            )}

            {newVoucher.creditNote && creditInfo.documentType?.value && (
              <div style={{ width: '100%' }}>
                <Col xl={12} lg={12} md={12} sm={12}>
                  <FormText
                    required
                    label={'Número de autorización del documento origen'}
                    info={'Numero de documento al que se le hará la nota de crédito'}
                    value={creditInfo.numberAuth}
                    changeValue={newValue =>
                      setCreditInfo({ ...creditInfo, numberAuth: newValue })
                    }
                  />
                </Col>
                <Col xl={12} lg={12} md={12} sm={12}>
                  <FormText
                    required
                    label={'Número del documento origen'}
                    info={
                      'Numero interno que identifica el documento al que se le hará la nota de crédito'
                    }
                    value={creditInfo.numberDocument}
                    changeValue={newValue =>
                      setCreditInfo({ ...creditInfo, numberDocument: newValue })
                    }
                  />
                </Col>
                <Col xl={12} lg={12} md={12} sm={12}>
                  <FormText
                    required
                    label={'Serie del documento origen'}
                    info={
                      'Son los primeros 8 dígitos del número de autorización de una FEL'
                    }
                    value={creditInfo.serie}
                    changeValue={newValue =>
                      setCreditInfo({ ...creditInfo, serie: newValue })
                    }
                  />
                </Col>
                <Col xl={12} lg={12} md={12} sm={12}>
                  <CustomDatePicker
                    disabled={false}
                    label={'Fecha del documento al que le se le hará la nota de crédito.'}
                    value={creditInfo.dateEmision}
                    onDayChange={newValue =>
                      setCreditInfo({ ...creditInfo, dateEmision: newValue })
                    }
                  />
                </Col>
              </div>
            )}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            loading={loadingCreate}
            disabled={
              !newVoucher.amount ||
              newVoucher.amount <= 0 ||
              (newVoucher.invoice &&
                (!newVoucher.concept ||
                  !newVoucher.client ||
                  !newVoucher.client.value)) ||
              (newVoucher.creditNote &&
                (!newVoucher.client ||
                  !newVoucher.client.value ||
                  !newVoucher.concept ||
                  !creditInfo.numberAuth ||
                  !creditInfo.numberDocument ||
                  !creditInfo.dateEmision))
            }
            onClick={() => createNewVoucher()}
            style={{ maxWidth: '100%' }}>
            Crear
          </Button>
        </Modal.Footer>
      </Modal>

      <CreateCreditNoteSell
        show={createCreditNote.show}
        orderId={createCreditNote.orderId}
        code={createCreditNote.number}
        clientId={createCreditNote.clientId}
        onHide={update => {
          setCreateCreditNote({ ...createCreditNote, show: false })
          if (update) {
            setModalNew(false)
            setNewVoucher({})
            setCreditInfo({})
            setUp()
            dispatch(getClientsSimple({ withCreditNotes: true, force: true }))
          }
        }}
      />
      <Alert {...alert} />
    </div>
  )
}
export default Vouchers
