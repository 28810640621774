import { Col, OverlayTrigger, Row } from 'react-bootstrap'
import IconCompany from 'src/assets/v2/icon-company-card.svg'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentCompany, selectUserCompanies } from 'src/selectors/user.selector'
import {
  actionTypes,
  getCurrentUser,
  getUserCompanies,
  saveCurrentCompany,
  saveCurrentModule,
} from 'src/actions/user.actions'
import { getRecentNotifications } from 'src/actions/notifications.actions'
import { loadingSelector } from 'src/selectors/loading.selector'
import { hasErrorsSelector } from 'src/selectors/error.selector'
import { history } from 'src/App'
import {
  actionTypes as mTypes,
  getCurrentUserRolesAction,
  getGroups,
} from 'src/actions/modules.actions'
import { getCompanyData } from 'src/actions/company.actions'
import { selectModuleGroups } from 'src/selectors/modules.selector'
import SearchIcon from 'src/assets/v2/icon-search2.svg'
import FactoryIcon from 'src/assets/images/svg/factory.svg'
import { setMenu, setSearchShow } from 'src/actions/dashboard.actions'
import { selectSetCurrentCompany } from 'src/selectors/company.selector'
import { FormText, Icon } from '../index'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import Popover from 'react-bootstrap/Popover'
import { selectSearchShow } from 'src/selectors/dashboard.selector'
import { getActiveTurn } from 'src/actions/turn.actions'
import { getMeasurementUnits } from 'src/actions/products.actions'
import { planPendingPayment } from 'src/actions/plans.actions'

const CompanyList = ({ collapsed }) => {
  const dispatch = useDispatch()

  const companies = useSelector(selectUserCompanies)
  const groups = useSelector(selectModuleGroups)
  const company = useSelector(selectSetCurrentCompany)
  const current = useSelector(selectCurrentCompany)
  const showSearch = useSelector(selectSearchShow)

  const loading = useSelector(state =>
    loadingSelector([actionTypes.GET_USER_COMPANIES])(state),
  )
  const hasError = useSelector(state =>
    hasErrorsSelector([actionTypes.GET_USER_COMPANIES])(state),
  )

  const saveCompany = useSelector(state =>
    loadingSelector([actionTypes.SAVE_CURRENT_COMPANY])(state),
  )
  const loadGroups = useSelector(state =>
    loadingSelector([mTypes.GET_MODULE_GROUPS])(state),
  )

  const [action, setAction] = useState({
    companies: false,
    save: false,
    load: false,
  })
  const [ac, setAc] = useState({ companies: false })
  const [search, setSearch] = useState('')

  const filteredCompanies = companies.filter(
    c =>
      c.company.shortName?.toLowerCase().includes(search.toLowerCase()) ||
      c.company.email?.toLowerCase().includes(search.toLowerCase()) ||
      c.company.name?.toLowerCase().includes(search.toLowerCase()),
  )

  useEffect(() => {
    dispatch(getCurrentUser())
    dispatch(getUserCompanies())
    dispatch(getRecentNotifications())
  }, [])

  useEffect(() => {
    if (current && current > 0) {
      setup()
    }
    action.companies = true
  }, [current])

  useEffect(() => {
    if (loading) setAc({ ...ac, companies: true })
    else if (ac.companies) {
      setAc({ ...ac, companies: false })
      if (hasError) {
        // TODO: error
      } else {
        if (companies.length > 0 && current <= 0) {
          navigateTo(1000, '/distribucion', companies[0].company)
        }
      }
    }
  }, [loading])

  useEffect(() => {
    if (loadGroups) setAction({ ...action, load: true })
    else if (action.load) {
      setAction({ ...action, load: false })
      let menu = [{ id: 2000, text: 'Menú Productores', icon: FactoryIcon, modules: [] }]
      groups.forEach(group => {
        let modules = []
        group.modules.forEach(subModule => {
          subModule.name = subModule.name.replace('*', company.name || '')
          subModule.icon = subModule.logo || subModule.alterIcon
          modules.push({
            id: subModule.id,
            text: subModule.name,
            icon: subModule.icon,
            link: subModule.route,
            show: subModule.showIcon,
          })
        })
        menu.push({
          id: group.id,
          text: group.name,
          icon: group.icon,
          modules,
          show: true,
        })
      })
      dispatch(setMenu(menu))
      dispatch(getCurrentUserRolesAction(1000))
    }
  }, [loadGroups])

  useEffect(() => {
    if (saveCompany) setAction({ ...action, save: true })
    else if (action.save) {
      setAction({ ...action, save: false })
      history.push(`${process.env.PUBLIC_URL}/distribucion`)
    }
  }, [saveCompany])

  const setup = () => {
    dispatch(saveCurrentModule(1000))
    dispatch(getGroups(1000))
    dispatch(getCompanyData())
    dispatch(getActiveTurn())
    dispatch(getMeasurementUnits())
    dispatch(planPendingPayment())
  }

  const navigateTo = (module, route, company) => {
    if (!route) return

    history.push(`${process.env.PUBLIC_URL}${route}`)
    dispatch(saveCurrentCompany(company.id))
    dispatch(setSearchShow(false))
    setSearch('')
  }

  const tooltip = (children, style) => (
    <Popover
      id={'popover-contained'}
      style={{ backgroundColor: '#226095', color: 'white', width: 250, ...style }}>
      {children}
    </Popover>
  )

  useEffect(() => {
    setSearch('')
  }, [showSearch])

  return (
    <>
      {companies.length >= 5 && (
        <Row style={{ cursor: 'pointer' }}>
          <Col style={{ position: 'relative' }}>
            <OverlayTrigger
              placement="right"
              overlay={tooltip(
                <FormText
                  autoFocus
                  value={search}
                  placeholder={'Correo / Nombre / Razón Social'}
                  onChange={({ target }) => setSearch(target.value)}
                  style={{ marginTop: '10px !important' }}
                  mt={2}
                />,
                { padding: '0 5px', width: 264 },
              )}
              show={showSearch}
              onToggle={show => {
                dispatch(setSearchShow(show))
              }}
              trigger={['click']}>
              <div
                className={'company-logo mb-1 mt-1'}
                style={{
                  border: collapsed
                    ? 'none'
                    : showSearch
                    ? '2px solid #68c8d3'
                    : '2px solid #e8e8e8',
                }}>
                <img
                  className={`mt-1`}
                  src={SearchIcon}
                  alt={'Buscar empresa'}
                  width={'100%'}
                  height={'48px'}
                  style={{ objectFit: 'contain', padding: '8px 0' }}
                />

                {!collapsed && (
                  <div style={{ fontSize: 8, color: '#226095', textAlign: 'center' }}>
                    Buscar
                  </div>
                )}
              </div>
            </OverlayTrigger>
          </Col>
        </Row>
      )}
      <div className="sidebar-companies-list">
        {filteredCompanies.map(c => (
          <Row
            key={c.company.id}
            onClick={() => {
              navigateTo(1000, '/distribucion', c.company)
            }}
            style={{ cursor: 'pointer' }}>
            <Col style={{ position: 'relative' }}>
              <OverlayTrigger
                placement="right"
                overlay={tooltip(
                  <b style={{ textTransform: 'uppercase' }}>{c.company.name}</b>,
                  { padding: 10 },
                )}>
                <div
                  className={'company-logo mb-1 mt-1'}
                  style={{
                    opacity:
                      current === c.company.id && loadGroups && !collapsed ? 0.3 : 1,
                    border:
                      current === c.company.id
                        ? !collapsed
                          ? '2px solid #68c8d3'
                          : 'none'
                        : collapsed
                        ? 'none'
                        : '2px solid #e8e8e8',
                  }}>
                  <img
                    className={`mt-1`}
                    src={c.company.logo ? c.company.logo.url : IconCompany}
                    alt={c.company.name}
                    width={'100%'}
                    height={'48px'}
                    style={{ objectFit: 'contain' }}
                  />

                  {!collapsed && (
                    <div
                      style={{
                        fontSize: 8,
                        color: '#226095',
                        textAlign: 'center',
                        whiteSpace: 'nowrap',
                        margin: '0 3px',
                        overflow: 'hidden',
                      }}>
                      {c.company.shortName || c.company.name}
                    </div>
                  )}
                </div>
              </OverlayTrigger>
              <div style={{ position: 'absolute', left: '35%', top: '25%', zIndex: 2 }}>
                {current === c.company.id && loadGroups && !collapsed && (
                  <Icon
                    spin={loadGroups}
                    icon={faSpinner}
                    color={'secondary'}
                    size={'2x'}
                    style={{ left: '50%', right: '50%' }}
                  />
                )}
              </div>
            </Col>
          </Row>
        ))}
      </div>
    </>
  )
}

export default CompanyList
