import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Modal, Row, Col } from 'react-bootstrap'
import Alert from 'sweetalert-react'
import { Button, CustomReference, Dropdown, TableV2 } from '../../index'

import {
  getOrdersToPayByOrder,
  onSetPaymentId,
  actionTypes as orderActions,
  onConfirmOrderPayment,
  deleteSingleOrder,
} from 'src/actions/orders.actions'

import { onSetModalOrder, setModalOrderPayment } from 'src/actions/utilities.actions'

import { selectModalOrderPayment } from 'src/selectors/utilities.selector'
import { toMoney } from 'src/utils/utilities'
import { isAllowed, selectUsers } from 'src/selectors/modules.selector'
import { formatDateFromMillis } from 'src/utils/formatters'
import { selectOrdersToPay } from 'src/selectors/orders.selector'
import { selectCurrentUser } from 'src/selectors/user.selector'
import { loadingSelector } from 'src/selectors/loading.selector'
import { selectCurrentCurrency } from 'src/selectors/currencies.selector'
import { faCheck, faTrash } from '@fortawesome/free-solid-svg-icons'

const ModalPaymentDetail = () => {
  const dispatch = useDispatch()

  const modalOrderPayment = useSelector(selectModalOrderPayment)
  const {
    id,
    number,
    client,
    paymentTypeData,
    status,
    statusData,
    balance,
    createdAt,
    enabledAt,
    date,
    createdBy,
    confirmedBy,
    deposit,
    show,
  } = modalOrderPayment

  const user = useSelector(selectCurrentUser)
  const users = useSelector(selectUsers)
  const orders = useSelector(selectOrdersToPay)
  const currentCurrency = useSelector(selectCurrentCurrency)

  const loadingOrders = useSelector(state =>
    loadingSelector([orderActions.GET_ORDERS_TO_PAY_BY_ORDER])(state),
  )
  const loading = useSelector(state =>
    loadingSelector([
      orderActions.DELETE_SINGLE_ORDER,
      orderActions.ON_CONFIRM_PAYMENT_ORDER,
    ])(state),
  )

  const canConfirmOwn = useSelector(state => isAllowed(state, [12064]))
  const canConfirmOthers = useSelector(state => isAllowed(state, [12065]))

  const createdByUser = users.find(user => user.id === createdBy)?.name
  const confirmedByUser = users.find(user => user.id === confirmedBy)?.name

  const [depositModal, setDepositModal] = useState({ show: false })
  const [alert, setAlert] = useState({ title: 'title' })

  const headers = [
    {
      show: true,
      label: 'No.',
      value: ['number'],
      type: 'text',
      className: 'mini',
    },
    {
      show: true,
      label: 'Estado',
      value: ['statusData', 'name'],
      type: 'text',
      className: 'mini',
    },
    {
      show: true,
      label: 'Fecha',
      value: ['createdAt'],
      type: 'date',
      className: 'mini',
    },
    {
      show: true,
      label: `Monto (${currentCurrency.symbol})`,
      value: ['paymentAmount'],
      type: 'number',
      className: 'mini',
    },
    {
      show: true,
      label: '',
      className: 'mini',
      config: true,
      custom: item => (
        <Dropdown
          disabled={loadingOrders}
          items={[
            {
              title: 'Ver orden de venta',
              show: true,
              action: () => dispatch(onSetModalOrder(item.id)),
            },
          ]}
        />
      ),
    },
  ]

  useEffect(() => {
    if (!id) return
    dispatch(getOrdersToPayByOrder(id))
  }, [id])

  return (
    <div style={{ position: 'fixed' }}>
      <Modal
        show={show && !depositModal.show}
        size={'lg'}
        centered
        onHide={() => {
          dispatch(setModalOrderPayment({ show: false }))
          dispatch(onSetPaymentId({ id: null }))
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Orden de pago: {number}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={4} md={4} sm={6} xs={6} className={'column my-2'}>
              <b>Cliente: </b>
              {client?.name}
            </Col>
            <Col xl={4} md={4} sm={6} xs={6} className={'column my-2'}>
              <b>Tipo de pago: </b>
              {paymentTypeData?.name}
            </Col>
            <Col xl={4} md={4} sm={6} xs={6} className={'column my-2'}>
              <b>Estado: </b>
              {statusData?.name}
            </Col>
            <Col xl={4} md={4} sm={6} xs={6} className={'column my-2'}>
              <b>Fecha de ingreso: </b>
              {formatDateFromMillis(createdAt)}
            </Col>
            <Col xl={4} md={4} sm={6} xs={6} className={'column my-2'}>
              <b>Fecha del pago: </b>
              {enabledAt !== null ? formatDateFromMillis(enabledAt) : 'Sin información'}
            </Col>
            <Col xl={4} md={4} sm={6} xs={6} className={'column my-2'}>
              <b>Fecha de confirmación: </b>
              {date !== null ? formatDateFromMillis(date) : 'Sin confirmar'}
            </Col>
            <Col xl={4} md={4} sm={6} xs={6} className={'column my-2'}>
              <b>Ingresado por: </b>
              {createdByUser}
            </Col>
            <Col xl={4} md={4} sm={6} xs={6} className={'column my-2'}>
              <b>Confirmado por: </b>
              {confirmedByUser || 'Sin confirmar'}
            </Col>
            {paymentTypeData?.id === 1 && (
              <Col xl={4} md={4} sm={6} xs={6} className={'column my-2'}>
                <b>Usuario afectado: </b>
                {confirmedByUser || 'Sin confirmar'}
              </Col>
            )}
            <Col xs={12} className={'column my-2'} style={{ textAlign: 'end' }}>
              <TableV2
                storageKey={`paymentOrdersCPC-${user.id}`}
                items={orders.sort((a, b) => b.createdAt - a.createdAt)}
                headers={headers}
                loading={loadingOrders}
                mobileAuto={true}
              />
            </Col>
            <Col xs={12} className={'column my-2'} style={{ textAlign: 'end' }}>
              <h2>Monto total: {toMoney(balance)}</h2>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div className={'container-buttons'}>
            {deposit && (
              <Button
                onClick={() =>
                  setDepositModal({ ...depositModal, show: true, documentId: deposit })
                }>
                Ver depósito
              </Button>
            )}
            {status === 1 && (
              <>
                <Button
                  loading={loading}
                  color="secondary"
                  icon={faTrash}
                  right
                  onClick={() =>
                    setAlert({
                      show: true,
                      type: 'warning',
                      title: `¿Eliminar orden de pago #${number}?`,
                      showCancelButton: true,
                      onCancel: () => setAlert({ ...alert, show: false }),
                      onConfirm: () => {
                        setAlert({ ...alert, show: false })
                        dispatch(deleteSingleOrder(id))
                      },
                    })
                  }>
                  Eliminar
                </Button>
                {(createdBy === user.id ? canConfirmOwn : canConfirmOthers) && (
                  <Button
                    loading={loading}
                    icon={faCheck}
                    right
                    onClick={() =>
                      setAlert({
                        show: true,
                        type: 'info',
                        title: `¿Confirmar orden de pago #${number}?`,
                        showCancelButton: true,
                        onCancel: () => setAlert({ ...alert, show: false }),
                        onConfirm: () => {
                          setAlert({ ...alert, show: false })
                          dispatch(onConfirmOrderPayment(id, client))
                        },
                      })
                    }>
                    Confirmar
                  </Button>
                )}
              </>
            )}
          </div>
        </Modal.Footer>
      </Modal>

      <CustomReference
        show={depositModal.show}
        documentModule={2}
        documentType={4}
        documentId={depositModal.documentId}
        onHide={() => setDepositModal({ ...depositModal, show: false })}
      />

      <Alert {...alert} />
    </div>
  )
}
export default ModalPaymentDetail
