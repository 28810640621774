import React from 'react'

const CustomSummary = props => {
  return (
    <>
      {props.title && (
        <div className={'key-title'} style={{ textAlign: 'center' }}>
          {props.title}
        </div>
      )}
      <div className={'d-flex row'} data-cy={props.dataCy} data-testid={props.dataCy}>
        {props.items
          .filter(s => s.show === undefined || s.show || s.show === null)
          .map((i, index) => (
            <div key={`${index}`} className={'key-row'}>
              <div className={'key-title'}>{i.title}</div>
              {i.values ? (
                <div>
                  {i.values.map((value, index) => (
                    <>
                      <div key={`${index}`} className={'key-value'}>
                        {value}
                      </div>
                      <br />
                    </>
                  ))}
                </div>
              ) : (
                <div className={'key-value'}>{i.value}</div>
              )}
            </div>
          ))}
      </div>
    </>
  )
}
export default CustomSummary
