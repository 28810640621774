import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import { setMenu } from 'src/actions/dashboard.actions'
import {
  getCurrentUserRolesAction,
  getGroups,
  getSubmodules,
  setModuleId,
} from 'src/actions/modules.actions'
import { selectModuleGroups, selectSubModules } from 'src/selectors/modules.selector'
import { saveCurrentModule } from 'src/actions/user.actions'

import Dashboard from '../Dashboard/Dashboard'

import Producers from './Producers/Producers'
import DetailsProducers from './Producers/Details/DetailsProducer'

import Clients from '../Clients/Clients'
import EditClient from '../Clients/Edit/EditClient'
import CreateClient from '../Clients/Create/CreateClientIndex'
import ClientsMap from '../Clients/Map/ClientsMap'
import ClientOrderHistory from '../Clients/ClientOrderHistory/ClientOrderHistory'
import CPCDashboard from '../Clients/CPC/CPCDashboard'
import MapPolygon from '../Clients/MapPolygon/MapPolygon'

import CotizapCompanies from '../Admin/CotizapCompanies/CotizapCompanies'
import EditCompany from '../Admin/EditCompany/EditCompany'
import AdminDashboard from '../Admin/Dashboard/AdminDashboard'

import Permissions from '../Permissions/Dashboard/Permissions'
import GranularPermission from '../Permissions/Granular/GranularPermission'
import UpdatePermissions from '../Permissions/UpdatePermissions/UpdatePermissions'

import OrderDetail from '../Orders/OrderDetail/OrdersDetails'

import Sellers from './Sellers/Sellers'
import Payments from './Payments/Payments'

import TransferDetail from '../Transfer/DetailTransfers/DetailTransfers'

import Polygons from '../Polygons/Polygons'
import NewPolygon2 from '../Polygons/NewPolygon/NewPolygon2'

import CreateWarehouse from '../Warehouse/CreateWarehouse/CreateWarehouse'

import BalanceDashboard from '../Balance/Dashboard'
import CSVLoader from '../CSVLoader/CSVLoader'

import CreateManufacture from '../Conversions/CreateManufacture'

import Inventory from '../Inventory/Inventory'
import ProductDetails from '../../content/Products/ProductDetails'
import RecipesList from '../Inventory/RecipesList'

import Deposits from '../Deposits/Deposits'

import Expenses from '../Expenses/Expenses'

import { selectSetCurrentCompany } from 'src/selectors/company.selector'
import ReportsDashboard from '../Reports/Dashboard/ReportsDashboard'
import { ItemSale } from '../Reports/Products/ItemSale'

import InventoryHistory from '../Reports/InventoryHistory/InventoryHistory'
import Deliverers from './Deliverers/Deliverers'
import Purchase from '../Purchase/Purchase'
import NewPurchase from '../Purchase/Create/NewPurchase.jsx'

import ProductsList from '../Products/Items'
import PublicCategorization from '../Products/Categorization/Categorization'
import ExternalTransfer from '../Transfer/ExternalTransfer/ExternalTransfer'

import SubscriptionsOffer from './Subscriptions/SubscriptionsOffer'
import SubscriptionOfferDetail from './Subscriptions/SubscriptionOfferDetail/SubscriptionOfferDetail'
import Categorization from '../Production/Categorization/Categorization'
import BanksV2 from '../Company/Banks/BanksV2'
import InvoiceProfile from '../InvoiceProfile/InvoiceProfile'
import DepositsAdmin from '../Admin/Payments/Deposits/DepositsAdmin'
import Redirect from '../Redirect/Redirect'
import BalanceEntries from '../Balance/BalanceEntries'
import BalanceCompany from '../Balance/new/BalanceCompany'

import DashboardAdmin from '../Balance/DashboardAdmin'
import ClientPayments from '../Clients/CPC/Payment/Payments'
import PatrimonialReport from '../Reports/Patrimonial/PatrimonialReport'
import AccountingBook from '../AccountingBook/AccountingBook'
import KoloLInkProfileSingle from '../KoloLink/CreateContract/ContractResume'
import CreateContractKolo from '../KoloLink/CreateContract/CreateContractKolo'
import KoloLinkProfile from '../KoloLink/Profile/KoloLinkProfile'
import KoloLink from '../KoloLink/KoloLink'
import SaleReport from '../Reports/Sale/Sale'

import CategorizationCreate from '../Production/Categorization/Create/CategorizationCreate'
import CategorizationDetail from '../Production/Categorization/Detail/CategorizationDetail'

import Increase from '../Waste/Increase'
import Waste from '../Waste/Waste'

import Parcel from '../Parcel/Parcel'

import DepositPage from '../Deposits/DepositPage'
import ExpensesAndWaste from '../Reports/ExpensesAndWaste/ExpensesAndWaste'
import DashboardKolo from '../Kolo/DashboardKolo'
import ClientReport from '../Reports/Client/ClientReport'

import OrdersPage from '../Orders/OrdersPage'
import Invoices from '../Orders/Invoices/Invoices'
import RoutePage from './Route/RoutePage'
import Transfers from '../Transfer/Transfers'
import ConversionsPage from '../Conversions/ConversionsPage'
import ClientBalance from '../Reports/Client/ClientBalance'

import Cpp from '../Purchase/CPP/CppPage'
import OrderDetailPage from '../Orders/OrderDetail/OrderDetailPage'
import ClientRequest from '../Clients/ClientRequest/ClientRequest'
import ProductsHistory from '../Reports/Products/ProductsHistory'
import Providers from '../Providers/PurchaseProvider'
import CardCharges from '../cardCharges/CardCharges'
import Subscription from '../Subscription/Subscription'
import ModalLog from 'src/components/modal/ModalLog'

import RecipeDetail from 'src/components/custom/RecipeDetail/RecipeDetail'

import CreateOrder from '../Orders/OrderCreate/CreateOrder'
import Vouchers from '../Voucher/Vouchers'
import Account from '../Documents/Account/Account'
import Prints from '../Prints/Prints'
import CompanyConfigurations from '../Config/CompanyConfiguration'
import DocumentsDashboard from '../Documents/Dashboard/DocumentsDashboard'
import CompanyAccount from '../Company/Profile/CompanyAccount'
import TableOrderPage from '../Restaurant/TableOrderPage/TableOrderPage'
import SegmentationTable from '../Reports/Segmentation/SegmentationTable'
import DevolutionPage from '../Orders/DevolutionPage'
import ProfilePOS from '../Restaurant/ProfilePOS/ProfilePOS'
import WarehouseV2 from '../Warehouse/WarehouseV2'
import QuoteAdjustment from '../Quotes/QuoteAdjustment'
import Quote from '../Quotes/Quote'
import POSPage from '../Restaurant/POSPage'
import ManufacturePOS from '../Restaurant/ManufacturePOS/ManufacturePOS'
import Turns from '../Turns/Turns'
import CreateTransferPage from '../Transfer/CreateTransfers/CreateTransferPage'
import { ReportConfiguration } from 'src/content/Reports/Configuration/ReportConfiguration'
import Dispatch from 'src/content/Dispatch/Dispatch'
import OrderVisit from '../Orders/OrderVisit/OrderVisit'
import { InventoryKardex } from 'src/content/Reports/InventoryKardex/InventoryKardex'
import BranchesReport from 'src/content/Reports/Branches/BranchesReport'
import { UsersSale } from 'src/content/Reports/Users/UsersSale'
import { PaymentAccount } from 'src/content/Distrbution/Plans/PaymentAccount'
import SegmentationReport from '../Reports/Segmentation/SegmentationReport'
import { TotalSales } from 'src/content/Reports/Sale/TotalSales'
import PurchaseBook from '../Books/PurchaseBook'
import { VehiclesControl } from 'src/content/Reports/Vehicles/VehiclesControl'
import CreditNote from 'src/content/CreditNote/purchase/CreditNote'
import WasteRequestV2 from '../Waste/Create/WasteRequestV2'
import Bills from '../Production/Resolutions/BillsByResolution/BillsByResolutions'
import Resolutions from '../Production/Resolutions/Resolutions'
import CorrelativePage from '../Correlative/CorrelativePage'
import Dynamicfieldspage from './CompanyDynamicFields/DynamicFieldsPage'

import Taxes from 'src/content/Taxes/Taxes'

class DistributionRoutes extends Component {
  componentDidMount() {
    this.props.setModuleId({ id: 1000, title: 'Transporte' })
    // this.props.getSubmodules(1000);
    // this.props.getGroups(1000);
    // this.props.saveCurrentModule();
    // this.props.rolesAction();
  }

  UNSAFE_componentWillReceiveProps(next) {
    const { company } = this.props

    this.props.setModuleId({
      id: 1000,
      title: `Empresa - ${company.name || ''}`,
    })
  }

  render() {
    const { match } = this.props
    return (
      <Switch>
        <Route path={`${match.url}/impuestos`} component={Taxes} />

        <Route path={`${match.url}/resoluciones/:id`} component={Bills} />
        <Route path={`${match.url}/resoluciones`} component={Resolutions} />
        <Route path={`${match.url}/sfac`} component={CorrelativePage} />

        <Route path={`${match.url}/libros/compras`} component={PurchaseBook} />
        <Route path={`${match.url}/prints`} component={Prints} />
        <Route path={`${match.url}/configuraciones`} component={CompanyConfigurations} />
        <Route path={`${match.url}/camposdinamicos`} component={Dynamicfieldspage} />
        <Route path={`${match.url}/documentos`} component={DocumentsDashboard} />
        <Route
          path={`${match.url}/perfil/configuraciones`}
          component={CompanyConfigurations}
        />
        <Route path={`${match.url}/perfil/balance`} component={BalanceEntries} />
        <Route path={`${match.url}/perfil/cuenta`} component={PaymentAccount} />
        <Route path={`${match.url}/perfil`} component={CompanyAccount} />
        <Route
          path={`${match.url}/cuenta/factura/:orderId/:number`}
          component={Invoices}
        />
        <Route path={`${match.url}/cuenta`} component={Account} />
        <Route path={`${match.url}/producciones/crear`} component={CreateManufacture} />
        <Route path={`${match.url}/producciones`} component={ConversionsPage} />
        <Route path={`${match.url}/pos/manufacture`} component={ManufacturePOS} />
        <Route path={`${match.url}/pos/perfil`} component={ProfilePOS} />
        <Route path={`${match.url}/pos/:id`} component={TableOrderPage} />
        <Route path={`${match.url}/pos`} component={POSPage} />
        <Route path={`${match.url}/mesas/:id/:posId`} component={TableOrderPage} />
        <Route path={`${match.url}/mesas/:id`} component={TableOrderPage} />
        <Route path={`${match.url}/mesas`} component={POSPage} />
        <Route path={`${match.url}/proveedores`} component={Providers} />
        <Route path={`${match.url}/cpp`} component={Cpp} />
        <Route
          path={`${match.url}/ordenes/visita/detalle/:id/nueva`}
          component={CreateOrder}
        />
        <Route path={`${match.url}/ordenes/visita`} component={OrderVisit} />
        <Route
          path={`${match.url}/ordenes/:linked/detalle/:id`}
          component={OrderDetailPage}
        />
        <Route
          path={`${match.url}/ordenes/:linked/editar/:id/:sale`}
          component={CreateOrder}
        />
        <Route path={`${match.url}/ordenes/:linked/nueva`} component={CreateOrder} />
        <Route
          path={`${match.url}/ordenes/:linked/invoices/:orderId/:number`}
          component={Invoices}
        />
        <Route
          path={`${match.url}/ordenes/:linked/invoices/:orderId`}
          component={Invoices}
        />
        <Route path={`${match.url}/ordenes/:linked`} component={OrdersPage} />
        <Route path={`${match.url}/devoluciones`} component={DevolutionPage} />
        <Route
          path={`${match.url}/cotizaciones/ajustes`}
          exact
          component={QuoteAdjustment}
        />
        <Route path={`${match.url}/cotizaciones/:linked/nueva`} component={CreateOrder} />
        <Route
          path={`${match.url}/cotizaciones/:linked/editar/:id/:sale`}
          component={CreateOrder}
        />
        <Route
          path={`${match.url}/cotizaciones/:linked/invoices/:orderId/:number`}
          component={Invoices}
        />
        <Route
          path={`${match.url}/cotizaciones/:linked/invoices/:orderId`}
          component={Invoices}
        />
        <Route path={`${match.url}/cotizaciones/:linked`} component={Quote} />
        <Route
          path={`${match.url}/clientes/cpc/:id/orden/detalle/:id`}
          component={OrderDetail}
        />
        <Route path={`${match.url}/clientes/cpc/:id`} component={CPCDashboard} />
        <Route
          path={`${match.url}/clientes/historial/:client`}
          component={ClientOrderHistory}
        />
        <Route path={`${match.url}/clientes/editar/:client`} component={EditClient} />
        <Route path={`${match.url}/clientes/nuevo`} component={CreateClient} />
        <Route
          path={`${match.url}/clientes/mapa/poligono/historial/:client`}
          component={ClientOrderHistory}
        />
        <Route
          path={`${match.url}/clientes/mapa/poligono/editar/:client`}
          component={EditClient}
        />
        <Route path={`${match.url}/clientes/mapa/poligono/admin`} component={Polygons} />
        <Route path={`${match.url}/clientes/mapa/poligono`} component={MapPolygon} />
        <Route path={`${match.url}/clientes/mapa`} component={ClientsMap} />
        <Route path={`${match.url}/clientes/solicitudes`} component={ClientRequest} />
        <Route
          path={`${match.url}/clientes/suscripciones/detalle`}
          component={Subscription}
        />
        <Route path={`${match.url}/clientes`} component={Clients} />
        <Route path={`${match.url}/cxc/pagos/:client`} component={ClientPayments} />
        <Route
          path={`${match.url}/cxc/invoices/:orderId/:typeReceipt`}
          component={Invoices}
        />
        <Route path={`${match.url}/cxc`} component={ClientPayments} />
        <Route path={`${match.url}/gastos/nueva`} component={NewPurchase} />
        <Route path={`${match.url}/gastos/editar/:id`} component={NewPurchase} />
        <Route path={`${match.url}/gastos`} component={Expenses} />
        <Route path={`${match.url}/compras/invoices/:purchaseId`} component={Invoices} />
        <Route
          path={`${match.url}/compras/importaciones/nueva`}
          component={NewPurchase}
        />
        <Route path={`${match.url}/compras/nueva`} component={NewPurchase} />
        <Route path={`${match.url}/compras/orden/:id`} component={NewPurchase} />
        <Route path={`${match.url}/compras/editar/:id`} component={NewPurchase} />
        <Route path={`${match.url}/compras/orden`} component={NewPurchase} />
        <Route path={`${match.url}/compras`} component={Purchase} />
        <Route
          path={`${match.url}/ofertas/:moduleId/perfil/:id/:type/nuevo/:zone`}
          component={CreateContractKolo}
        />
        <Route
          path={`${match.url}/ofertas/:moduleId/perfil/:id/:type/editar/:zone`}
          component={CreateContractKolo}
        />
        <Route
          path={`${match.url}/ofertas/:moduleId/perfil/:id/:type/detalle/:id`}
          component={KoloLInkProfileSingle}
        />
        <Route
          path={`${match.url}/ofertas/:moduleId/perfil/:id/:type`}
          component={KoloLinkProfile}
        />
        <Route
          path={`${match.url}/ofertas/:moduleId/detalles/:id`}
          component={KoloLInkProfileSingle}
        />
        <Route path={`${match.url}/ofertas/:moduleId`} component={KoloLink} />
        <Route path={`${match.url}/productores/:id`} component={DetailsProducers} />
        <Route path={`${match.url}/productores`} component={Producers} />
        <Route
          path={`${match.url}/transferencias/kolo/:moduleId/crear`}
          component={ExternalTransfer}
        />
        <Route
          path={`${match.url}/transferencias/crear/:val`}
          component={CreateTransferPage}
        />
        <Route
          path={`${match.url}/transferencias/crear`}
          component={CreateTransferPage}
        />
        <Route
          path={`${match.url}/transferencias/kolo/:moduleId`}
          component={Transfers}
        />
        <Route path={`${match.url}/transferencias/:id`} component={TransferDetail} />
        <Route path={`${match.url}/transferencias`} component={Transfers} />
        <Route path={`${match.url}/bodegas/crear`} component={CreateWarehouse} />
        <Route path={`${match.url}/bodegas/editar/:id`} component={CreateWarehouse} />
        <Route path={`${match.url}/bodegas`} component={WarehouseV2} />
        <Route path={`${match.url}/admin/empresas/editar`} component={EditCompany} />
        <Route path={`${match.url}/admin/empresas`} component={CotizapCompanies} />
        <Route path={`${match.url}/admin/`} component={AdminDashboard} />
        <Route
          path={`${match.url}/permisos/:module/:user/:name`}
          component={UpdatePermissions}
        />
        <Route
          path={`${match.url}/permisos/:module/matriz`}
          component={GranularPermission}
        />
        <Route path={`${match.url}/permisos/:module`} component={Permissions} />
        <Route path={`${match.url}/repartidores`} component={Deliverers} />
        <Route path={`${match.url}/vendedores`} component={Sellers} />
        <Route path={`${match.url}/pagos/boletas/nueva`} component={Payments} />
        <Route path={`${match.url}/pagos/boletas`} component={Deposits} />
        <Route path={`${match.url}/pagos/:type/:moduleId`} component={DepositPage} />
        <Route path={`${match.url}/pagos/:type`} component={DepositPage} />
        <Route path={`${match.url}/poligonos/editar/:polygon`} component={NewPolygon2} />
        <Route path={`${match.url}/poligonos/nuevo`} component={NewPolygon2} />
        <Route path={`${match.url}/poligonos`} component={Polygons} />
        <Route path={`${match.url}/rutas/:linked`} component={RoutePage} />
        <Route path={`${match.url}/rutas`} component={RoutePage} />
        <Route path={`${match.url}/balance/propios`} component={BalanceCompany} />
        <Route path={`${match.url}/balance/kolo/:moduleId`} component={BalanceCompany} />
        <Route path={`${match.url}/desembolsos`} component={DashboardAdmin} />
        <Route path={`${match.url}/cuadres/propio`} component={BalanceEntries} />
        <Route path={`${match.url}/cuadres/kolo/:moduleId`} component={BalanceEntries} />
        <Route path={`${match.url}/balance/general`} component={BalanceEntries} />
        <Route path={`${match.url}/balance`} component={BalanceDashboard} />
        <Route path={`${match.url}/patrimonio`} component={AccountingBook} />
        <Route path={`${match.url}/csv`} component={CSVLoader} />
        <Route path={`${match.url}/kolo`} component={DashboardKolo} />
        <Route path={`${match.url}/conversiones`} component={Transfers} />
        <Route
          path={`${match.url}/inventario/productos/detalle/:id`}
          component={ProductDetails}
        />
        <Route path={`${match.url}/inventario/recetas/:id`} component={RecipeDetail} />
        <Route path={`${match.url}/inventario/recetas`} component={RecipesList} />
        <Route path={`${match.url}/inventario`} component={Inventory} />
        <Route path={`${match.url}/reportes/ventas/totales`} component={TotalSales} />
        <Route path={`${match.url}/reportes/ventas/totales/old`} component={SaleReport} />
        <Route path={`${match.url}/reportes/ventas`} component={SaleReport} />
        <Route path={`${match.url}/reportes/balance`} component={ClientBalance} />
        <Route path={`${match.url}/reportes/patrimonial`} component={PatrimonialReport} />
        <Route path={`${match.url}/reportes/usuarios`} component={UsersSale} />
        <Route
          path={`${match.url}/reportes/inventario/kardex`}
          component={InventoryKardex}
        />
        <Route path={`${match.url}/reportes/inventario`} component={InventoryHistory} />
        <Route
          path={`${match.url}/reportes/productos/historial`}
          component={ProductsHistory}
        />
        <Route path={`${match.url}/reportes/productos`} component={ItemSale} />
        <Route path={`${match.url}/reportes/gastos`} component={ExpensesAndWaste} />
        <Route path={`${match.url}/reportes/compras`} component={ExpensesAndWaste} />
        <Route path={`${match.url}/reportes/merma`} component={ExpensesAndWaste} />
        <Route path={`${match.url}/reportes/logs`} component={ModalLog} />
        <Route path={`${match.url}/reportes/clientes`} component={ClientReport} />
        <Route
          path={`${match.url}/reportes/segmentación/deprecado`}
          component={SegmentationTable}
        />
        <Route
          path={`${match.url}/reportes/segmentación`}
          component={SegmentationReport}
        />
        <Route path={`${match.url}/reportes/sucursales`} component={BranchesReport} />
        <Route path={`${match.url}/reportes/vehiculos`} component={VehiclesControl} />
        <Route path={`${match.url}/reportes`} component={ReportsDashboard} />
        <Route
          path={`${match.url}/configuracion/reportes`}
          component={ReportConfiguration}
        />
        <Route
          path={`${match.url}/productos/categorizaciones/lista/nuevo`}
          component={CategorizationCreate}
        />
        <Route
          path={`${match.url}/productos/categorizaciones/lista`}
          component={Categorization}
        />
        <Route
          path={`${match.url}/productos/categorizaciones`}
          component={PublicCategorization}
        />
        <Route path={`${match.url}/productos/detalles/:id`} component={ProductDetails} />
        <Route path={`${match.url}/productos`} component={ProductsList} />
        <Route
          path={`${match.url}/subscriptions/detail`}
          component={SubscriptionOfferDetail}
        />
        <Route path={`${match.url}/subscriptions`} component={SubscriptionsOffer} />
        <Route
          path={`${match.url}/categorizaciones/detalle/:id`}
          component={CategorizationDetail}
        />
        <Route
          path={`${match.url}/categorizaciones/nuevo`}
          component={CategorizationCreate}
        />
        <Route path={`${match.url}/categorizaciones`} component={Categorization} />
        <Route path={`${match.url}/bancos`} component={BanksV2} />
        <Route path={`${match.url}/facturacion`} component={InvoiceProfile} />
        <Route path={`${match.url}/depositos`} component={DepositsAdmin} />
        <Route
          path={`${match.url}/:module/redirect/:type/:id/:company/:packageId`}
          component={Redirect}
        />
        <Route
          path={`${match.url}/:module/redirect/:type/:id/:company`}
          component={Redirect}
        />
        <Route path={`${match.url}/:module/redirect/:type/:id`} component={Redirect} />
        <Route path={`${match.url}/increase/nuevo`} component={WasteRequestV2} />
        <Route path={`${match.url}/increase`} component={Increase} />
        <Route path={`${match.url}/waste/nuevo`} component={WasteRequestV2} />
        <Route path={`${match.url}/waste`} component={Waste} />
        <Route path={`${match.url}/paqueteria`} component={Parcel} />
        <Route path={`${match.url}/cobros`} component={CardCharges} />
        <Route
          path={`${match.url}/vales/:creditNote/:orderId/:number`}
          component={Invoices}
        />
        <Route path={`${match.url}/vales`} component={Vouchers} />
        <Route
          path={`${match.url}/notas/:creditNote/:orderId/:number`}
          component={Invoices}
        />
        <Route path={`${match.url}/notas/credito`} component={CreditNote} />
        <Route path={`${match.url}/notas`} component={Vouchers} />
        <Route path={`${match.url}/recetas`} component={RecipeDetail} />
        {/*<Route path={`${match.url}/planes/:companyId`} component={Plans} />*/}
        {/*<Route path={`${match.url}/planes`} component={Plans} />*/}
        <Route path={`${match.url}/turnos`} component={Turns} />
        <Route path={`${match.url}/despachos`} component={Dispatch} />

        <Route path={``} component={Dashboard} />
      </Switch>
    )
  }
}

const mapStateToProps = state => ({
  submodules: selectSubModules(state),
  company: selectSetCurrentCompany(state),
  groups: selectModuleGroups(state),
})

const mapDispatchToProps = dispatch => ({
  setMenu: routes => dispatch(setMenu(routes)),
  getGroups: module => dispatch(getGroups(module)),
  setModuleId: module => dispatch(setModuleId(module)),
  getSubmodules: module => dispatch(getSubmodules(module)),
  saveCurrentModule: () => dispatch(saveCurrentModule(1000)),
  rolesAction: () => dispatch(getCurrentUserRolesAction(1000)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DistributionRoutes)
