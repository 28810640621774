import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Alert from 'sweetalert-react'
import { Row, Col } from 'react-bootstrap'
import { ItemsToSelect, Title, Card, Switch, Select, FAB } from 'src/components'
import { itemTypes } from 'src/components/Items/ItemsToSelect'
import { faSave } from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes as actionW,
  createTransfer,
  getWarehouseLite,
} from 'src/actions/warehouse.actions'
import {
  selectOwnWarehouseLite,
  selectWarehouseLite,
} from 'src/selectors/warehouse.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import { handlerError, handlerSuccess, hasErrors } from 'src/selectors/error.selector'

const CreateTransferPage = ({ history, match }) => {
  const dispatch = useDispatch()

  const warehouses = useSelector(selectWarehouseLite)
  const ownWarehouses = useSelector(selectOwnWarehouseLite)
  const loadingW = useSelector(state =>
    loadingSelector([actionW.GET_WAREHOUSE_LITE])(state),
  )

  const loadingC = useSelector(state => loadingSelector([actionW.CREATE_TRANSFER])(state))
  const hasError = useSelector(state => hasErrors([actionW.CREATE_TRANSFER])(state))

  const [flags, setFlags] = useState({ create: false, getW: false })
  const [alert, setAlert] = useState({ title: '' })

  const [isRequest, setIsRequest] = useState(false)
  const [fromWarehouse, setFromWarehouse] = useState({
    value: null,
    label: 'Sin seleccionar',
  })
  const [toWarehouse, setToWarehouse] = useState({
    value: null,
    label: 'Sin seleccionar',
  })
  const [selected, setSelected] = useState([])
  const [allInventory, setAllInventory] = useState(false)

  useEffect(() => {
    dispatch(getWarehouseLite({ all: true }))
    if (match) {
      const { val } = match.params
      if (val && val === 'solicitud') setIsRequest(true)
    }
  }, [])

  useEffect(() => {
    if (ownWarehouses.length === 0) return
    if (ownWarehouses.length > 0 && fromWarehouse.value === null && !isRequest)
      setFromWarehouse(ownWarehouses[0])
  }, [ownWarehouses])

  useEffect(() => {
    if (loadingC) setFlags({ ...flags, create: true })
    else if (flags.create) {
      setFlags({ ...flags, create: false })
      if (hasError) onAlert(hasError.message)
      else
        onAlert(
          `${
            isRequest ? 'Solicitud de transferencia' : 'Transferencia'
          } realizada satisfactoriamente`,
          true,
          () => {
            history.push('/distribucion/transferencias')
          },
        )
    }
  }, [loadingC])

  const filterWarehouse = (item, warehouseId) => {
    return item.value !== warehouseId
  }

  const onAlert = (message, success, onConfirm) => {
    const initial = success ? handlerSuccess(message) : handlerError(message)
    setAlert({
      ...initial,
      onConfirm: () => {
        setAlert({ ...alert, show: false })
        if (onConfirm) onConfirm()
      },
    })
  }

  /**
   * Valida si los items en la tabla tienen cantidades > 0
   * @returns {boolean} true if all items have quantities
   */
  const validateItems = () => {
    return selected.filter(i => i.haveQuantities === true).length === selected.length
  }

  return (
    <div>
      <Title title={`Crear ${isRequest ? 'solicitud de' : ''} transferencia`} />

      <Row>
        <Col xl={6} lg={6} md={6} sm={12}>
          <Card>
            <Row>
              <Col xl={12}>
                <div className={'space-between'}>
                  <div>
                    <h6 className={'dashboard-title dashboard-f-color'}>
                      Bodega {isRequest ? 'a solicitar' : 'saliente'}
                    </h6>
                  </div>
                  <div>
                    <Switch
                      label={'Inventario completo'}
                      mt={0}
                      checked={allInventory}
                      changeValue={value => setAllInventory(value)}
                    />
                  </div>
                </div>
              </Col>
              <Col xl={12}>
                <Select
                  label={'Seleccionar una bodega'}
                  options={isRequest ? warehouses : ownWarehouses}
                  value={fromWarehouse}
                  loading={loadingW}
                  onChange={fw => {
                    setFromWarehouse(fw)
                    if (toWarehouse.value === fw.value)
                      setToWarehouse({ value: null, label: 'Sin seleccionar' })
                  }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xl={6} lg={6} md={6} sm={12}>
          <Card>
            <Row>
              <Col xl={12}>
                <div className={'space-between'}>
                  <div>
                    <h6 className={'dashboard-title dashboard-f-color'}>
                      Bodega Receptora
                    </h6>
                  </div>
                </div>
              </Col>
              <Col xl={12}>
                <Select
                  label={'Seleccionar una bodega'}
                  options={(isRequest ? ownWarehouses : warehouses).filter(w =>
                    filterWarehouse(w, fromWarehouse.value),
                  )}
                  value={toWarehouse}
                  loading={loadingW}
                  onChange={tw => {
                    setToWarehouse(tw)
                    if (fromWarehouse.value === tw.value)
                      setFromWarehouse({
                        value: null,
                        label: 'Sin seleccionar',
                      })
                  }}
                />
              </Col>
            </Row>
          </Card>
        </Col>

        <Col xl={12}>
          <ItemsToSelect
            disabled={loadingC}
            type={itemTypes.TRANSFER}
            warehouseId={fromWarehouse.value}
            onSelected={selected => {
              setSelected(selected)
            }}
            allInventory={allInventory}
          />
        </Col>
      </Row>

      <FAB
        show={validateItems() || allInventory}
        icon={faSave}
        loading={loadingC}
        onClick={() => {
          let errorMessage = ''
          if (!fromWarehouse.value) errorMessage = 'Bodega saliente, '
          if (!toWarehouse.value) errorMessage += 'Bodega destino, '
          if (!allInventory) {
            if (selected.length === 0) errorMessage += 'Ítems a transferir'
            else {
              let i = ''
              selected
                .filter(i => i.value && i.haveQuantities === false)
                .forEach(s => {
                  i += `${s.name}, `
                })
              if (i.length > 0) errorMessage += `ítems sin cantidad asignada: ${i}`
            }
          }

          if (errorMessage.length > 0) {
            onAlert(`Campos obligatorios: ${errorMessage}`)
            return
          }
          const items = []
          let counter = 0
          if (!allInventory)
            selected.forEach(s => {
              s.lineItems.forEach(line => {
                if (line.quantity > 0) {
                  items.push({
                    index: counter++,
                    productId: line.productId,
                    quantity: line.quantity,
                    variations: s.listVariations
                      ? s.listVariations.map(x => {
                          return x.id
                        })
                      : [],
                    listIdLocations: s.listLocations
                      ? s.listLocations.map(x => {
                          return x.id || x.value
                        })
                      : [],
                    commentary: s.commentary,
                  })
                }
              })
            })

          setAlert({
            title: 'Transferencia entre bodegas',
            show: true,
            type: 'input',
            inputPlaceholder: 'Descripción',
            text: 'Ingrese una descripción o concepto para la transferencia\n(OPCIONAL)',
            showCancelButton: true,
            cancelButtonText: 'Atrás',
            confirmButtonText: 'Crear',
            confirmButtonColor: '#226095',
            onCancel: () => setAlert({ ...alert, show: false }),
            onConfirm: description => {
              setAlert({ ...alert, show: false })

              dispatch(
                createTransfer(
                  fromWarehouse.value,
                  toWarehouse.value,
                  false,
                  description,
                  isRequest,
                  items,
                  allInventory,
                ),
              )
            },
          })
        }}
      />

      <Alert {...alert} />
    </div>
  )
}
export default CreateTransferPage
