import { Country } from './countries'

const posPanelConfigurations = {
  tips: 1,
  discounts: 2,
  comments: 3,
  concepts: 4,
  paymentCash: 101,
  paymentCredit: 102,
  paymentCard: 103,
  paymentTransfer: 104,
  payPreBill: 201,
  payBill: 202,
  payOrder: 203, // INVOICE WITHOUT IVA
  payReceipt: 204,
  payExcludedSubject: 607, // EXCLUDED SUBJECT
  payFiscalCredit: 608, // FISCAL CREDIT
  payFiscalInvoice: 609, // INVOICE
  tabOrders: 301,
  tabSplit: 302,
  actionTransfer: 401,
  actionBarcode: 402,
  actionClear: 403,
  actionPaymentMultiple: 404,
  actionAgileOrderPrint: 405,
  timer: 501,
  identification1: 601,
  identification2: 602,
  identification3: 603,
  identification4: 604,
  identification5: 605,
  identification6: 606,
}

const posConfigurationGroup = {
  GENERAL: 1,
  CLIENT: 2,
  PAYMENTS: 3,
  CLOSE: 4,
  INVOICE: 5,
  TABS: 6,
  ACTIONS: 7,
}

const posPanelConfigurationFields: IDictionary<IPOSPanelConfigurationField> = {
  [posPanelConfigurations.tips]: {
    type: posPanelConfigurations.tips,
    name: 'Agregar propina',
    groupBy: posConfigurationGroup.GENERAL,
  },
  [posPanelConfigurations.discounts]: {
    type: posPanelConfigurations.discounts,
    name: 'Agregar descuentos',
    groupBy: posConfigurationGroup.GENERAL,
  },
  [posPanelConfigurations.comments]: {
    type: posPanelConfigurations.comments,
    name: 'Agregar comentarios',
    groupBy: posConfigurationGroup.GENERAL,
  },
  [posPanelConfigurations.concepts]: {
    type: posPanelConfigurations.concepts,
    name: 'Agregar conceptos de facturación',
    groupBy: posConfigurationGroup.GENERAL,
  },
  [posPanelConfigurations.paymentCash]: {
    type: posPanelConfigurations.paymentCash,
    name: 'Pago en efectivo',
    groupBy: posConfigurationGroup.PAYMENTS,
  },
  [posPanelConfigurations.paymentCredit]: {
    type: posPanelConfigurations.paymentCredit,
    name: 'Pago con crédito',
    groupBy: posConfigurationGroup.PAYMENTS,
  },
  [posPanelConfigurations.paymentCard]: {
    type: posPanelConfigurations.paymentCard,
    name: 'Pago con tarjeta',
    groupBy: posConfigurationGroup.PAYMENTS,
  },
  [posPanelConfigurations.paymentTransfer]: {
    type: posPanelConfigurations.paymentTransfer,
    name: 'Transferencia bancaria',
    groupBy: posConfigurationGroup.PAYMENTS,
  },
  [posPanelConfigurations.payPreBill]: {
    type: posPanelConfigurations.payPreBill,
    name: 'Pre cuenta',
    groupBy: posConfigurationGroup.CLOSE,
  },
  [posPanelConfigurations.payBill]: {
    type: posPanelConfigurations.payBill,
    name: 'Facturar',
    groupBy: posConfigurationGroup.CLOSE,
  },
  [posPanelConfigurations.payOrder]: {
    type: posPanelConfigurations.payOrder,
    name: 'Orden',
    groupBy: posConfigurationGroup.CLOSE,
  },
  [posPanelConfigurations.payReceipt]: {
    type: posPanelConfigurations.payReceipt,
    name: 'Recibo',
    groupBy: posConfigurationGroup.CLOSE,
  },
  [posPanelConfigurations.payExcludedSubject]: {
    type: posPanelConfigurations.payExcludedSubject,
    name: 'Sujeto excluido',
    groupBy: posConfigurationGroup.INVOICE,
    countries: [Country.SV],
  },
  [posPanelConfigurations.payFiscalCredit]: {
    type: posPanelConfigurations.payFiscalCredit,
    name: 'Crédito Fiscal',
    groupBy: posConfigurationGroup.INVOICE,
    countries: [Country.SV],
  },
  [posPanelConfigurations.payFiscalInvoice]: {
    type: posPanelConfigurations.payFiscalInvoice,
    name: 'Factura',
    groupBy: posConfigurationGroup.INVOICE,
    countries: [Country.SV],
  },
  [posPanelConfigurations.tabOrders]: {
    type: posPanelConfigurations.tabOrders,
    name: 'Comandas',
    groupBy: posConfigurationGroup.TABS,
  },
  [posPanelConfigurations.tabSplit]: {
    type: posPanelConfigurations.tabSplit,
    name: 'Cuentas separadas',
    groupBy: posConfigurationGroup.TABS,
  },
  [posPanelConfigurations.actionTransfer]: {
    type: posPanelConfigurations.actionTransfer,
    name: 'Transferir terminal',
    groupBy: posConfigurationGroup.ACTIONS,
  },
  [posPanelConfigurations.actionBarcode]: {
    type: posPanelConfigurations.actionBarcode,
    name: 'Escanear código de barras',
    groupBy: posConfigurationGroup.ACTIONS,
  },
  [posPanelConfigurations.actionClear]: {
    type: posPanelConfigurations.actionClear,
    name: 'Limpiar terminal',
    groupBy: posConfigurationGroup.ACTIONS,
  },
  [posPanelConfigurations.actionPaymentMultiple]: {
    type: posPanelConfigurations.actionPaymentMultiple,
    name: 'Pagos mixtos',
    groupBy: posConfigurationGroup.ACTIONS,
  },
  [posPanelConfigurations.actionAgileOrderPrint]: {
    type: posPanelConfigurations.actionAgileOrderPrint,
    name: 'imprimir comandas',
    groupBy: posConfigurationGroup.ACTIONS,
    posSteps: [4],
  },
  [posPanelConfigurations.timer]: {
    type: posPanelConfigurations.timer,
    name: 'Temporizador',
    groupBy: posConfigurationGroup.GENERAL,
  },
  [posPanelConfigurations.identification1]: {
    type: posPanelConfigurations.identification1,
    name: 'NIT',
    groupBy: posConfigurationGroup.CLIENT,
    countries: [Country.GT],
  },
  [posPanelConfigurations.identification2]: {
    type: posPanelConfigurations.identification2,
    name: 'DPI',
    groupBy: posConfigurationGroup.CLIENT,
    countries: [Country.GT],
  },
  [posPanelConfigurations.identification3]: {
    type: posPanelConfigurations.identification3,
    name: 'NIT',
    groupBy: posConfigurationGroup.CLIENT,
    countries: [Country.SV],
  },
  [posPanelConfigurations.identification4]: {
    type: posPanelConfigurations.identification4,
    name: 'NRC',
    groupBy: posConfigurationGroup.CLIENT,
    countries: [Country.SV],
  },
  [posPanelConfigurations.identification5]: {
    type: posPanelConfigurations.identification5,
    name: 'DUI',
    groupBy: posConfigurationGroup.CLIENT,
    countries: [Country.SV],
  },
  [posPanelConfigurations.identification6]: {
    type: posPanelConfigurations.identification6,
    name: 'ID Extranjero',
    groupBy: posConfigurationGroup.CLIENT,
  },
}

const posConfigurationGroupFields: IDictionary<IPOSPanelConfiguration> = {
  [posConfigurationGroup.GENERAL]: {
    type: posConfigurationGroup.GENERAL,
    name: 'Campos del POS',
  },
  [posConfigurationGroup.CLIENT]: {
    type: posConfigurationGroup.CLIENT,
    name: 'Datos del cliente',
  },
  [posConfigurationGroup.PAYMENTS]: {
    type: posConfigurationGroup.PAYMENTS,
    name: 'Tipos de pago',
  },
  [posConfigurationGroup.CLOSE]: {
    type: posConfigurationGroup.CLOSE,
    name: 'Tipos de cierre',
  },
  [posConfigurationGroup.INVOICE]: {
    type: posConfigurationGroup.INVOICE,
    name: 'Tipos de facturación',
  },
  [posConfigurationGroup.TABS]: { type: posConfigurationGroup.TABS, name: 'Pestañas' },
  [posConfigurationGroup.ACTIONS]: {
    type: posConfigurationGroup.ACTIONS,
    name: 'Acciones',
  },
}

export {
  posPanelConfigurations,
  posConfigurationGroupFields,
  posPanelConfigurationFields,
}
