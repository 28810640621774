import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FormTextField from 'src/components/inputs/FormTextField/FormTextField'
import Card from 'src/components/cards/Card'
import { Col, Row, Modal } from 'react-bootstrap'
import { faExclamationTriangle, faPlus, faSave } from '@fortawesome/free-solid-svg-icons'
import Button from 'src/components/buttons/Button'
import { loadingSelector } from 'src/selectors/loading.selector'
import {
  handlerError,
  handlerSuccess,
  hasErrors,
  hasErrorsSelector,
  singleErrorSelector,
} from 'src/selectors/error.selector'

import CustomSelect from 'src/components/inputs/Select/CustomSelect'
import Alert from 'sweetalert-react'

import { selectClientRequest, selectSingleClient } from 'src/selectors/clients.selector'
import {
  selectAllRegionsForSelect,
  selectRegionLocalitiesForSelect,
  selectSingleLocality,
} from 'src/selectors/address.selector'
import {
  actionTypes as typesAddress,
  getLocalitiesByRegion,
  getSingleLocality,
  getAllRegions,
} from 'src/actions/address.actions'
import {
  actionTypes as typesClients,
  createClientEditRequest,
  getSingleClient,
  updateClient,
} from 'src/actions/clients.actions'
import { isAllowed } from 'src/selectors/modules.selector'
import {
  FormText,
  Select,
  CustomTabs,
  ListCategories,
  ImageInput,
  Folder,
  SwitchV2,
} from 'src/components/index'
import CustomDatePicker from 'src/components/CustomDatePicker/CustomDatePicker'
import IconButton from 'src/components/buttons/IconButton'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'

import ClientContacts from 'src/content/Clients/Contacts/ClientContacts'
import {
  assignCategorizationToEntity,
  getCategorizationsByEntity,
} from 'src/actions/categorization.actions'
import {
  selectAllCategorizations,
  selectCategorizationAssignedForSelect,
} from 'src/selectors/categorizations.selector'
import { InfoCUI } from 'src/components/infoCUI/InfoCUI'
import {
  getAllEconomicActivity,
  getExportationCountries,
} from 'src/actions/currencies.actions'
import {
  selectEconomicActivities,
  selectExportationCountries,
} from 'src/selectors/currencies.selector'
import {
  selectCompanyCountry,
  selectCompanyFieldById,
  selectIdentificationTypes,
} from 'src/selectors/company.selector'
import { Country } from 'src/enums/countries'
import IdentificationField from 'src/components/IdentificationField/IdentificationField'
import { clientPermissions } from 'src/enums/permissions'
import { identificationTypesEnum } from 'src/enums/identificationTypes'
import {
  selectModalEditClient,
  selectNeedAddressComponents,
} from 'src/selectors/utilities.selector'
import { setModalEditClient } from 'src/actions/utilities.actions'
import { actionTypes as categorizationActions } from 'src/actions/categorization.actions'
import { categoryType } from 'src/components/folders/Folder'
import { imageTypes } from 'src/components/gallery/Gallery'
import {
  actionTypes as companyAction,
  getCompanyField,
} from 'src/actions/company.actions'
import companyFields from 'src/enums/companyFields'
import { ClientAdditionalInfo } from 'src/components/modal/CreatClientExtra/ClientAdditionalInfo'
import {
  fetchDynamicFieldsByEntity,
  fetchDynamicFieldsValueByEntity,
} from 'src/actions/dynamicfield.actions'
import {
  selectEntityFields,
  selectEntityFieldsValueEntity,
} from 'src/selectors/dynamicFields.selector'
import { dynamicEntityType } from '../../../enums/dynamicFieldsEnum'
import { isNecessaryAddressComponents } from 'src/utils/clientHelpers/clientFunctions'

const defaultOption = { value: null, label: 'Sin seleccionar' }
const defaultClient = { image: { url: null } }
const contactTypes = [
  { value: 1, label: 'Visita' },
  { value: 2, label: 'Llamada' },
  { value: 3, label: 'WhatsApp' },
]

/**
 * Show a client detail
 * @param {boolean} modal - If true show a modal
 * @param {number} id - Client id
 * @param {function} onDataChange - Function to run on data change
 * @param {object} setError - Object with error data
 */
const ClientDetail = ({ modal, id, onDataChange, setError }) => {
  const dispatch = useDispatch()

  const modalEditClient = useSelector(selectModalEditClient)
  const { onSave, showProfile = false } = modalEditClient
  id ??= modalEditClient.id

  const client = useSelector(selectSingleClient)
  const request = useSelector(selectClientRequest)
  const economicActivities = useSelector(selectEconomicActivities)
  const country = useSelector(selectCompanyCountry)
  const categorization = useSelector(selectAllCategorizations)

  const regions = useSelector(selectAllRegionsForSelect)
  const localities = useSelector(selectRegionLocalitiesForSelect)
  const identificationTypes = useSelector(selectIdentificationTypes)
  const exportationCountries = useSelector(selectExportationCountries)
  const useExportationInvoice = useSelector(state =>
    selectCompanyFieldById(state, companyFields.useExportationInvoice),
  )

  const clientCategorization = useSelector(selectCategorizationAssignedForSelect)

  const setAddressComponents = useSelector(selectNeedAddressComponents)

  const loadingClient = useSelector(state =>
    loadingSelector([typesClients.GET_SINGLE_CLIENTS])(state),
  )
  const loadingUpdate = useSelector(state =>
    loadingSelector([typesClients.UPDATE_SINGLE_CLIENTS])(state),
  )
  const hasErrorUpdate = useSelector(state =>
    hasErrors([typesClients.UPDATE_SINGLE_CLIENTS])(state),
  )

  const loadingCreateRequest = useSelector(state =>
    loadingSelector([typesClients.CREATE_CLIENT_REQUESTS])(state),
  )
  const hasErrorCreateRequest = useSelector(state =>
    hasErrorsSelector([typesClients.CREATE_CLIENT_REQUESTS])(state),
  )
  const errorCreateRequest = useSelector(state =>
    singleErrorSelector([typesClients.CREATE_CLIENT_REQUESTS])(state),
  )

  const loadingUpdateRequest = useSelector(state =>
    loadingSelector([typesClients.UPDATE_CLIENT_REQUEST])(state),
  )
  const hasErrorUpdateRequest = useSelector(state =>
    hasErrorsSelector([typesClients.UPDATE_CLIENT_REQUEST])(state),
  )
  const errorUpdateRequest = useSelector(state =>
    singleErrorSelector([typesClients.UPDATE_CLIENT_REQUEST])(state),
  )

  const loadingAssigned = useSelector(state =>
    loadingSelector([categorizationActions.GET_ASSIGNED])(state),
  )
  const loadingAssign = useSelector(state =>
    loadingSelector([categorizationActions.ADD_ENTITY_CATEGORIZATION])(state),
  )
  const hasErrorAssign = useSelector(state =>
    hasErrorsSelector([categorizationActions.ADD_ENTITY_CATEGORIZATION])(state),
  )
  const loadingField = useSelector(state =>
    loadingSelector([companyAction.GET_FIELD])(state),
  )

  useEffect(() => {
    if (loadingAssign) setAction({ ...action, assign: true })
    else if (action.assign && !hasErrorAssign)
      dispatch(getCategorizationsByEntity('clients', id))
  }, [loadingAssign])

  const locality = useSelector(selectSingleLocality)
  const loadingLocality = useSelector(state =>
    loadingSelector([typesAddress.GET_SINGLE_LOCALITY])(state),
  )
  const hasErrorLocality = useSelector(state =>
    hasErrorsSelector([typesAddress.GET_SINGLE_LOCALITY])(state),
  )

  const [action, setAction] = useState({
    update: false,
    get: false,
    create: false,
    status: null,
    assign: false,
  })
  const [clientItem, setClientItem] = useState(defaultClient)
  const [alert, setAlert] = useState({ title: '' })

  const [modalC, setModalC] = useState({ show: false, email: null })
  const [emails, setEmails] = useState([])
  const [identifications, setIdentifications] = useState({})
  const [inputStates, setInputStates] = useState([])
  const canEditClient = useSelector(state => isAllowed(state, [clientPermissions.update]))
  const [flag, setFlag] = useState(false)
  const [isValid, setIsValid] = useState(true)
  const entityFields = useSelector(selectEntityFields)
  const valuesEntity = useSelector(selectEntityFieldsValueEntity)

  useEffect(() => {
    dispatch(fetchDynamicFieldsByEntity(dynamicEntityType.CLIENTS))
  }, [])

  useEffect(() => {
    if (clientItem?.id) {
      dispatch(fetchDynamicFieldsValueByEntity(dynamicEntityType.CLIENTS, clientItem?.id))
    }
  }, [clientItem?.id])

  useEffect(() => {
    if (valuesEntity) {
      setInputStates(valuesEntity)
    }
  }, [valuesEntity])

  useEffect(() => {
    if (modalEditClient.show && id) init()
  }, [modalEditClient.show])

  useEffect(() => {
    if (loadingField) setFlag(true)
    else if (flag) {
      setFlag(false)
      if (useExportationInvoice?.value === 'true') dispatch(getExportationCountries())
    }
  }, [loadingField])

  useEffect(() => {
    if (!client.companyName) return

    if (client.localityId) dispatch(getSingleLocality(client.localityId))

    let item = { ...client }
    if (client.businessType == null) item.businessType = undefined
    if (client.phoneCompany == null) item.phoneCompany = undefined
    if (client.latitude && client.longitude) {
      item.center = {
        lat: Number(client.latitude),
        lng: Number(client.longitude),
      }
      item.latitude = Number(client.latitude)
      item.longitude = Number(client.longitude)
    } else {
      item.center = { lat: 0, lng: 0 }
      item.latitude = 0
      item.longitude = 0
    }
    if (!client.secondAddress) item.secondAddress = client.address
    if (client && client.contactType) {
      const contactType = contactTypes.find(c => c.value === client.contactType)
      if (contactType) item.contactType = contactType
    }
    if (client.emails && client.emails.length > 0)
      setEmails(client.emails.map(item => ({ value: item, label: item })))
    else setEmails([])

    if (client.economicActivity !== null)
      item.economicActivity = {
        ...client.economicActivity,
        value: client.economicActivity.id,
        label:
          client.economicActivity.phrase + ' - ' + client.economicActivity.description,
      }

    const newClientIdentifications = {}
    client.identifications?.forEach(
      item => (newClientIdentifications[item.identificationTypeId] = item),
    )

    setIdentifications(newClientIdentifications)

    if (item.exportationCountry) {
      item.exportationCountry = exportationCountries.find(
        c => c.id === item.exportationCountry,
      )
    } else
      item.exportationCountry = {
        value: null,
        label: 'Ninguno',
      }

    setClientItem({
      ...item,
      contactType: item.contactType,
      email:
        client.multiEmails && client.email
          ? { value: client.email, label: client.email }
          : client.email,
      date: item.birthDate ? new Date(item.birthDate) : null,
      image: client.image ? { ...client.image, url: client.image.label } : { url: null },
    })
  }, [client])

  useEffect(() => {
    if (loadingLocality) setAction({ ...action, get: true })
    else if (action.get) {
      if (!hasErrorLocality) {
        const region = regions.find(r => r.value === locality.regionId)
        setClientItem({
          ...clientItem,
          locality: { ...locality, value: locality.id, label: locality.name },
          region,
        })
        dispatch(getLocalitiesByRegion(region?.value || locality.regionId))
      }
    }
  }, [loadingLocality])

  useEffect(() => {
    if (request.companyName) {
      let item = {}
      if (request.businessType == null) item.businessType = undefined
      if (request.phoneCompany == null) item.phoneCompany = undefined
      if (request.latitude && request.longitude) {
        request.center = {
          lat: Number(request.latitude),
          lng: Number(request.longitude),
        }
        request.latitude = Number(request.latitude)
        request.longitude = Number(request.longitude)
      } else {
        request.center = { lat: 0, lng: 0 }
        request.latitude = 0
        request.longitude = 0
      }
      if (!request.secondAddress) request.secondAddress = request.address
      if (request && request.contactType) {
        const contactType = contactTypes.find(c => c.value === request.contactType)
        if (contactType) request.contactType = contactType
      }
    }
  }, [request])

  useEffect(() => {
    if (clientItem && clientItem.id) {
      if (loadingCreateRequest) setAction({ ...action, create: true })
      else if (action.create) {
        setAction({ ...action, create: false })
        if (hasErrorCreateRequest)
          setAlert({
            ...handlerError(errorCreateRequest.message),
            onConfirm: () => setAlert({ ...alert, show: false }),
          })
        else
          setAlert({
            ...handlerSuccess('Solicitud Enviada'),
            onConfirm: () => setAlert({ ...alert, show: false }),
          })
      }
    }
  }, [loadingCreateRequest])

  useEffect(() => {
    if (clientItem && clientItem.id) {
      if (loadingUpdate) setAction({ ...action, update: true })
      else if (action.update) {
        setAction({ ...action, update: false })
        if (hasErrorUpdate)
          setAlert({
            ...handlerError(hasErrorUpdate.message),
            onConfirm: () => setAlert({ ...alert, show: false }),
          })
        else {
          setClientItem({
            ...clientItem,
            date: clientItem.date ? new Date(clientItem.date) : null,
          })
          setAlert({
            ...handlerSuccess('Cliente actualizado'),
            onConfirm: () => {
              if (onSave) {
                onSave(clientItem, identifications)
                setClientItem(defaultClient)
                dispatch(setModalEditClient({ show: false }))
              }
              setAlert({ ...alert, show: false })
            },
          })
        }
      }
    }
  }, [loadingUpdate])

  useEffect(() => {
    if (clientItem && clientItem.id) {
      if (loadingUpdateRequest) setAction({ ...action, update: true })
      else if (action.update) {
        setAction({ ...action, update: false })
        if (hasErrorUpdateRequest) {
          setAction({ ...action, status: null })
          setAlert({
            ...handlerError(errorUpdateRequest.message),
            onConfirm: () => setAlert({ ...alert, show: false }),
          })
        } else {
          setAlert({
            ...handlerSuccess(
              action.status === 2
                ? 'Solicitud rechazada'
                : 'Solicitud aceptada. Cliente actualizado',
            ),
            onConfirm: () => setAlert({ ...alert, show: false }),
          })
          getSingleClient(clientItem.id)
        }
      }
    }
  }, [loadingUpdateRequest])

  useEffect(() => {
    const validData = validateFields(entityFields, inputStates)

    if (validData) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }
  }, [inputStates])

  useEffect(() => {
    if (onDataChange)
      onDataChange({
        ...clientItem,
        identifications: Object.values(identifications),
        extraFieldValues: inputStates,
      })
  }, [clientItem, identifications])

  useEffect(() => {
    if (setError && setError.show) {
      alert.title = 'Datos inválidos'
      alert.text = setError.type || 'Se deben ingresar los datos requeridos.'
      alert.type = 'warning'
      alert.show = true
      alert.onConfirm = () => setAlert({ ...alert, show: false })
      setAlert({ ...alert })
    }
  }, [setError])

  const init = () => {
    dispatch(getSingleClient(id))
    dispatch(getAllRegions())
    dispatch(getCategorizationsByEntity('clients', id))
    dispatch(getAllEconomicActivity())
    dispatch(getCompanyField(companyFields.useExportationInvoice))
  }

  const onChange = event => {
    const { name, value } = event.target
    setClientItem({ ...clientItem, [name]: value })
  }
  const onChecked = event => {
    const { name, checked } = event.target
    setClientItem({ ...clientItem, [name]: checked })

    if (!checked)
      setClientItem({
        ...clientItem,
        [name]: checked,
        email: clientItem.email ? clientItem.email.value : null,
      })
    else {
      if (clientItem.email) {
        let array = [{ label: clientItem.email, value: clientItem.email }, ...emails]
        let newArray = array.map(data => {
          return [data.value, data]
        })
        var MapArr = new Map(newArray) // Pares de clave y valor
        let values = [...MapArr.values()]

        setEmails([...values])
        setClientItem({
          ...clientItem,
          [name]: checked,
          email: { label: clientItem.email, value: clientItem.email },
        })
      }
    }
  }

  const save = () => {
    let error = false
    let type = null
    if (!clientItem.companyName) {
      error = true
      type = 'El nombre del cliente está vacío'
    }
    if (country.id === Country.SV) {
      if (!clientItem.exportationCountry?.value) {
        if (!clientItem.region) {
          error = true
          type = 'El departamento está vacío'
        }
        if (!clientItem.locality) {
          error = true
          type = 'El municipio está vacío'
        }
      }
    }

    if (country.id === Country.SV) {
      if (!clientItem.economicActivity) {
        error = true
        type = 'La actividad económica está vacía'
      }
      const clientDui = identifications[5]
      if (clientDui) {
        let dui = clientDui.value
        if (dui.length < 8 && dui !== '') {
          error = true
          type = 'El DUI debe tener 8 dígitos'
        }
      }
      if (!clientItem.email) {
        error = true
        type = 'El email es requerido'
      }

      if (!clientItem?.locality) {
        error = true
        type = 'Es requerido el departamento y municipio'
      }
    }

    const validData = validateFields(entityFields, inputStates)

    if (!validData) {
      error = true
      type = 'Campos vacíos'
    }

    if (error) {
      alert.title = 'Datos inválidos'
      alert.text = type || 'Se deben ingresar los datos requeridos.'
      alert.type = 'warning'
      alert.show = true
      alert.onConfirm = () => setAlert({ ...alert, show: false })
      setAlert({ ...alert })
      return
    }

    let request = Object.assign({}, clientItem)
    if (clientItem.locality) request.localityId = clientItem.locality.value
    if (clientItem.exportationCountry)
      request.exportationCountry = clientItem.exportationCountry.value
    if (clientItem.phoneCompany) request.phoneCompany = clientItem.phoneCompany.value
    if (clientItem.businessType) request.businessType = clientItem.businessType.value
    if (clientItem.area) request.area = clientItem.area.value
    if (request.area === '') request.area = null
    if (clientItem.callDay) request.callDay = clientItem.callDay.value
    if (clientItem.visitDay) request.visitDay = clientItem.visitDay.value
    if (clientItem.vehicleType) request.vehicleType = clientItem.vehicleType.value
    if (clientItem.deliverer) request.deliverer = clientItem.deliverer.value
    if (clientItem.vendor) request.vendor = clientItem.vendor.value
    if (clientItem.contactType) request.contactType = clientItem.contactType.value
    if (clientItem.date) request.date = clientItem.date.valueOf()
    if (clientItem.email)
      request.email = clientItem.multiEmails ? clientItem.email.value : clientItem.email

    request.multiEmails = clientItem.multiEmails ? true : null
    request.emails = []
    if (clientItem.multiEmails && emails.length > 0)
      emails.map(s => request.emails.push(s.value))

    if (clientItem.economicActivity)
      request.economicActivity = clientItem.economicActivity.value
    request.identifications = Object.values(identifications)

    if (canEditClient) {
      request.extraFieldValues = inputStates.filter(
        item => item !== null && item !== undefined,
      )

      dispatch(updateClient(request.id, request))
    } else {
      request.clientId = request.id

      dispatch(createClientEditRequest(request))
    }
  }

  const validateForm = (value, rule) => {
    const rules = {
      required: () => {
        return clientItem[value] ? '' : 'Es necesario'
      },
    }
    return rules[rule]()
  }

  const categoriesElement = (
    <div style={{ display: 'flex', justifyContent: 'flex-start', flexFlow: 'wrap' }}>
      <ListCategories
        label={'Categorías'}
        items={clientCategorization}
        onRemove={category => {
          dispatch(assignCategorizationToEntity('clients', category.value, id, true))
          dispatch(
            setModalEditClient({
              ...modalEditClient,
            }),
          )
        }}
        addItem={() =>
          dispatch(
            setModalEditClient({
              ...modalEditClient,
              showCategorizations: true,
              isFilter: false,
              isDetail: true,
            }),
          )
        }
        seeAddItem
      />
    </div>
  )

  const validateFields = (fields, arrayVals) => {
    const requiredFields = fields.filter(field => field.dynamicField.required)

    const allRequiredFieldsFilled = requiredFields.every(field => {
      const correspondingInput = arrayVals.find(
        input => input && input.fieldId === field.dynamicField.id,
      )
      return (
        correspondingInput &&
        correspondingInput.value &&
        correspondingInput.value.toString().trim() !== ''
      )
    })

    return allRequiredFieldsFilled
  }

  const handleValuesEntityChange = updatedValues => {
    setInputStates(updatedValues)
  }

  const bodyElement = (
    <>
      <Row>
        <Col xl={12} style={{ marginTop: 10 }}>
          <CustomTabs
            loading={loadingClient}
            noCard
            items={[
              {
                title: 'Información general',
                icon: faExclamationTriangle,
                // info: Object.keys(errors.general).length > 0 && 'Errores encontrados',
                component: (
                  <>
                    <Row>
                      <Col xs={12}>{categoriesElement}</Col>
                    </Row>
                    <Row>
                      <Col md={12} lg={4}>
                        <ImageInput
                          src={clientItem.image}
                          onSuccessFile={url => {
                            setClientItem({ ...clientItem, image: { url } })
                          }}
                          imageType={imageTypes.CLIENT}
                          entityId={id}
                          gallery={undefined}
                          placeholder={undefined}
                          style={{ margin: 'auto' }}
                        />
                      </Col>
                      <Col md={12} lg={8}>
                        <Row>
                          {country.id === Country.GT ? (
                            <>
                              <Col md={6}>
                                <IdentificationField
                                  name={'nit'}
                                  identificationTypeId={identificationTypesEnum.NIT_GT}
                                  value={clientItem.nit}
                                  onChange={nit => setClientItem({ ...clientItem, nit })}
                                  onSearch={client => {
                                    setClientItem({
                                      ...clientItem,
                                      nit: client.NIT,
                                      companyName:
                                        client.clientName || clientItem.companyName,
                                      businessName:
                                        client.clientName || clientItem.businessName,
                                    })
                                  }}
                                  required
                                />
                              </Col>
                              <Col md={6}>
                                <InfoCUI
                                  cui={clientItem.cui}
                                  onChange={onChange}
                                  onChangeName={name =>
                                    setClientItem({ ...clientItem, companyName: name })
                                  }
                                />
                              </Col>
                            </>
                          ) : (
                            identificationTypes
                              .filter(
                                identificationType =>
                                  identificationType.countryId !== null,
                              )
                              .map(identificationType => (
                                <Col key={identificationType.id} md={6}>
                                  <IdentificationField
                                    identificationTypeId={identificationType.id}
                                    value={identifications[identificationType.id]?.value}
                                    noValidate={identificationType.id === 5}
                                    onChange={value => {
                                      const newClientIdentifications = {
                                        ...identifications,
                                      }
                                      newClientIdentifications[identificationType.id] = {
                                        identificationTypeId: identificationType.id,
                                        value,
                                      }

                                      setIdentifications(newClientIdentifications)
                                    }}
                                  />
                                </Col>
                              ))
                          )}
                          {identificationTypes
                            .filter(
                              identificationType => identificationType.countryId === null,
                            )
                            .map(identificationType => (
                              <Col key={identificationType.id} md={6}>
                                <IdentificationField
                                  identificationTypeId={identificationType.id}
                                  value={identifications[identificationType.id]?.value}
                                  noValidate={identificationType.id === 5}
                                  onChange={value => {
                                    const newClientIdentifications = {
                                      ...identifications,
                                    }
                                    newClientIdentifications[identificationType.id] = {
                                      identificationTypeId: identificationType.id,
                                      value,
                                    }

                                    setIdentifications(newClientIdentifications)
                                  }}
                                />
                              </Col>
                            ))}

                          {country.id === Country.HN && (
                            <Col xl={6} sm={12}>
                              <SwitchV2
                                label={'Exento de impuestos'}
                                info={
                                  'Cuando esta opción está marcada, no se detallará el impuesto en las facturas emitidas a este cliente'
                                }
                                checked={clientItem.taxExempt}
                                onChange={taxExempt =>
                                  setClientItem({ ...clientItem, taxExempt })
                                }
                              />
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <FormTextField
                          label={'Código'}
                          type={'text'}
                          id={'code'}
                          placeholder={'Código'}
                          name={'code'}
                          value={clientItem.code}
                          onChange={onChange}
                          error={''}
                        />
                      </Col>
                      <Col md={4}>
                        <FormTextField
                          label={'Nombre comercial'}
                          type={'text'}
                          id={'companyName'}
                          placeholder={'Nombre'}
                          required
                          name={'companyName'}
                          value={clientItem.companyName}
                          info={'Es el nombre con el que es conocido el cliente.'}
                          onChange={onChange}
                          error={validateForm('companyName', 'required')}
                        />
                      </Col>
                      <Col md={4}>
                        <FormTextField
                          label={'Razón social'}
                          id={'businessName'}
                          placeholder={'Razón social'}
                          name={'businessName'}
                          value={clientItem.businessName}
                          onChange={onChange}
                          info={
                            'Es el nombre con el que se generan facturas en órdenes de venta.'
                          }
                        />
                      </Col>
                      {country.id === Country.SV &&
                        clientItem.exportationCountry !== null && (
                          <Col md={4} sm={12} className="mb-2">
                            <Select
                              label={'País de exportación'}
                              value={clientItem.exportationCountry}
                              info={
                                'País del cliente para emisión de Facturas de exportación'
                              }
                              options={[
                                { value: null, label: 'Ninguno' },
                                ...exportationCountries,
                              ]}
                              onChange={exportationCountry => {
                                setClientItem({ ...clientItem, exportationCountry })
                              }}
                              dataCy={'exportationCountry'}
                            />
                          </Col>
                        )}

                      <Col md={4}>
                        {clientItem.multiEmails ? (
                          <CustomSelect
                            required={country.id === Country.SV}
                            value={clientItem.email}
                            options={emails}
                            label={'Correo electrónico principal'}
                            placeholder={'Seleccionar'}
                            name={'emails'}
                            onChange={data => {
                              setClientItem({ ...clientItem, email: data })
                            }}
                            error={
                              country.id === Country.SV &&
                              validateForm('email', 'required')
                            }
                          />
                        ) : (
                          <FormTextField
                            required={country.id === Country.SV}
                            label={'Correo electrónico'}
                            type={'email'}
                            id={'email'}
                            placeholder={'Correo electrónico'}
                            name={'email'}
                            value={clientItem.email}
                            onChange={onChange}
                            error={
                              country.id === Country.SV &&
                              validateForm('email', 'required')
                            }
                          />
                        )}
                        <label className={'checkbox-label'} style={{ float: 'left' }}>
                          <input
                            type="checkbox"
                            name="multiEmails"
                            checked={clientItem.multiEmails}
                            onChange={onChecked}
                          />{' '}
                          Multiples correos
                        </label>
                      </Col>
                      {clientItem.multiEmails && (
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Row className={'mt-2'}>
                            {emails &&
                              emails.map((email, index) => (
                                <div key={index} className={'c-item assigned ml-2'}>
                                  <label className={'label-user-tag'}>
                                    {' '}
                                    {email.value}
                                  </label>
                                  <IconButton
                                    className={'c-item-button assigned'}
                                    icon={faTimes}
                                    iconStyle={{ marginTop: 10 }}
                                    tooltip={'Eliminar'}
                                    color={'white'}
                                    onClick={() => {
                                      setEmails(
                                        emails.filter(p => email.value !== p.value),
                                      )
                                      if (email === clientItem.email)
                                        setClientItem({ ...clientItem, email: null })
                                    }}
                                  />
                                </div>
                              ))}
                            <div className={'c-item ml-2'}>
                              <br />
                              <IconButton
                                className={'c-item-button assigned'}
                                style={{ borderRadius: 5 }}
                                icon={faPlus}
                                iconStyle={{ marginTop: 10 }}
                                tooltip={'Agregar nueva opción'}
                                color={'white'}
                                onClick={() => setModalC({ show: true })}
                              />
                              Agregar correo electrónico
                            </div>
                          </Row>
                        </Col>
                      )}

                      <Col md={12}>
                        <FormTextField
                          label={'Dirección'}
                          type={'text'}
                          id={'address'}
                          placeholder={'Dirección'}
                          name={'address'}
                          required={true}
                          value={clientItem.address}
                          onChange={onChange}
                          error={validateForm('address', 'required')}
                        />
                      </Col>

                      {setAddressComponents &&
                        clientItem.exportationCountry?.value === null && (
                          <>
                            <Col md={4}>
                              <CustomSelect
                                value={clientItem.region}
                                options={[defaultOption, ...regions]}
                                label={'Departamento'}
                                info={
                                  country.id === Country.SV &&
                                  'Necesario para facturación'
                                }
                                required={
                                  country.id === Country.SV ||
                                  isNecessaryAddressComponents(clientItem.address)
                                }
                                placeholder={'Seleccionar'}
                                name={'region'}
                                onChange={region => {
                                  if (region.value === clientItem.region?.value) return

                                  dispatch(getLocalitiesByRegion(region.value))
                                  setClientItem({ ...clientItem, region, locality: null })
                                }}
                                error={
                                  country.id === Country.SV &&
                                  validateForm('region', 'required')
                                }
                              />
                            </Col>
                            <Col md={4}>
                              <CustomSelect
                                value={clientItem.locality}
                                options={[defaultOption, ...localities]}
                                label={'Municipio'}
                                info={
                                  country.id === Country.SV &&
                                  'Necesario para facturación'
                                }
                                required={
                                  country.id === Country.SV ||
                                  isNecessaryAddressComponents(clientItem.address)
                                }
                                placeholder={'Seleccionar'}
                                name={'locality'}
                                onChange={locality =>
                                  setClientItem({ ...clientItem, locality })
                                }
                                error={
                                  country.id === Country.SV &&
                                  validateForm('locality', 'required')
                                }
                              />
                            </Col>
                          </>
                        )}
                      {country.id === Country.SV && (
                        <Col md={4}>
                          <Select
                            required
                            label={'Actividad económica'}
                            value={clientItem.economicActivity}
                            info={'Actividad económica del cliente para emission de FEL'}
                            options={economicActivities}
                            onChange={value =>
                              setClientItem({ ...clientItem, economicActivity: value })
                            }
                            dataCy={'economicActivity'}
                            error={validateForm('economicActivity', 'required')}
                          />
                        </Col>
                      )}
                    </Row>
                  </>
                ),
              },
              {
                title: 'Información Extra',

                icon: faExclamationTriangle,
                component: (
                  <ClientAdditionalInfo
                    client={clientItem}
                    mode={'edit'}
                    extraFields={entityFields}
                    valuesEntity={inputStates}
                    onChangeValues={handleValuesEntityChange}
                  />
                ),
              },
              {
                title: 'Información adicional',
                icon: faExclamationTriangle,
                // info: Object.keys(errors.advanced).length > 0 && 'Errores encontrados',
                component: (
                  <Row>
                    <Col md={4}>
                      <FormTextField
                        label={'Límite de crédito'}
                        type={'number'}
                        id={'creditLimit'}
                        placeholder={'Límite de crédito'}
                        name={'creditLimit'}
                        value={clientItem.creditLimit}
                        onChange={({ target }) =>
                          setClientItem({
                            ...clientItem,
                            creditLimit:
                              !target.value || target.value <= 0 ? 0 : target.value,
                          })
                        }
                      />
                    </Col>
                    <Col md={4}>
                      <FormTextField
                        label={'Código de proveedor'}
                        type={'text'}
                        id={'codeProvider'}
                        placeholder={'Añadir código de proveedor'}
                        name={'codeProvider'}
                        value={clientItem.codeProvider}
                        onChange={onChange}
                        info={
                          'El valor indicado sera visible en ordenes de cotización, el código de proveedor corresponde al código dado al cliente como empresa proveedora'
                        }
                      />
                    </Col>
                    <Col md={4}>
                      <CustomDatePicker
                        label={'Fecha de nacimiento'}
                        id={'date'}
                        name={'date'}
                        value={clientItem.date}
                        onDayChange={date => {
                          if (date) setClientItem({ ...clientItem, date })
                        }}
                      />
                    </Col>
                    <Col md={4}>
                      <FormTextField
                        label={'Teléfono'}
                        type={'text'}
                        id={'phone'}
                        placeholder={'Teléfono'}
                        name={'phone'}
                        value={clientItem.phone}
                        onChange={onChange}
                      />
                    </Col>
                    <Col md={8}>
                      <FormTextField
                        label={'Referencia para entregas y delivery'}
                        type={'text'}
                        id={'reference'}
                        placeholder={'Referencia para entregas y delivery'}
                        name={'reference'}
                        value={clientItem.reference}
                        onChange={onChange}
                        error={''}
                      />
                    </Col>
                    <Col xl={12} md={12}>
                      <ClientContacts
                        show={clientItem.id}
                        options={clientItem.contacts}
                        id={clientItem.id}
                        onChange={list => {
                          setClientItem({ ...clientItem, contacts: list })
                        }}
                      />
                    </Col>

                    <Col xl={12} md={12} sm={12}>
                      <FormText
                        label={'Descripción'}
                        name={'description'}
                        as={'textarea'}
                        rows={5}
                        max={2000}
                        value={clientItem.description}
                        placeholder={'Detalles adicionales'}
                        onChange={onChange}
                      />
                    </Col>
                  </Row>
                ),
              },
            ]}
          />
        </Col>
      </Row>

      <Modal
        show={modalC.show}
        centered
        size={'md'}
        onHide={() => setModalC({ show: false, email: null })}>
        <Modal.Header closeButton>
          <Modal.Title>Nuevo correo electrónico</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormText
            value={modalC.email}
            type={'text'}
            label={'Correo electrónico'}
            required
            onChange={({ target }) => {
              let { value } = target
              setModalC({ ...modalC, email: value })
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              disabled={!modalC.email}
              icon={faSave}
              color={'primary'}
              onClick={() => {
                let value = { label: modalC.email, value: modalC.email }
                if (emails.length === 0) setClientItem({ ...clientItem, email: value })
                setEmails([...emails, value])
                setModalC({ show: false, email: null })
              }}>
              Guardar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <Alert {...alert} />
    </>
  )

  return (
    <>
      {modal ? (
        <Modal
          show={modalEditClient.show}
          size={'xl'}
          centered
          aria-labelledby={'contained-modal-title-vcenter'}
          onHide={() => {
            setClientItem(defaultClient)
            dispatch(setModalEditClient({ show: false }))
          }}>
          <Modal.Header closeButton>
            <Modal.Title>Detalle del cliente</Modal.Title>
          </Modal.Header>
          <Modal.Body>{bodyElement}</Modal.Body>
          <Modal.Footer>
            <Row>
              <Col style={{ alignSelf: 'flex-end', textAlign: 'right' }}>
                {/*{showProfile && client.cotizapClient !== 3000 && (
                  <Button
                    color={'secondary'}
                    className={'align-bottom'}
                    onClick={() => {
                      dispatch(setModalEditClient({ show: false }))
                      history.push(`clientes/editar/${id}`)
                    }}>
                    <FontAwesomeIcon icon={faUserAlt} /> Ver Perfil
                  </Button>
                )}*/}
                <Button
                  icon={faSave}
                  className={'align-bottom'}
                  onClick={save}
                  loading={loadingUpdate}>
                  {canEditClient ? 'Guardar' : 'Solicitar Cambio'}
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        </Modal>
      ) : (
        <Card>{bodyElement}</Card>
      )}
      <Folder
        noMessage
        onHide={() => {
          if (modalEditClient.isDetail)
            dispatch(
              setModalEditClient({
                ...modalEditClient,
                showCategorizations: false,
                isFilter: false,
                isDetail: false,
              }),
            )
          else
            dispatch(
              setModalEditClient({
                ...modalEditClient,
                showCategorizations: false,
                isFilter: false,
              }),
            )
        }}
        onAssign={item => {
          if (!modalEditClient.isFilter)
            dispatch(assignCategorizationToEntity('clients', item.id, id, true))

          const itemToConcat = {
            label: item.name,
            value: item.id,
          }
          const selectedCategorization = modalEditClient.selectedCategorization
            ? modalEditClient.selectedCategorization.concat(itemToConcat)
            : [itemToConcat]

          dispatch(
            setModalEditClient({
              ...modalEditClient,
              selectedCategorization,
            }),
          )
        }}
        data1={categorization?.children ? categorization.children[0] : {}}
        data2={categorization?.children ? categorization.children[1] : {}}
        show={modalEditClient.showCategorizations}
        list={clientCategorization.map(p => p.id)}
        loading={loadingAssigned}
        type={categoryType.CLIENTS}
      />
    </>
  )
}

export default ClientDetail
