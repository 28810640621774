import axios from 'axios'

import { API_URL } from '../settings/app.settings'
import { firebaseAuth } from '../utils/firebase'

export const $http = axios.create({
  baseURL: API_URL,
  headers: { 'content-type': 'application/json' },
})

$http.interceptors.request.use(
  async request => {
    const token = await firebaseAuth.currentUser.getIdToken(false)
    request.headers.Authorization = 'Bearer ' + token
    return request
  },
  err => Promise.reject(err),
)

export const $httpPublic = axios.create({
  baseURL: API_URL,
  headers: {
    'content-type': 'application/json',
  },
})

$httpPublic.interceptors.request.use(
  async request => {
    request.headers.ApiKey = process.env.REACT_APP_PUBLIC_API_KEY
    return request
  },
  err => Promise.reject(err),
)
