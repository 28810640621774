import React from 'react'

import { toMoney } from 'src/utils/utilities'
import { formatDateFromMillis } from 'src/utils/formatters'
import { style } from 'src/components/Print/TicketStyles'

const RenderSegment = ({ segment }) => (
  <ul>
    {segment.subSegmentation?.length > 0 ? (
      <li>
        <span style={style.text}>
          {segment.name} {toMoney(segment.total)}
        </span>
        {segment.subSegmentation.map((subSegment, index) => (
          <RenderSegment key={index} segment={subSegment} />
        ))}
      </li>
    ) : (
      <li>
        <span style={style.text}>
          {segment.name} {toMoney(segment.total)}
        </span>
        <ul>
          {segment.items.map((item, index) => (
            <li key={index}>
              <span style={style.textContent}>
                {item.quantity} {item.name} {toMoney(item.total)}
              </span>
            </li>
          ))}
        </ul>
      </li>
    )}
  </ul>
)

const TurnTicket = ({
  created,
  finished,
  startDate,
  endDate,
  balanceStart,
  balanceEnd,
  creditStart,
  paymentsEnd,
  cashEnd,
  creditEnd,
  depositsEnd,
  expensesEnd,
  name,
  tip,
  totalSellQuantity,
  totalCommandsQuantity,
  segmentation,
  creditNotes,
  vouchers,
  sentCashTransfers,
  receivedCashTransfers,
}) => {
  return (
    <div id={'ticket_turn'} style={{ display: 'none' }}>
      <div style={{ marginRight: 10 }}>
        <div style={{ ...style.center, ...style.title }}>Turno Finalizado</div>
        <div>
          <hr />
        </div>
        <div style={{ ...style.center, ...style.subtitle }}>
          {name} ha finalizado su turno
        </div>
        <div>
          <hr />
        </div>
        <div style={style.spaceBetween}>
          <div style={style.text}>Iniciado Por:</div>
          <div style={style.textContent}>{created}</div>
        </div>
        <div style={style.spaceBetween}>
          <div style={style.text}>Finalizado Por:</div>
          <div style={style.textContent}>{finished}</div>
        </div>
        <div style={style.spaceBetween}>
          <div style={style.text}>Fecha de inicio:</div>
          <div style={style.textContent}>{formatDateFromMillis(startDate)}</div>
        </div>
        <div style={style.spaceBetween}>
          <div style={style.text}>Fecha de finalización:</div>
          <div style={style.textContent}>{formatDateFromMillis(endDate)}</div>
        </div>
        <div style={style.spaceBetween}>
          <div style={style.text}>Efectivo Circulante Inicial:</div>
          <div style={style.textContent}>{toMoney(balanceStart)}</div>
        </div>
        <div style={style.spaceBetween}>
          <div style={style.text}>Efectivo Circulante Final:</div>
          <div style={style.textContent}>{toMoney(balanceEnd)}</div>
        </div>
        <div style={style.spaceBetween}>
          <div style={style.text}>Total de ventas:</div>
          <div style={style.textContent}>{totalSellQuantity || 0}</div>
        </div>
        <div style={style.spaceBetween}>
          <div style={style.text}>Total de comandas:</div>
          <div style={style.textContent}>{totalCommandsQuantity || 0}</div>
        </div>
        <div style={style.spaceBetween}>
          <div style={style.text}>Créditos:</div>
          <div style={style.textContent}>{toMoney(creditStart)}</div>
        </div>
        <div style={style.spaceBetween}>
          <div style={style.text}>Pagos:</div>
          <div style={style.textContent}>{toMoney(paymentsEnd)}</div>
        </div>
        <div style={style.spaceBetween}>
          <div style={style.text}>Ventas al contado:</div>
          <div style={style.textContent}>{toMoney(cashEnd)}</div>
        </div>
        <div style={style.spaceBetween}>
          <div style={style.text}>Ventas con tarjeta:</div>
          <div style={style.textContent}>{toMoney(creditEnd)}</div>
        </div>
        <div style={style.spaceBetween}>
          <div style={style.text}>Propina:</div>
          <div style={style.textContent}>{toMoney(tip)}</div>
        </div>
        <div style={style.spaceBetween}>
          <div style={style.text}>Depósitos:</div>
          <div style={style.textContent}>{toMoney(depositsEnd)}</div>
        </div>
        <div style={style.spaceBetween}>
          <div style={style.text}>Gastos:</div>
          <div style={style.textContent}>{toMoney(expensesEnd)}</div>
        </div>
        <div style={style.spaceBetween}>
          <div style={style.text}>Notas de crédito:</div>
          <div style={style.textContent}>{toMoney(creditNotes)}</div>
        </div>
        <div style={style.spaceBetween}>
          <div style={style.text}>Vales:</div>
          <div style={style.textContent}>{toMoney(vouchers)}</div>
        </div>
        <div style={style.spaceBetween}>
          <div style={style.text}>Efectivo enviado:</div>
          <div style={style.textContent}>{sentCashTransfers}</div>
        </div>
        <div style={style.spaceBetween}>
          <div style={style.text}>Efectivo recibido:</div>
          <div style={style.textContent}>{receivedCashTransfers}</div>
        </div>
        {segmentation?.length > 0 && (
          <>
            <div>
              <hr />
            </div>
            <div style={{ ...style.center, ...style.subtitle }}>Ventas del turno:</div>
            <div>
              <hr />
            </div>
            {segmentation.map((segment, index) => (
              <RenderSegment key={index} segment={segment} />
            ))}
          </>
        )}
      </div>
    </div>
  )
}
export default TurnTicket
