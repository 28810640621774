import UtilitiesServices from '../services/utilities.services'
import OrderServices from '../services/orders.service'
import { executeAction } from './global.actions'
import PurchaseService from '../services/purchase.services'

export const types = {
  SET_DATA_TO_ALERT: 'SET_DATA_TO_ALERT',
  GET_PHONE_COMPANIES: 'GET_PHONE_COMPANIES',
  GET_BUSINESS_TYPES: 'GET_BUSINESS_TYPES',
  GET_ARCHIVED_DATA: 'GET_ARCHIVED_DATA',
  CHANGE_STATUS_DATA: 'CHANGE_STATUS_DATA',
  GET_METHODS_PAYMENT: 'GET_METHODS_PAYMENT',
  GET_REPORT: 'GET_REPORT',
  CREATE_EXCEL: 'CREATE_EXCEL',
  CUSTOM_REFERENCE: 'CUSTOM_REFERENCE',
  INVENTORY_BY_COMPONENT: 'INVENTORY_BY_COMPONENT',
  INVENTORY_SELECTED: 'INVENTORY_SELECTED',
  MODAL_ORDER: 'MODAL_ORDER',
  GET_MODAL_ORDER: 'GET_MODAL_ORDER',
  GET_MODAL_CONFIRM: 'GET_MODAL_CONFIRM',
  SET_MODAL_CONFIRM: 'SET_MODAL_CONFIRM',
  SET_MODAL_ORDER_PAYMENT: 'SET_MODAL_ORDER_PAYMENT',
  ON_REFRESH: 'ON_REFRESH',
  HIDE_NAVIGATION: 'HIDE_NAVIGATION',
  SET_ORDER_PROCESS_ID: 'SET_ORDER_PROCESS_ID',
  SET_ORDER_MODAL_TRANSFER: 'SET_ORDER_MODAL_TRANSFER',
  SET_ORDER_MODAL_PAYMENTS: 'SET_ORDER_MODAL_PAYMENTS',
  SET_ORDER_MODAL_SUMMARY: 'SET_ORDER_MODAL_SUMMARY',
  MODAL_ORDER_PRESALE: 'SET_MODAL_ORDER_PRESALE',
  MODAL_WELCOME_SHOW: 'MODAL_WELCOME_SHOW',
  MODAL_PURCHASE: 'MODAL_PURCHASE',
  GET_MODAL_PURCHASE: 'GET_MODAL_PURCHASE',
  MODAL_USERS: 'MODAL_USERS',
  MODAL_ADD_USER: 'MODAL_ADD_USER',
  MODAL_INVOICE_PACKAGES: 'MODAL_INVOICE_PACKAGES',
  MODAL_CREATE_CARD: 'MODAL_CREATE_CARD',
  MODAL_MANAGE_CARDS: 'MODAL_MANAGE_CARDS',
  MODAL_MANAGE_CONCEPTS: 'MODAL_MANAGE_CONCEPTS',
  MODAL_CREATE_CONCEPTS: 'MODAL_CREATE_CONCEPTS',
  MODAL_CREATE_CLIENT: 'MODAL_CREATE_CLIENT',
  MODAL_EDIT_CLIENT: 'MODAL_EDIT_CLIENT',
  MODAL_CREATE_CASH_TRANSFER: 'MODAL_CREATE_CASH_TRANSFER',
  GET_CSV_TEMPLATE: 'GET_CSV_TEMPLATE',
  MODAL_PENDING_PAYMENT: 'MODAL_PENDING_PAYMENT',
}

const setDataToAlert = alert => ({ type: types.SET_DATA_TO_ALERT, alert })
const setModalOrder = (id, currency) => ({
  type: types.MODAL_ORDER,
  id,
  currency,
})
const setModalOrderPreSale = id => ({ type: types.MODAL_ORDER_PRESALE, id })

export const onSetDataToAlert = alert => async dispatch => {
  dispatch(setDataToAlert(alert))
}

export const getPhoneCompanies = () => dispatch => {
  const process = async () => ({
    phonesCompanies: await UtilitiesServices.getPhoneCompanies(),
  })
  dispatch(executeAction(types.GET_PHONE_COMPANIES, process))
}

export const getBusinessTypes = () => dispatch => {
  const process = async () => ({
    businessTypes: await UtilitiesServices.getBusinessTypes(),
  })
  dispatch(executeAction(types.GET_BUSINESS_TYPES, process))
}

export const getArchivedData = (service, isKolo) => dispatch => {
  const process = async () => ({
    archived: await UtilitiesServices.getArchivedData(service, isKolo),
  })
  dispatch(executeAction(types.GET_ARCHIVED_DATA, process))
}

export const changeArchivedStatus = (service, id) => dispatch => {
  const process = async () => await UtilitiesServices.changeArchivedStatus(service, [id])
  dispatch(executeAction(types.CHANGE_STATUS_DATA, process))
}

export const changeArchivedStatusByList = (service, list) => dispatch => {
  const process = async () => await UtilitiesServices.changeArchivedStatus(service, list)
  dispatch(executeAction(types.CHANGE_STATUS_DATA, process))
}

export const changeDeletedStatusByList = (service, list) => dispatch => {
  const promises = []
  list.map(async l => {
    promises.push(UtilitiesServices.changeDeletedStatus(service, l))
  })
  return Promise.all(promises).then(() => {
    dispatch(executeAction(types.CHANGE_STATUS_DATA))
  })
}

export const getPaymentMethods = () => dispatch => {
  const process = async () => ({
    methods: await UtilitiesServices.getPaymentMethods(),
  })
  dispatch(executeAction(types.GET_METHODS_PAYMENT, process))
}

export const getExcelByReport = (start, end) => dispatch => {
  const process = async () => ({
    report: await UtilitiesServices.createExcel(start, end),
  })
  dispatch(executeAction(types.CREATE_EXCEL, process))
}

export const getInfoReference =
  (documentModule, documentType, documentId) => dispatch => {
    const process = async () => ({
      reference: await UtilitiesServices.getInfoReference(
        documentModule,
        documentType,
        documentId,
      ),
    })
    dispatch(executeAction(types.CUSTOM_REFERENCE, process))
  }

export const getProductByComponent = params => dispatch => {
  const process = async () => ({
    inventory: await UtilitiesServices.getProductByComponent(params),
  })
  dispatch(executeAction(types.INVENTORY_BY_COMPONENT, process))
}

export const setProductSelected = selected => dispatch => {
  const process = () => ({ inventorySelected: selected })
  dispatch(executeAction(types.INVENTORY_SELECTED, process))
}

export const onSetModalOrder = (id, currency) => dispatch => {
  dispatch(setModalOrder(id, currency))
}

export const onSetModalOrderPreSale = id => dispatch => {
  dispatch(setModalOrderPreSale(id))
}

export const getModalOrder = id => async dispatch => {
  const process = async () => ({
    order: await OrderServices.getSingleOrder(id),
  })
  dispatch(executeAction(types.GET_MODAL_ORDER, process))
}

export const getModalConfirm = id => async dispatch => {
  const process = async () => ({
    order: await OrderServices.getSingleOrder(id),
  })
  dispatch(executeAction(types.GET_MODAL_CONFIRM, process))
}

export const setModalConfirm = modalConfirm => async dispatch => {
  const process = async () => ({
    modalConfirm,
  })
  dispatch(executeAction(types.SET_MODAL_CONFIRM, process))
}

export const setModalOrderPayment = payload => async dispatch => {
  const process = async () => ({
    payload,
  })
  dispatch(executeAction(types.SET_MODAL_ORDER_PAYMENT, process))
}

export const onRefresh = value => dispatch => {
  const process = () => ({ refresh: value })
  dispatch(executeAction(types.ON_REFRESH, process))
}

export const onHide = value => dispatch => {
  const process = () => ({ hide: value })
  dispatch(executeAction(types.HIDE_NAVIGATION, process))
}

export const setOrderProcessId = value => dispatch => {
  const process = () => ({ processId: value })
  dispatch(executeAction(types.SET_ORDER_PROCESS_ID, process))
}

export const setModalOrderTransferInfo = data => dispatch => {
  const process = () => ({ data })
  dispatch(executeAction(types.SET_ORDER_MODAL_TRANSFER, process))
}

export const setModalOrderPaymentsInfo = data => dispatch => {
  const process = () => ({ data })
  dispatch(executeAction(types.SET_ORDER_MODAL_PAYMENTS, process))
}

export const onShowModalWelcome = value => dispatch => {
  const process = () => ({ show: value })
  dispatch(executeAction(types.MODAL_WELCOME_SHOW, process))
}

export const onSetModalPurchase = (id, onClose) => dispatch => {
  const process = () => ({
    seePurchase: {
      id: id,
      onClose: () => (onClose ? onClose() : undefined),
    },
  })
  dispatch(executeAction(types.MODAL_PURCHASE, process))
}

export const getModalPurchase = id => async dispatch => {
  const process = async () => ({
    purchase: await PurchaseService.getPurchase(id),
  })
  dispatch(executeAction(types.GET_MODAL_PURCHASE, process))
}

export const setModalUsers = payload => dispatch => {
  const process = () => ({ payload })
  dispatch(executeAction(types.MODAL_USERS, process))
}

export const setModalAddUser = payload => dispatch => {
  const process = () => ({ payload })
  dispatch(executeAction(types.MODAL_ADD_USER, process))
}

export const setModalInvoicePackages = payload => dispatch => {
  const process = () => ({ payload })
  dispatch(executeAction(types.MODAL_INVOICE_PACKAGES, process))
}

/**
 * Manipula el modal para crear una tarjeta
 * @param {Object} payload
 */
export const setModalCreateCard = payload => dispatch => {
  const process = () => ({ payload })
  dispatch(executeAction(types.MODAL_CREATE_CARD, process))
}

/**
 * Manipula el modal para administrar las tarjetas
 * @param {Object} payload
 */
export const setModalManageCards = payload => dispatch => {
  const process = () => ({ payload })
  dispatch(executeAction(types.MODAL_MANAGE_CARDS, process))
}

/**
 * Change modal for managing concepts
 * @param {Object} payload
 */
export const setModalManageConcepts = payload => dispatch => {
  const process = () => ({ payload })
  dispatch(executeAction(types.MODAL_MANAGE_CONCEPTS, process))
}

/**
 * Change modal for creating concepts
 * @param {Object} payload
 */
export const setModalCreateConcepts = payload => dispatch => {
  const process = () => ({ payload })
  dispatch(executeAction(types.MODAL_CREATE_CONCEPTS, process))
}

/**
 * Change modal for creating a client
 * @param {Object} payload
 */
export const setModalCreateClient = payload => dispatch => {
  const process = () => ({ payload })
  dispatch(executeAction(types.MODAL_CREATE_CLIENT, process))
}

/**
 * Change modal for editing a client
 * @param {Object} payload
 */
export const setModalEditClient = payload => dispatch => {
  const process = () => ({ payload })
  dispatch(executeAction(types.MODAL_EDIT_CLIENT, process))
}

/**
 * Change modal for creating a cash transfer
 * @param {Object} payload
 */
export const setModalCreateCashTransfer = payload => dispatch => {
  const process = () => ({ payload })
  dispatch(executeAction(types.MODAL_CREATE_CASH_TRANSFER, process))
}

/**
 * Retrieves a CSV template as a byte array based on the specified type.
 * @param {number} type The type of CSV template to retrieve.
 * @param {string} name The name of CSV template to retrieve.
 * **/
export const getCSVTemplate = (type, name) => dispatch => {
  const process = async () => UtilitiesServices.getCSVTemplate(type, name)
  dispatch(executeAction(types.GET_CSV_TEMPLATE, process))
}

/**
 * Action to show or hide modal pending payment
 * @param {boolean} value Show modal pending payment
 * @returns void
 */
export const setModalPendingPayments = value => dispatch => {
  const process = () => ({ response: value })
  dispatch(executeAction(types.MODAL_PENDING_PAYMENT, process))
}
