import React, { useEffect, useState } from 'react'
import { Card, Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { loadingSelector } from 'src/selectors/loading.selector'
import {
  actionTypes,
  getWarehouseInventoryByVariations,
  getWarehouseInventoryVariation,
} from 'src/actions/warehouse.actions'
import { Button, Dropdown, Icon, NumberField, Switch, TableV2 } from 'src/components'
import { ListVariations } from 'src/content/Production/Categorization/Variation/ListVariations'
import { isAllowed } from 'src/selectors/modules.selector'
import { formatNumberWithCommas } from 'src/utils/formatters'
import { toMoney } from 'src/utils/utilities'
import {
  faArrowDown,
  faArrowRight,
  faSave,
  faTable,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { CreateLocation } from 'src/content/Warehouse/Location/CreateLocation'
import { selectCompanyFieldById } from 'src/selectors/company.selector'
import {
  createCategorization,
  getCategorizationByType,
} from 'src/actions/categorization.actions'
import { VariationComponent } from 'src/content/Production/Categorization/Variation/VariationComponent'
import { selectCategorizationByType } from 'src/selectors/categorizations.selector'
import IconButton from 'src/components/buttons/IconButton'
import { setGeneralConfig, setItemTable } from 'src/actions/transaction.action'
import { SubItemInventory } from 'src/content/Warehouse/SubItemInventory'

export const WarehouseVariatons = ({
  id,
  show,
  onHide,
  name,
  isInventory,
  productId,
  singleWarehouse,
  baseItemData,
  action,
  // Para transacciones de salida
  forOuts,
  onSave,
  mobileOuts,
  dontGetCategorization,
  typeTransaction,
  isQuote, // TODO: Remove when quote use ItemSelectedTable.
  // Filtrado por variaciones.
  filterByVariations,
}) => {
  const dispatch = useDispatch()

  const companyLocations = useSelector(selectCategorizationByType)
  const variations = useSelector(selectCategorizationByType)
  const response = useSelector(state => state.warehouse.inventoryVariation)
  const { items, total } = response
  const seeSum = useSelector(state => isAllowed(state, [1160, 2110]))
  const seeMoneyBalances = useSelector(state => isAllowed(state, [1163, 2112]))
  const [newUbication, setNewUbication] = useState({
    show: false,
    warehouseId: id,
    item: {},
  })
  const loading = useSelector(state =>
    loadingSelector([actionTypes.GET_INVENTORY_VARIATION])(state),
  )

  const [data, setData] = useState([])
  const [seeBase, setSeeBase] = useState(false)
  const [nameLocations, setNameLocations] = useState(['Pasillo', 'Estante', 'Nivel'])
  const seeWarehouseLocations = useSelector(state => selectCompanyFieldById(state, 123))
  const [locationQuantity, setLocationQuantity] = useState({ value: 3, label: '3' })

  const [location, setLocation] = useState({
    show: false,
    id: null,
    key: null,
    name: '',
    code: '',
    productId: 0,
  })
  const [showVariations, setShowVariations] = useState(false)
  const [listVariations, setListVariations] = useState([])

  useEffect(() => {
    if (JSON.stringify(seeWarehouseLocations) !== '{}') {
      if (!dontGetCategorization) dispatch(getCategorizationByType(21))
      const locationsN = seeWarehouseLocations.value.split(',')
      setNameLocations(locationsN)
      setLocationQuantity({
        value: locationsN.length,
        label: locationsN.length.toString(),
      })
    }
  }, [JSON.stringify(seeWarehouseLocations)])

  useEffect(() => {
    if (Object.entries(companyLocations).length !== 0 && nameLocations.length > 0) {
      const generalCategory = companyLocations.children.find(
        x => x.name === 'Ubicaciones',
      )
      if (generalCategory) {
        for (let i = 0; i < nameLocations.length; i++) {
          const locationExist = generalCategory.children.find(
            x => x.name === nameLocations[i],
          )
          if (!locationExist)
            createLocations(nameLocations[i], `Ubicación ${i + 1}`, generalCategory.id)
        }
      } else createLocations('Ubicaciones', 'General', null)
    }
  }, [companyLocations])

  const createLocations = (name, description, parent) => {
    dispatch(
      createCategorization({
        description: description,
        name: name,
        parentCategorizationId: parent,
        type: 21,
      }),
    )
  }
  useEffect(() => {
    if (Array.isArray(items) && items.length > 0 && forOuts) {
      let a = items.filter(x => {
        let exist = x.subItems.findIndex(y => y.id === productId)
        if (exist > -1) return true
        else return false
      })
      setData(JSON.parse(JSON.stringify(a)))
    } else {
      setData([])
    }
  }, [items])

  useEffect(() => {
    if (id && !forOuts && !filterByVariations) {
      dispatch(getWarehouseInventoryVariation(id, productId, {}))
    }
  }, [id])

  // useEffect(() => {
  //   if (filterByVariations && !forOuts) {
  //     let categorization = ''
  //     listVariations.forEach((c, i) => {
  //       categorization += c.id
  //       if (i < listVariations.length - 1) {
  //         categorization += ','
  //       }
  //     })
  //     dispatch(getWarehouseInventoryByVariations(id, ))
  //   }
  // }, [filterByVariations])
  useEffect(() => {
    if (filterByVariations && show && !forOuts) {
      setShowVariations(true)
    }
  }, [filterByVariations])

  const getSubItems = (item, type, defaultValue) => {
    return (
      <SubItemInventory
        item={item}
        type={type}
        defaultValue={defaultValue}
        seeBase={seeBase}
      />
    )
  }
  const getItemValue = (label, value) => (
    <div
      className={'d-flex'}
      style={{
        justifyContent: 'space-between',
        paddingLeft: 10,
        paddingRight: 20,
      }}>
      <div className={'b-user-email'} style={{ minWidth: 100 }}>
        <b>{label}</b>
      </div>
      <div className={'b-user-email text-right'}>{value}</div>
    </div>
  )
  const getActions = item => {
    return (
      <Dropdown
        items={[
          {
            title: 'Reasignar ubicacion',
            action: () => {
              setNewUbication({
                warehouseId: id,
                item: JSON.parse(JSON.stringify(item)),
                show: true,
              })
            },
          },
        ]}
      />
    )
  }
  const getHeaders = () => {
    return [
      {
        label: 'SKU / Código',
        show: true,
        value: ['code'],
        type: 'text',
        className: 'medium',
        style: { minWidth: 140 },
        custom: item => {
          return getSubItems(item, 1, item.code)
        },
      },

      {
        label: 'Nombre',
        show: true,
        value: ['name'],
        type: 'text',
        style: { minWidth: seeWarehouseLocations.value !== '0' ? 200 : 150 },
        custom: item => {
          return getSubItems(item, 2, item.name)
        },
      },
      {
        label: 'Variaciones',
        show: filterByVariations || baseItemData?.categories !== null,
        value: ['name'],
        type: 'text',
        style: { minWidth: forOuts ? 100 : 200 },
        custom: item => (
          <div className="column">
            <ListVariations selected={item.listCategorization} hideMessage />
          </div>
        ),
      },
      {
        label: nameLocations[0],
        show: seeWarehouseLocations.value !== '0',
        value: ['listLocations', 0, 'name'],
        type: 'text',
        className: 'medium',
      },
      {
        label:
          locationQuantity.value === 2 || locationQuantity.value === 3
            ? nameLocations[1]
            : '',
        show:
          seeWarehouseLocations.value !== '0' &&
          (locationQuantity.value === 2 || locationQuantity.value === 3),
        value: ['listLocations', 1, 'name'],
        type: 'text',
        className: 'medium',
      },
      {
        label: locationQuantity.value === 3 ? nameLocations[2] : '',
        show: seeWarehouseLocations.value !== '0' && locationQuantity.value === 3,
        value: ['listLocations', 2, 'name'],
        type: 'text',
        className: 'medium',
      },
      {
        label: 'Disponible',
        show: true,
        value: ['available'],
        type: 'number',
        className: 'medium',
        style: { minWidth: 100 },
        custom: item => {
          return getSubItems(item, 4, formatNumberWithCommas(item.available))
        },
      },
      {
        label: 'Reservado',
        show: !forOuts,
        value: ['reserved'],
        type: 'wholeNumber',
        className: 'mini',
        style: { minWidth: 100 },
        custom: item => {
          return getSubItems(item, 5, formatNumberWithCommas(item.reserved))
        },
      },
      {
        label: 'Total',
        show: !forOuts,
        value: ['existence'],
        type: 'number',
        className: 'medium',
        style: { minWidth: 100 },
        custom: item => {
          return getSubItems(item, 6, formatNumberWithCommas(item.existence))
        },
      },
      {
        label: 'Costo',
        show: (seeSum || seeMoneyBalances) && !isInventory && !forOuts,
        value: ['cost'],
        type: 'currency',
        className: 'medium',
        style: { minWidth: 140 },
        custom: item => {
          return getSubItems(item, 7, toMoney(item.cost))
        },
      },
      {
        label: 'Cantidad',
        show: forOuts ? true : false,
        value: [],
        type: 'currency',
        className: 'medium',
        style: { minWidth: 200 },
        custom: item => (
          <NumberField
            value={item.quantity || 0}
            onValueChange={value => {
              item.quantity = value
              setData([...data])
            }}
            decimals={item.decimals}
          />
        ),
      },
      {
        show: true,
        label: '',
        config: true,
        custom: (item, i) => action && getActions(item, i),
      },
    ]
  }

  const getItems = () => {
    return forOuts ? data.filter(x => x.available > 0) : items
  }

  const getStringVariations = variations => {
    let categorization = null
    variations
      .sort(function (a, b) {
        return a.id - b.id
      })
      .forEach((c, i) => {
        categorization += c.id
        if (i < variations.length - 1) {
          categorization += ','
        }
      })

    return categorization
  }

  const requestVariation = list => {
    setListVariations(list)
    if (list.length === 0)
      dispatch(getWarehouseInventoryVariation(id, productId, getStringVariations(list)))
    else dispatch(getWarehouseInventoryByVariations(id, getStringVariations(list)))
  }

  const VariationFilter = name => {
    return (
      <Row>
        <Col xl={12} md={12} sm={12} xs={12}>
          <Button
            variant={`${name ? 'outline-' : ''}info`}
            color={'a'}
            className={'align-bottom'}
            onClick={() => {
              setShowVariations(true)
              if (!forOuts) dispatch(getCategorizationByType(20))
            }}>
            {name ? name : 'Por variaciones'}
          </Button>
        </Col>
        <Col xs={12}>
          {' '}
          <Row>
            {listVariations.map(p => (
              <div className={'user-tag product-t ml-1 mr-1'} key={p.id}>
                <label className={'label-user-tag'}>{p.name}</label>
                <IconButton
                  className={'delete-user-tag d-product-t'}
                  icon={faTimes}
                  tooltip={'Quitar'}
                  color={'white'}
                  onClick={() => {
                    const list = listVariations.filter(f => f.id !== p.id)
                    requestVariation(list)
                  }}
                />
              </div>
            ))}
          </Row>
        </Col>
      </Row>
    )
  }

  const customFilter = () => {
    return (
      <div className={'mb-2'}>
        <Row>
          <Col className={'borderRight'}>
            <Row>
              <Col xl={5} md={6} sm={6} xs={12}>
                <Switch
                  mt={3}
                  topLabel
                  label={'Ver cantidad respecto al ítem base'}
                  info={
                    'Se visualizaran las cantidades de los ítems en unidades del ítem base'
                  }
                  checked={seeBase}
                  changeValue={value => {
                    localStorage.setItem('presentation-factor', value)
                    setSeeBase(value)
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col className="mt-4 mb-4 ml-2" xl={4} md={6} sm={6} xs={12}>
            <Row className={'pl-1'}>{VariationFilter()}</Row>
          </Col>
        </Row>
      </div>
    )
  }

  const render = () => {
    let items = getItems()
    return (
      <div>
        {forOuts && mobileOuts ? (
          <div>
            <Row>
              <Col xl={12} md={12} sm={12} xs={12}>
                <div style={{ textAlign: 'center' }}>
                  <h4>{baseItemData?.name}</h4>
                </div>
                <div>{VariationFilter('Filtro')}</div>
              </Col>
            </Row>
            <Row>
              <div className=" t-items-body" style={{ width: '100%' }}>
                {items.length > 0 ? (
                  items.map(item => (
                    <>
                      <Col xl={6} md={6} xs={12}>
                        <Card style={{ minHeight: '80px', width: '100%' }}>
                          <div
                            style={{
                              marginLeft: 30,
                              marginTop: 20,
                              paddingRight: 20,
                            }}>
                            <ListVariations
                              selected={item.listLocations}
                              groupName={<b>Ubicacion</b>}
                              border
                              isLocations
                            />
                            <ListVariations
                              selected={item.listCategorization}
                              groupName={<b>Variación</b>}
                              hideMessage={true}
                              border
                            />
                          </div>

                          <div
                            className="space-between"
                            style={{
                              margin: '5px 20px 10px 30px',
                            }}>
                            <div>
                              {' '}
                              Existencia : {item?.subItems &&
                                item.subItems[0].existence}{' '}
                              u.
                            </div>
                            <div>
                              <Icon
                                icon={faArrowRight}
                                size={'2x'}
                                color={'accent'}
                                onClick={() => {
                                  if (typeTransaction === 1 && !isQuote) {
                                    dispatch(
                                      setGeneralConfig({
                                        showList: false,
                                        showDetails: true,
                                        value: baseItemData.productId,
                                        getItemValue: true,
                                        showModalItems: false,
                                      }),
                                    )
                                    dispatch(
                                      setItemTable({
                                        item: {
                                          ...baseItemData,
                                          baseProductQuantity: item.baseProductQuantity,
                                          listCategorization: item.listCategorization,
                                          listLocations: item.listLocations,
                                          categories: item.categories,
                                          locations: item.locations,
                                        },
                                      }),
                                    )
                                  } else {
                                    item.quantity = 1
                                    onSave(data, false)
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </Card>
                      </Col>
                    </>
                  ))
                ) : (
                  <h2> No hay variaciones o ubicaciones asignadas para este producto</h2>
                )}
              </div>
            </Row>
          </div>
        ) : (
          <TableV2
            customFilter={customFilter()}
            loading={loading}
            customClass={'scrollX'}
            headers={getHeaders()}
            total={total}
            items={items}
            placeholder={'Buscar por nombre/código'}
            noItemsLegend={`No hay ítems con los filtros aplicados`}
            //  mobileAuto
            onClick={
              action
                ? () =>
                    setNewUbication({
                      show: true,
                      warehouseId: id,
                      item: JSON.parse(JSON.stringify(baseItemData)),
                    })
                : undefined
            }
            buttonTitle={action ? 'Crear ubicacion' : undefined}
            mobileAuto
            storageKey={
              forOuts
                ? 'warehouseOuts'
                : seeWarehouseLocations.value !== '0'
                ? 'warehouseLocations'
                : 'warehouseinventory'
            }
            principalAction={item =>
              item?.item.categories && (
                <ListVariations selected={item.item.listCategorization} />
              )
            }
            mobileSlide={({ item }) => (
              <div className={'column'}>
                {getItemValue('SKU / Código', item.code)}
                {getItemValue('Nombre', item.name)}
                {getItemValue('Disponible', formatNumberWithCommas(item.available))}
                {getItemValue('Reservado', formatNumberWithCommas(item.reserved))}
                {getItemValue('Total', formatNumberWithCommas(item.existence))}
                {(seeSum || seeMoneyBalances) &&
                  getItemValue('Costo', toMoney(item.cost))}
                {item.listLocations && (
                  <div
                    style={{
                      paddingLeft: 20,
                      paddingRight: 20,
                    }}>
                    <ListVariations
                      selected={item.listLocations}
                      groupName={<b>Ubicaciones</b>}
                    />
                  </div>
                )}
                {item.subItems && item.subItems.length > 0 && (
                  <div>
                    <div className={'b-user-name mb-1 mt-2 ml-2'}>Sub ítems</div>
                    {item.subItems.map(subItem => (
                      <div key={subItem.id} className={'column'}>
                        {getItemValue(
                          subItem.code,
                          formatNumberWithCommas(subItem.available),
                        )}
                        {getItemValue(
                          subItem.name,
                          formatNumberWithCommas(subItem.reserved),
                        )}
                        {getItemValue('total', formatNumberWithCommas(subItem.existence))}
                        {getItemValue('costo', toMoney(subItem.cost))}
                        <div>
                          <hr className={'m-0'} />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          />
        )}
      </div>
    )
  }

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          setListVariations([])
          dispatch(getWarehouseInventoryVariation(null))
          onHide()
        }}
        centered
        size={'xl'}>
        <Modal.Header closeButton>
          <Modal.Title>
            {singleWarehouse ? singleWarehouse.name : name ? name : 'Bodega'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>{render()}</Col>
          </Row>
          <Col xl={12} md={12} sm={12}>
            <hr />
            <Row className={'container-buttons'}>
              {action && (
                <Button
                  icon={faTable}
                  color={'primary'}
                  onClick={() => {
                    dispatch(getCategorizationByType(21))
                    setLocation({ ...location, show: true })
                  }}>
                  Crear ubicaciones
                </Button>
              )}
              {forOuts && (
                <div className="space-between">
                  <Button
                    icon={faArrowDown}
                    color={'primary'}
                    onClick={() => {
                      if (mobileOuts) {
                        dispatch(
                          setGeneralConfig({
                            showList: false,
                            showDetails: true,
                            value: baseItemData.productId,
                            getItemValue: true,
                            showModalItems: false,
                          }),
                        )
                        dispatch(
                          setItemTable({
                            item: {
                              ...baseItemData,
                              listCategorization: listVariations,
                            },
                          }),
                        )
                      } else onSave(data, true)
                      setListVariations([])
                    }}>
                    Sin existencia
                  </Button>
                  <Button
                    disabled={data.filter(x => x.quantity > 0).length === 0}
                    icon={faSave}
                    color={'primary'}
                    onClick={() => {
                      setListVariations([])
                      onSave(
                        data.filter(x => x.quantity > 0),
                        false,
                      )
                    }}>
                    Agregar
                  </Button>
                </div>
              )}
            </Row>
          </Col>
        </Modal.Body>
      </Modal>
      <Modal
        show={newUbication.show}
        onHide={() => {
          setNewUbication({ show: false, warehouseId: id, item: {} })
        }}
        centered
        size={'xl'}>
        <Modal.Header closeButton>
          <Modal.Title>
            {' '}
            Asignar nuevas ubicaciones a {newUbication.item.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <CreateLocation
                itemFrom={newUbication.item}
                warehouseId={id}
                onSuccess={() => {
                  setNewUbication({ show: false, warehouseId: id, item: {} })
                  dispatch(getWarehouseInventoryVariation(id, productId, {}))
                }}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Modal
        show={location.show && !filterByVariations}
        onHide={() => {
          setLocation({ show: false })
        }}
        centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {' '}
            Asignar nuevas ubicaciones a {newUbication.item.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VariationComponent
            forLocations
            data={
              Object.entries(companyLocations).length !== 0
                ? companyLocations.children.find(x => x.name === 'Ubicaciones')
                : undefined
            }
            selected={[]}
            parentId={21}
            nameCategories={'Ubicacion'}
          />
        </Modal.Body>
      </Modal>

      <ListVariations
        show={showVariations}
        onHide={() => setShowVariations(false)}
        onSave={list => {
          setShowVariations(false)
          requestVariation(list)
        }}
        data={forOuts ? baseItemData?.listCategorization : variations}
        selected={listVariations}
        modeSelection
        hideVariations
        isFilter
      />
    </>
  )
}
