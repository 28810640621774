import { useSelector } from 'react-redux'
import { felDocumentType } from 'src/enums/felDocumentTypes'

import {
  selectCompanyCountry,
  selectCompanyFieldValue,
} from 'src/selectors/company.selector'
import { companyFieldsInvoice } from 'src/enums/companyFields'
import { getTaxDetailed, getTaxes, isNecessaryDetailTax } from 'src/utils/utilitiesV2'

const felDocumentTypeExemptFromTaxes = [
  felDocumentType.INVOICE_WITHOUT_IVA,
  felDocumentType.SUJETO_EXC,
]

interface IItemTaxBehavior {
  quantity: number
  price: number
  discount: number
  taxRate: number
}

/**
 * Hook to calculate the tax for a given amount based on the company's settings and the fiscal document to be issued.
 *
 * @param {number} felDocumentId - The ID of the fiscal document.
 * @param {IItemTaxBehavior[]} items - The amount for which to calculate the tax.
 * @returns {number} The calculated tax.
 */
const useTaxBehavior = (felDocumentId: number, items: IItemTaxBehavior[]): number => {
  const country = useSelector(selectCompanyCountry)

  const withTaxDetailed = useSelector(state =>
    selectCompanyFieldValue(state, companyFieldsInvoice.taxDetailed),
  )

  const isTaxIncluded = useSelector(state =>
    selectCompanyFieldValue(state, companyFieldsInvoice.taxIncluded),
  )

  const felDocumentTypeToValidate = felDocumentId || felDocumentType.INVOICE

  const isExemptFromTaxes: boolean = felDocumentTypeExemptFromTaxes.includes(
    felDocumentTypeToValidate,
  )

  const isTaxDetailed: boolean = isNecessaryDetailTax(
    isTaxIncluded,
    withTaxDetailed,
    country?.id,
    felDocumentTypeToValidate,
  )

  const isNegativeTax: boolean = isTaxIncluded && isExemptFromTaxes

  return items.reduce((taxTotal, item) => {
    const { quantity, price, discount, taxRate } = item

    const subtotal = quantity * price
    const totalWithoutTaxes = subtotal - discount

    const taxRateValue =
      felDocumentType.SUJETO_EXC === felDocumentTypeToValidate ? 0.1 : taxRate

    const tax =
      isTaxDetailed || isNegativeTax
        ? isNegativeTax
          ? -getTaxDetailed(totalWithoutTaxes, taxRateValue)
          : getTaxes(totalWithoutTaxes, taxRateValue)
        : 0

    return tax + taxTotal
  }, 0)
}
export default useTaxBehavior
