export enum PrinterTextSizesEnum {
  SMALL = 'sm',
  MEDIUM = 'md',
  LARGE = 'lg',
}

export const printerTextSizes = {
  [PrinterTextSizesEnum.SMALL]: { value: PrinterTextSizesEnum.SMALL, label: 'Pequeño' },
  [PrinterTextSizesEnum.MEDIUM]: { value: PrinterTextSizesEnum.MEDIUM, label: 'Mediano' },
  [PrinterTextSizesEnum.LARGE]: { value: PrinterTextSizesEnum.LARGE, label: 'Grande' },
}

export const printerTextSizesArray: ISelect[] = Object.values(printerTextSizes)

export enum PrintConnectionTypeEnum {
  WEB = 'web',
  EPSON = 'epson',
  ESC_POS = 'esc/pos',
}

export const printConnectionTypes = {
  [PrintConnectionTypeEnum.WEB]: { value: PrintConnectionTypeEnum.WEB, label: 'Directa' },
  [PrintConnectionTypeEnum.EPSON]: {
    value: PrintConnectionTypeEnum.EPSON,
    label: 'Epson',
  },
  [PrintConnectionTypeEnum.ESC_POS]: {
    value: PrintConnectionTypeEnum.ESC_POS,
    label: 'ESC/POS',
  },
}

export const printConnectionTypesArray: ISelect[] = Object.values(printConnectionTypes)
