import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Alert from 'sweetalert-react'
import { Col, Row, Modal } from 'react-bootstrap'
import {
  Card,
  Icon,
  TableV2,
  Button,
  FormText,
  Dropdown,
  Select,
  Title,
  Action,
  CustomSummary,
  ModalTable,
  IconImg,
  FAB,
  SelectedDates,
  Folder,
  ListCategories,
} from 'src/components'
import Gallery, { imageTypes } from 'src/components/gallery/Gallery'

import IconSearch from 'src/assets/v2/icon-search2.svg'
import {
  faCheckDouble,
  faEye,
  faFileExcel,
  faInfoCircle,
  faQuoteRight,
  faSave,
  faSearch,
  faSync,
  faTags,
  faWindowClose,
} from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes as typeDeposit,
  getCompanyDeposits,
  updateDeposit,
  getAssociatedOrders,
  exportExcel,
} from 'src/actions/deposits.actions'
import {
  selectGetAssociated,
  selectGetDeposits,
  selectGetTotalDeposits,
} from 'src/selectors/deposits.selector'

import { getBalanceInCompany } from 'src/actions/balance.actions'
import { selectBalanceInCompany } from 'src/selectors/balance.selector'

import {
  actionTypes as typeB,
  getAccounts,
  getBanks,
  getMovementMatchDeposit,
} from 'src/actions/banks.actions'
import {
  selectMovementByDeposit,
  selectGetAccountsForSelect,
} from 'src/selectors/banks.selector'

import { selectCurrentUser } from 'src/selectors/user.selector'
import { selectSetCurrentCompany } from 'src/selectors/company.selector'

import {
  selectCurrentUserRoles,
  selectUsersToSelect,
} from 'src/selectors/modules.selector'

import { depositsPermissions } from 'src/enums/permissions'
import { isAllowed } from 'src/selectors/modules.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import {
  hasErrorsSelector,
  singleErrorSelector,
  handlerError,
  handlerSuccess,
} from 'src/selectors/error.selector'
import { formatNumberWithCommas } from 'src/utils/formatters'

import { getUsersChildrenByModule } from 'src/actions/modules.actions'
import { onSetModalOrder } from 'src/actions/utilities.actions'

import {
  actionTypes as typeC,
  assignCategorizationToMultipleEntities,
  getAllCategorizations,
  getCustomCategorizationsByEntity,
} from 'src/actions/categorization.actions'
import { selectAllCategorizations } from 'src/selectors/categorizations.selector'

import { selectCurrentCurrency } from 'src/selectors/currencies.selector'
import { getAllCurrencies } from 'src/actions/currencies.actions'

import CustomReference from 'src/components/modal/CustomReference'
import Operation from 'src/components/Banks/Operation'
import { Deposit } from 'src/content/Deposits/Deposit'
import { showAlert } from 'src/actions/alert.actions'

let d = new Date()
d.setDate(d.getDate() - 31)

const DepositPage = ({ hide, ...props }) => {
  const dispatch = useDispatch()

  const roles = useSelector(selectCurrentUserRoles)
  const users = useSelector(selectUsersToSelect)
  const balance = useSelector(selectBalanceInCompany)
  const accounts = useSelector(selectGetAccountsForSelect)
  const allCurrencies = useSelector(state =>
    Object.assign(
      [],
      state.currencies.allCurrencies.map(c => ({ ...c, label: c.code, value: c.id })),
    ),
  )

  const deposits = useSelector(selectGetDeposits)
  const total = useSelector(selectGetTotalDeposits)
  const loading = useSelector(state => loadingSelector([typeDeposit.GET_DEPOSITS])(state))

  const loadingU = useSelector(state =>
    loadingSelector([typeDeposit.UPDATE_DEPOSIT])(state),
  )
  const hasErrorU = useSelector(state =>
    hasErrorsSelector([typeDeposit.UPDATE_DEPOSIT])(state),
  )
  const errorU = useSelector(state =>
    singleErrorSelector([typeDeposit.UPDATE_DEPOSIT])(state),
  )

  const loadingM = useSelector(state =>
    loadingSelector([typeB.GET_MOVEMENT_BY_DEPOSIT])(state),
  )
  const movements = useSelector(selectMovementByDeposit)
  const associated = useSelector(selectGetAssociated)
  const loadingA = useSelector(state =>
    loadingSelector([typeDeposit.GET_ASSOCIATED])(state),
  )

  const company = useSelector(selectSetCurrentCompany)
  const user = useSelector(selectCurrentUser)

  const categorization = useSelector(selectAllCategorizations)
  const loadingAssigned = useSelector(state =>
    loadingSelector([typeC.GET_ASSIGNED, typeC.GET_ALL])(state),
  )

  const loadingCreate = useSelector(state =>
    loadingSelector([typeDeposit.CREATE_DEPOSIT, typeDeposit.UPDATE_DATA_DEPOSIT])(state),
  )
  const hasErrorCreate = useSelector(state =>
    hasErrorsSelector([typeDeposit.CREATE_DEPOSIT, typeDeposit.UPDATE_DATA_DEPOSIT])(
      state,
    ),
  )
  const errorCreate = useSelector(state =>
    singleErrorSelector([typeDeposit.CREATE_DEPOSIT, typeDeposit.UPDATE_DATA_DEPOSIT])(
      state,
    ),
  )

  const currentCurrency = useSelector(selectCurrentCurrency)

  /** PERMISSIONS **/
  const seeAll = useSelector(state =>
    isAllowed(state, [depositsPermissions.seeOthersDeposits]),
  )
  const create = useSelector(state =>
    isAllowed(state, [depositsPermissions.createDeposit]),
  )
  const approve = useSelector(state =>
    isAllowed(state, [depositsPermissions.approveAndReject, 11750]),
  )
  const nullify = useSelector(state => isAllowed(state, [depositsPermissions.nullify]))
  const approveWD = useSelector(state =>
    isAllowed(state, [depositsPermissions.approveWithoutDocument]),
  )
  const skip = useSelector(state =>
    isAllowed(state, [depositsPermissions.approveWithoutMovBank]),
  )

  const createMovement = useSelector(state => isAllowed(state, [6159, 6209]))

  //permissions kolo
  const seeAllK = useSelector(state => isAllowed(state, [12033, 12018]))
  const createK = useSelector(state => isAllowed(state, [12032, 12019]))

  const [initialLoading, setInitialLoading] = useState(hide)
  const [module, setModule] = useState(1000)

  const [alert, setAlert] = useState({ title: '' })

  const [actions, setActions] = useState({
    loading: false,
    create: false,
    update: false,
    associated: false,
  })

  /** FILTERS **/
  const [coreFilters, setCoreFilters] = useState({
    skip: 0,
    size: 10,
    search: null,
  })

  const [filters, setFilters] = useState({
    submitted: true,
    approved: true,
    rejected: true,
    nullify: true,
    user: { value: null, label: '- sin seleccionar -' },
    bank: { value: null, label: '- sin seleccionar -' },
    status: { value: null, label: '- sin seleccionar -' },
    categories: '',
    start: null,
    end: null,
  })

  const [coreFiltersText, setCoreFilterText] = useState(undefined)

  const [kolo, setKolo] = useState(false)
  const [modalDeposit, setModalDeposit] = useState({ show: false, item: null })
  const [deposit, setDeposit] = useState({ show: false, item: {} })

  const [receipt, setReceipt] = useState(null)
  const [see, setSeeBankAccounts] = useState(false)
  const [ref, setRef] = useState({ show: false })
  const [showAssociated, setAssociated] = useState(false)
  const [modalCreate, setModalCreate] = useState({ show: false })
  const [selectedCategories, setSelectedCategories] = useState([])
  const [showCategorization, setShowCategorization] = useState({ show: false })
  const [selected, setSelected] = useState([])

  const valKolo = () => {
    const { match, admin } = props
    let isKolo = false
    if (admin) isKolo = true
    else if (match) {
      const { params } = match
      if (params) {
        const { type } = params
        if (type === 'true') isKolo = true
      }
    }

    if (match && match.params && match.params.moduleId)
      setModule(Number(match.params.moduleId))

    return isKolo
  }

  useEffect(() => {
    setInitialLoading(false)
    dispatch(getAllCurrencies())
    dispatch(getUsersChildrenByModule(1000))
    const isKolo = valKolo()
    if (!props.bank) dispatch(getAccounts(isKolo))
    setKolo(isKolo)
    dispatch(getBalanceInCompany(user.id, isKolo ? 1 : 0))
    dispatch(getAllCategorizations(17))

    if (!initialLoading) {
      dispatch(getBanks())
    }
  }, [])

  useEffect(() => {
    if (loadingCreate) setActions({ ...actions, create: true })
    else if (actions.create) {
      setActions({ ...actions, create: false })
      if (hasErrorCreate) {
        dispatch(showAlert(handlerError(errorCreate.message)))
      } else dispatch(showAlert(handlerSuccess('Depósito registrado satisfactoriamente')))
      setUp()
    }
  }, [loadingCreate])

  useEffect(() => {
    if (!roles || roles.length === 0) return
    setCoreFilterText(JSON.stringify(coreFilters))
  }, [roles])

  useEffect(() => {
    if (coreFiltersText === undefined || coreFiltersText === JSON.stringify(coreFilters))
      return
    setCoreFilterText(JSON.stringify(coreFilters))
  }, [coreFilters])

  useEffect(() => {
    if (coreFiltersText === undefined || coreFiltersText !== JSON.stringify(coreFilters))
      return
    setUp()
  }, [coreFiltersText])

  useEffect(() => {
    if (initialLoading || !props.bank || props.admin) return
    setFilters({ ...filters, bank: { value: props.bank.id, label: props.bank.name } })
  }, [props.bank])

  useEffect(() => {
    if (loadingA) setActions({ ...actions, associated: true })
    else if (actions.associated) {
      setAssociated(true)
    }
  }, [loadingA])

  useEffect(() => {
    if (loadingU) setActions({ ...actions, update: true })
    else if (actions.update) {
      setActions({ ...actions, update: false })
      if (hasErrorU)
        setAlert({
          ...handlerError(errorU.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else
        setAlert({
          ...handlerSuccess('Deposito actualizado satisfactorimanete'),
          onConfirm: () => {
            setUp()
            dispatch(getBalanceInCompany())
            setAlert({ ...alert, show: false })
            if (props.bank) {
              dispatch(getAccounts(false))
            }
          },
        })
    }
  }, [loadingU])

  useEffect(() => {
    if (initialLoading || actions.loading || modalDeposit.show) return

    let string = ''
    selectedCategories.forEach(p => {
      string += p.id + ','
    })
    string = string.slice(0, string.length - 1)
    setFilters({ ...filters, categories: string })
  }, [selectedCategories])

  const setUp = (receipt, excel) => {
    const { start, end } = filters

    const isKolo = valKolo()

    let userId
    if (props.admin) userId = null
    else if ((!kolo && seeAll) || (kolo && seeAllK)) userId = filters.user.value
    else userId = user.id

    if (excel)
      dispatch(
        exportExcel({
          userId,
          start: start ? start.valueOf() : null,
          end: end ? end.valueOf() : null,
          kolo: isKolo,
          skip: coreFilters.skip,
          size: coreFilters.size,
          search: coreFilters.search,
          bank: props.bank || filters.bank.value,
          status: filters.status.value,
          categories: filters.categories,
        }),
      )
    else
      dispatch(
        getCompanyDeposits({
          userId,
          start: start ? start.valueOf() : null,
          end: end ? end.valueOf() : null,
          kolo: isKolo,
          skip: coreFilters.skip,
          size: coreFilters.size,
          search: coreFilters.search,
          bank: props.bank || filters.bank.value,
          status: filters.status.value,
          categories: filters.categories,
          receipt: !receipt || receipt === '' ? null : receipt,
          admin: props.admin,
        }),
      )

    setAlert({ ...alert, show: false })
    setDeposit({ ...deposit, show: false })
    setActions({ ...actions, loading: true })
    if (props.onDateChange && start && end) props.onDateChange(start, end)
  }

  const getState = state => {
    let statusColor, statusName, statusIcon
    switch (state) {
      case 101:
        statusName = 'Aprobada'
        statusColor = 'green'
        statusIcon = faCheckDouble
        break
      case 102:
        statusName = 'Rechazada'
        statusColor = 'red'
        statusIcon = faWindowClose
        break
      case 103:
        statusName = 'Anulada'
        statusColor = 'red'
        statusIcon = faSync
        break
      default:
        statusName = 'Ingresada'
        statusColor = 'blue'
        statusIcon = faInfoCircle
        break
    }
    return { statusColor, statusName, statusIcon }
  }

  const isSelected = item => {
    const select = selected.find(s => item.id === s.id)
    return select !== undefined
  }

  const addOrRemoveSelected = item => {
    const cs = Object.assign([], selected)
    if (isSelected(item)) {
      //remove
      setSelected(cs.filter(s => s.id !== item.id))
    } else {
      //add
      cs.push(item)
      setSelected(cs)
    }
  }

  const assignCategorizations = categorization => {
    let array = []
    if (selected.length > 0) array = selected.map(s => s.id)
    else array.push(showCategorization.deposit)
    dispatch(
      assignCategorizationToMultipleEntities(
        null,
        categorization,
        array,
        !!showCategorization.deposit,
        false,
        17,
      ),
    )
  }

  const getButtons = () => {
    return (
      <Row className={'container-buttons'}>
        <Icon
          style={{ marginRight: '15px' }}
          color={'green'}
          icon={faFileExcel}
          tooltip={'Exportar a Excel'}
          onClick={() => {
            setUp(null, true)
          }}
        />
        <Icon
          onClick={() => setSeeBankAccounts(true)}
          icon={faInfoCircle}
          tooltip={'Ver cuentas de banco'}
        />
      </Row>
    )
  }

  const customFilter = () => (
    <Row className={'mb-3'}>
      <Col xl={12}>
        <SelectedDates
          withOptionNull
          onDateChange={(start, end) => setFilters({ ...filters, start, end })}
        />
      </Col>

      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
        <Select
          label={'Por estado'}
          isSearchable={false}
          value={filters.status}
          options={[
            { value: null, label: '- sin seleccionar -' },
            { value: 100, label: 'Ingresadas' },
            { value: 101, label: 'Aprobada' },
            { value: 102, label: 'Rechazada' },
            { value: 103, label: 'Anulada' },
          ]}
          onChange={value => setFilters({ ...filters, status: value })}
          info={'Filtra por estado del depósito'}
        />
      </Col>
      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
        <FormText
          placeholder={'Buscar...'}
          id={'receipt'}
          label={'Por número de recibo'}
          info={
            ' Se buscaran todas los depositos que esten asociados a los depositos que tengan el número de recibo que se ingresara en este campo.'
          }
          value={receipt || ''}
          onChange={({ target }) => {
            let { value } = target
            setReceipt(value)
          }}
          append={
            <IconImg
              style={{ padding: '5px' }}
              icon={IconSearch}
              tooltip={'Buscar'}
              onClick={() => {
                setUp(receipt)
              }}
            />
          }
          aPadding
        />
      </Col>
      {!hide && (
        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
          <Select
            label={'Por cuenta de banco'}
            isSearchable={false}
            value={filters.bank}
            options={[{ value: null, label: '- sin seleccionar -' }, ...accounts]}
            onChange={value => setFilters({ ...filters, bank: value })}
            info={'Filtra por cuenta bancaria'}
          />
        </Col>
      )}
      {((seeAll && !kolo) || (seeAllK && kolo)) && (
        <Col xl={!hide ? 6 : 12} lg={!hide ? 6 : 12} md={!hide ? 6 : 12} sm={12} xs={12}>
          <Select
            label={'Por usuario'}
            value={filters.user}
            options={[{ value: null, label: '- sin seleccionar -' }, ...users]}
            onChange={value => setFilters({ ...filters, user: value })}
            info={'Filtar por usuario que ingreso el deposito'}
          />
        </Col>
      )}

      <Col xl={12} className={'mt-3'}>
        <Button
          color={'primary'}
          onClick={() => {
            setShowCategorization({
              ...showCategorization,
              show: true,
              filter: true,
            })
          }}>
          Filtrar por categorías
        </Button>
      </Col>

      {!modalDeposit.show && (
        <Col xl={12}>
          <ListCategories
            items={selectedCategories}
            onRemove={p =>
              setSelectedCategories(selectedCategories.filter(f => f.id !== p.id))
            }
          />
        </Col>
      )}

      <Col xl={12}>
        <hr />
      </Col>
      <Col xl={12}>
        <Row className={'container-buttons'}>
          <Button
            loading={loading}
            color={'accent'}
            icon={faSearch}
            onClick={() => setUp()}>
            Aplicar filtros
          </Button>
        </Row>
      </Col>
    </Row>
  )

  const getHeaders = () => {
    let headers = [
      {
        label: '',
        select: true,
        show: true,
        custom: item => {
          const { statusName, statusIcon } = getState(item.status)
          return <Icon color={'white'} icon={statusIcon} tooltip={statusName} />
        },
        classNameCustom: item => {
          return 'mini center  kolo_' + getState(item.status).statusColor
        },
      },
      {
        label: 'Fecha',
        show: true,
        value: ['createdAt'],
        type: 'date',
        className: 'mini',
      },
      {
        label: 'Fecha de Aprobación',
        show: true,
        value: ['authorizedDate'],
        type: 'date',
        className: 'mini',
      },
      {
        label: 'Banco',
        show: true,
        value: ['bankName'],
        type: 'text',
        className: 'mini',
      },
      {
        label: 'Cuenta',
        show: true,
        value: ['accountC'],
        type: 'text',
        className: 'mini',
        custom: item => (item.accountC = `${item.accountNumber} - ${item.accountName}`),
      },
      {
        label: 'Referencia',
        show: true,
        value: ['number'],
        type: 'text',
        className: 'mini',
      },
      {
        label: 'Recibo',
        show: true,
        value: ['receipt'],
        type: 'text',
        className: 'mini',
        custom: item => (item.receipt ? item.receipt : 'Desconocido'),
      },
      {
        label: `Monto`,
        show: true,
        select: true,
        value: ['amount'],
        type: 'text',
        className: 'mini',
        custom: item =>
          `${
            props.currency?.symbol || getBankCurrency(item.bank)
          }.${formatNumberWithCommas(item.amount)}`,
      },
      {
        label: 'Estado',
        show: true,
        value: ['statusName'],
        type: 'text',
        className: 'mini',
        custom: item => getState(item.status).statusName,
      },
      {
        label: 'Creado por',
        show: true,
        value: ['user'],
        type: 'text',
        className: 'mini',
      },
      {
        label: 'Autorizado por',
        show: true,
        value: ['auth'],
        type: 'text',
        className: 'mini',
      },
      {
        label: 'Descipción',
        show: true,
        value: ['description'],
        type: 'text',
        className: 'medium',
        custom: item => item.description || 'Sin descripción',
      },
      {
        label: 'Mensaje',
        show: true,
        value: ['message'],
        type: 'text',
        className: 'medium',
        custom: item => item.message || 'Sin mensaje',
      },
    ]
    !kolo &&
      headers.push({
        label: 'Acciones',
        action: true,
        select: true,
        show: true,
        type: 'text',
        className: 'short center',
        custom: item => {
          let show = props.admin || item.own
          return (
            <Dropdown
              items={[
                {
                  show: show,
                  title: 'Ver detalle',
                  action: () => {
                    setRef({ ...ref, show: true, documentId: item.id })
                  },
                },
                {
                  show: item.status === 100,
                  title: 'Editar',
                  action: () => {
                    setModalDeposit({
                      ...modalDeposit,
                      show: true,
                      item: {
                        ...item,
                        date: new Date(item.createdAt),
                        userId: item.createdBy,
                        account: item.bank,
                      },
                    })
                  },
                },
                {
                  show: approve && item.status === 100,
                  title: 'Aprobar',
                  action: () => {
                    setDeposit({
                      show: true,
                      item,
                      status: { withDocument: true, value: 101, label: 'Aprobar' },
                    })
                    setModalCreate({
                      ...setModalCreate,
                      id: item.id,
                      currency: item.currency,
                      amount: item.amount,
                    })
                    dispatch(getMovementMatchDeposit(item.id, 1, item.currency))
                  },
                },
                {
                  show: item.status === 100 && approveWD,
                  title: 'Aprobar sin documento',
                  action: () => {
                    setAlert({
                      show: true,
                      type: 'input',
                      inputPlaceholder: '(opcional) Descripcrión',
                      title: `Aceptar deposito: ${item.number}`,
                      text: 'Al aceptar el deposito sin documento de respaldo, afectara al balance bancario pero no afectara el efectivo circulante. ¿Desea aceptar el deposito seleccionado?',
                      showCancelButton: true,
                      onCancel: () => setAlert({ ...alert, show: false }),
                      onConfirm: message => {
                        setAlert({ ...alert, show: false })
                        dispatch(updateDeposit(item.id, 101, message, true, null, false))
                      },
                    })
                  },
                },
                {
                  title: 'Rechazar',
                  show: item.status === 100,
                  action: () => {
                    setAlert({
                      show: true,
                      type: 'input',
                      inputPlaceholder: '(opcional) motivo',
                      title: `Rechazar deposito: ${item.number}`,
                      text: '¿Desea rechazar el deposito seleccionado?',
                      showCancelButton: true,
                      onCancel: () => setAlert({ ...alert, show: false }),
                      onConfirm: description => {
                        setAlert({ ...alert, show: false })
                        dispatch(updateDeposit(item.id, 102, description, true, null))
                      },
                    })
                  },
                },
                {
                  show: false,
                  title: 'Ver facturas asociadas',
                  action: () => dispatch(getAssociatedOrders(item.id)),
                },
                {
                  show: nullify && item.status === 101,
                  title: 'Anular',
                  action: () =>
                    setAlert({
                      show: true,
                      type: 'input',
                      inputPlaceholder: 'Motivo',
                      title: `Anular deposito: ${item.number}`,
                      text: '¿Desea anular el deposito seleccionado? Esta acción disminuira el balance en bancos y aumentara el efectivo circulante',
                      showCancelButton: true,
                      onCancel: () => setAlert({ ...alert, show: false }),
                      onConfirm: description => {
                        if (!description) return
                        setAlert({ ...alert, show: false })
                        dispatch(updateDeposit(item.id, 103, description, true, null))
                      },
                    }),
                },
                {
                  title: 'Ver categorias',
                  action: () => {
                    dispatch(getCustomCategorizationsByEntity(item.id, false, 17))
                    setShowCategorization({ show: true, deposit: item.id, filter: false })
                  },
                },
              ]}
            />
          )
        },
      })
    headers.push({ config: true, show: true, label: '', className: 'mini' })
    return headers
  }

  const getBankCurrency = bankId => {
    const account = accounts.find(item => item.id === bankId)
    return account ? allCurrencies.find(c => c.id === account.currency)?.symbol : 'Q'
  }

  return (
    <div>
      <Title
        title={kolo ? 'Depósitos KOLO' : 'Depósitos'}
        action={!hide && ((!kolo && create) || (kolo && createK))}
        actionTitle={'Ingresar deposito'}
        onClick={() => {
          setSelectedCategories([])
          setModalDeposit({ show: true, own: !kolo })
        }}
      />
      <Row>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <Card
            white
            title={!hide ? 'Depósitos' : false}
            button={!hide ? getButtons() : ''}>
            <div className={'column'}>
              <Action
                action={!hide && ((!kolo && create) || (kolo && createK))}
                actionTitle={'Ingresar depósito'}
                onClick={() => setModalDeposit({ show: true, own: !kolo })}
              />

              {module !== 11000 && (
                <CustomSummary
                  items={[
                    {
                      title: 'Efectivo circulante',
                      value: formatNumberWithCommas(
                        module === 1000
                          ? balance.balanceTransportist
                          : balance.balanceProducer,
                      ),
                    },
                  ]}
                />
              )}

              <TableV2
                storageKey={`depositPage-${user.id}`}
                items={deposits}
                getPagination={(skip, size, search) =>
                  setCoreFilters({ ...coreFilters, skip, size, search })
                }
                handleChange={search => setCoreFilters({ ...coreFilters, search })}
                total={total}
                mobileAuto
                loading={loading}
                noItemsLegend={
                  'No hay depositos registrados en los filtros seleccionados'
                }
                customClass={'scrollX'}
                tableDivStyle={{ minHeight: `350px` }}
                customFilter={customFilter()}
                headers={getHeaders()}
                isSelected={item => {
                  return isSelected(item)
                }}
                onClickTr={(e, item) => addOrRemoveSelected(item)}
                getItems={item => {
                  if (item === null) {
                    setSelected([])
                  } else addOrRemoveSelected(item)
                }}
              />
            </div>
          </Card>
        </Col>
      </Row>

      <Modal show={see} centered size={'md'} onHide={() => setSeeBankAccounts(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {kolo ? 'Cuentas de banco de KOLO' : 'Cuentas de banco'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {kolo && (
              <div className={'text-center'}>Nombre: COTIZAP, SOCIEDAD ANONIMA</div>
            )}
            <TableV2
              hideFilter
              //items={!kolo ? accounts : banks}
              items={accounts}
              renderRow={(item, index) => (
                <div className={'b-user-item'} key={index}>
                  <div
                    className={'justify-content-start align-items-start bu-en'}
                    style={{ textAlign: 'left' }}>
                    <div className={'b-user-name'}>{item.name}</div>
                    <div className={'b-user-balance-m'}>{item.accountNumber}</div>
                  </div>
                  <div
                    className={'d-flex row align-items-center mr-2 bu-b'}
                    style={{ textAlign: 'right' }}>
                    <div className={'b-user-balance mr-2'}>{item.accountNumber}</div>
                  </div>
                </div>
              )}
            />

            {kolo && (
              <div className={'text-center'}>
                Puedes Realizar depositos desde BI-Banking con el codigo <br />
                <b>
                  {company.alphanumericId}
                  {user.alphanumericId}
                </b>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={deposit.show && !modalCreate.show}
        size={'xl'}
        centered
        onHide={() => setDeposit({ item: {}, show: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Depositos</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col xl={5} lg={5} md={12} sm={12} xs={12}>
              <Gallery
                company={deposit.item.company}
                imageType={imageTypes.DEPOSITS}
                entityId={deposit?.item.id}
                noItemLegend={'Sin depósito para mostrar'}
                showUploader={false}
                maxWidth={380}
              />
            </Col>
            <Col xl={7} lg={7} md={12} sm={12} xs={12}>
              <Row>
                <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                  <FormText
                    disabled={loadingU}
                    prepend={<Icon tooltip={'Mensaje'} icon={faQuoteRight} />}
                    label={'Mensaje'}
                    type={'text'}
                    placeholder={'pj. deposito aceptado'}
                    value={deposit.message}
                    onChange={({ target }) => {
                      const { value } = target
                      setDeposit({ ...deposit, message: value })
                    }}
                  />
                </Col>
                <Col xl={12} lg={12} md={12} className={'column'}>
                  <span>
                    <strong>
                      Selecciona un movimiento bancario para la acreditación
                    </strong>
                  </span>
                  <TableV2
                    headers={[
                      {
                        label: '',
                        select: true,
                        action: true,
                        show: true,
                        type: '',
                        classNameCustom: item =>
                          'mini t-'.concat(item.id === deposit.movement && 'green'),
                        custom: item => (
                          <label className={'checkbox-label'}>
                            <input
                              type={'checkbox'}
                              name={'selected'}
                              checked={item.id === deposit.movement}
                              onChange={() => {
                                let movement
                                if (item.id === deposit.movement) movement = null
                                else movement = item.id
                                setDeposit({ ...deposit, movement })
                              }}
                            />
                          </label>
                        ),
                      },
                      {
                        label: 'Cuenta',
                        show: true,
                        value: ['accountLabel'],
                        type: 'text',
                        className: 'medium',
                      },
                      {
                        label: 'Referencia',
                        show: true,
                        value: ['reference'],
                        type: 'text',
                        className: 'medium',
                        custom: item =>
                          item.duplicate
                            ? `${item.reference || '--'} - (DUPLICADO)`
                            : item.reference || '--',
                      },

                      {
                        label: `Monto (${currentCurrency.symbol})`,
                        show: true,
                        value: ['amountC'],
                        type: 'text',
                        className: 'mini',
                        custom: item =>
                          (item.amountC = formatNumberWithCommas(item.amount)),
                      },
                      {
                        label: 'Desc.',
                        show: true,
                        value: ['description'],
                        type: 'text',
                        className: 'medium',
                      },
                      {
                        label: 'Creado',
                        show: true,
                        value: ['createdAt'],
                        type: 'date',
                        className: 'medium',
                      },
                      { config: true, show: true, label: '', className: 'mini' },
                    ]}
                    items={movements.sort((a, b) => b.createdAt - a.createdAt)}
                    loading={loadingM}
                    noItemsLegend={
                      'No se encontraron movimientos bancarios que coincidan con el deposito'
                    }
                    onClickTr={(e, item) => {
                      let movement
                      if (item.id === deposit.movement) movement = null
                      else movement = item.id
                      setDeposit({ ...deposit, movement })
                    }}
                    storageKey={`approveDeposit`}
                    mobileAuto={true}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Row className={'container-buttons'}>
            {createMovement && (
              <Button
                color={'secondary'}
                onClick={() => {
                  setModalCreate({ ...modalCreate, show: true })
                }}>
                Crear movimiento bancario{' '}
              </Button>
            )}
            <Button
              loading={loadingU}
              disabled={!deposit.status}
              variant={'info'}
              icon={faSave}
              onClick={() => {
                if (deposit.movement || skip || props.admin) {
                  // todo
                  dispatch(
                    updateDeposit(
                      deposit.item.id,
                      deposit.status.value,
                      deposit.message,
                      true,
                      deposit.movement,
                      true,
                    ),
                  )
                } else {
                  setAlert({
                    ...handlerError('Seleccione un movimiento bancario de referencia'),
                    onConfirm: () => setAlert({ ...alert, show: false }),
                  })
                }
              }}>
              Guardar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <ModalTable
        show={showAssociated}
        items={associated}
        loading={loadingA}
        title={'Facturas asociadas'}
        onHide={() => setAssociated(false)}
        size={'lg'}
        headers={[
          { label: 'No', show: true, value: ['number'], type: 'text', className: 'mini' },
          {
            label: 'Responsable',
            show: true,
            value: ['user'],
            type: 'text',
            className: 'mini',
          },
          {
            label: 'Cliente',
            show: true,
            value: ['name'],
            type: 'text',
            className: 'mini',
          },
          { label: 'Nit', show: true, value: ['nit'], type: 'text', className: 'mini' },
          {
            label: 'Total',
            show: true,
            value: ['total'],
            type: 'currency',
            className: 'mini',
          },
          {
            config: true,
            show: true,
            label: '',
            className: 'mini center',
            custom: item => (
              <Icon
                icon={faEye}
                size={'md'}
                onClick={() => dispatch(onSetModalOrder(item.order))}
                tooltip={'Ver orden'}
              />
            ),
          },
        ]}
      />

      <CustomReference
        show={ref.show}
        documentModule={2}
        documentType={4}
        documentId={ref.documentId}
        onHide={() => setRef({ ...ref, show: false })}
      />

      <Operation
        show={modalCreate.show}
        onHide={() => setModalCreate({ ...modalCreate, show: false })}
        deposit
        refresh={() =>
          dispatch(getMovementMatchDeposit(modalCreate.id, 1, modalCreate.currency))
        }
        amount={modalCreate.amount}
      />

      <Folder
        noMessage
        onHide={() => setShowCategorization({ show: false })}
        onAssign={item => {
          if (showCategorization.filter) {
            let d = selectedCategories.find(d => d.id === item.id)
            if (!d) {
              selectedCategories.push(item)
              setSelectedCategories([...selectedCategories])
            }
          } else assignCategorizations(item.id)
        }}
        data1={
          categorization && categorization.children ? categorization.children[0] : {}
        }
        data2={
          categorization && categorization.children ? categorization.children[1] : {}
        }
        show={showCategorization.show}
        loading={loadingAssigned}
      />

      <Deposit
        show={modalDeposit.show}
        onHide={refresh => {
          if (refresh) setUp()
          setModalDeposit({ show: false, item: null })
        }}
        depositDetail={modalDeposit.item}
        loading={loadingCreate}
      />

      <FAB
        show={selected.length > 0}
        tooltip={'Asignar categorías'}
        icon={faTags}
        onClick={() => {
          setShowCategorization({ show: true })
        }}
        mainButtonStyles={{ backgroundColor: '#B35796' }}
      />

      <Alert {...alert} />
    </div>
  )
}
export default DepositPage
